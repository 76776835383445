import React, { useState, useEffect } from "react";
import { useParams, withRouter } from "react-router";
import { Grid, Button, makeStyles, TextField } from "@material-ui/core";
import { API_URL } from "../../../common/constants";
import axios from "axios";
import PopLogo from "../../../assets/images/logo.png";

import authStyles from "../Login/styles/login";

const useStyles = makeStyles(authStyles);

const colorBrand = "rgba(250, 128, 114,1)";
const colorInfluencer = "#825DFF";

const ResetPasswordForm = withRouter(({ history }) => {
  const classes = useStyles();
  const { token, type } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPasswordError] = useState(false);
  const [isInfluencer, setIsInfluencer] = useState(false);
  const [passwordDifferenceError, setPasswordDifferenceError] = useState(false);

  async function handleOnResetPassword(e) {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setPasswordDifferenceError(true);
      return null;
    }
    await axios.post(`${API_URL}/${type}/auth/reset`, {
      token,
      password: newPassword,
    });

    history.push(`/login/${type}`);
  }

  useEffect(() => {
    localStorage.setItem("token", "");
  }, [token]);

  useEffect(() => {
    setIsInfluencer(type === "influencer");
  }, [type]);

  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={5}
      lg={5}
      style={{
        marginTop: 150,
        boxShadow: "0 0px 50px rgba(0, 0, 0, 0.2)",
        borderRadius: 5,
        marginLeft: 10,
        marginRight: 10,
      }}
    >
      <div
        className={
          isInfluencer ? classes.coloredHeader : classes.coloredHeaderBrand
        }
      ></div>
      <form onSubmit={handleOnResetPassword}>
        <Grid
          container
          justify="space-around"
          alignItems="center"
          style={{ textAlign: "center" }}
        >
          <div className={classes.popLogo}>
            <a href="/">
              <img alt="logo" src={PopLogo} style={{ height: 60 }} />
            </a>
          </div>
          <Grid item xs={12} style={{ marginBottom: 40, textAlign: "center" }}>
            <p style={{ fontFamily: "nunito-bold", fontSize: 20 }}>
              Quel est votre email pour réinitialiser votre mot de passe ?
            </p>
          </Grid>
          <Grid item xs={8} style={{ marginBottom: 20 }}>
            <TextField
              fullWidth
              inputProps={{ style: { padding: "8.5px 10px" } }}
              id="newPassword"
              name="newPassword"
              variant="outlined"
              type="password"
              InputLabelProps={{
                shrink: true,
              }}
              label="Nouveau mot de passe"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              error={newPasswordError}
              helperText={
                newPasswordError && "Le mot de passe est obligatoire."
              }
            />
          </Grid>
          <Grid item xs={8} style={{ marginBottom: 20 }}>
            <TextField
              fullWidth
              inputProps={{ style: { padding: "8.5px 10px" } }}
              id="confirmPassword"
              name="confirmPassword"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              type="password"
              label="Confirmer le mot de passe"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              error={passwordDifferenceError}
              helperText={
                passwordDifferenceError && "Les mots de passe sont différents."
              }
            />
          </Grid>
          <Grid
            item
            xs={8}
            style={{ marginTop: 20, marginBottom: 20, textAlign: "center" }}
          >
            <Button
              style={{
                fontFamily: "nunito-bold",
                fontSize: 13,
                color: "#FFF",
                width: "100%",
                height: 40,
                paddingLeft: 20,
                paddingRight: 20,
                borderRadius: 18,
                textTransform: "none",
                backgroundColor: isInfluencer ? colorInfluencer : colorBrand,
              }}
              type="submit"
            >
              Réinitaliser mon mot de passe
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
});

export default ResetPasswordForm;

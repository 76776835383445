import React from "react";
import { Helmet } from "react-helmet";
import Login from "../../../Components/Auth/Login/Login";

const LoginWrapper = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>POP App - Connexion Marque</title>
        <link rel="canonical" href="https://pop-app.fr" />
      </Helmet>
      <Login isInfluencer={false} />;
    </>
  );
};

export default LoginWrapper;

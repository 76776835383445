import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useQueryParam, StringParam } from "use-query-params";

import { CircularProgress, Grid, Hidden } from "@material-ui/core";

import messagesStyles from "./styles/messagesStyle";
import ContactListing from "./ContactListing/ContactListing";
import Discussion from "./Discussion/Discussion";

import NewOfferModal from "./modals/NewOffer";
import axios from "axios";
import { API_URL } from "../../../common/constants";
import ContactHeader from "./ContactHeader/ContactHeader";
import ContactBrandHeader from "./ContactHeader/ContactBrandHeader";

const useStyles = makeStyles(messagesStyles);

const MessagesContainer = ({
  isInfluencer,
  missionId,
  missionInfluencers,
  handleRefreshMission,
  mission = {},
  brand = {},
}) => {
  const classes = useStyles();

  const [influencerParam, setInfluencerParam] = useQueryParam(
    "influencer",
    StringParam
  );

  const [discussion, setDiscussion] = useState([]);
  const [selectedInfluencer, setSelectedInfluencer] = useState(undefined);

  const [isNewOfferModalOpen, setIsNewOfferModalOpen] = useState(false);

  const getDiscussionBrand = (influencerId) => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .get(
        `${API_URL}/brand/missions/${missionId}/messages/influencer/${influencerId}`
      )
      .then((res) => {
        setDiscussion(res.data);
      });
  };

  const getDiscussionInfluencer = () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .get(`${API_URL}/influencer/missions/${missionId}/messages`)
      .then((res) => {
        setDiscussion(res.data);
      });
  };

  useEffect(() => {
    if (isInfluencer) {
      getDiscussionInfluencer();
    } else {
      if (missionInfluencers && missionInfluencers.length > 0) {
        const influencer = missionInfluencers.find(
          (mInf) => mInf.influencerId._id === influencerParam
        );

        if (influencer) {
          setSelectedInfluencer(influencer.influencerId);
          getDiscussionBrand(influencer.influencerId._id);
        } else if (
          missionInfluencers.some((influencer) => influencer.startDiscussion)
        ) {
          setSelectedInfluencer(
            missionInfluencers.filter(
              (inf) =>
                inf?.influencerStatus !== "DONE" &&
                !inf?.followProcess?.influencerEvaluation?.rating
            )[0].influencerId
          );
          getDiscussionBrand(
            missionInfluencers.filter(
              (inf) =>
                inf?.influencerStatus !== "DONE" &&
                !inf?.followProcess?.influencerEvaluation?.rating
            )[0].influencerId._id
          );
        }
      }
    }
  }, [missionId]);

  if (!isInfluencer && !selectedInfluencer) return <CircularProgress />;

  return (
    <Grid container className={classes.pageLayout} style={{ maxHeight: 800 }}>
      {!isInfluencer && (
        <Hidden only={["sm", "xs"]}>
          <Grid
            item
            container
            xs={4}
            style={{ maxheight: 800 }}
            className={classes.contactListing}
            justify="center"
            alignItems="flex-start"
          >
            <ContactListing
              missionInfluencers={missionInfluencers}
              handleSelectInfluencer={(influencerId) => {
                setInfluencerParam(influencerId._id);
                setSelectedInfluencer(influencerId);
                getDiscussionBrand(influencerId._id);
              }}
              selectedInfluencer={selectedInfluencer}
            />
          </Grid>
        </Hidden>
      )}
      <Grid
        item
        container
        xs={isInfluencer ? 12 : 12}
        sm={isInfluencer ? 12 : 12}
        md={isInfluencer ? 12 : 8}
        lg={isInfluencer ? 12 : 8}
      >
        {!isInfluencer && (
          <ContactHeader
            selectedInfluencer={selectedInfluencer}
            setIsNewOfferModalOpen={setIsNewOfferModalOpen}
            influencerMission={missionInfluencers.find(
              (inf) => inf.influencerId._id === selectedInfluencer._id
            )}
            handleRefreshFollowProcess={handleRefreshMission}
            mission={mission}
          />
        )}
        {isInfluencer && (
          <ContactBrandHeader
            brand={brand}
            discussionLength={discussion.length}
          />
        )}
        <Grid item container xs={12} alignItems="flex-end">
          <Discussion
            isInfluencer={isInfluencer}
            discussion={discussion}
            missionId={missionId}
            influencerId={selectedInfluencer ? selectedInfluencer._id : ""}
            handleRefreshDiscussion={() =>
              isInfluencer
                ? getDiscussionInfluencer()
                : getDiscussionBrand(selectedInfluencer._id)
            }
            influencerMission={missionInfluencers?.find(
              (inf) => inf.influencerId._id === selectedInfluencer._id
            )}
            handleRefreshMission={handleRefreshMission}
          />
        </Grid>
      </Grid>
      <NewOfferModal
        isModalOpen={isNewOfferModalOpen}
        handleCloseModal={setIsNewOfferModalOpen}
        missionId={missionId}
        influencerId={selectedInfluencer ? selectedInfluencer._id : ""}
        handleRefreshDiscussion={() =>
          getDiscussionBrand(selectedInfluencer._id)
        }
      />
    </Grid>
  );
};

MessagesContainer.propTypes = {};

export default MessagesContainer;

// ####################################
// // // Profile Page styles
// ###################################

const profilePageStyle = (theme) => ({
  pageLayout: {
    margin: "auto",
  },
  widgetContainer: {
    marginBottom: 30,
    backgroundColor: "#FFF",
    padding: 40,
    borderRadius: 6,
    position: "relative",
    cursor: "pointer",
  },
  widgetTitle: {
    fontSize: 28,
    fontFamily: "nunito-bold",
    fontWeight: 700,
    marginBottom: 20,
    color: "#333",
  },
  widgetTextContent: {
    fontSize: 17,
    fontWeight: 400,
    whiteSpace: "pre-line",
  },
  widgetActivityContainer: {
    marginBottom: 100,
    marginTop: 20,
    backgroundColor: "#FFF",
    padding: 40,
    borderRadius: 6,
    position: "relative",
  },
  widgetDescriptionEditable: {
    borderRadius: 6,
    cursor: "pointer",
    padding: 15,
    "&:hover": {
      backgroundColor: "rgba(130,93,255,0.1)",
    },
  },
  emptyContent: {
    borderRadius: 6,
    padding: 15,
    border: "1px solid rgba(130,93,255,0.1)",
    color: "#888",
    fontStyle: "italic",
    "&:hover": {
      backgroundColor: "rgba(130,93,255,0.1)",
    },
  },
  widgetDescription: {
    borderRadius: 6,
    padding: 15,
    cursor: "default",
  },
  widgetCompanyTitle: {
    fontSize: 20,
    fontWeight: 600,
  },
  widgetCompanyDescription: {
    fontSize: 14,
    marginTop: 15,
  },
  companyContentContainer: {
    marginBottom: 30,
    paddingBottom: 30,
    borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
    cursor: "default",
    padding: 10,
  },
  companyContentContainerEditable: {
    marginBottom: 30,
    paddingBottom: 30,
    borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    padding: 10,
    "&:hover": {
      backgroundColor: "rgba(130,93,255,0.1)",
      borderRadius: 6,
    },
  },
  noticeContentContainer: {
    marginBottom: 30,
    paddingBottom: 30,
    cursor: "pointer",
    padding: 10,
  },
  widgetPostDate: {
    fontSize: 12,
    color: "rgba(0, 0, 0, 0.4)",
    marginLeft: 10,
  },
  userPicture: {
    width: 120,
    height: 120,
    objectFit: "fill",
  },
  pictureContainer: {},
  fieldLabel: {
    fontSize: 18,
    fontWeight: 400,
    marginBottom: 10,
    color: "#484848",
  },
  personalInformationContainer: {
    marginBottom: 50,
    minHeight: 300,
    backgroundColor: "#FFF",
    borderRadius: 6,
    position: "relative",
    [theme.breakpoints.up("md")]: {
      padding: 40,
    },
  },
});

export default profilePageStyle;

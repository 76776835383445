import React, { useState } from "react";
import {
  useParams,
  Route,
  Switch,
  useHistory,
  NavLink,
  Redirect,
} from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

import {
  Grid,
  Avatar,
  Chip,
  CircularProgress,
  Button,
  Snackbar,
  Tabs,
  Tab,
} from "@material-ui/core";

import MissionSidenav from "../../../Common/MissionSidenav/MissionSidenav";
import MissionDetails from "./MissionDetails/MissionDetails";

import missionPageStyle from "./styles/missionPageStyles";
import MessagesContainer from "../../../Common/Messages/MessagesContainer";
import InfluencerList from "./InfluencerList/InfluencerList";

import {
  DescriptionOutlined,
  MessageOutlined,
  PeopleAltOutlined,
} from "@material-ui/icons";
import { getFormattedSocialNetworks } from "../../../Influencer/InfluencerProfilePage/PersonalInformation/utils";
import axios from "axios";
import { API_URL } from "../../../../common/constants";
import { StringParam, useQueryParam } from "use-query-params";
import { useEffect } from "react";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles(missionPageStyle);

const noInfluencersMenu = [
  {
    id: 1,
    title: "Details",
    icon: <DescriptionOutlined />,
    component: <MissionDetails />,
  },
  {
    id: 2,
    title: "Les influenceurs",
    icon: <PeopleAltOutlined />,
    component: <MessagesContainer />,
  },
];

const fullMenu = [
  {
    id: 1,
    title: "Details",
    icon: <DescriptionOutlined />,
    component: <MissionDetails />,
  },
  {
    id: 2,
    title: "Les influenceurs",
    icon: <PeopleAltOutlined />,
    component: <InfluencerList />,
  },
  {
    id: 3,
    title: "Messages",
    icon: <MessageOutlined />,
    component: <MessagesContainer />,
  },
];

// const routes = [
//   {
//     icon: <DescriptionOutlined />,
//     label: "Détails",
//     to: "/details",
//     path: "/brand/missions/:id/details",
//     component: MissionDetails,
//     exact: true,
//   },
//   {
//     icon: <PeopleAltOutlined />,
//     label: "Les influenceurs",
//     to: "/influencers",
//     path: "/brand/missions/:id/influencers",
//     component: InfluencerList,
//     exact: true,
//   },
//   {
//     icon: <MessageOutlined />,
//     label: "Messages",
//     to: "/messages",
//     path: "/brand/missions/:id/messages",
//     component: MessagesContainer,
//     exact: true,
//   },
// ];

const BrandMissionPage = ({
  mission,
  isLoading,
  handleRefreshMission,
  routes,
  handleUpdateMissionBriefs,
}) => {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();

  const [selectedStep, setSelectedStep] = useState(0);
  const [statusParam] = useQueryParam("status", StringParam);
  const [sessionParam] = useQueryParam("session", StringParam);
  const [offerParam] = useQueryParam("offerId", StringParam);

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const [selectedMenuItem, setSelectedMenuItem] = useState({
    id: 1,
  });

  const handleOnActivateMission = () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .put(`${API_URL}/brand/missions/${mission.mission._id}/activate`)
      .then(() => {
        handleRefreshMission();
        history.push(`/brand/missions/${mission.mission._id}/influencers`);
      })
      .catch((error) => console.error(error));
  };

  const checkStatusMissionOffer = () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .post(
        `${API_URL}/brand/missions/${id}/offer/success/${sessionParam}/${offerParam}`
      )
      .then(() => {
        setIsSnackbarOpen(true);
        handleRefreshMission();
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (statusParam === "success") {
      checkStatusMissionOffer();
    }
  }, [statusParam]);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile = width <= 768;

  useEffect(() => {
    let paths = history.location.pathname.split("/");
    paths = paths.filter((path) => path.length > 0);

    const index = routes.findIndex(
      (route) => route.to === `/${paths[paths.length - 1]}`
    );
    setSelectedStep(index);
  }, [history.location.pathname]);

  if (isLoading) return <CircularProgress />;

  return (
    <>
      {mission.mission.status === "DONE" && (
        <Alert
          severity="info"
          variant="filled"
          style={{
            justifyContent: "center",
            fontSize: 20,
            alignItems: "center",
          }}
        >
          Cette mission est terminée.
        </Alert>
      )}
      <Grid
        item
        container
        style={{
          marginBottom: 30,
          background: "#FFF",
          minHeight: 200,
          padding: 40,
          borderRadius: 6,
          position: "relative",
        }}
      >
        <Grid
          item
          container
          className={classes.pageLayout}
          justify="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Avatar
              className={classes.companyAvatar}
              src={mission.mission.thumbnail ?? mission.brand.avatar}
            ></Avatar>
          </Grid>
          <Grid item container xs={12} sm={12} md={8} lg={8} justify="center">
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <p className={classes.brandLabel}>{mission.mission.title}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <p className={classes.widgetTextContent}>
                {mission.mission.description}
              </p>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={8}
              lg={8}
              justify="flex-start"
              style={{ marginTop: 20 }}
            >
              {getFormattedSocialNetworks(mission.brand.socialNetworks)?.map(
                (socialNetwork) => (
                  <Grid
                    item
                    xs={5}
                    sm={5}
                    md={4}
                    lg={4}
                    style={{ marginBottom: 5, marginLeft: 5 }}
                    xl={3}
                    key={socialNetwork.value}
                  >
                    <a
                      href={`${socialNetwork.link}/${socialNetwork.username}`}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none", cursor: "pointer" }}
                    >
                      <Chip
                        style={{
                          background: socialNetwork.background,
                          color: "#FFF",
                          cursor: "pointer",
                        }}
                        icon={socialNetwork.icon}
                        label={socialNetwork.label}
                      />
                    </a>
                  </Grid>
                )
              )}
            </Grid>
            {mission.mission.status === "INACTIVE" && (
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={8}
                lg={8}
                justify="flex-start"
                style={{ paddingLeft: 0 }}
                className={classes.buttonSection}
              >
                <Grid item style={{ paddingLeft: 0 }}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleOnActivateMission();
                    }}
                    style={{
                      backgroundColor: "rgba(250, 128, 114,1)",
                      borderRadius: 18,
                    }}
                  >
                    Activer la campagne
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={() => history.push(`/brand/update-mission/${id}`)}
                    style={{
                      border: "1px solid rgba(250, 128, 114,1)",
                      color: "rgba(250, 128, 114,1)",
                      boxShadow: "none",
                      background: "transparent",
                      borderRadius: 18,
                      marginLeft: 10,
                    }}
                  >
                    <p>Modifier la campagne</p>
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {isMobile && (
        <Grid item xs={12}>
          <Tabs
            value={selectedStep}
            variant="fullWidth"
            scrollButtons={false}
            onChange={(e, value) => {
              setSelectedStep(value);
              // history.push(`/brand/missions/${id}${routes[value].to}`);
            }}
          >
            {routes.map((config, index) => (
              <Tab
                style={{
                  textDecoration: "none",
                  textTransform: "none",
                  fontSize: 16,
                  fontFamily: "nunito-semibold",
                }}
                icon={config.icon}
                className={classes.mobileTab}
                label={config.label}
                value={index}
                component={NavLink}
                exact={config.exact}
                to={`/brand/missions/${id}${config.to}`}
              />
            ))}
          </Tabs>
        </Grid>
      )}
      <Grid container className={classes.missionContentContainer}>
        {!isMobile && (
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={2}
            lg={2}
            style={{
              border: "1px solid rgba(0, 0, 0, 0.12)",
              height: "fit-content",
              background: "#FFF",
            }}
          >
            <MissionSidenav
              missionId={id}
              selectedMenuItem={selectedMenuItem}
              setSelectedMenuItem={setSelectedMenuItem}
              configMenu={
                mission.influencers && mission.influencers.length === 0
                  ? noInfluencersMenu
                  : fullMenu
              }
              isInfluencer={false}
              routes={routes}
            />
          </Grid>
        )}
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={10}
          lg={10}
          alignItems="flex-start"
          style={{
            maxHeight: "100%",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            background: "#FFF",
            minHeight: 550,
          }}
        >
          <Switch>
            {routes
              .map((prop) => {
                if (!prop.disabled) {
                  return (
                    <Route
                      key={prop.path}
                      render={(props) => (
                        <prop.component
                          {...props}
                          mission={mission.mission}
                          influencers={mission.influencers}
                          isInfluencer={false}
                          missionId={mission.mission._id}
                          missionInfluencers={mission.influencers}
                          briefs={mission.mission.briefs}
                          handleRefreshMission={handleRefreshMission}
                          handleUpdateMissionBriefs={handleUpdateMissionBriefs}
                        />
                      )}
                      exact={prop.exact}
                      path={prop.path}
                    />
                  );
                }
              })
              .filter((value) => value)}
            <Redirect
              from={"/brand/missions/:id"}
              to={"/brand/missions/:id/details"}
            />
          </Switch>
        </Grid>
      </Grid>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        style={{ zIndex: 1400, paddingTop: 60 }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setIsSnackbarOpen(false)}
          severity="success"
          variant="filled"
        >
          Votre paiement a été accepté !
        </Alert>
      </Snackbar>
    </>
  );
};

BrandMissionPage.propTypes = {};

export default BrandMissionPage;

import axios from "axios";
import React from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import { API_URL } from "../../../common/constants";
import NewMissionForm from "../../../Components/Brand/NewMission/NewMissionForm";

const NewMission = () => {
  const history = useHistory();
  const handleSaveNewMission = async (values) => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    let thumbnail = null;
    if (values.thumbnail) {
      let formData = new FormData();

      formData.append("file", values.thumbnail);
      const res = await axios.post(`${API_URL}/images/mission`, formData);
      thumbnail = `https://pop-influence.s3.us-east-2.amazonaws.com/${res.data.filename}`;
    }

    const finalBriefs =
      (await Promise.all(
        values?.briefs.map(async (brief) => {
          if ((brief?.files ?? [])?.length === 0) return brief;
          return {
            ...brief,
            files: await Promise.all(
              brief?.files?.map(async (file) => {
                if (typeof file === "string") return file;
                let formData = new FormData();

                formData.append("file", file);

                const res = await axios.post(
                  `${API_URL}/images/brief`,
                  formData
                );
                const newFile = `https://pop-influence.s3.us-east-2.amazonaws.com/${res.data.filename}`;
                return newFile;
              })
            ),
          };
        })
      )) ?? [];

    axios
      .post(`${API_URL}/brand/missions`, {
        thumbnail,
        title: values.title,
        description: values.description,
        influencerCaracteristics: {
          age: values.age,
          categories: values.categories.map((value) => value.value),
          gender: values.gender.map((value) => value.value),
          location: values.location,
        },
        socialNetworks: values.socialNetworks.map((network) => ({
          socialNetwork: network.value,
          followers: network.followers,
          prices: network.prices,
        })),
        isGifting: values.isGifting ?? false,
        target: values.target,
        briefs: finalBriefs,
      })
      .then((res) => {
        history.push("/brand/missions");
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>POP App - Création de campagne</title>
        <link rel="canonical" href="https://pop-app.fr" />
      </Helmet>
      <NewMissionForm handleSaveMission={handleSaveNewMission} />
    </>
  );
};

export default NewMission;

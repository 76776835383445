import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Collapse,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import newMissionStyle from "../styles/newMissionStyle";
import {
  AddOutlined,
  CloudUpload,
  Delete,
  ExpandMore,
} from "@material-ui/icons";

const useStyles = makeStyles(newMissionStyle);

const CompleteBrief = ({ formik }) => {
  const classes = useStyles();

  const [editingBrief, setEditingBrief] = useState(0);
  const [openedBrief, setOpenedBrief] = useState(0);
  // const [briefsData, setBriefsData] = useState([]);

  const handleAddBrief = () => {
    formik.setFieldValue("briefs", [
      ...formik.values.briefs,
      {
        title: "",
        content: "",
      },
    ]);
    setOpenedBrief(formik.values.briefs.length);
  };

  function handleDeleteBrief(key) {
    const briefs = [...formik.values.briefs];

    briefs.splice(key, 1);
    formik.setFieldValue("briefs", briefs);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <label className={classes.socialNetworkLabel}>
          Création du brief complet
        </label>
        <div className={classes.socialNetworkBorder}></div>
      </Grid>
      <Grid item xs={12}>
        <p
          style={{
            color: "#888",
            fontStyle: "italic",
            fontSize: 14,
          }}
        >
          Créez ou ajoutez vos briefs pour pouvoir les partager avec les
          influenceurs de votre choix.
        </p>
      </Grid>
      <Grid item container xs={12}>
        <Button onClick={() => handleAddBrief()} startIcon={<AddOutlined />}>
          Ajouter un brief
        </Button>
      </Grid>

      {formik.values.briefs.map((brief, key) => {
        return (
          <Accordion
            // style={{ width: "100%", borderRadius: 4, marginTop: 20,  }}
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              width: "100%",
              marginTop: 20,
              borderRadius: 10,
            }}
            expanded={openedBrief === key}
            onChange={() => {
              if (openedBrief !== key) {
                setOpenedBrief(key);
              }
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMore
                  onClick={() =>
                    setOpenedBrief((openedBrief) =>
                      openedBrief === key ? -1 : key
                    )
                  }
                />
              }
            >
              {openedBrief !== key ? (
                <Typography style={{ fontFamily: "nunito-bold" }}>
                  {brief.title?.length > 0
                    ? `${brief.title}`
                    : `Brief ${key + 1}`}
                </Typography>
              ) : (
                <TextField
                  fullWidth
                  inputProps={{ style: { padding: "8.5px 10px" } }}
                  id="title"
                  name="title"
                  variant="outlined"
                  label="Nom du brief"
                  value={brief.title}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    formik.setFieldValue(`briefs[${key}].title`, e.target.value)
                  }
                  helperText={
                    formik.errors.briefs?.length > 0 &&
                    formik.errors?.briefs[key]?.title
                  }
                  error={Boolean(
                    formik.errors.briefs?.length > 0 &&
                      formik.errors?.briefs[key]?.title
                  )}
                />
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Grid container xs={12}>
                {/* <Grid item container xs={12} style={{ marginBottom: 30 }}>
                  <label className={classes.fieldLabel}>Titre du brief</label>
                  <TextField
                    fullWidth
                    inputProps={{ style: { padding: "8.5px 10px" } }}
                    id="title"
                    name="title"
                    variant="outlined"
                    value={brief.title}
                    onChange={(e) =>
                      formik.setFieldValue(
                        `briefs[${key}].title`,
                        e.target.value
                      )
                    }
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <CKEditor
                    editor={ClassicEditor}
                    config={{
                      placeholder:
                        "Redigez votre brief ou ajoutez vos propres documents",
                    }}
                    data={brief.content}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();

                      // formik.setFieldValue("briefs", briefData);
                      formik.setFieldValue(`briefs[${key}].content`, data);
                    }}
                    onBlur={(event, editor) => {}}
                    onFocus={(event, editor) => {}}
                  />
                  {formik.errors.briefs?.length > 0 &&
                    formik.errors?.briefs[key]?.title && (
                      <p
                        style={{
                          marginTop: 10,
                          color: "#f44336",
                          fontFamily: "nunito-regular",
                          fontSize: 14,
                          textAlign: "left",
                        }}
                      >
                        Le contenu du brief est obligatoire
                      </p>
                    )}
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <label htmlFor={`contained-button-file-${key}`}>
                    <input
                      style={{ display: "none" }}
                      id={`contained-button-file-${key}`}
                      multiple
                      type="file"
                      onChange={(e) => {
                        let files = e.target.files;
                        formik.setFieldValue(`briefs[${key}].files`, [
                          ...files,
                          ...(brief?.files ?? []),
                        ]);
                        // handleOnEditBrief(
                        //   [...files, ...(brief?.files ?? [])],
                        //   "files",
                        //   key
                        // );
                      }}
                    />
                    <Button
                      variant="contained"
                      color="default"
                      className={classes.button}
                      startIcon={<CloudUpload />}
                      component="span"
                    >
                      Ajouter des fichiers
                    </Button>
                  </label>
                  <Grid item container xs={12} style={{ marginTop: 20 }}>
                    {Array.from(brief?.files ?? []).map((data, index) => {
                      return (
                        <Grid item key={index}>
                          <Chip
                            label={
                              typeof data === "string"
                                ? data.replace(
                                    "https://pop-influence.s3.us-east-2.amazonaws.com/briefs/",
                                    ""
                                  )
                                : data.name
                            }
                            onDelete={() => {
                              const tmpFiles = [...brief.files];
                              tmpFiles.splice(index, 1);

                              formik.setFieldValue(`briefs[${key}].files`, [
                                ...tmpFiles,
                              ]);
                              // setFiles(tmpFiles);
                              // handleOnEditBrief(
                              //   [...tmpFiles],
                              //   "files",
                              //   index
                              // );
                            }}
                            style={{ margin: 5 }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid item xs={4} style={{ marginTop: 40 }}>
                  <Button
                    variant="outlined"
                    startIcon={<Delete />}
                    style={{
                      border: "1px solid rgba(250, 128, 114,1)",
                      color: "rgba(250, 128, 114,1)",
                      boxShadow: "none",
                      background: "transparent",
                      borderRadius: 18,
                    }}
                    className="button-new-mission"
                    onClick={() => handleDeleteBrief(key)}
                  >
                    Supprimer
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          // <Grid key={key} item container xs={12}>
          //   <Grid item xs={12} style={{ marginBottom: 10 }}>
          //     <Button
          //       onClick={() => setEditingBrief(key)}
          //       style={{ textDecoration: "underline" }}
          //       variant="contained"
          //     >
          //       Brief {key + 1}
          //     </Button>
          //   </Grid>
          //   <Collapse
          //     key={key}
          //     in={key === editingBrief}
          //     timeout="auto"
          //     unmountOnExit
          //     style={{ width: "100%" }}
          //   >
          //     <Grid item container xs={12}>
          //       <Grid item container xs={12} style={{ marginBottom: 30 }}>
          //         <label className={classes.fieldLabel}>Titre du brief</label>
          //         <TextField
          //           fullWidth
          //           inputProps={{ style: { padding: "8.5px 10px" } }}
          //           id="title"
          //           name="title"
          //           variant="outlined"
          //           value={brief.title}
          //           onChange={(e) =>
          //             formik.setFieldValue(
          //               `briefs[${key}].title`,
          //               e.target.value
          //             )
          //           }
          //         />
          //       </Grid>

          //       <Grid item xs={12}>
          //         <CKEditor
          //           editor={ClassicEditor}
          //           data={brief.content}
          //           onReady={(editor) => {
          //             // You can store the "editor" and use when it is needed.
          //             console.log("Editor is ready to use!", editor);
          //           }}
          //           onChange={(event, editor) => {
          //             const data = editor.getData();

          //             // formik.setFieldValue("briefs", briefData);
          //             formik.setFieldValue(`briefs[${key}].content`, data);
          //             console.log({ event, editor, data });
          //           }}
          //           onBlur={(event, editor) => {
          //             console.log("Blur.", editor);
          //           }}
          //           onFocus={(event, editor) => {
          //             console.log("Focus.", editor);
          //           }}
          //         />
          //       </Grid>
          //     </Grid>
          //   </Collapse>
          // </Grid>
        );
      })}

      {/* <Grid item container xs={12}>
        <label className={classes.fieldLabel}>Titre du brief</label>
        <TextField
          fullWidth
          inputProps={{ style: { padding: "8.5px 10px" } }}
          id="title"
          name="title"
          variant="outlined"
          value={formik.values.briefTitle}
          onChange={formik.handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <CKEditor
          editor={ClassicEditor}
          data="<p>Donnez autant d'information que possible à l'influenceur.</p></br><p>Ce brief pourra être envoyé à l'influenceur que vous aurez sélectionné pour votre campagne.</p>"
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            console.log("Editor is ready to use!", editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();

            // setBriefData(data);
            // formik.setFieldValue("briefs", briefData);
            console.log({ event, editor, data });
          }}
          onBlur={(event, editor) => {
            console.log("Blur.", editor);
          }}
          onFocus={(event, editor) => {
            console.log("Focus.", editor);
          }}
        />
      </Grid> */}
    </>
  );
};

export default CompleteBrief;

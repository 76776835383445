// ####################################
// // // Personal Information styles
// ###################################

const widgetStyle = (theme) => ({
  pageLayout: {
    margin: "auto",
  },
  pageLayoutForm: {
    width: "80%",
    margin: "auto",
  },
  userAvatar: {
    width: 220,
    height: 220,
    "& img": {
      objectFit: "cover !important",
    },
    [theme.breakpoints.up("md")]: {
      float: "right",
    },
    [theme.breakpoints.down("md")]: {
      margin: "auto",
    },
  },
  userAvatarEditable: {
    width: 220,
    height: 220,
    [theme.breakpoints.up("md")]: {
      float: "right",
    },
    [theme.breakpoints.down("md")]: {
      margin: "auto",
      marginTop: 20,
    },
    cursor: "pointer",
    "& > img": {
      objectFit: "cover !important",
    },
    "&:hover": {
      backgroundColor: "rgba(130,93,255,0.3)",
    },
    "&:hover > img": {
      opacity: 0.6,
    },
  },
  userName: {
    fontWeight: 500,
    fontSize: 36,
    marginBottom: 10,
    fontFamily: "nunito-bold",
  },
  profileInfo: {
    height: 100,
    border: "1px solid #e0ded9",
    padding: "10px 15px",
    borderRadius: 6,
  },
  editableContainer: {
    "& div .editable": {
      display: "none",
    },
    "&:hover div .editable": {
      display: "block",
    },
  },
  notEditableContainer: {
    "& div .editable": {
      display: "none",
    },
  },
  profileInfoEditable: {
    height: 100,
    border: "1px solid #e0ded9",
    padding: "10px 15px",
    borderRadius: 6,
    "&:hover": {
      backgroundColor: "rgba(130,93,255,0.1)",
    },
  },
  infoTitle: {
    paddingLeft: 0,
    padding: 5,
  },
  infoContent: {
    padding: 5,
  },
  fieldLabel: {
    fontSize: 18,
    fontWeight: 400,
    marginBottom: 10,
    color: "#484848",
  },
  checked: {
    color: "#76CD99 !important",
  },
  track: {
    backgroundColor: "rgba(18, 207, 201, 0.4) !important",
  },
  availabilityDotActive: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    borderStyle: "solid",
    borderColor: "#76CD99",
    backgroundColor: "#76CD99",
    marginLeft: 30,
    marginBottom: 5,
  },
  availabilityDotNotActive: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    borderStyle: "solid",
    borderColor: "#ff5c57",
    backgroundColor: "#ff5c57",
    marginLeft: 30,
    marginBottom: 5,
  },
});

export default widgetStyle;

/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Grid, Button, Hidden } from "@material-ui/core";
import { NavLink, Switch, Route, Redirect, useHistory } from "react-router-dom";
import { Menu, MenuOpen } from "@material-ui/icons";

import { makeStyles } from "@material-ui/styles";

import PopLogo from "../../assets/images/logo.png";

import Image1 from "./assets/step1.png";
import Image2 from "./assets/step2.png";
import KesakoImage from "./assets/Kesako.png";
import SaveTimeImage from "./assets/save-time.png";
import GrowthImage from "./assets/growth.png";
import TargetImage from "./assets/target.png";
import CheckImage from "./assets/check.png";
import VisibilityPercent from "./assets/visibility-percent.png";
import VisibilityPic from "./assets/visibility-pic.png";
import InfluencerPic from "./assets/influencer-pic.png";
import OurClients from "./assets/our-clients.png";
// import DefaultCard from "../../assets/images/defaultCard.jpeg";

import showcaseStyle from "./styles/showcaseStyle";
import Showcase from "../../Components/Showcase/Showcase";
import authRoutes from "../../routes/authRoutes";
// import Pricing from "./Pricing";

const useStyles = makeStyles(showcaseStyle);

function useOutsideAlerter(ref, setOpenNav) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenNav(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const ShowcaseWrapper = () => {
  const classes = useStyles();
  const history = useHistory();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setMobileMenuOpen);

  const [sticky, setSticky] = useState({});
  const stickyRef = useRef(null);

  // handle scroll event
  const handleScroll = (elTopOffset, elHeight) => {
    if (window.pageYOffset > elTopOffset + elHeight) {
      setSticky({ isSticky: true, offset: elHeight });
    } else {
      setSticky({ isSticky: false, offset: 0 });
    }
  };

  // add/remove scroll event listener
  useEffect(() => {
    var header = stickyRef.current?.getBoundingClientRect();
    const handleScrollEvent = () => {
      handleScroll(header?.top || 0, header?.height || 0);
    };

    window.addEventListener("scroll", handleScrollEvent);

    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);

  return (
    <>
      <Grid
        container
        justify="center"
        style={{
          height: "100%",
          // background: "#FFFFFF",
          // background: mobileMenuOpen ? "rgba(8,54,113,.4)" : "transparent",
          // background:
          //   "linear-gradient(to right top, rgba(250, 128, 165,0.3), rgba(247, 155, 197,0.3), rgba(243, 181, 222, 1), rgba(239, 205, 240,0.3), rgba(241, 228, 250,0.3), rgba(236, 223, 251,0.3), rgba(230, 219, 253,0.3), rgba(223, 215, 255,0.3), rgba(200, 185, 255,0.3), rgba(178, 155, 255,0.3), rgba(154, 125, 255,0.3), rgba(130, 93, 255,0.3))",
        }}
      >
        {mobileMenuOpen && (
          <Grid
            ref={wrapperRef}
            container
            direction="column"
            xs={12}
            style={{
              height: "90%",
              background: "#FFF",
              position: "fixed",
              textAlign: "center",
              bottom: 0,
              borderRadius: 10,
              zIndex: 3,
            }}
          >
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              style={{
                marginTop: 20,
                padding: 15,
                fontFamily: "nunito-bold",
              }}
              onClick={() => {
                history.push("/");
                setMobileMenuOpen(false);
                window.scrollTo(0, 0);
              }}
            >
              <p style={{ fontFamily: "nunito-bold" }}>Accueil</p>
            </Grid>{" "}
            <a
              href="/#contact-us"
              style={{
                textDecoration: "none",
                color: "#000",
              }}
            >
              <Grid
                item
                container
                justify="center"
                alignItems="center"
                style={{
                  padding: 15,
                  fontFamily: "nunito-bold",
                }}
                onClick={() => {
                  // history.push("/");
                  setMobileMenuOpen(false);
                }}
              >
                <p style={{ fontFamily: "nunito-bold" }}>Contact</p>
              </Grid>
            </a>
            <a
              href="/#faq"
              style={{
                textDecoration: "none",
                color: "#000",
              }}
            >
              <Grid
                item
                container
                justify="center"
                alignItems="center"
                style={{
                  padding: 15,
                  fontFamily: "nunito-bold",
                }}
                onClick={() => {
                  setMobileMenuOpen(false);
                }}
              >
                <p style={{ fontFamily: "nunito-bold" }}>FAQ</p>
              </Grid>
            </a>
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              style={{
                padding: 15,
                fontFamily: "nunito-bold",
                marginTop: 40,
              }}
            >
              <p
                style={{
                  fontFamily: "nunito-bold",
                  background: "rgba(130,93,255,1)",
                  borderRadius: 18,
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingRight: 100,
                  color: "#FFF",
                  paddingLeft: 100,
                }}
                onClick={() => {
                  history.push("/select-role/login");
                  setMobileMenuOpen(false);
                }}
              >
                Connexion
              </p>
            </Grid>
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              style={{
                padding: 15,
              }}
            >
              <p
                style={{
                  fontFamily: "nunito-bold",
                  background: "rgba(250, 128, 114,0.8)",
                  color: "#FFF",
                  borderRadius: 18,
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingRight: 100,
                  paddingLeft: 100,
                }}
                onClick={() => {
                  history.push("/select-role/register");
                  setMobileMenuOpen(false);
                }}
              >
                Inscription
              </p>
            </Grid>
          </Grid>
        )}
        <Grid container justify="center" style={{ margin: "auto" }}>
          <Hidden only={["md", "lg", "xl"]}>
            <Grid
              item
              container
              style={{
                position: "fixed",
                top: 0,
                borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
                paddingBottom: 10,
                background: "#FFFFFF",
                zIndex: 3,
              }}
              justify="space-between"
              alignItems="center"
            >
              <Grid
                item
                xs
                style={{ paddingTop: 10, paddingLeft: 30 }}
                onClick={() => history.push("/")}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push("/");
                    window.scrollTo(0, 0);
                  }}
                >
                  <img
                    alt="pop influence"
                    src={PopLogo}
                    style={{ height: 30 }}
                    title="POP, la plateforme qui permet aux marques de créer des campagnes d'influence sur mesure"
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={2}
                container
                style={{ paddingTop: 10, paddingRight: 30 }}
                alignItems="center"
                onClick={() => setMobileMenuOpen(true)}
              >
                {/* <NavLink
                  to="/select-role/login"
                  style={{ textDecoration: "none", marginRight: 20 }}
                >
                  <Grid item>
                    <p
                      style={{
                        fontFamily: "nunito-bold",
                        background: "rgba(130,93,255,0.7)",
                        borderRadius: 4,
                        paddingTop: 10,
                        paddingBottom: 10,
                        color: "#FFF",
                        paddingRight: 10,
                        paddingLeft: 10,
                      }}
                      onClick={() => {
                        history.push("/select-role/login");
                        setMobileMenuOpen(false);
                      }}
                    >
                      Connexion
                    </p>
                  </Grid>
                </NavLink> */}
                {mobileMenuOpen ? (
                  <MenuOpen style={{ width: 30, height: 30 }} />
                ) : (
                  <Menu style={{ width: 30, height: 30 }} />
                )}
              </Grid>
            </Grid>
          </Hidden>
          <Hidden only={["sm", "xs"]}>
            <Grid
              container
              // className={classes.topContainer}
              alignItems="flex-start"
            >
              <Grid item container>
                <Grid
                  item
                  container
                  style={{ boxShadow: "none", height: 100 }}
                  ref={stickyRef}
                  className={
                    sticky?.isSticky
                      ? classes.headerContainerSticky
                      : classes.headerContainer
                  }
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item container xs={7} alignItems="center">
                    <Grid item xs={2}>
                      <div
                        style={{ cursor: "pointer" }}
                        className={classes.popLogo}
                        onClick={() => {
                          history.push("/");
                          window.scrollTo(0, 0);
                        }}
                      >
                        <img
                          alt="pop influence"
                          src={PopLogo}
                          style={{ height: 60 }}
                          title="POP, la plateforme qui permet aux marques de créer des campagnes d'influence sur mesure"
                        />
                      </div>
                    </Grid>
                    <Grid item container xs={10} style={{ paddingLeft: 60 }}>
                      <NavLink
                        className={classes.menuLink}
                        to="/"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <Grid item>
                          <span className={classes.headerMenuText}>
                            Accueil
                          </span>
                        </Grid>
                      </NavLink>
                      <a className={classes.menuLink} href="/#contact-us">
                        <Grid item>
                          <span className={classes.headerMenuText}>
                            Contact
                          </span>
                        </Grid>
                      </a>
                      <a className={classes.menuLink} href="/#faq">
                        <Grid item>
                          <span className={classes.headerMenuText}>FAQ</span>
                        </Grid>
                      </a>
                    </Grid>
                  </Grid>
                  <Grid item container justify="flex-end" xs={5}>
                    <Grid
                      item
                      container
                      sm={7}
                      md={7}
                      lg={7}
                      xl={7}
                      justify="space-between"
                      alignItems="center"
                      className={classes.headerText}
                    >
                      <Grid item xs={5} style={{ textAlign: "center" }}>
                        <p
                          style={{
                            fontFamily: "nunito-bold",
                            background: "rgba(130,93,255,1)",
                            borderRadius: 18,
                            paddingTop: 10,
                            paddingBottom: 10,
                            cursor: "pointer",
                            color: "#FFF",
                          }}
                          onClick={() => {
                            history.push("/select-role/login");
                            setMobileMenuOpen(false);
                          }}
                        >
                          Connexion
                        </p>
                      </Grid>
                      <Grid item xs={5} style={{ textAlign: "center" }}>
                        <p
                          style={{
                            fontFamily: "nunito-bold",
                            background: "#FFF",
                            color: "rgba(250, 128, 114,1)",
                            border: "1px solid rgba(250, 128, 114,1)",
                            cursor: "pointer",
                            borderRadius: 18,
                            paddingTop: 10,
                            paddingBottom: 10,
                          }}
                          onClick={() => {
                            history.push("/select-role/register");
                            setMobileMenuOpen(false);
                          }}
                        >
                          Inscription
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          {!mobileMenuOpen && (
            <Switch>
              {authRoutes.map((prop, key) => {
                const path = prop.path.split("/");
                if (path[1] === "select-role") {
                  return (
                    <Route
                      exact
                      path={prop.path}
                      render={(props) => <prop.component type={path[2]} />}
                      key={key}
                    />
                  );
                }
                return (
                  <Route
                    exact
                    path={prop.path}
                    render={(props) => <prop.component />}
                    key={key}
                  />
                );
              })}
              <Redirect to="/" />
            </Switch>
          )}
          <Grid style={{ paddingTop: 100 }} item xs={12}></Grid>
          {/* <Pricing /> */}
          {/* <Grid item container className={classes.coloredFooter}> */}
          {/* <Hidden only={["sm", "xs"]}>
            <div className={classes.whiteFooter}>
              <Grid container xs={10} style={{ margin: "auto" }}>
                <Grid item xs={2}>
                  <div className={classes.popLogoFooter}>
                    <img alt="logo" src={PopLogo} style={{ height: 40 }} />
                  </div>
                </Grid>
                <Grid
                  item
                  container
                  xs={10}
                  style={{
                    marginTop: 50,
                  }}
                  direction="column"
                  justify="center"
                >
                  <NavLink
                    className={classes.menuLink}
                    to="/"
                    style={{ paddingTop: 10 }}
                  >
                    <Grid item>
                      <span className={classes.headerMenuText}>Accueil</span>
                    </Grid>
                  </NavLink>
                  <NavLink
                    className={classes.menuLink}
                    to="/"
                    style={{ paddingTop: 10 }}
                  >
                    <Grid item>
                      <span className={classes.headerMenuText}>Contact</span>
                    </Grid>
                  </NavLink>
                  <NavLink
                    className={classes.menuLink}
                    to="/"
                    style={{ paddingTop: 10 }}
                  >
                    <Grid item>
                      <span className={classes.headerMenuText}>FAQ</span>
                    </Grid>
                  </NavLink>
                </Grid>
              </Grid>
            </div>
          </Hidden> */}
          {/* <Hidden only={["sm", "xs"]}> */}
          {/* </Hidden> */}

          {/* <Hidden only={["md", "lg", "xl"]}>
            <div className={classes.whiteFooter}>
              <Grid container xs={12} style={{ margin: "auto" }}>
                <Grid item xs={2}>
                  <div className={classes.popLogoFooter}>
                    <img alt="logo" src={PopLogo} style={{ height: 40 }} />
                  </div>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    marginTop: 10,
                    marginLeft: 50,
                  }}
                  direction="column"
                  justify="center"
                >
                  <NavLink
                    className={classes.menuLink}
                    to="/"
                    style={{ paddingTop: 10, paddingLeft: 0 }}
                  >
                    <Grid item>
                      <span className={classes.headerMenuText}>Accueil</span>
                    </Grid>
                  </NavLink>
                  <NavLink
                    className={classes.menuLink}
                    to="/"
                    style={{ paddingTop: 10, paddingLeft: 0 }}
                  >
                    <Grid item>
                      <span className={classes.headerMenuText}>Contact</span>
                    </Grid>
                  </NavLink>
                  <NavLink
                    className={classes.menuLink}
                    to="/"
                    style={{ paddingTop: 10, paddingLeft: 0 }}
                  >
                    <Grid item>
                      <span className={classes.headerMenuText}>FAQ</span>
                    </Grid>
                  </NavLink>
                </Grid>
              </Grid>
            </div>
          </Hidden> */}
          {/* </Grid>  */}
        </Grid>
        <Grid
          item
          container
          xs={12}
          justify="center"
          alignItems="center"
          style={{
            backgroundColor: "#733be0",
            height: 60,
            borderTopRightRadius: 6,
            borderTopLeftRadius: 6,
            position: "absolute",
            width: "100%",
            bottom: 0,
          }}
        >
          <Grid item style={{ color: "#FFFFFF" }}>
            <p>POP © 2021. Tous droits réservés</p>
            <a
              style={{ color: "#FFFFFF" }}
              href="https://drive.google.com/file/d/1FQfGoic9wFgJt2EKHpSTingA65kEGGL6/view?usp=share_link"
              target="__blank"
            >
              CGV
            </a>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ShowcaseWrapper;

import React, { useEffect } from "react";

import {
  Avatar,
  Badge,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { useHistory } from "react-router";
import axios from "axios";
import { API_URL } from "../../../common/constants";
// import axios from "axios";

// import { API_URL } from "../../../common/constants";

const statusToText = {
  NEW_MESSAGE: "Vous avez reçu un nouveau message de ",
  NEW_BRIEF: "Les briefs de la mission avec ",
  OFFER: " vous a fait une offre",
  NEW_MISSION: "Une nouvelle mission vous a été proposé",
  ACCEPTED_OFFER: " a accepté votre offre",
  DECLINED_OFFER: " a décliné votre offre",
  PAID_OFFER:
    " a payé l’offre ! Envoyer leur votre travail pour qu’il le valide",
  INFLUENCER_WORK_DONE: " vous a envoyé son travail, à vous de le valider",
  ACCEPTED_INFLUENCER: " a postulé à votre campagne",
  DECLINED_INFLUENCER: " n'est plus interessé par votre campagne",
  VALIDATE_JOB: " a validé votre travail, postez le sur les réseaux sociaux",
  DECLINED_JOB: " n'est pas satisfait de votre travail ! Allez voir pourquoi ?",
};

function getStatusContent(notification) {
  if (notification?.status === "NEW_MESSAGE") {
    return `${statusToText.NEW_MESSAGE} ${
      notification?.recipient === "influencer"
        ? notification?.brandId?.brandName
        : notification?.influencerId?.fullname
    }`;
  } else if (notification?.status === "NEW_BRIEF") {
    return `${statusToText.NEW_BRIEF} ${notification.brandId.brandName} ont été modifiés !`;
  } else if (notification?.status === "OFFER") {
    return `${notification?.brandId?.brandName} ${statusToText.OFFER}`;
  } else if (notification?.status === "ACCEPTED_OFFER") {
    return `${notification?.influencerId?.fullname} ${statusToText.ACCEPTED_OFFER}`;
  } else if (notification?.status === "DECLINED_OFFER") {
    return `${notification?.influencerId?.fullname} ${statusToText.DECLINED_OFFER}`;
  } else if (notification?.status === "NEW_MISSION") {
    return `${statusToText.NEW_MISSION}`;
  } else if (notification?.status === "ACCEPTED_INFLUENCER") {
    return `${notification?.influencerId?.fullname} ${statusToText.ACCEPTED_INFLUENCER}`;
  } else if (notification?.status === "PAID_OFFER") {
    return `${notification?.brandId?.brandName} ${statusToText.PAID_OFFER}`;
  } else if (notification?.status === "DECLINED_INFLUENCER") {
    return `${notification?.influencerId?.fullname} ${statusToText.DECLINED_INFLUENCER}`;
  } else if (notification?.status === "INFLUENCER_WORK_DONE") {
    return `${notification?.influencerId?.fullname} ${statusToText.INFLUENCER_WORK_DONE}`;
  } else if (notification?.status === "VALIDATE_JOB") {
    return `${notification?.brandId?.brandName} ${statusToText.VALIDATE_JOB}`;
  } else if (notification?.status === "DECLINED_JOB") {
    return `${notification?.brandId?.brandName} ${statusToText.DECLINED_JOB}`;
  }
}

const colorBrand = "rgba(250, 128, 114,1)";
const colorInfluencer = "#825DFF";

const Notifications = ({
  notifications,
  setNotifications,
  handleClose,
  isInfluencer,
  badgeColorBrand,
  badgeColorInfluencer,
}) => {
  const history = useHistory();

  function handleReadNotifications() {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    const isInfluencer = localStorage.getItem("isInfluencer");
    axios
      .put(
        `${API_URL}/${
          localStorage.getItem("isInfluencer") === "true"
            ? "influencer"
            : "brand"
        }/profile/notifications/`
      )
      .then((res) => {})
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    handleReadNotifications();
  }, [notifications]);

  return (
    <Grid container style={{ paddingLeft: 15, paddingRight: 15 }}>
      <List style={{ width: "100%" }} dense={true}>
        {notifications.length === 0 && (
          <Grid
            item
            container
            justify="flex-start"
            style={{ padding: "20px 20px 20px 20px" }}
          >
            <Grid
              item
              container
              xs={12}
              justify="space-between"
              alignItems="flex-start"
            >
              <Grid item container xs={12}>
                <Grid item container xs={12}>
                  <Grid item xs={12}>
                    <p style={{ fontFamily: "nunito-bold", fontSize: 12.5 }}>
                      Aucune nouvelle notification.
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {notifications.map((notification, key) => {
          let date = new Date(notification.createdAt);
          const options = {
            weekday: "long",
            month: "long",
            day: "numeric",
          };
          date = date.toLocaleDateString("fr-FR", options);

          return (
            <ListItem
              button
              onClick={() => {
                handleClose();
                history.push(notification.link);
              }}
            >
              <Badge
                style={{ width: "100%", marginTop: 5 }}
                classes={{
                  badge: isInfluencer ? badgeColorInfluencer : badgeColorBrand,
                }}
                variant="dot"
                invisible={notification.isRead}
              >
                <ListItemAvatar style={{ marginRight: 30, margin: "auto" }}>
                  <Avatar
                    src={
                      notification?.recipient === "brand"
                        ? notification?.influencerId?.avatar
                        : notification?.brandId?.avatar
                    }
                  ></Avatar>
                </ListItemAvatar>
                <ListItemText
                  style={{ fontFamily: "nunito-regular" }}
                  primary={getStatusContent(notification)}
                  secondary={date}
                />
              </Badge>
            </ListItem>
          );
        })}
      </List>
    </Grid>
  );
};

export default Notifications;

import { Button, Dialog, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

import {
  DialogActions,
  DialogTitle,
  DialogContent,
} from "../../../../Common/Dialog/Dialog";
import axios from "axios";
import { API_URL } from "../../../../../common/constants";
import { useHistory } from "react-router";
import { useQueryParam, StringParam } from "use-query-params";

const RemoveInfluencerModal = ({
  isModalOpen,
  handleCloseModal,
  influencerMissionId,
  influencer,
  handleRefreshMission,
  isDiscussion,
  missionId,
}) => {
  const [influencerParam, setInfluencerParam] = useQueryParam(
    "influencer",
    StringParam
  );
  const history = useHistory();
  const handleRemoveInfluencer = () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .put(
        `${API_URL}/brand/missions/influencer-mission/${influencerMissionId}/decline-influencer`
      )
      .then((res) => {
        handleRefreshMission();
        if (isDiscussion) {
          history.push(`/brand/missions/${missionId}/messages`);
          window.location.reload();
        }
        handleCloseModal(false);
      });
  };

  return (
    <Dialog
      open={isModalOpen}
      maxWidth="xs"
      onClose={() => handleCloseModal(false)}
    >
      <DialogTitle onClose={() => handleCloseModal(false)}>
        Retirer un influenceur
      </DialogTitle>
      <DialogContent dividers>
        <Grid container>
          <p>
            Êtes-vous sur de vouloir supprimer {influencer.fullname} de votre
            liste d'influenceurs ?
          </p>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          style={{
            backgroundColor: "rgba(250, 128, 114,1)",
            borderRadius: 18,
          }}
          onClick={() => {
            handleRemoveInfluencer();
            handleCloseModal(false);
          }}
        >
          Supprimer
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{
            border: "1px solid rgba(250, 128, 114,1)",
            color: "rgba(250, 128, 114,1)",
            boxShadow: "none",
            background: "transparent",
            borderRadius: 18,
          }}
          onClick={() => handleCloseModal(false)}
        >
          Annuler
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RemoveInfluencerModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  influencerMissionId: PropTypes.string.isRequired,
  influencer: PropTypes.object.isRequired,
};

export default RemoveInfluencerModal;

// ##############################
// // // Settings styles
// #############################

import { green } from "@material-ui/core/colors";

const settingStyle = (theme) => ({
  ////////////////////////////
  // BankInformation STYLES //
  ////////////////////////////
  cardContainer: {
    cursor: "pointer",
  },
  cardImage: {
    width: "100%",
    // height: "100%",
    // width: "250px",
    height: "100%",
    borderRadius: 2,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  },
  menuChoice: {
    [theme.breakpoints.up("md")]: {
      borderLeft: "1px solid rgba(0, 0, 0, 0.2)",
    },
    marginTop: 50,
    paddingLeft: 30,
  },
  doneMissions: {
    [theme.breakpoints.up("md")]: {
      borderLeft: "1px dashed rgba(130,93,255,0.3)",
      paddingLeft: 30,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 30,
    },
    minHeight: 200,
  },
  formContainer: {
    padding: "20px 20px",
    backgroundColor: "#fff",
    borderRadius: 8,
    margin: "auto",
  },
  fieldLabel: {
    fontSize: 18,
    fontWeight: 400,
    marginBottom: 10,
    color: "#484848",
  },
  socialNetworkLabel: {
    fontSize: 24,
    fontWeight: 500,
    marginBottom: 10,
    color: "#484848",
  },
  socialNetworkBorder: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
    paddingTop: 10,
    marginBottom: 20,
  },

  //TODO: Clean
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  userAvatar: {
    "& > img": {
      objectFit: "cover !important",
    },
  },
});

export default settingStyle;

import { Accordion, AccordionSummary, Avatar, Grid } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import React from "react";
import { makeStyles } from "@material-ui/styles";

import missionStyle from "./styles/missionStyle";
import { ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles(missionStyle);

const MissionDashboardMobile = ({ missions, type }) => {
  const classes = useStyles();
  return (
    <Grid item container justify="center">
      <Grid item xs={12} style={{ textAlign: "center", marginBottom: 30 }}>
        <p
          style={{
            color: "#484848",
            fontSize: 30,
            fontWeight: 600,
            fontFamily: "nunito-bold",
          }}
        >
          Mes missions
        </p>
      </Grid>
      {type === "CURRENT" ? (
        <>
          <Grid
            item
            container
            xs={11}
            sm={11}
            md={4}
            lg={4}
            style={{ marginBottom: 30 }}
            direction="column"
            justify="flex-start"
            alignItems="center"
          >
            <Accordion
              style={{
                width: "100%",
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                classes={{
                  expanded: classes.expanded,
                }}
                style={{ border: "1px solid rgb(227, 227, 227)" }}
              >
                <Grid item xs={12}>
                  <p className={classes.blockTitle}>{`Proposées (${
                    missions.filter(
                      (mission) => mission.influencerStatus === "PROPOSED"
                    ).length
                  })`}</p>
                </Grid>
              </AccordionSummary>
              <Grid item container style={{ marginTop: 30 }}>
                {missions
                  .filter((mission) => mission.influencerStatus === "PROPOSED")
                  .map((mission) => (
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.missionItems}
                      justify="center"
                      style={{ margin: "auto", marginBottom: 10 }}
                    >
                      <NavLink
                        className={classes.navLink}
                        exact={true}
                        to={`/influencer/missions/${mission.missionId._id}`}
                      >
                        <Grid item container>
                          <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Avatar
                              className={classes.brandAvatar}
                              src={
                                mission?.missionId?.thumbnail ??
                                mission?.brandId?.avatar
                              }
                              style={{ margin: "auto" }}
                            ></Avatar>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={11}
                            sm={11}
                            md={7}
                            lg={7}
                            className={classes.missionContent}
                          >
                            <Grid item xs={12}>
                              <p className={classes.brandTitle}>
                                {mission.missionId.title}
                              </p>
                            </Grid>
                            <Grid item xs={12}>
                              <p className={classes.missionDescription}>
                                {mission.missionId.description}
                              </p>
                            </Grid>
                          </Grid>
                          {/* <Grid
                    item
                    container
                    xs={2}
                    className={classes.missionContent}
                  >
                    <Grid item>
                      <p>550€</p>
                    </Grid>
                  </Grid> */}
                        </Grid>
                      </NavLink>
                    </Grid>
                  ))}
              </Grid>
            </Accordion>
          </Grid>
          <Grid
            item
            container
            xs={11}
            sm={11}
            md={4}
            lg={4}
            style={{ marginBottom: 30 }}
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Accordion
              style={{
                width: "100%",
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                classes={{
                  expanded: classes.expanded,
                }}
                style={{ border: "1px solid rgb(227, 227, 227)" }}
              >
                <Grid item xs={12}>
                  <p className={classes.blockTitle}>{`Acceptées (${
                    missions.filter(
                      (mission) => mission.influencerStatus === "ACCEPTED"
                    ).length
                  })`}</p>
                </Grid>
              </AccordionSummary>
              <Grid item container style={{ marginTop: 30 }}>
                {missions
                  .filter((mission) => mission.influencerStatus === "ACCEPTED")
                  .map((mission) => (
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.missionItems}
                      justify="center"
                      style={{ margin: "auto", marginBottom: 10 }}
                    >
                      <NavLink
                        className={classes.navLink}
                        exact={true}
                        to={`/influencer/missions/${mission.missionId._id}`}
                      >
                        <Grid item container>
                          <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Avatar
                              className={classes.brandAvatar}
                              src={
                                mission?.missionId?.thumbnail ??
                                mission?.brandId?.avatar
                              }
                              style={{ margin: "auto" }}
                            ></Avatar>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={11}
                            sm={11}
                            md={7}
                            lg={7}
                            className={classes.missionContent}
                          >
                            <Grid item xs={12}>
                              <p className={classes.brandTitle}>
                                {mission.missionId.title}
                              </p>
                            </Grid>
                            <Grid item xs={12}>
                              <p className={classes.missionDescription}>
                                {mission.missionId.description}
                              </p>
                            </Grid>
                          </Grid>
                          {/* <Grid
                    item
                    container
                    xs={2}
                    className={classes.missionContent}
                  >
                    <Grid item>
                      <p>550€</p>
                    </Grid>
                  </Grid> */}
                        </Grid>
                      </NavLink>
                    </Grid>
                  ))}
              </Grid>
            </Accordion>
          </Grid>
          <Grid
            item
            container
            xs={11}
            sm={11}
            md={4}
            lg={4}
            style={{ marginBottom: 30 }}
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Accordion
              style={{
                width: "100%",
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                classes={{
                  expanded: classes.expanded,
                }}
                style={{ border: "1px solid rgb(227, 227, 227)" }}
              >
                <Grid item xs={12}>
                  <p className={classes.blockTitle}>{`En cours (${
                    missions.filter(
                      (mission) => mission.influencerStatus === "DOING"
                    ).length
                  })`}</p>
                </Grid>
              </AccordionSummary>
              <Grid item container style={{ marginTop: 30 }}>
                {missions
                  .filter((mission) => mission.influencerStatus === "DOING")
                  .map((mission) => (
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.missionItems}
                      justify="center"
                      style={{ margin: "auto", marginBottom: 10 }}
                    >
                      <NavLink
                        className={classes.navLink}
                        exact={true}
                        to={`/influencer/missions/${mission.missionId._id}`}
                      >
                        <Grid item container>
                          <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Avatar
                              className={classes.brandAvatar}
                              src={
                                mission?.missionId?.thumbnail ??
                                mission?.brandId?.avatar
                              }
                              style={{ margin: "auto" }}
                            ></Avatar>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={11}
                            sm={11}
                            md={7}
                            lg={7}
                            className={classes.missionContent}
                          >
                            <Grid item xs={12}>
                              <p className={classes.brandTitle}>
                                {mission.missionId.title}
                              </p>
                            </Grid>
                            <Grid item xs={12}>
                              <p className={classes.missionDescription}>
                                {mission.missionId.description}
                              </p>
                            </Grid>
                          </Grid>
                          {/* <Grid
                    item
                    container
                    xs={2}
                    className={classes.missionContent}
                  >
                    <Grid item>
                      <p>550€</p>
                    </Grid>
                  </Grid> */}
                        </Grid>
                      </NavLink>
                    </Grid>
                  ))}
              </Grid>
            </Accordion>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            item
            container
            xs={11}
            sm={11}
            md={4}
            lg={4}
            style={{ marginBottom: 30 }}
            direction="column"
            justify="flex-start"
            alignItems="center"
          >
            <Accordion
              style={{
                width: "100%",
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                classes={{
                  expanded: classes.expanded,
                }}
                style={{ border: "1px solid rgb(227, 227, 227)" }}
              >
                <Grid item xs={12}>
                  <p className={classes.blockTitle}>{`Terminées (${
                    missions.filter(
                      (mission) => mission.influencerStatus === "DONE"
                    ).length
                  })`}</p>
                </Grid>
              </AccordionSummary>
              <Grid item container style={{ marginTop: 30 }}>
                {missions
                  .filter((mission) => mission.influencerStatus === "DONE")
                  .map((mission) => (
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.missionItems}
                      justify="center"
                      style={{ margin: "auto", marginBottom: 10 }}
                    >
                      <NavLink
                        className={classes.navLink}
                        exact={true}
                        to={`/influencer/missions/${mission.missionId._id}`}
                      >
                        <Grid item container>
                          <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Avatar
                              className={classes.brandAvatar}
                              src={
                                mission?.missionId?.thumbnail ??
                                mission?.brandId?.avatar
                              }
                              style={{ margin: "auto" }}
                            ></Avatar>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            sm={12}
                            md={7}
                            lg={7}
                            className={classes.missionContent}
                          >
                            <Grid item xs={12}>
                              <p className={classes.brandTitle}>
                                {mission.missionId.title}
                              </p>
                            </Grid>
                            <Grid item xs={12}>
                              <p className={classes.missionDescription}>
                                {mission.missionId.description}
                              </p>
                            </Grid>
                          </Grid>
                          {/* <Grid
                  item
                  container
                  xs={2}
                  className={classes.missionContent}
                >
                  <Grid item>
                    <p>550€</p>
                  </Grid>
                </Grid> */}
                        </Grid>
                      </NavLink>
                    </Grid>
                  ))}
              </Grid>
            </Accordion>
          </Grid>
          <Grid
            item
            container
            xs={11}
            sm={11}
            md={4}
            lg={4}
            style={{ marginBottom: 30 }}
            direction="column"
            justify="flex-start"
            alignItems="center"
          >
            <Accordion
              style={{
                width: "100%",
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                classes={{
                  expanded: classes.expanded,
                }}
                style={{ border: "1px solid rgb(227, 227, 227)" }}
              >
                <Grid item xs={12}>
                  <p className={classes.blockTitle}>{`Missions refusées (${
                    missions.filter(
                      (mission) => mission.influencerStatus === "DECLINED"
                    ).length
                  })`}</p>
                </Grid>
              </AccordionSummary>
              <Grid item container style={{ marginTop: 30 }}>
                {missions
                  .filter((mission) => mission.influencerStatus === "DECLINED")
                  .map((mission) => (
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.missionItems}
                      justify="center"
                      style={{ margin: "auto", marginBottom: 10 }}
                    >
                      <NavLink
                        className={classes.navLink}
                        exact={true}
                        to={`/influencer/missions/${mission.missionId._id}`}
                      >
                        <Grid item container>
                          <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Avatar
                              className={classes.brandAvatar}
                              src={
                                mission?.missionId?.thumbnail ??
                                mission?.brandId?.avatar
                              }
                              style={{ margin: "auto" }}
                            ></Avatar>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            sm={12}
                            md={7}
                            lg={7}
                            className={classes.missionContent}
                          >
                            <Grid item xs={12}>
                              <p className={classes.brandTitle}>
                                {mission.missionId.title}
                              </p>
                            </Grid>
                            <Grid item xs={12}>
                              <p className={classes.missionDescription}>
                                {mission.missionId.description}
                              </p>
                            </Grid>
                          </Grid>
                          {/* <Grid
                  item
                  container
                  xs={2}
                  className={classes.missionContent}
                >
                  <Grid item>
                    <p>550€</p>
                  </Grid>
                </Grid> */}
                        </Grid>
                      </NavLink>
                    </Grid>
                  ))}
              </Grid>
            </Accordion>
          </Grid>
          <Grid
            item
            container
            xs={11}
            sm={11}
            md={4}
            lg={4}
            style={{ marginBottom: 30 }}
            direction="column"
            justify="flex-start"
            alignItems="center"
          >
            <Accordion
              style={{
                width: "100%",
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                classes={{
                  expanded: classes.expanded,
                }}
                style={{ border: "1px solid rgb(227, 227, 227)" }}
              >
                <Grid item xs={12}>
                  <p className={classes.blockTitle}>{`Candidatures déclinées (${
                    missions.filter(
                      (mission) => mission.influencerStatus === "REFUSED"
                    ).length
                  })`}</p>
                </Grid>
              </AccordionSummary>
              <Grid item container style={{ marginTop: 30 }}>
                {missions
                  .filter((mission) => mission.influencerStatus === "REFUSED")
                  .map((mission) => (
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.missionItems}
                      justify="center"
                      style={{ margin: "auto", marginBottom: 10 }}
                    >
                      <NavLink
                        className={classes.navLink}
                        exact={true}
                        to={`/influencer/missions/${mission.missionId._id}`}
                      >
                        <Grid item container>
                          <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Avatar
                              className={classes.brandAvatar}
                              src={
                                mission?.missionId?.thumbnail ??
                                mission?.brandId?.avatar
                              }
                              style={{ margin: "auto" }}
                            ></Avatar>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            sm={12}
                            md={7}
                            lg={7}
                            className={classes.missionContent}
                          >
                            <Grid item xs={12}>
                              <p className={classes.brandTitle}>
                                {mission.missionId.title}
                              </p>
                            </Grid>
                            <Grid item xs={12}>
                              <p className={classes.missionDescription}>
                                {mission.missionId.description}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>
                      </NavLink>
                    </Grid>
                  ))}
              </Grid>
            </Accordion>
          </Grid>
        </>
      )}
    </Grid>
  );
};

MissionDashboardMobile.propTypes = {};

export default MissionDashboardMobile;

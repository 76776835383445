import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import BrandProfilePage from "../../../Components/Brand/BrandProfilePage/BrandProfilePage";
import { API_URL } from "../../../common/constants";
import { CircularProgress } from "@material-ui/core";

const ProfilePage = () => {
  const [profile, setProfile] = useState(undefined);

  const getProfile = () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .get(`${API_URL}/brand/profile`)
      .then((res) => setProfile(res.data))
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getProfile();
  }, []);

  const handleUpdateBrandProfile = (body) => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .put(`${API_URL}/brand/profile`, {
        ...body,
      })
      .then((res) => getProfile())
      .catch((error) => console.error(error));
  };

  if (!profile) return <CircularProgress />;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`POP App - Dashboard - ${profile.brandName}`}</title>
        <link rel="canonical" href="https://pop-app.fr" />
      </Helmet>
      <BrandProfilePage
        profile={profile}
        handleUpdateBrandProfile={handleUpdateBrandProfile}
      />
    </>
  );
};

export default ProfilePage;

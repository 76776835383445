import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import { Badge, Grid } from "@material-ui/core";

import missionPageStyle from "./styles/missionPageStyles";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles(missionPageStyle);

const MissionSidenav = ({
  missionId,
  influencerMission,
  influencerStatus,
  selectedMenuItem,
  setSelectedMenuItem,
  configMenu,
  isInfluencer,
  routes,
}) => {
  const classes = useStyles();
  return (
    <Grid
      item
      container
      className={classes.sidenavMenu}
      justify="center"
      alignItems="flex-start"
    >
      <Grid item container className={classes.listing} alignItems="flex-start">
        {routes.map((item, key) => {
          return (
            <NavLink
              key={item.to}
              exact={item.exact}
              to={`/${
                isInfluencer ? "influencer" : "brand"
              }/missions/${missionId}${item.to}`}
              className={
                item.disabled
                  ? classes.menuItemDisabled
                  : isInfluencer
                  ? classes.menuItem
                  : classes.menuItemBrand
              }
              style={{ pointerEvents: item.disabled ? "none" : "initial" }}
              activeClassName={
                isInfluencer
                  ? classes.selectedMenuItem
                  : classes.selectedMenuItemBrand
              }
            >
              <Grid key={key} item container xs={12} alignItems="center">
                <Grid item xs={3} style={{ textAlign: "center" }}>
                  <Badge
                    color="secondary"
                    variant="dot"
                    invisible={
                      !isInfluencer ||
                      influencerMission.startDiscussion ||
                      item.label !== "Messages"
                    }
                  >
                    {item.icon}
                  </Badge>
                </Grid>
                <Grid item container xs={9}>
                  <Grid item xs={12}>
                    <p className={classes.title}>{item.label}</p>
                  </Grid>
                </Grid>
              </Grid>
            </NavLink>
          );
        })}
      </Grid>
    </Grid>
  );
};

MissionSidenav.propTypes = {
  selectedMenuItem: PropTypes.object,
  setSelectedMenuItem: PropTypes.func,
  configMenu: PropTypes.array,
  isInfluencer: PropTypes.bool,
};

MissionSidenav.defaultProps = {
  isInfluencer: true,
};

export default MissionSidenav;

// ####################################
// // // Modals Messages styles
// ###################################

const modalsStyle = (theme) => ({
  fieldLabel: {
    marginBottom: 10,
  },
  brief: {
    width: "100%",
    borderRadius: 10,
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    marginRight: 10,
  },
  selectedBrief: {
    backgroundColor: "rgba(250, 128, 114,0.2)",
    borderRadius: 10,
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    marginRight: 10,
    width: "100%",
  },
  containerListItem: {
    width: "100%",
    marginTop: 20,
  },
  checkboxColor: {
    color: "rgba(250, 128, 114,0.8)",
    "&checked": {
      fill: "rgba(250, 128, 114,1)",
    },
  },
  checkboxColorChecked: {
    fill: "rgba(250, 128, 114,0.8)",
    "&checked": {
      color: "rgba(250, 128, 114,1)",
    },
  },
});

export default modalsStyle;

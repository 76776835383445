import {
  categoriesOptions,
  priceOptions,
  socialNetworkOptions,
} from "../../../Brand/NewMission/utils";

export const getFormattedSocialNetworksSimple = (socialNetworks) => {
  return socialNetworks
    .map((social) => {
      const network = socialNetworkOptions.find((opt) => opt.value === social);
      return {
        ...network,
      };
    })
    .filter((value) => value);
};

export const getFormattedSocialNetworks = (socialNetworks) => {
  return socialNetworks
    ?.map((social) => {
      const network = socialNetworkOptions.find(
        (opt) => opt.value === social.socialNetwork
      );
      return {
        ...social,
        ...network,
      };
    })
    ?.filter((value) => value);
};

export const getFormattedPrice = (price) => {
  const foundPrice = priceOptions.find((opt) => opt.value === price);
  return foundPrice;
};

export const getFormattedActivities = (activities) => {
  return activities
    .map((activity) => {
      const foundActivity = categoriesOptions.find(
        (category) => category.value === activity
      );
      return foundActivity;
    })
    .filter((value) => value);
};

import { Button, Grid, Snackbar, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Rating from "@material-ui/lab/Rating";
import axios from "axios";
import React, { useState } from "react";
import { API_URL } from "../../../../../common/constants";

const RatingBrand = ({
  influencerMissionId,
  handleRefreshFollowProcess,
  brandName,
}) => {
  const [isDisplayingSnackbar, setIsDisplayingSnackbar] = useState(false);

  const [comment, setComment] = useState("");
  const [rate, setRate] = useState(1);

  const handleRateBrand = () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;

    axios
      .put(
        `${API_URL}/influencer/missions/influencer-mission/${influencerMissionId}/rating`,
        {
          comment,
          rate,
        }
      )
      .then((res) => {
        setIsDisplayingSnackbar(true);
        handleRefreshFollowProcess();
      });
  };

  return (
    <>
      <Grid
        item
        container
        xs={12}
        alignItems="center"
        justify="center"
        spacing={2}
      >
        <Grid item container direction="column">
          <p style={{ width: "100%", textAlign: "center", fontSize: 24 }}>
            Donnez une note à cette marque
          </p>
          <p
            style={{
              textAlign: "center",
              margin: "auto",
              marginTop: 10,
              fontSize: 16,
            }}
          >
            {`Votre avis compte pour nous, comment s'est déroulé votre mission avec ${brandName} ?`}
          </p>
        </Grid>
        <Grid
          item
          xs={12}
          justify="center"
          style={{ textAlign: "center", marginTop: 40 }}
        >
          <Rating
            name="size-large"
            value={rate}
            size="large"
            style={{ fontSize: 50 }}
            onChange={(event, newValue) => setRate(newValue)}
          />
        </Grid>
        <Grid item container xs={12}>
          <TextField
            fullWidth
            rows={5}
            multiline
            id="comment"
            name="comment"
            variant="outlined"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Grid>
        <Grid item container xs={12}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            style={{
              fontSize: 12,
              textTransform: "none",
              background: "rgba(250, 128, 114,1)",
              color: "#FFF",
            }}
            onClick={() => handleRateBrand()}
          >
            Enregistrer
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={isDisplayingSnackbar}
        autoHideDuration={6000}
        onClose={() => setIsDisplayingSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        disableWindowBlurListener
      >
        <Alert
          onClose={() => setIsDisplayingSnackbar(false)}
          severity="success"
        >
          Merci d'avoir effectué une campagne avec nous !
        </Alert>
      </Snackbar>
    </>
  );
};

export default RatingBrand;

import { Button, Grid, TextField, Chip, makeStyles } from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import { Upload } from "react-feather";

import { API_URL } from "../../../../../common/constants";

import missionPageStyle from "../styles/missionPageStyles";

const useStyles = makeStyles(missionPageStyle);

const WaitingForInfluencer = ({
  influencerMissionId,
  handleRefreshFollowProcess,
  followProcess,
}) => {
  const classes = useStyles();
  const [content, setContent] = useState("");
  const [contentError, setContentError] = useState(false);
  const [files, setFiles] = useState([]);

  const handleSendContentToBrand = async () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;

    if (content.length === 0) {
      setContentError(true);
      return;
    }

    const finalFiles = await Promise.all(
      files?.map(async (file) => {
        if (typeof file === "string") return file;
        let formData = new FormData();

        formData.append("file", file);

        const res = await axios.post(
          `${API_URL}/images/follow-process`,
          formData
        );
        const newFile = `https://pop-influence.s3.us-east-2.amazonaws.com/${res.data.filename}`;
        return newFile;
      })
    );
    axios
      .put(
        `${API_URL}/influencer/missions/influencer-mission/${influencerMissionId}/follow-process`,
        {
          influencerContent: content,
          files: finalFiles,
        }
      )
      .then((res) => handleRefreshFollowProcess());
  };

  return (
    <Grid item container xs={12}>
      <p
        style={{
          width: "100%",
          textAlign: "center",
          fontSize: 24,
          padding: 10,
        }}
      >
        Finalisation de la campagne
      </p>
      <Grid item container xs={12}>
        <p
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: 14,
            marginBottom: 20,
          }}
        >
          Une fois la campagne réalisé, donnez à la marque les infos et liens
          nécessaire afin de vérfier le travail effectué.
        </p>
      </Grid>
      {followProcess?.brandContent?.length > 0 && (
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{ marginBottom: 20 }}
        >
          <Grid item>
            <p
              style={{
                marginTop: 20,
                fontSize: 18,
              }}
            >
              Retours de la marque
            </p>
          </Grid>
          {followProcess?.brandContent
            ?.filter((content) => content.comment.length > 0)
            ?.map((content) => {
              let date = new Date(content.createdAt);
              const options = {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              };
              date = date.toLocaleString("fr-FR", options);
              return (
                <Grid
                  item
                  xs={12}
                  style={{
                    paddingLeft: 20,
                    paddingTop: 20,
                    color: "#484848",
                  }}
                >
                  <p style={{ fontSize: 16 }}>{content.comment}</p>
                  <p
                    style={{
                      color: "#888",
                      fontStyle: "italic",
                      fontSize: 10,
                    }}
                  >
                    {date}
                  </p>
                </Grid>
              );
            })}
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          rows={5}
          multiline
          variant="outlined"
          fullWidth
          error={contentError}
          helperText={
            contentError &&
            "La description pour valider votre travail est obligatoire."
          }
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
      </Grid>
      <Grid item container xs={12} style={{ marginTop: 20 }}>
        <label htmlFor={`contained-button-file`}>
          <input
            style={{ display: "none" }}
            id={`contained-button-file`}
            multiple
            type="file"
            onChange={(e) => {
              let newFiles = e.target.files;
              setFiles((files) => [...newFiles, ...files]);
            }}
          />
          <Button
            variant="contained"
            color="default"
            startIcon={<Upload />}
            component="span"
          >
            Ajouter des fichiers
          </Button>
        </label>
        <Grid item container xs={12} style={{ marginTop: 20 }}>
          {Array.from(files ?? []).map((data, index) => {
            return (
              <Grid item key={index}>
                <Chip
                  label={
                    typeof data === "string"
                      ? data.replace(
                          "https://pop-influence.s3.us-east-2.amazonaws.com/follow-process/",
                          ""
                        )
                      : data.name
                  }
                  onDelete={() => {
                    const tmpFiles = [...files];
                    tmpFiles.splice(index, 1);
                    setFiles(tmpFiles);
                  }}
                  style={{ margin: 5 }}
                  onClick={() => {
                    typeof data === "string" && window.open(data);
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Button
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
        style={{
          fontSize: 12,
          textTransform: "none",
          background: "rgba(130,93,255,1)",
          color: "#FFF",
          marginTop: 20,
        }}
        onClick={() => handleSendContentToBrand()}
      >
        Envoyer
      </Button>
    </Grid>
  );
};

export default WaitingForInfluencer;

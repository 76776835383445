import { Button, Dialog, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

import {
  DialogActions,
  DialogTitle,
  DialogContent,
} from "../../../../Common/Dialog/Dialog";
import axios from "axios";
import { API_URL } from "../../../../../common/constants";
import { useHistory } from "react-router";
import { useQueryParam, StringParam } from "use-query-params";

const DeleteCampaignModal = ({
  isModalOpen,
  handleCloseModal,
  handleOnDeleteCampaign,
}) => {
  const history = useHistory();

  return (
    <Dialog
      open={isModalOpen}
      maxWidth="xs"
      onClose={() => handleCloseModal(false)}
    >
      <DialogTitle onClose={() => handleCloseModal(false)}>
        Supprimer cette campagne
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <p>Êtes-vous sur de vouloir supprimer cette campagne ?</p>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          style={{
            backgroundColor: "rgba(250, 128, 114,1)",
            borderRadius: 18,
          }}
          onClick={() => {
            handleOnDeleteCampaign();
            handleCloseModal(false);
          }}
        >
          Supprimer
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{
            border: "1px solid rgba(250, 128, 114,1)",
            color: "rgba(250, 128, 114,1)",
            boxShadow: "none",
            background: "transparent",
            borderRadius: 18,
          }}
          onClick={() => handleCloseModal(false)}
        >
          Annuler
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteCampaignModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleOnDeleteCampaign: PropTypes.func.isRequired,
};

export default DeleteCampaignModal;

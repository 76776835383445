import {
  Dialog,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import PropTypes from "prop-types";
import {
  // makeStyles,
  useTheme,
} from "@material-ui/styles";
import RatingInfluencer from "../../../Brand/Missions/BrandMissionPage/MissionFollow/RatingInfluencer";
import React, { useEffect, useState } from "react";

import { DialogTitle, DialogContent } from "../../Dialog/Dialog";
import WaitingForInfluencer from "../../../Brand/Missions/BrandMissionPage/MissionFollow/WaitingForInfluencer";
import BrandValidation from "../../../Brand/Missions/BrandMissionPage/MissionFollow/BrandValidation";

// const useStyles = makeStyles(modalsStyle);

const steps = [
  "En attente de l'influenceur",
  "Validez le travail de l'influenceur",
  "Donnez nous votre avis",
];

const followProcessConfig = {
  INFLUENCER_VALIDATION: 0,
  BRAND_VALIDATION: 1,
  EVALUATION: 2,
};

const FollowProcessModal = ({
  isModalOpen,
  handleCloseModal,
  influencerMissionId,
  followProcess = { step: "INFLUENCER_VALIDATION" },
  handleRefreshFollowProcess,
  influencerName,
  missionId,
}) => {
  // const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [activeStep, setActiveStep] = useState(
    followProcessConfig[followProcess.step]
  );

  const isStepOptional = (step) => {
    return step === 2;
  };

  useEffect(() => {
    if (followProcess && followProcess.step) {
      setActiveStep(followProcessConfig[followProcess.step]);
    }
  }, [followProcess.step]);

  return (
    <Dialog
      open={isModalOpen}
      maxWidth="md"
      fullWidth={true}
      fullScreen={matches}
      onClose={() => handleCloseModal(false)}
    >
      <DialogTitle onClose={() => handleCloseModal(false)}>Suivi</DialogTitle>
      <DialogContent dividers>
        <Grid item xs={12}>
          <Stepper
            activeStep={activeStep}
            style={
              matches
                ? { textAlign: "center", padding: 0, paddingBottom: 20 }
                : {}
            }
            orientation={`${matches ? "vertical" : "horizontal"}`}
          >
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              return (
                <Step
                  key={label}
                  {...stepProps}
                  // style={{ cursor: "pointer" }}
                  // onClick={() => setActiveStep(index)}
                >
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
        <Grid
          item
          container
          xs={12}
          style={{ marginTop: 25, marginBottom: 50 }}
        >
          {activeStep === 0 && <WaitingForInfluencer />}
          {activeStep === 1 && (
            <BrandValidation
              influencerMissionId={influencerMissionId}
              handleRefreshFollowProcess={handleRefreshFollowProcess}
              followProcess={followProcess}
              missionId={missionId}
            />
          )}
          {activeStep === 2 && (
            <RatingInfluencer
              influencerMissionId={influencerMissionId}
              handleRefreshFollowProcess={handleRefreshFollowProcess}
              influencerName={influencerName}
              missionId={missionId}
            />
          )}
        </Grid>
      </DialogContent>
      {/* <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            // handleSendNewOffer();
            handleCloseModal(false);
          }}
        >
          Enregistrer
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleCloseModal(false)}
        >
          Annuler
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};

FollowProcessModal.propTypes = {
  description: PropTypes.string,
  handleUpdateDescription: PropTypes.func,
  isModalOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func,
};

export default FollowProcessModal;

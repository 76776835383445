// ####################################
// // // Personal Information styles
// ###################################

const personalInformationStyle = (theme) => ({
  pageLayout: {
    [theme.breakpoints.up("md")]: {
      width: "70%",
    },
    margin: "auto",
  },
  pageLayoutForm: {
    width: "80%",
    margin: "auto",
  },
  userAvatar: {
    width: 220,
    height: 220,
    float: "right",
  },
  brandAvatar: {
    objectFit: "contain",
    width: 220,
    height: 220,
    [theme.breakpoints.up("md")]: {
      float: "right",
    },
    [theme.breakpoints.down("md")]: {
      margin: "auto",
    },
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(250, 128, 114,0.3)",
    },
    "&:hover > img": {
      opacity: 0.6,
    },
  },
  userName: {
    fontWeight: 500,
    fontSize: 36,
    fontFamily: "nunito-bold",
    marginBottom: 10,
  },
  profileInfo: {
    height: 100,
    border: "1px solid #e0ded9",
    padding: "10px 15px",
    borderRadius: 6,
  },
  editableContainer: {
    "& div .editable": {
      display: "none",
    },
    "&:hover div .editable": {
      display: "block",
    },
  },
  infoTitle: {
    padding: 5,
  },
  infoContent: {
    padding: 5,
  },
  fieldLabel: {
    fontSize: 18,
    fontWeight: 400,
    marginBottom: 10,
    color: "#484848",
  },
  personalInformationContainer: {
    marginBottom: 50,
    minHeight: 300,
    backgroundColor: "#FFF",
    borderRadius: 6,
    position: "relative",
    [theme.breakpoints.up("md")]: {
      padding: 40,
    },
  },
  brandTitle: {
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
});

export default personalInformationStyle;

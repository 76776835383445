import { Grid } from "@material-ui/core";
import React from "react";

import ClockPicture from "../../../../../assets/svg/clock1.svg";

const WaitingForInfluencer = () => {
  return (
    <Grid item container direction="column" xs={12}>
      <p style={{ width: "100%", textAlign: "center", fontSize: 24 }}>
        En attente de la validation de l'influenceur
      </p>
      <img src={ClockPicture} alt="clock" />
      <p
        style={{
          width: "60%",
          textAlign: "center",
          margin: "auto",
          marginTop: 10,
          fontSize: 16,
        }}
      >
        Lorsque l'influenceur aura finalisé la mission vous serez notifié et
        pourrez à votre tour valider son travail.
      </p>
    </Grid>
  );
};

export default WaitingForInfluencer;

import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";

import { Grid } from "@material-ui/core";

import profilePageStyle from "../styles/profilePage";
import DescriptionModal from "./modals/DescriptionModal";

const useStyles = makeStyles(profilePageStyle);

const UserDescription = ({
  profileDescription,
  handleUpdateInfluencerProfile,
  isEditable = false,
  isTourOpen,
}) => {
  const classes = useStyles();

  const [description, setDescription] = useState(profileDescription);
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <Grid
        item
        container
        className={classes.widgetContainer}
        onClick={() => isEditable && !isTourOpen && setIsModalOpen(true)}
        id="influencer-description"
      >
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <p className={classes.widgetTitle}>Vous en quelques mots</p>
          </Grid>
          {!isEditable ? (
            <Grid
              item
              xs={12}
              className={
                isEditable
                  ? classes.widgetDescriptionEditable
                  : classes.widgetDescription
              }
              onClick={() => isEditable && !isTourOpen && setIsModalOpen(true)}
            >
              <p className={classes.widgetTextContent}>
                {description || "Aucune description."}
              </p>
            </Grid>
          ) : description?.length > 0 ? (
            <Grid
              item
              xs={12}
              className={
                isEditable
                  ? classes.widgetDescriptionEditable
                  : classes.widgetDescription
              }
              onClick={() => isEditable && !isTourOpen && setIsModalOpen(true)}
            >
              <p className={classes.widgetTextContent}>
                {description ||
                  "Venez vous décrire en quelques mots pour aider les marques à vous reconnaitre."}
              </p>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              className={classes.emptyContent}
              onClick={() => {
                if (isEditable && !isTourOpen) {
                  setIsModalOpen(true);
                }
              }}
            >
              <p className={classes.widgetTextContent}>
                Venez vous décrire en quelques mots pour aider les marques à
                vous reconnaitre.
              </p>
            </Grid>
          )}
        </Grid>
      </Grid>
      <DescriptionModal
        description={description}
        handleUpdateDescription={(updatedDescription) => {
          handleUpdateInfluencerProfile({ description: updatedDescription });
          setDescription(updatedDescription);
        }}
        isModalOpen={isModalOpen}
        handleCloseModal={setIsModalOpen}
      />
    </>
  );
};

UserDescription.propTypes = {};

export default UserDescription;

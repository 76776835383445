// ##############################
// // // Login styles
// #############################

const loginStyle = (theme) => ({
  popLogo: {
    flexGrow: 1,
    marginTop: 40,
    marginBottom: 40,
  },
  coloredHeader: {
    background: "rgb(130, 93, 255)",
    height: 10,
    width: "100%",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  coloredHeaderBrand: {
    background: "rgba(250, 128, 114,1)",
    height: 10,
    width: "100%",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
});

export default loginStyle;

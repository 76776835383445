import {
  Avatar,
  Chip,
  CircularProgress,
  Grid,
  Hidden,
} from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../../common/constants";
import { getFormattedSocialNetworks } from "../../Brand/BrandProfilePage/PersonalInformation/utils";
import PersonalInformationMobileWidget from "./PersonalInformationMobileWidget";
import { useTour } from "@reactour/tour";
import { Edit2 } from "react-feather";

import widgetStyle from "./styles/widgetStyles";

const useStyles = makeStyles(widgetStyle);

const PersonalInformationWidget = ({
  profile,
  isEditable = false,
  setIsDisplayingFormPersonalInfo,
  setIsDisplayingFormPrice,
  handleUpdateInfluencerProfile,
  setIsLoading,
}) => {
  const classes = useStyles();
  const { setIsOpen, isOpen } = useTour();

  const handleUploadImage = (file) => {
    let formData = new FormData();

    setIsLoading(true);
    formData.append("file", file);
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .post(`${API_URL}/images`, formData)
      .then((res) => {
        handleUpdateInfluencerProfile({
          avatar: `https://pop-influence.s3.us-east-2.amazonaws.com/${res.data.filename}`,
        });
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    setTimeout(() => {
      if (!profile?.isOnBoarded) setIsOpen(true);
    }, 2000);
  }, [profile]);

  let minPrice = Math.min(
    ...profile?.socialNetworks?.map((network) => {
      return network.price;
    })
  );

  if (
    typeof minPrice !== "number" ||
    Number.isNaN(minPrice) ||
    !Number.isFinite(minPrice)
  ) {
    minPrice = 0;
  }

  return (
    <>
      <Hidden only={["md", "lg", "xl"]}>
        <PersonalInformationMobileWidget
          handleUploadImage={handleUploadImage}
          classes={classes}
          profile={profile}
          isEditable={isEditable}
          setIsDisplayingFormPersonalInfo={setIsDisplayingFormPersonalInfo}
          setIsDisplayingFormPrice={setIsDisplayingFormPrice}
          handleUpdateInfluencerProfile={handleUpdateInfluencerProfile}
        />
      </Hidden>
      <Hidden only={["sm", "xs"]}>
        <Grid
          container
          xs={12}
          sm={12}
          md={10}
          lg={10}
          xl={10}
          className={classes.pageLayout}
          justify="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <label>
              <Avatar
                className={
                  isEditable ? classes.userAvatarEditable : classes.userAvatar
                }
                src={profile.avatar}
              >
                <Edit2 style={{ width: 50, height: 50 }} />
              </Avatar>
              {isEditable && (
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    let file = e.target.files[0];
                    handleUploadImage(file);
                  }}
                />
              )}
            </label>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={8}
            lg={8}
            id="influencer-profile"
            justify="center"
            className={
              isEditable
                ? classes.editableContainer
                : classes.notEditableContainer
            }
          >
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={8}
              lg={8}
              alignItems="center"
              style={{ cursor: isEditable ? "pointer" : "default" }}
              onClick={() =>
                isEditable && setIsDisplayingFormPersonalInfo(true)
              }
            >
              <p className={classes.userName}>{profile.fullname}</p>
              <span
                className={
                  profile?.isActive
                    ? classes.availabilityDotActive
                    : classes.availabilityDotNotActive
                }
              ></span>
              <EditOutlined
                className="editable"
                style={{ marginLeft: 30, marginBottom: 10 }}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <p>{profile.email}</p>
            </Grid> */}
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={8}
              lg={8}
              justify="flex-start"
            >
              {getFormattedSocialNetworks(profile?.socialNetworks).map(
                (socialNetwork) => {
                  return (
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={3}
                      lg={3}
                      style={{ marginBottom: 5 }}
                      key={socialNetwork.value}
                    >
                      <a
                        href={`${socialNetwork.link}${socialNetwork.username}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none", cursor: "pointer" }}
                      >
                        <Chip
                          style={{
                            background: socialNetwork.background,
                            color: socialNetwork.color,
                            cursor: "pointer",
                            minWidth: 80,
                          }}
                          icon={socialNetwork.icon}
                          label={new Intl.NumberFormat("fr-FR", {
                            notation: "compact",
                            compactDisplay: "short",
                          }).format(socialNetwork.followers)}
                        />
                      </a>
                    </Grid>
                  );
                }
              )}
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={8}
              lg={8}
              style={{ marginTop: 40 }}
            >
              <Grid
                item
                container
                xs={5}
                className={classes.profileInfo}
                style={{ cursor: "default", maxHeight: 80 }}
              >
                <Grid item xs={12}>
                  <p className={classes.infoTitle}>Tarif minimum</p>
                </Grid>
                <Grid item xs={12}>
                  <strong>{`${
                    minPrice === 0 ? "À renseigner" : `${minPrice}€`
                  }`}</strong>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={5}
                className={classes.profileInfo}
                style={{ maxHeight: 80 }}
              >
                <Grid item xs={12}>
                  <p className={classes.infoTitle}>Taux de réponse</p>
                </Grid>
                <Grid item xs={12}>
                  <strong>{Math.round(profile.responseRate)}%</strong>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};

export default PersonalInformationWidget;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Button, Avatar, TextField, Snackbar } from "@material-ui/core";
import { useHistory } from "react-router";
import { Alert } from "@material-ui/lab";
import settingsStyle from "./styles/settingsStyle";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(settingsStyle);

const Profile = ({ title, profile, isInfluencer, handleUpdateMyProfile }) => {
  const classes = useStyles();
  const history = useHistory();
  const [fullname, setFullname] = useState(profile?.fullname || "");
  const [brandName, setBrandName] = useState(profile?.brandName || "");
  const [phoneNumber, setPhoneNumber] = useState(profile?.phoneNumber || "");
  const [SIRET, setSiret] = useState(profile?.SIRET || "");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  useEffect(() => {
    if (isInfluencer) {
      setFullname(profile.fullname);
    } else {
      setBrandName(profile.brandName);
      setPhoneNumber(profile.phoneNumber);
      setSiret(profile?.SIRET);
    }
  }, [profile]);

  return (
    <>
      <Grid
        item
        container
        justify="space-between"
        style={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
          marginBottom: 30,
          paddingBottom: 10,
          height: 50,
        }}
      >
        <Grid item>
          <p
            style={{
              fontFamily: "nunito-bold",
              fontSize: 24,
              marginBottom: 20,
              display: "inline",
            }}
          >
            {title}
          </p>
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={12} md={2} lg={2}>
        <Avatar
          style={{ width: 100, height: 100, margin: "auto" }}
          className={classes.userAvatar}
          src={profile.avatar}
        >
          DA
        </Avatar>
      </Grid>
      <Grid item container xs={12} sm={12} md={10} lg={10}>
        <Grid
          item
          container
          xs={12}
          alignItems="flex-start"
          style={{ marginBottom: 30 }}
        >
          <Grid item xs={12}>
            <p
              style={{
                fontFamily: "nunito-semibold",
                fontSize: 12.5,
              }}
            >
              Email
            </p>
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={{ width: "80%" }}
              value={profile.email}
              disabled
            />
          </Grid>
        </Grid>
        {isInfluencer ? (
          <Grid
            item
            container
            xs={6}
            alignItems="flex-start"
            style={{ marginBottom: 30 }}
          >
            <Grid item xs={12}>
              <p
                style={{
                  fontFamily: "nunito-semibold",
                  fontSize: 12.5,
                }}
              >
                Nom complet
              </p>
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={{ width: "80%" }}
                value={fullname || ""}
                onChange={(e) => setFullname(e.target.value)}
              />
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid
              item
              container
              xs={6}
              alignItems="flex-start"
              style={{ marginBottom: 30 }}
            >
              <Grid item xs={12}>
                <p
                  style={{
                    fontFamily: "nunito-semibold",
                    fontSize: 12.5,
                  }}
                >
                  Nom de la marque
                </p>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  style={{ width: "80%" }}
                  value={brandName}
                  onChange={(e) => setBrandName(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={6}
              alignItems="flex-start"
              style={{ marginBottom: 30 }}
            >
              <Grid item xs={12}>
                <p
                  style={{
                    fontFamily: "nunito-semibold",
                    fontSize: 12.5,
                  }}
                >
                  Numéro de téléphone
                </p>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  style={{ width: "80%" }}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Grid>
            </Grid>
          </>
        )}
        <Grid
          item
          container
          xs={6}
          alignItems="flex-start"
          style={{ marginBottom: 30 }}
        >
          <Grid item xs={12}>
            <p
              style={{
                fontFamily: "nunito-semibold",
                fontSize: 12.5,
              }}
            >
              Mot de passe
            </p>
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={{ width: "80%" }}
              value="**********"
              type="password"
              disabled
            />
          </Grid>
        </Grid>
        {!isInfluencer && (
          <Grid
            item
            container
            xs={12}
            alignItems="flex-start"
            style={{ marginBottom: 30 }}
          >
            <Grid item xs={12}>
              <p
                style={{
                  fontFamily: "nunito-semibold",
                  fontSize: 12.5,
                }}
              >
                SIRET
              </p>
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={{ width: "80%" }}
                value={SIRET}
                onChange={(e) => setSiret(e.target.value)}
              />
            </Grid>
          </Grid>
        )}
        <Grid
          item
          container
          justify="flex-start"
          style={{ marginTop: 20 }}
          spacing={2}
        >
          <Grid item>
            <Button
              style={{
                fontFamily: "nunito-regular",
                fontSize: 12,
                color: "#FFFFFF",
                height: 30,
                paddingLeft: 20,
                paddingRight: 20,
                border: "1px solid #825DFF",
                borderRadius: 4,
                backgroundColor: "#825DFF",
              }}
              onClick={() => {
                handleUpdateMyProfile(
                  isInfluencer
                    ? { fullname }
                    : { brandName, SIRET, phoneNumber }
                );
                setIsSnackbarOpen(true);
              }}
            >
              Enregistrer
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{
                fontFamily: "nunito-regular",
                fontSize: 12,
                color: "red",
                height: 30,
                paddingLeft: 20,
                paddingRight: 20,
                border: "1px solid red",
                borderRadius: 4,
                backgroundColor: "#FFF",
              }}
              onClick={() => {
                localStorage.removeItem("token");
                history.push("/");
              }}
            >
              Se déconnecter
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        style={{ zIndex: 1400, paddingTop: 60 }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setIsSnackbarOpen(false)}
          severity="success"
          variant="filled"
        >
          Votre profil a bien été modifié !
        </Alert>
      </Snackbar>
    </>
  );
};

Profile.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default Profile;

import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useFormik } from "formik";
import * as yup from "yup";

import newMissionStyle from "./styles/newMissionStyle";
import {
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import MissionInformation from "./FormSteps/MissionInformation";
import InfluencerInformation from "./FormSteps/InfluencerInformation";
import CompleteBrief from "./FormSteps/CompleteBrief";
import { useEffect } from "react";
import {
  categoriesOptions,
  genderOptions,
  socialNetworkOptions,
} from "./utils";

const useStyles = makeStyles(newMissionStyle);

const steps = [
  "Caractéristique de la campagne",
  "Information relative à l'influenceur",
  "Ajout du/des briefs complet",
];

const NewMissionForm = ({ mission = {}, handleSaveMission }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [activeStep, setActiveStep] = useState(0);

  const validationSchema = [
    yup.object({
      title: yup
        .string("Choisissez un titre pour votre campagne")
        .required("Le titre est obligatoire"),
      description: yup
        .string("Décrivez votre campagne")
        .required("La description est obligatoire"),
      socialNetworks: yup
        .array(yup.object())
        .required("Les réseaux sociaux sont obligatoire"),
      isGifting: yup.boolean("Est-ce une campagne de gifting"),
    }),
    yup.object({
      categories: yup
        .array(yup.object())
        .required("Les catégories sont obligatoires"),
      gender: yup.array(yup.object()).required("Le sexe est obligatoire"),
      age: yup.object().required(),
      isGifting: yup.boolean(),
      target: yup.object().shape({
        age: yup
          .object()
          .shape({
            min: yup
              .number("L'âge minimum est obligatoire")
              .typeError("L'âge est obligatoire")
              .required("L'âge est obligatoire"),
            max: yup
              .number("Ce champs est obligatoire")
              .typeError("L'âge est obligatoire")
              .required("L'âge est obligatoire"),
          })
          .required("L'âge de votre cible est obligatoire"),
        gender: yup.array(yup.string()).required("Le sexe est obligatoire"),
      }),
      socialNetworks: yup
        .array(
          yup.object().shape({
            followers: yup
              .object()
              .shape({
                min: yup
                  .number("Ce champs est obligatoire")
                  .typeError("Saisissez un nombre valide")
                  .required("Ce champs est obligatoire"),
                max: yup
                  .number("Ce champs est obligatoire")
                  .typeError("Saisissez un nombre valide"),
              })
              .required(),
            prices: yup
              .object()
              .shape({
                min: yup
                  .number("Ce champs est obligatoire")
                  .typeError("Saisissez un prix valide"),
                max: yup
                  .number("Ce champs est obligatoire")
                  .typeError("Saisissez un prix valide"),
              })
              .required(),
          })
        )
        .required("Les réseaux sociaux sont obligatoire")
        .when("isGifting", {
          is: false,
          then: yup
            .array(
              yup.object().shape({
                followers: yup
                  .object()
                  .shape({
                    min: yup
                      .number("Ce champs est obligatoire")
                      .typeError("Saisissez un nombre valide")
                      .required("Ce champs est obligatoire"),
                    max: yup
                      .number("Ce champs est obligatoire")
                      .typeError("Saisissez un nombre valide"),
                  })
                  .required(),
                prices: yup
                  .object()
                  .shape({
                    min: yup
                      .number("Ce champs est obligatoire")
                      .typeError("Saisissez un prix valide"),
                    max: yup
                      .number("Ce champs est obligatoire")
                      .typeError("Saisissez un prix valide")
                      .required("Ce champs est obligatoire"),
                  })
                  .required(),
              })
            )
            .required("Les réseaux sociaux sont obligatoire"),
          otherwise: yup
            .array(
              yup.object().shape({
                followers: yup
                  .object()
                  .shape({
                    min: yup
                      .number("Ce champs est obligatoire")
                      .typeError("Saisissez un nombre valide")
                      .required("Ce champs est obligatoire"),
                    max: yup
                      .number("Ce champs est obligatoire")
                      .typeError("Saisissez un nombre valide"),
                  })
                  .required(),
                prices: yup
                  .object()
                  .shape({
                    min: yup
                      .number("Ce champs est obligatoire")
                      .typeError("Saisissez un prix valide"),
                    max: yup
                      .number("Ce champs est obligatoire")
                      .typeError("Saisissez un prix valide"),
                  })
                  .required(),
              })
            )
            .required("Les réseaux sociaux sont obligatoire"),
        }),
    }),
    yup.object({
      briefs: yup.array(
        yup.object().shape({
          title: yup.string().required("Le titre du brief est obligatoire"),
          content: yup
            .string()
            .required("La description du brief est obligatoire"),
        })
      ),
    }),
  ];

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      age: { min: 0, max: 0 },
      isGifting: false,
      briefs: [],
      target: {
        gender: [],
      },
    },
    validationSchema: validationSchema[activeStep],
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      if (activeStep !== 2) {
        setActiveStep(activeStep + 1);
      } else {
        handleSaveMission(values);
      }
    },
  });

  const isStepOptional = (step) => {
    return step === 2;
  };

  useEffect(() => {
    if (mission._id) {
      const { categories, gender, ...rest } = mission.influencerCaracteristics;
      formik.setValues({
        ...mission,
        socialNetworks: mission.socialNetworks.map((socialNetwork) => {
          return {
            ...socialNetwork,
            ...(socialNetworkOptions.find(
              (sn) => sn.value === socialNetwork.socialNetwork
            ) || {}),
          };
        }),
        ...rest,
        categories: categories.map((category) =>
          categoriesOptions.find((cat) => category === cat.value)
        ),
        gender: gender.map((g) =>
          genderOptions.find((gend) => gend.value === g)
        ),
      });
    }
  }, [mission]);

  return (
    <div className={classes.formContainer}>
      <form onSubmit={formik.handleSubmit}>
        <Stepper
          activeStep={activeStep}
          style={
            matches
              ? { textAlign: "center", padding: 0, paddingBottom: 20 }
              : {}
          }
          orientation={`${matches ? "vertical" : "horizontal"}`}
        >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            return (
              <Step
                key={label}
                {...stepProps}
                style={{
                  cursor: "pointer",
                }}
              >
                <StepLabel
                  StepIconProps={{
                    classes: {
                      active: classes.activeStep,
                      completed: classes.activeStep,
                    },
                  }}
                  {...labelProps}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Grid container spacing={4}>
          {activeStep === 0 && <MissionInformation formik={formik} />}
          {activeStep === 1 && <InfluencerInformation formik={formik} />}
          {activeStep === 2 && <CompleteBrief formik={formik} />}
          <Grid item container xs={12} spacing={4}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Button
                color="primary"
                variant="contained"
                className="button-new-mission"
                fullWidth
                type="submit"
                style={{
                  backgroundColor: "rgba(250, 128, 114,1)",
                  borderRadius: 18,
                  fontFamily: "nunito-bold !important",
                }}
                onClick={formik.handleSubmit}
              >
                {activeStep === 2 ? "Créer ma campagne" : "Suivant"}
              </Button>
            </Grid>
            {activeStep > 0 && (
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Button
                  color="primary"
                  variant="contained"
                  className="button-new-mission"
                  fullWidth
                  type="submit"
                  style={{
                    border: "1px solid rgba(250, 128, 114,1)",
                    color: "rgba(250, 128, 114,1)",
                    boxShadow: "none",
                    background: "transparent",
                    borderRadius: 18,
                    fontFamily: "nunito-bold !important",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (activeStep > 0) {
                      formik.setErrors({});
                      setActiveStep(activeStep - 1);
                    }
                  }}
                >
                  Précédent
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default NewMissionForm;

import React, { useEffect, useState } from "react";
import { Grid, Tab, Tabs, useMediaQuery, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import {
  settingsConfigBrand,
  settingsConfigInfluencer,
} from "./settingsConfig";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import settingsStyle from "./styles/settingsStyle";
import { LocalConvenienceStoreOutlined } from "@material-ui/icons";

const useStyles = makeStyles(settingsStyle);

const SettingsWrapper = ({ profile, isInfluencer, handleUpdateMyProfile }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [selectedStep, setSelectedStep] = useState(0);

  const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    setSelectedStep(
      (isInfluencer ? settingsConfigInfluencer : settingsConfigBrand).findIndex(
        (setting) => setting.path === location.pathname
      )
    );
  }, []);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile = width <= 768;

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        marginBottom: 50,
        padding: "30px 40px",
        backgroundColor: "#fff",
        borderRadius: 8,
        boxShadow: "0 0 8px 0 rgb(0 0 0 / 5%)",
        margin: "auto",
      }}
    >
      {isMobile && (
        <Grid item xs={12}>
          <Tabs
            value={selectedStep}
            onChange={(e, value) => {
              setSelectedStep(value);
              history.push(
                (isInfluencer ? settingsConfigInfluencer : settingsConfigBrand)[
                  value
                ].path
              );
            }}
          >
            {(isInfluencer
              ? settingsConfigInfluencer
              : settingsConfigBrand
            ).map((config, index) => (
              <Tab
                style={{
                  textDecoration: "none",
                  textTransform: "none",
                  fontSize: 14,
                  fontFamily: "nunito-regular",
                }}
                label={config.title}
                value={index}
              />
            ))}
          </Tabs>
        </Grid>
      )}
      <Grid
        item
        container
        xs={12}
        sm={12}
        lg={9}
        md={9}
        style={{
          paddingBottom: 20,
          paddingRight: 20,
          marginTop: 50,
        }}
        justify="space-between"
        alignItems="flex-start"
      >
        {/* <ComponentToDisplay
          title={
            isInfluencer
              ? settingsConfigInfluencer[selectedStep].title
              : settingsConfigBrand[selectedStep].title
          }
          profile={profile}
          isInfluencer={isInfluencer}
          handleUpdateMyProfile={handleUpdateMyProfile}
        /> */}
        <Switch>
          {(isInfluencer ? settingsConfigInfluencer : settingsConfigBrand).map(
            (prop) => {
              return (
                <Route
                  key={prop.path}
                  render={(props) => (
                    <prop.component
                      profile={profile}
                      isInfluencer={isInfluencer}
                      handleUpdateMyProfile={handleUpdateMyProfile}
                      {...prop}
                    />
                  )}
                  exact={prop.exact}
                  path={prop.path}
                />
              );
            }
          )}
        </Switch>
      </Grid>
      {!isMobile && (
        <Grid
          item
          container
          justify="flex-start"
          alignItems="flex-start"
          xs={12}
          sm={12}
          lg={3}
          md={3}
          className={classes.menuChoice}
        >
          <Grid item container justify="flex-start">
            {(isInfluencer
              ? settingsConfigInfluencer
              : settingsConfigBrand
            ).map((config, index) => {
              return (
                <Grid
                  key={config.title}
                  item
                  container
                  xs={12}
                  style={{ marginBottom: 20, cursor: "pointer" }}
                  onClick={() => {
                    history.push(config.path);
                    setSelectedStep(index);
                  }}
                >
                  {selectedStep === index && (
                    <Grid
                      item
                      style={{
                        width: 0,
                        height: 0,
                        borderStyle: "solid",
                        borderWidth: "10px 10px 10px 0px",
                        borderColor: `transparent #825DFF transparent transparent`,
                        marginLeft: -10,
                        marginTop: 35,
                      }}
                    ></Grid>
                  )}
                  <Grid
                    item
                    container
                    style={{
                      height: 90,
                      border: "1px solid #825DFF",
                      width: "90%",
                      borderRadius: 5,
                      padding: 12,
                    }}
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <p
                        style={{
                          fontSize: 16,
                          fontFamily: "nunito-bold",
                          color: "#000",
                        }}
                      >
                        {config.title}
                      </p>
                    </Grid>
                    <Grid item xs={12}>
                      <p
                        style={{
                          fontSize: 12.5,
                          fontFamily: "nunito-regular",
                          color: "#000",
                        }}
                      >
                        {config.description}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default SettingsWrapper;

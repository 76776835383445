import { Grid } from "@material-ui/core";
import React from "react";

import Check from "../../../../../assets/svg/check1.svg";

const BrandValidation = () => {
  return (
    <Grid item container direction="column" xs={12}>
      <p style={{ width: "100%", textAlign: "center", fontSize: 24 }}>
        En attente de la validation de la marque
      </p>
      <img src={Check} alt="check" />
      <p
        style={{
          width: "60%",
          textAlign: "center",
          margin: "auto",
          marginTop: 10,
          fontSize: 16,
        }}
      >
        Lorsque la marque aura validé votre travail vous serez notifié et allez
        recevoir votre argent.
      </p>
    </Grid>
  );
};

export default BrandValidation;

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";

import { Avatar, Badge, Chip, Grid, Hidden } from "@material-ui/core";

import {
  IoPeopleOutline,
  IoLocationOutline,
  IoLogoEuro,
  IoCardOutline,
} from "react-icons/io5";

import WaitingInfluencers from "../../../../../assets/svg/waiting-influencers.svg";

import missionPageStyle from "../styles/missionPageStyles";
import { categoriesOptions } from "../../../NewMission/utils";
import { getFormattedSocialNetworks } from "../../../../Influencer/InfluencerProfilePage/PersonalInformation/utils";
import axios from "axios";
import { API_URL } from "../../../../../common/constants";
import RemoveInfluencerModal from "./RemoveInfluencerModal";
import { useState } from "react";
import { Check, Clear, Remove } from "@material-ui/icons";
import { MessageCircle, X, Search } from "react-feather";
import FollowProcessModal from "../../../../Common/Messages/modals/FollowProcessModal";

const useStyles = makeStyles(missionPageStyle);

const InfluencerList = ({
  missionId,
  influencers = [],
  handleRefreshMission,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [isDisplayingRemoveModal, setIsDisplayingRemoveModal] = useState(false);
  const [removedInfluencerMission, setRemovedInfluencerMission] =
    useState(undefined);
  const [followModalInfluencer, setFollowModalInfluencer] = useState(undefined);

  const handleStartDiscussionWithInfluencer = (influencerMissionId) => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .put(
        `${API_URL}/brand/missions/influencer-mission/${influencerMissionId}/start-discussion`
      )
      .then((res) => handleRefreshMission());
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  if (influencers && influencers.length === 0)
    return (
      <Grid item container direction="column" xs={12} style={{ marginTop: 50 }}>
        <p style={{ width: "100%", textAlign: "center", fontSize: 24 }}>
          En attente de la réponse des influenceurs
        </p>
        <img
          src={WaitingInfluencers}
          alt="check"
          className={classes.waitingForInfluencer}
        />
        <p
          style={{
            width: "60%",
            textAlign: "center",
            margin: "auto",
            marginTop: 10,
            fontSize: 16,
          }}
        >
          Les influenceurs ont été contacté grâce à notre algorithme de
          matching, ils vont bientôt postuler pour votre campagne.
        </p>
      </Grid>
    );

  return (
    <>
      <Grid
        item
        container
        xs={12}
        justify="center"
        alignItems="flex-start"
        style={{
          border: "1px solid rgba(0, 0, 0, 0.12)",
          background: "#FFF",
        }}
      >
        <Grid
          item
          container
          xs={12}
          style={{
            backgroundColor: "#FFF",
            padding: 40,
            paddingBottom: 10,
            borderRadius: 6,
            position: "relative",
          }}
        >
          <p className={classes.widgetTitle}>
            Les Influenceurs correspondant le plus à votre recherche
          </p>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: "#FFF",
            paddingLeft: 40,
            borderRadius: 6,
            position: "relative",
          }}
        >
          <p
            style={{
              color: "#888",
              fontStyle: "italic",
              fontSize: 14,
              marginBottom: 20,
            }}
          >
            Ces influenceurs ont postulé à votre campagne.
            <br />
            Envoyez leur un message afin de lancer la discussion.
          </p>
        </Grid>
        <Grid
          item
          container
          className={classes.listing}
          alignItems="flex-start"
        >
          {influencers
            .map((influencerMission, key) => {
              if (!influencerMission.influencerId) return null;
              let minPrice = Math.min(
                ...influencerMission?.influencerId?.socialNetworks?.map(
                  (network) => {
                    return network.price;
                  }
                )
              );

              if (
                typeof minPrice !== "number" ||
                Number.isNaN(minPrice) ||
                !Number.isFinite(minPrice)
              ) {
                minPrice = 0;
              }

              return (
                <Grid
                  key={key}
                  item
                  container
                  xs={12}
                  className={classes.influencerItem}
                  style={{
                    opacity:
                      influencerMission?.influencerStatus === "DONE" ||
                      influencerMission?.followProcess?.influencerEvaluation
                        ?.rating
                        ? 0.5
                        : 1,
                  }}
                  alignItems="center"
                >
                  <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    md={10}
                    lg={10}
                    alignItems="center"
                    className={classes.userAvatarContainer}
                  >
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <Avatar
                        className={classes.userAvatar}
                        src={influencerMission.influencerId?.avatar}
                      ></Avatar>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={10}
                      sm={10}
                      md={10}
                      lg={10}
                      style={{
                        paddingTop: 20,
                        paddingBottom: 20,
                      }}
                    >
                      <Grid
                        item
                        container
                        xs={6}
                        sm={6}
                        md={4}
                        lg={4}
                        className={classes.influencerInformationContainer}
                      >
                        <Grid item xs={12} style={{ paddingBottom: 5 }}>
                          <p
                            className={classes.userName}
                            style={{
                              fontFamily: "nunito-semibold",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              influencerMission?.influencerStatus !== "DONE" &&
                                !influencerMission?.followProcess
                                  ?.influencerEvaluation?.rating &&
                                window.open(
                                  `/brand/missions/influencer-profile/${influencerMission.influencerId._id}`
                                );
                            }}
                          >
                            {influencerMission.influencerId.fullname}
                            <Search
                              style={{ width: 16, height: 16, marginLeft: 10 }}
                            />
                          </p>
                        </Grid>
                        {getFormattedSocialNetworks(
                          influencerMission.influencerId.socialNetworks
                        ).map((socialNetwork) => {
                          return (
                            <Grid
                              item
                              container
                              xs={12}
                              key={socialNetwork.value}
                              style={{
                                marginTop: 5,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                influencerMission?.influencerStatus !==
                                  "DONE" &&
                                !influencerMission?.followProcess
                                  ?.influencerEvaluation?.rating &&
                                window.open(
                                  `${socialNetwork.link}${socialNetwork.username}/`
                                )
                              }
                            >
                              {socialNetwork.iconWithoutChip}
                              <p className={classes.separator}> | </p>
                              <IoPeopleOutline />
                              <p
                                className={classes.influencerContent}
                                style={{ marginLeft: 5 }}
                              >
                                {new Intl.NumberFormat("fr-FR", {
                                  notation: "compact",
                                  compactDisplay: "short",
                                }).format(socialNetwork.followers)}
                              </p>
                            </Grid>
                          );
                        })}
                        <Grid item container xs={12} style={{ marginTop: 5 }}>
                          <p className={classes.influencerContent}>
                            Tarif minimum: {minPrice} €
                          </p>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={6}
                        sm={6}
                        md={7}
                        lg={7}
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid item container xs={12}>
                          {influencerMission.influencerId.categories
                            .slice(0, 3)
                            .map((category) => (
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={4}
                                lg={4}
                                xl={3}
                                style={{ padding: 10, paddingLeft: 0 }}
                                key={category}
                              >
                                <Chip
                                  style={{
                                    width: "90%",
                                    backgroundColor: "rgba(130,93,255,0.6)",
                                    color: "#FFF",
                                  }}
                                  label={
                                    categoriesOptions.find(
                                      (opt) => opt.value === category
                                    ).label
                                  }
                                />
                              </Grid>
                            ))}
                        </Grid>
                        {influencerMission.status === "PAID" && (
                          <Grid item container xs={12}>
                            <p
                              style={{
                                fontSize: 14,
                                color: "#083671",
                                fontFamily: "nunito-semibold",
                                textDecoration: "underline",
                                cursor: "pointer",
                                marginTop: 20,
                              }}
                              onClick={() => {
                                influencerMission?.influencerStatus !==
                                  "DONE" &&
                                  !influencerMission?.followProcess
                                    ?.influencerEvaluation?.rating &&
                                  setFollowModalInfluencer(influencerMission);
                              }}
                            >
                              Voir le suivi
                            </p>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  {(influencerMission?.influencerStatus === "DONE" ||
                    influencerMission?.followProcess?.influencerEvaluation
                      ?.rating) && (
                    <Grid
                      item
                      container
                      xs={12}
                      sm={12}
                      md={2}
                      lg={2}
                      justify="space-between"
                      style={{
                        padding: 20,
                        height: "100%",
                        borderLeft: "1px dashed rgba(250, 128, 114,0.3)",
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        className={classes.customChip}
                        container
                        alignItems="center"
                        justify="center"
                      >
                        <p style={{ fontFamily: "nunito-bold" }}>
                          Mission terminée
                        </p>
                      </Grid>
                    </Grid>
                  )}
                  {influencerMission?.influencerStatus !== "DONE" &&
                    !influencerMission?.followProcess?.influencerEvaluation
                      ?.rating && (
                      <Hidden only={["sm", "xs"]}>
                        <Grid
                          item
                          container
                          xs={2}
                          justify="space-between"
                          style={{
                            padding: 40,
                            height: "100%",
                            borderLeft: "1px dashed rgba(250, 128, 114,0.3)",
                            marginBottom: 40,
                          }}
                        >
                          <Grid item xs={6} style={{ textAlign: "center" }}>
                            <Badge
                              color="secondary"
                              variant="dot"
                              invisible={influencerMission?.hasMessages}
                            >
                              <MessageCircle
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={async () => {
                                  await handleStartDiscussionWithInfluencer(
                                    influencerMission._id
                                  );
                                  history.push(
                                    `/brand/missions/${missionId}/messages?influencer=${influencerMission.influencerId._id}`
                                  );
                                }}
                              />
                            </Badge>
                          </Grid>
                          <Grid item xs={6} style={{ textAlign: "center" }}>
                            <X
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setIsDisplayingRemoveModal(
                                  influencerMission._id
                                );
                                setRemovedInfluencerMission(influencerMission);
                              }}
                            />
                          </Grid>
                          {influencerMission?.influencerStatus === "DOING" && (
                            <Grid
                              item
                              xs={12}
                              className={classes.customChip}
                              container
                              alignItems="center"
                              justify="center"
                              style={{ marginTop: 20 }}
                            >
                              <p style={{ fontFamily: "nunito-bold" }}>
                                En cours
                              </p>
                            </Grid>
                          )}
                        </Grid>
                      </Hidden>
                    )}
                  {influencerMission?.influencerStatus !== "DONE" &&
                    !influencerMission?.followProcess?.influencerEvaluation
                      ?.rating && (
                      <Hidden only={["md", "lg", "xl"]}>
                        <Grid
                          item
                          container
                          xs={12}
                          justify="space-between"
                          style={{
                            padding: 40,
                            height: "100%",
                          }}
                        >
                          <Grid item xs={6}>
                            <p
                              style={{ textDecoration: "underline" }}
                              onClick={async () => {
                                await handleStartDiscussionWithInfluencer(
                                  influencerMission._id
                                );
                                history.push(
                                  `/brand/missions/${missionId}/messages?influencer=${influencerMission.influencerId._id}`
                                );
                              }}
                            >
                              Envoyer un message
                            </p>
                          </Grid>
                          <Grid item xs={6}>
                            <p
                              style={{ color: "red" }}
                              onClick={() => {
                                setIsDisplayingRemoveModal(
                                  influencerMission._id
                                );
                                setRemovedInfluencerMission(influencerMission);
                              }}
                            >
                              Supprimer
                            </p>
                          </Grid>
                        </Grid>
                      </Hidden>
                    )}
                </Grid>
              );
            })
            .filter((value) => value)}
        </Grid>
      </Grid>
      {removedInfluencerMission && (
        <RemoveInfluencerModal
          isModalOpen={isDisplayingRemoveModal}
          handleCloseModal={setIsDisplayingRemoveModal}
          missionId={missionId}
          influencerMissionId={removedInfluencerMission?._id}
          influencer={removedInfluencerMission?.influencerId}
          handleRefreshMission={handleRefreshMission}
        />
      )}
      {followModalInfluencer && (
        <FollowProcessModal
          isModalOpen={followModalInfluencer}
          handleCloseModal={() => setFollowModalInfluencer(undefined)}
          influencerMissionId={followModalInfluencer._id}
          followProcess={followModalInfluencer.followProcess}
          handleRefreshFollowProcess={handleRefreshMission}
          influencerName={followModalInfluencer.influencerId.fullname}
          missionId={missionId}
        />
      )}
    </>
  );
};

export default InfluencerList;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";

import { Avatar, Grid } from "@material-ui/core";

import profilePageStyle from "../styles/profilePage";
import { Rating } from "@material-ui/lab";
import axios from "axios";
import { API_URL } from "../../../../common/constants";

const useStyles = makeStyles(profilePageStyle);

const Notices = ({ influencerId, brandId, isInfluencer }) => {
  const classes = useStyles();

  const [notices, setNotices] = useState([]);

  const getNoticesInfluencer = () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .get(`${API_URL}/influencer/profile/notices/${influencerId}`)
      .then((res) => setNotices(res.data));
  };

  const getNoticesBrand = () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .get(`${API_URL}/brand/profile/notices/${brandId}`)
      .then((res) => setNotices(res.data));
  };

  useEffect(() => {
    if (isInfluencer) {
      getNoticesInfluencer();
    } else {
      getNoticesBrand();
    }
  }, []);

  console.log("notices", notices);

  return (
    <Grid
      item
      container
      className={classes.widgetContainer}
      alignItems="flex-start"
    >
      <Grid item container xs={12} justify="space-between">
        <Grid item xs={12}>
          <p className={classes.widgetTitle}>{notices.length} avis Pop</p>
        </Grid>
      </Grid>
      {notices.map((notice, key) => {
        return (
          <Grid
            key={key}
            item
            container
            xs={12}
            className={classes.noticeContentContainer}
            alignItems="center"
          >
            <Grid item xs={2}>
              <Avatar
                style={{ width: 50, height: 50 }}
                src={
                  isInfluencer
                    ? notice.brandId.avatar
                    : notice.influencerId.avatar
                }
              >
                DA
              </Avatar>
            </Grid>
            <Grid item container xs={8} style={{ marginLeft: 20 }}>
              <Grid item>
                <p className={classes.widgetCompanyTitle}>
                  {isInfluencer
                    ? notice.brandId.brandName
                    : notice.influencerId.fullname}
                  -
                </p>
              </Grid>
              <Grid item style={{ marginLeft: 10 }}>
                <p className={classes.widgetCompanyTitle}>
                  <Rating
                    readOnly
                    value={
                      isInfluencer
                        ? notice.followProcess.influencerEvaluation.rating
                        : notice.followProcess.brandEvaluation.rating
                    }
                  />
                </p>
              </Grid>
              <Grid item xs={12}>
                <p className={classes.widgetCompanyDescription}>
                  {isInfluencer
                    ? notice.followProcess.influencerEvaluation.comment
                    : notice.followProcess.brandEvaluation.comment}
                </p>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

Notices.propTypes = {};

export default Notices;

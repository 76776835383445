import { Grid } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Helmet } from "react-helmet";
import { API_URL } from "../../../common/constants";
import SettingsWrapper from "../../../Components/Common/SettingsPage/SettingsWrapper";

const SettingsPage = () => {
  const history = useHistory();
  const [profile, setProfile] = useState({});

  const getProfile = () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .get(`${API_URL}/influencer/profile`)
      .then((res) => setProfile(res.data))
      .catch((error) => {
        localStorage.removeItem("token");
        history.push("/");
      });
  };

  const handleUpdateMyProfile = (body) => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .put(`${API_URL}/influencer/profile`, {
        ...body,
      })
      .then((res) => getProfile())
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={10}
      lg={10}
      style={{ margin: "auto" }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`POP App - Réglages - ${profile.fullname}`}</title>
        <link rel="canonical" href="https://pop-app.fr" />
      </Helmet>
      <SettingsWrapper
        profile={profile}
        isInfluencer={true}
        handleUpdateMyProfile={handleUpdateMyProfile}
      />
    </Grid>
  );
};

export default SettingsPage;

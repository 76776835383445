import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import reportWebVitals from "./reportWebVitals";

import Routes from "./routes/index";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

ReactDOM.render(
  // <React.StrictMode>
  //   <div style={{ background: "#f7f7f5" }}>
  //     <App />
  //   </div>
  // </React.StrictMode>,
  <Elements stripe={stripePromise}>
    <Routes />
  </Elements>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import { Grid } from "@material-ui/core";
import { Check } from "@material-ui/icons";

import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

import profilePageStyle from "../styles/profilePage";
import { useState } from "react";
import axios from "axios";
import { API_URL } from "../../../../common/constants";

const useStyles = makeStyles(profilePageStyle);

// const QontoConnector = withStyles({
//   alternativeLabel: {
//     top: 10,
//     left: "calc(-50% + 16px)",
//     right: "calc(50% + 16px)",
//   },
//   active: {
//     "& $line": {
//       borderColor: "#784af4",
//     },
//   },
//   completed: {
//     "& $line": {
//       borderColor: "#784af4",
//     },
//   },
//   line: {
//     borderColor: "#eaeaf0",
//     borderTopWidth: 3,
//     borderRadius: 1,
//   },
// })(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { completed } = props;

  return (
    <div className={classes.root}>
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const UserEvolution = ({ influencerId, brandId, isInfluencer }) => {
  const classes = useStyles();
  const [notices, setNotices] = useState([]);

  const getNoticesInfluencer = () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .get(`${API_URL}/influencer/profile/notices/${influencerId}`)
      .then((res) => {
        setNotices(res.data);
      });
  };

  const getNoticesBrand = () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .get(`${API_URL}/brand/profile/notices/${brandId}`)
      .then((res) => setNotices(res.data));
  };

  // const getActiveStep = () => {
  //   if (notices.length <= 5) {
  //     return 1;
  //   } else if (notices.length <= 15) {
  //     return 2;
  //   } else if (notices.length <= 50) {
  //     return 3;
  //   } else if (notices.length <= 100) {
  //     return 4;
  //   } else if (notices.length <= 200) {
  //     return 5;
  //   } else {
  //     return 1;
  //   }
  // };

  useEffect(() => {
    if (isInfluencer) {
      getNoticesInfluencer();
    } else {
      getNoticesBrand();
    }
  }, []);

  return (
    <Grid item container className={classes.widgetContainer} justify="center">
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <p className={classes.widgetTitle}>Mon évolution</p>
        </Grid>
        <Grid item xs={11} style={{ margin: "auto", paddingTop: 30 }}>
          <ProgressBar
            percent={(notices.length / 50) * 100}
            filledBackground="linear-gradient(to right, #784af4, #784af9)"
          >
            <Step transition="scale">
              {({ accomplished }) => (
                <p
                  style={{
                    fontSize: 18,
                    borderRadius: "50%",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    padding: 10,
                    height: 20,
                    background: "rgba(130,93,255,1)",
                    color: "#FFF",
                    width: 20,
                    textAlign: "center",
                  }}
                >
                  0
                </p>
              )}
            </Step>
            <Step transition="scale">
              {({ accomplished }) => (
                <p
                  style={{
                    fontSize: 18,
                    borderRadius: "50%",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    padding: 10,
                    height: 20,
                    background: "rgba(130,93,255,1)",
                    color: "#FFF",
                    width: 20,
                    textAlign: "center",
                  }}
                >
                  10
                </p>
              )}
            </Step>
            <Step transition="scale">
              {({ accomplished }) => (
                <p
                  style={{
                    fontSize: 18,
                    borderRadius: "50%",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    padding: 10,
                    height: 20,
                    background: "rgba(130,93,255,1)",
                    color: "#FFF",
                    width: 20,
                    textAlign: "center",
                  }}
                >
                  20
                </p>
              )}
            </Step>
            <Step transition="scale">
              {({ accomplished }) => (
                <p
                  style={{
                    fontSize: 18,
                    borderRadius: "50%",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    padding: 10,
                    height: 20,
                    background: "rgba(130,93,255,1)",
                    color: "#FFF",
                    width: 20,
                    textAlign: "center",
                  }}
                >
                  30
                </p>
              )}
            </Step>
            <Step transition="scale">
              {({ accomplished }) => (
                <p
                  style={{
                    fontSize: 18,
                    borderRadius: "50%",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    padding: 10,
                    height: 20,
                    background: "rgba(130,93,255,1)",
                    color: "#FFF",
                    width: 20,
                    textAlign: "center",
                  }}
                >
                  40
                </p>
              )}
            </Step>
          </ProgressBar>
          {/* <Stepper
            alternativeLabel
            activeStep={getActiveStep()}
            connector={<QontoConnector />}
          >
            <Step key={"1"}>
              <StepLabel StepIconComponent={QontoStepIcon}>5 posts</StepLabel>
            </Step>
            <Step key={"2"}>
              <StepLabel StepIconComponent={QontoStepIcon}>15 posts</StepLabel>
            </Step>
            <Step key={"3"}>
              <StepLabel StepIconComponent={QontoStepIcon}>50 posts</StepLabel>
            </Step>
            <Step key={"4"}>
              <StepLabel StepIconComponent={QontoStepIcon}>100 posts</StepLabel>
            </Step>
            <Step key={"5"}>
              <StepLabel StepIconComponent={QontoStepIcon}>200 posts</StepLabel>
            </Step>
          </Stepper> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserEvolution;

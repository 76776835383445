import React, { useEffect, useState } from "react";
import PersonalInformation from "./PersonalInformation/PersonalInformation";
import { makeStyles } from "@material-ui/styles";

import { Grid, Snackbar } from "@material-ui/core";

import profilePageStyle from "./styles/profilePage";
import Activity from "./Widgets/Activity";
import UserDescription from "./Widgets/UserDescription";
import Experiences from "./Widgets/Experiences";
import UserEvolution from "./Widgets/UserEvolution";
import Notices from "./Widgets/Notices";
import { PermDeviceInformationOutlined } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { useTour } from "@reactour/tour";

const useStyles = makeStyles(profilePageStyle);

const InfluencerProfilePage = ({ profile, handleUpdateInfluencerProfile }) => {
  const classes = useStyles();
  const [isEndFormSnackbarOpen, setIsEndFormSnackbarOpen] = useState(false);
  const { isOpen } = useTour();

  useEffect(() => {
    if (
      profile?.age > 0 &&
      profile?.gender?.length > 0 &&
      profile?.categories?.length > 0 &&
      profile?.description?.length > 0 &&
      !profile?.hasFillProfile
    ) {
      setIsEndFormSnackbarOpen(true);
      handleUpdateInfluencerProfile({ ...profile, hasFillProfile: true });
    }
  }, [profile]);

  return (
    <div>
      <PersonalInformation
        profile={profile}
        handleUpdateInfluencerProfile={handleUpdateInfluencerProfile}
      />
      <Grid
        container
        xs={12}
        sm={12}
        md={10}
        lg={10}
        className={classes.pageLayout}
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid item container xs={12}>
          <UserEvolution isInfluencer influencerId={profile._id} />
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={4}
          lg={4}
          alignItems="flex-start"
          justify="center"
          style={{ marginTop: 20 }}
        >
          <Activity
            userActivities={profile.categories}
            handleUpdateInfluencerProfile={handleUpdateInfluencerProfile}
            isEditable={!isOpen}
          />
          {/* <UserPictures /> */}
          <Notices isInfluencer influencerId={profile._id} />
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={8}
          lg={8}
          alignItems="flex-start"
          justify="center"
          style={{ paddingTop: 20, paddingLeft: 10 }}
        >
          <UserDescription
            isEditable
            isTourOpen={isOpen}
            profileDescription={profile.description}
            handleUpdateInfluencerProfile={handleUpdateInfluencerProfile}
          />
          <Experiences
            handleUpdateInfluencerProfile={handleUpdateInfluencerProfile}
            isEditable
            isTourOpen={isOpen}
            experiences={profile.experiences}
          />
        </Grid>
      </Grid>
      <Snackbar
        open={isEndFormSnackbarOpen}
        autoHideDuration={6000}
        style={{ zIndex: 1400, paddingTop: 60 }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setIsEndFormSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setIsEndFormSnackbarOpen(false)}
          severity="success"
          variant="filled"
        >
          Merci d'avoir complété votre profil ! Les marques pourront très
          bientôt vous contacter !
        </Alert>
      </Snackbar>
    </div>
  );
};

InfluencerProfilePage.propTypes = {};

export default InfluencerProfilePage;

import React from "react";
import { Helmet } from "react-helmet";
import Register from "../../../Components/Auth/Register/Register";

const RegisterWrapper = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>POP App - Inscription Marque</title>
        <link rel="canonical" href="https://pop-app.fr" />
      </Helmet>
      <Register isInfluencer={false} />
    </>
  );
};

export default RegisterWrapper;

import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Tab,
  Tabs,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTheme, makeStyles, styled } from "@material-ui/styles";

import { DialogTitle, DialogContent } from "../../Dialog/Dialog";

import settingsStyle from "../styles/settingsStyle";
import { CloudUpload } from "@material-ui/icons";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { API_URL } from "../../../../common/constants";
import { useEffect } from "react";

const useStyles = makeStyles(settingsStyle);

const Input = styled("input")({
  display: "none",
});

const WithdrawMoneyModal = ({
  isModalOpen,
  handleCloseModal,
  profile,
  handleUpdateInfluencerProfile,
}) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [file, setFile] = useState(undefined);
  const [isDataSaved, setIsDataSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMissingFile, setIsMissingFile] = useState(false);

  const validationSchema = yup.object({
    companyName: yup
      .string("Nom de la société")
      .required("Le nom de la société est obligatoire"),
    SIRET: yup.string("SIRET").required("Le numéro de siret est obligatoire"),
  });

  const formik = useFormik({
    initialValues: {
      companyName: "",
      SIRET: "",
    },
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      if (!file) {
        setIsMissingFile(true);
      } else {
        handleUploadImage(values);
      }
    },
  });

  useEffect(() => {
    formik.setValues({ ...profile?.companyInformation });
    if (profile?.paymentMeans) {
      setFile({
        name: `RIB-${profile?.fullname.replace(/\s/g, "-")}`,
      });
    }
  }, [profile]);

  function handleWithdrawMoney() {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .post(`${API_URL}/influencer/profile/withdraw-money`)
      .then((res) => {})
      .catch((error) => console.error(error));
  }

  const handleUploadImage = (values) => {
    let formData = new FormData();

    formData.append("file", file);
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    setIsLoading(true);
    axios
      .post(`${API_URL}/influencer/profile/payment-means`, formData)
      .then((res) => {
        handleUpdateInfluencerProfile({
          ...profile,
          companyInformation: values,
          paymentMeans: res.data.filename,
        });
        handleWithdrawMoney();
        setIsLoading(false);
        setIsDataSaved(true);
        setTimeout(() => {
          setIsDataSaved(false);
          handleCloseModal(false);
        }, 5000);
      })
      .catch((error) => console.error(error));
  };

  function handleOnClose() {
    handleCloseModal(false);
  }

  return (
    <Dialog
      open={isModalOpen}
      maxWidth="md"
      fullWidth={true}
      fullScreen={matches}
      onClose={handleOnClose}
    >
      <DialogTitle onClose={handleOnClose}>Retirer mon argent</DialogTitle>
      <DialogContent dividers>
        <Grid container xs={12} justify="center">
          <AppBar position="static" color="default">
            <Tabs
              value={currentTab}
              onChange={(e, value) => {
                setCurrentTab(value);
              }}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              centered
            >
              <Tab label="J'ai une société" />
              <Tab label="Je n'ai pas de société" />
            </Tabs>
          </AppBar>
          {currentTab === 0 && (
            <Grid
              item
              container
              xs={12}
              justify="center"
              style={{ marginTop: 40 }}
            >
              {isLoading && <CircularProgress />}
              {isDataSaved && (
                <Grid item container xs={12} justify="center">
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <p>
                      Merci d'avoir effectué une demande de retrait de votre
                      argent.
                    </p>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <p>Cette demande sera traité sous 48 heures.</p>
                  </Grid>
                </Grid>
              )}
              {!isLoading && !isDataSaved && (
                <>
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <p style={{ textAlign: "center" }}>
                      Renseignez les informations de votre société et retirer
                      votre argent.
                    </p>
                  </Grid>
                  <form
                    style={{ width: "100%" }}
                    className={classes.formContainer}
                  >
                    <Grid
                      item
                      container
                      xs={12}
                      style={{ marginTop: 30 }}
                      justify="space-between"
                    >
                      <Grid item xs={12}>
                        <label className={classes.socialNetworkLabel}>
                          Informations de votre société
                        </label>
                        <div className={classes.socialNetworkBorder}></div>
                      </Grid>
                      <Grid
                        item
                        container
                        direction="column"
                        xs={12}
                        sm={12}
                        md={5}
                        lg={5}
                      >
                        <label className={classes.fieldLabel}>
                          Nom de la société
                        </label>
                        <TextField
                          fullWidth
                          inputProps={{ style: { padding: "8.5px 10px" } }}
                          id="companyName"
                          name="companyName"
                          variant="outlined"
                          value={formik?.values.companyName}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.companyName &&
                            Boolean(formik.errors.companyName)
                          }
                          helperText={
                            formik.touched.companyName &&
                            formik.errors.companyName
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        container
                        direction="column"
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                      >
                        <label className={classes.fieldLabel}>SIRET</label>
                        <TextField
                          fullWidth
                          inputProps={{ style: { padding: "8.5px 10px" } }}
                          id="SIRET"
                          name="SIRET"
                          variant="outlined"
                          value={formik?.values.SIRET}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.SIRET && Boolean(formik.errors.SIRET)
                          }
                          helperText={
                            formik.touched.SIRET && formik.errors.SIRET
                          }
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 20 }}>
                        <label className={classes.socialNetworkLabel}>
                          RIB
                        </label>
                        <div className={classes.socialNetworkBorder}></div>
                        <p
                          style={{
                            fontSize: 12,
                            color: "rgb(158, 158, 158)",
                            marginBottom: 20,
                          }}
                        >
                          Un RIB à jour est nécessaire pour recevoir votre
                          paiement chaque mois. Si vous changez de banque
                          pendant une mission, pensez à bien mettre à jour votre
                          RIB sur cette page.
                        </p>
                      </Grid>
                      <Grid
                        item
                        container
                        direction="column"
                        xs={12}
                        sm={12}
                        md={5}
                        lg={5}
                      >
                        {/* <input
                          type="file"
                          onChange={(e) => {
                            let newFile = e.target.files[0];

                            setFile(newFile);
                            setIsMissingFile(false);
                            // handleUploadImage(file);
                          }}
                        /> */}
                        <label htmlFor="contained-button-file">
                          <Input
                            id="contained-button-file"
                            type="file"
                            onChange={(e) => {
                              let newFile = e.target.files[0];

                              setFile(newFile);
                              setIsMissingFile(false);
                            }}
                          />
                          <Button
                            variant="contained"
                            component="span"
                            style={{ textTransform: "none" }}
                            endIcon={<CloudUpload />}
                          >
                            Sélectionner mon RIB
                          </Button>
                        </label>
                        {file?.name && (
                          <p style={{ marginTop: 10 }}>{file?.name}</p>
                        )}
                      </Grid>
                    </Grid>
                    {isMissingFile && (
                      <Grid item xs={12}>
                        <p
                          style={{
                            color: "#f44336",
                            marginTop: 10,
                            fontSize: "0.75rem",
                          }}
                        >
                          Le RIB est obligatoire
                        </p>
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={5}
                      lg={5}
                      style={{ marginTop: 20 }}
                    >
                      <Button
                        style={{
                          fontFamily: "nunito-bold",
                          fontSize: 13,
                          color: "#FFF",
                          width: "100%",
                          height: 40,
                          paddingLeft: 20,
                          paddingRight: 20,
                          borderRadius: 4,
                          backgroundColor: "#825DFF",
                          marginTop: 20,
                          textTransform: "none",
                        }}
                        onClick={formik.handleSubmit}
                      >
                        Retirer mon argent
                      </Button>
                    </Grid>
                  </form>
                </>
              )}
            </Grid>
          )}
          {currentTab === 1 && (
            <Grid
              item
              container
              xs={12}
              justify="center"
              style={{ marginTop: 40 }}
            >
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <p style={{ textAlign: "center" }}>
                  Vous ne disposez pas d'une société, nous ne pourrons pas vous
                  reverser votre argent directement via un virement. Nous
                  pouvons donc vous envoyez des bons cadeaux, merci de choisir
                  parmis la liste le type de bon cadeaux que vous souhaitez.
                </p>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default WithdrawMoneyModal;

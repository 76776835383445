import React from "react";
import {
  IoLogoTiktok,
  IoLogoInstagram,
  IoLogoYoutube,
  IoLogoTwitch,
  IoLogoSnapchat,
} from "react-icons/io5";

export const colourStyles = (isInfluencer = false) => {
  return {
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: isInfluencer
          ? "rgba(130,93,255,0.5)"
          : "rgba(250, 128, 114, 0.1)",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: isInfluencer ? "white" : "rgba(250, 128, 114, 1)",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: isInfluencer ? "rgba(130,93,255,0.7)" : "rgba(250, 128, 114,1)",
      ":hover": {
        backgroundColor: data.color,
        color: "white",
      },
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (base) => ({
      ...base,
      zIndex: 9999,
    }),
  };
};

export const socialNetworkOptions = [
  {
    value: "instagram",
    link: "https://instagram.com/",
    label: "Instagram",
    iconWithoutChip: <IoLogoInstagram />,
    icon: <IoLogoInstagram style={{ fill: "#FFF" }} />,
    background:
      "linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)",
    color: "#FFF",
  },
  {
    value: "tiktok",
    link: "https://tiktok.com/@",
    label: "TikTok",
    iconWithoutChip: <IoLogoTiktok />,
    icon: <IoLogoTiktok style={{ fill: "#000" }} />,
    background: "#EE1D52",
    color: "#FFF",
  },
  {
    value: "youtube",
    link: "https://youtube.com/",
    label: "Youtube",
    iconWithoutChip: <IoLogoYoutube />,
    icon: <IoLogoYoutube style={{ fill: "#FFF" }} />,
    background: "#C4302B",
    color: "#FFF",
  },
  {
    value: "twitch",
    link: "https://twitch.tv/",
    label: "Twitch",
    iconWithoutChip: <IoLogoTwitch />,
    icon: <IoLogoTwitch style={{ fill: "#FFF" }} />,
    background: "#6441a5",
    color: "#FFF",
  },
  {
    value: "snapchat",
    link: "https://snapchat.com",
    label: "Snapchat",
    iconWithoutChip: <IoLogoSnapchat />,
    icon: <IoLogoSnapchat style={{ fill: "#000" }} />,
    background: "#FFFC00",
    color: "#000",
  },
];

export const priceOptions = [
  {
    value: "1",
    label: "0-50 euros",
  },
  {
    value: "2",
    label: "50-150 euros",
  },
  {
    value: "3",
    label: "150-300 euros",
  },
  {
    value: "4",
    label: "300 - 500 euros",
  },
  {
    value: "5",
    label: "500+",
  },
];

export const categoriesOptions = [
  {
    value: "sport",
    label: "Sport",
  },
  {
    value: "lifestyle",
    label: "Lifestyle",
  },
  {
    value: "makeup",
    label: "Beauté",
  },
  {
    value: "trip",
    label: "Voyage",
  },
  {
    value: "food",
    label: "Food",
  },
  {
    value: "videogame",
    label: "Jeux vidéos",
  },
  {
    value: "deco",
    label: "Décoration",
  },
  {
    value: "humour",
    label: "Humour",
  },
  {
    value: "music",
    label: "Musique",
  },
  {
    value: "cine",
    label: "Cinéma",
  },
  {
    value: "technology",
    label: "Technologie",
  },
];

export const followersOptions = [
  {
    value: "1",
    label: "0-1000 followers",
  },
  {
    value: "2",
    label: "1000-5000 followers",
  },
  {
    value: "3",
    label: "5000-10K followers",
  },
  {
    value: "4",
    label: "10K - 50K followers",
  },
  {
    value: "5",
    label: "50K+",
  },
];

export const ageOptions = [
  {
    value: "1",
    label: "15-18 ans",
  },
  {
    value: "2",
    label: "18-25 ans",
  },
  {
    value: "3",
    label: "25-35 ans",
  },
  {
    value: "4",
    label: "35+",
  },
];

export const genderOptions = [
  {
    value: "man",
    label: "Homme",
  },
  {
    value: "woman",
    label: "Femme",
  },
  {
    value: "other",
    label: "Autre",
  },
];

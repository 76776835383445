import {
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import axios from "axios";
import { Helmet } from "react-helmet";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../../common/constants";
import MissionsDashboard from "../../../Components/Influencer/Missions/MissionsDashboard/MissionsDashboard";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  missionDashboardContainer: {
    [theme.breakpoints.up("md")]: {
      width: "90%",
      padding: "30px 40px",
    },
    margin: "auto",
    boxhadow: "0 0 8px 0 rgb(0 0 0 / 5%)",
    borderRadius: 8,
    backgroundColor: "#fff",
  },
}));

const MissionsDashboardWrapper = () => {
  const classes = useStyles();
  const [selectedFilter, setSelectedFilter] = useState("CURRENT");
  const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [missions, setMissions] = useState([]);
  const [profile, setProfile] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const getMissions = () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .get(`${API_URL}/influencer/missions`)
      .then((res) => {
        setIsLoading(false);
        setMissions(res.data);
      })
      .catch((error) => console.error(error));
  };

  const getProfile = () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .get(`${API_URL}/influencer/profile`)
      .then((res) => {
        setProfile(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getMissions();
    getProfile();
  }, []);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile = width <= 768;

  if (isLoading) return <CircularProgress />;
  return (
    <Grid
      container
      className={classes.missionDashboardContainer}
      style={{ background: "#FFF" }}
      justify="center"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`POP App - Mes campagnes - ${profile.fullname}`}</title>
        <link rel="canonical" href="https://pop-app.fr" />
      </Helmet>
      <Grid item xs={12}>
        <Tabs
          value={selectedFilter}
          onChange={(e, value) => {
            setSelectedFilter(value);
          }}
          aria-label="simple tabs example"
        >
          <Tab
            style={{
              fontSize: 14,
              fontFamily: "nunito-semibold",
              textTransform: "none",
            }}
            label="Actuelles"
            value={"CURRENT"}
          />
          <Tab
            style={{
              fontSize: 14,
              fontFamily: "nunito-semibold",
              textTransform: "none",
            }}
            label="Passées"
            value={"PASSED"}
          />
        </Tabs>
      </Grid>
      <MissionsDashboard missions={missions} type={selectedFilter} />
      {/* <div
        style={{
          position: "absolute",
          width: isMobile ? "80%" : "40%",
          height: isMobile ? 200 : 150,
          background: "#FFF",
          borderRadius: 18,
          padding: 20,
          marginTop: 200,
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
          display: "flex",
        }}
      >
        <p
          style={{
            fontFamily: "nunito-semibold",
            fontSize: 20,
            margin: "auto",
            textAlign: "center",
          }}
        >
          L'accès à vos missions ne sera permis qu'à partir du jour de lancement
          officiel de POP aux marques
        </p>
      </div> */}
    </Grid>
  );
};

MissionsDashboardWrapper.propTypes = {};

export default MissionsDashboardWrapper;

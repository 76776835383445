import { Button, Grid, TextField } from "@material-ui/core";
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import Rating from "@material-ui/lab/Rating";
import axios from "axios";
import React, { useState } from "react";
import { API_URL } from "../../../../../common/constants";
import { makeStyles } from "@material-ui/styles";

import missionPageStyle from "../styles/missionPageStyles";
import { useHistory } from "react-router";

const useStyles = makeStyles(missionPageStyle);

const RatingInfluencer = ({
  influencerMissionId,
  handleRefreshFollowProcess,
  influencerName,
  missionId,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [comment, setComment] = useState("");
  const [rate, setRate] = useState(1);
  const [isInfluencerSaved, setIsInfluencerSaved] = useState(false);

  const handleRateInfluencer = () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .put(
        `${API_URL}/brand/missions/influencer-mission/${influencerMissionId}/rating`,
        {
          comment,
          rate,
          isInfluencerSaved,
        }
      )
      .then((res) => {
        handleRefreshFollowProcess();
        history.push(`/brand/missions/${missionId}/influencers`);
      });
  };

  return (
    <Grid
      item
      container
      xs={12}
      alignItems="center"
      justify="center"
      spacing={2}
    >
      <Grid item container direction="column">
        <p style={{ width: "100%", textAlign: "center", fontSize: 24 }}>
          Donnez une note à cet influenceur
        </p>
        <p
          style={{
            textAlign: "center",
            margin: "auto",
            marginTop: 10,
            fontSize: 16,
          }}
        >
          {`Votre avis compte pour nous, comment s'est déroulé votre mission avec ${influencerName} ?`}
        </p>
      </Grid>
      <Grid
        item
        xs={12}
        justify="center"
        style={{ textAlign: "center", marginTop: 20 }}
      >
        <Rating
          name="size-large"
          value={rate}
          size="large"
          style={{ fontSize: 50 }}
          onChange={(event, newValue) => setRate(newValue)}
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        justify="center"
        style={{ textAlign: "center", marginTop: 20 }}
      >
        <div className={classes.saveInfluencer}>
          <p
            onClick={() => setIsInfluencerSaved(!isInfluencerSaved)}
            style={{ marginRight: 20 }}
          >
            Sauvegarder {influencerName} dans ma liste d'influenceur
          </p>
          {!isInfluencerSaved ? (
            <FavoriteBorder
              onClick={() => setIsInfluencerSaved(!isInfluencerSaved)}
            />
          ) : (
            <Favorite
              onClick={() => setIsInfluencerSaved(!isInfluencerSaved)}
            />
          )}
        </div>
      </Grid>
      <Grid item container xs={12}>
        <TextField
          fullWidth
          rows={5}
          multiline
          id="comment"
          name="comment"
          variant="outlined"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </Grid>
      <Grid item container xs={12}>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          style={{
            fontSize: 12,
            textTransform: "none",
            background: "rgba(250, 128, 114,1)",
            color: "#FFF",
          }}
          onClick={() => handleRateInfluencer()}
        >
          Enregistrer
        </Button>
      </Grid>
    </Grid>
  );
};

export default RatingInfluencer;

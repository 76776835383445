/* eslint-disable */
// ##############################
// // // Showcase styles
// #############################

const showcaseStyle = (theme) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    "&:after": {
      display: "table",
      clear: "both",
      content: '" "',
    },
  },
  popLogo: {
    marginLeft: 50,
    flexGrow: 1,
  },
  appBar: {},
  borderHeader: {
    height: 5,
    background:
      "linear-gradient(90deg, rgba(254,200,90,1) 13%, rgba(252,48,161,1) 32%, rgba(130,93,255,1) 60%, rgba(252,48,161,1) 75%, rgba(254,200,90,1) 100%)",
  },
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  menuText: {
    textAlign: "center",
  },
  menuItem: {
    justifyContent: "center",
    textAlign: "center",
    borderBottom: "2px solid transparent",
    cursor: "pointer",
  },
  menuLink: {
    color: "#000",
    textDecoration: "none",
    paddingLeft: 20,
    paddingRight: 20,
  },
  menuItemSelected: {
    justifyContent: "center",
    textAlign: "center",
    cursor: "pointer",
    color: "#000",
    borderBottom: "2px solid rgba(130, 93, 255,1)",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    padding: 0,
    textAlign: "center",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  content: {
    width: 1200,
    margin: "auto",
  },
  flexContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    padding: 0,
  },
  headerText: {
    "& span": {
      fontSize: 16,
      fontFamily: "nunito-semibold",
      color: "#241C4C",
    },
    marginRight: 100,
  },

  /////NEW
  topContainer: {
    // background:
    //   "linear-gradient(to right top, #fa80a5, #f79bc5, #f3b5de, #efcdf0, #f1e4fa, #ecdffb, #e6dbfd, #dfd7ff, #c8b9ff, #b29bff, #9a7dff, #825dff)",
    background:
      "linear-gradient(to right top, rgba(250, 128, 165,0.3), rgba(247, 155, 197,0.3), rgba(243, 181, 222, 1), rgba(239, 205, 240,0.3), rgba(241, 228, 250,0.3), rgba(236, 223, 251,0.3), rgba(230, 219, 253,0.3), rgba(223, 215, 255,0.3), rgba(200, 185, 255,0.3), rgba(178, 155, 255,0.3), rgba(154, 125, 255,0.3), rgba(130, 93, 255,0.3))",
  },
  // topContainer: {
  //   // height: 600,
  //   background: "rgba(130, 93, 255,1)",
  //   background:
  //     " -webkit-linear-gradient(bottom right, rgba(130,93,255,0.8), rgba(252,48,161,0.6), rgba(254,200,90,1))",
  //   background:
  //     "-moz-linear-gradient(bottom right, rgba(130,93,255,0.8), rgba(252,48,161,0.6), rgba(254,200,90,1))",
  //   background:
  //     "linear-gradient(to top left, rgba(130,93,255,0.8), rgba(252,48,161,0.6), rgba(254,200,90,1))",
  //   borderBottomRightRadius: "80px 80px",
  //   // width: 1200,
  //   marginRight: 30
  // },

  headerContainerSticky: {
    backgroundColor: "#FFF",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    position: "fixed",
    transition: "all 0.5s ease",
    animation: "smoothScroll 1s forwards",
    zIndex: 4,
    // borderBottomLeftRadius: "80px 80px",
    // marginLeft: "auto",
    // marginLeft: 30,
  },
  headerContainer: {
    position: "fixed",
    background: "#FFF",
    zIndex: 5,
    // borderBottomLeftRadius: "80px 80px",
    // marginLeft: "auto",
    // marginLeft: 30,
  },
  cardImage: {
    width: 350,
    borderRadius: 2,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  },
  backgroundCardModel: {
    backgroundColor: "#FED37B",
    width: 350,
    height: 250,
    position: "absolute",
  },
  cardContainer: {
    zIndex: 1,
  },
  blockPricing: {
    border: "solid 2px #825DFF",
    marginTop: 50,
    marginBottom: 100,
  },
  pricingColumn: {
    borderRight: "solid 2px #825DFF",
    paddingBottom: 30,
  },
  pricingTitle: {
    fontFamily: "nunito-bold",
    fontSize: 30,
    textAlign: "center",
  },
  pricingSubtitle: {
    fontFamily: "nunito-regular",
    fontSize: 15,
    textAlign: "center",
  },
  pricingPrice: {
    fontFamily: "nunito-bold",
    fontSize: 63,
    textAlign: "center",
  },
  pricingDescription: {
    fontFamily: "nunito-regular",
    fontSize: 12,
    textAlign: "center",
  },
  pricingDescriptionFeatures: {
    fontFamily: "nunito-regular",
    fontSize: 13,
    textAlign: "left",
    marginLeft: -5,
  },
  iconCheck: {
    fontSize: 13,
    color: "#825DFF",
  },
  containerFeatures: {
    marginLeft: 20,
    marginBottom: 15,
  },

  pricingColumnImportant: {
    borderRight: "solid 2px #825DFF",
    paddingBottom: 30,
    backgroundColor: "#825DFF",
    marginLeft: -2,
    marginTop: -15,
    height: "105%",
  },
  pricingTitleImportant: {
    fontFamily: "nunito-bold",
    fontSize: 30,
    textAlign: "center",
    color: "#FFF",
  },
  pricingSubtitleImportant: {
    fontFamily: "nunito-regular",
    fontSize: 15,
    textAlign: "center",
    color: "#FFF",
  },
  pricingPriceImportant: {
    fontFamily: "nunito-bold",
    fontSize: 63,
    textAlign: "center",
    color: "#FFF",
  },
  pricingDescriptionImportant: {
    fontFamily: "nunito-regular",
    fontSize: 12,
    textAlign: "center",
    color: "#FFF",
  },
  pricingDescriptionFeaturesImportant: {
    fontFamily: "nunito-regular",
    fontSize: 13,
    textAlign: "left",
    marginLeft: -5,
    color: "#FFF",
  },
  iconCheckImportant: {
    fontSize: 13,
    color: "#FFF",
  },
  coloredFooter: {
    height: 200,
    width: "100%",
    background: "rgba(130, 93, 255,1)",
    background:
      " -webkit-linear-gradient(bottom right, rgba(130,93,255,0.8), rgba(252,48,161,0.6), rgba(254,200,90,1))",
    background:
      "-moz-linear-gradient(bottom right, rgba(130,93,255,0.8), rgba(252,48,161,0.6), rgba(254,200,90,1))",
    background:
      "linear-gradient(to top left, rgba(130,93,255,0.8), rgba(252,48,161,0.6), rgba(254,200,90,1))",
    borderTopLeftRadius: 20,
  },
  whiteFooter: {
    marginTop: 40,
    borderTopRightRadius: 20,
    width: "100%",
    backgroundColor: "white",
    height: 300,
    margin: "auto",
    alignItems: "center",
    justifyContent: "center",
  },
  popLogoFooter: {
    marginLeft: 50,
    marginTop: 50,
    flexGrow: 1,
  },
  headerMenuText: {
    fontSize: 16,
    fontFamily: "nunito-semibold",
    color: "#485481",
    "&:hover": {
      textDecoration: "none",
      color: "rgba(130, 93, 255,1)",
    },
  },
});

export default showcaseStyle;

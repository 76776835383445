// ####################################
// // // Mission Page styles
// ###################################

const missionPageStyle = (theme) => ({
  pageLayout: {
    [theme.breakpoints.up("md")]: {
      width: "70%",
    },
    margin: "auto",
  },
  companyAvatar: {
    width: 150,
    height: 150,
    [theme.breakpoints.up("md")]: {
      float: "right",
    },
  },
  brandLabel: {
    fontWeight: 500,
    fontSize: 36,
    fontFamily: "nunito-bold",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      marginTop: 10,
    },
  },
  profileInfo: {
    height: 100,
    border: "1px solid #e0ded9",
    padding: "10px 15px",
    borderRadius: 6,
  },
  infoTitle: {
    padding: 5,
  },
  infoContent: {
    padding: 5,
  },
  widgetContainer: {
    marginBottom: 100,
    backgroundColor: "#FFF",
    [theme.breakpoints.up("md")]: {
      padding: 40,
    },
    borderRadius: 6,
    position: "relative",
  },
  widgetTitle: {
    fontSize: 28,
    fontFamily: "nunito-bold",
    fontWeight: 700,
  },
  widgetTextContent: {
    fontSize: 17,
    fontWeight: 400,
    whiteSpace: "pre-line",
  },
  buttonSection: {
    marginTop: 20,
  },
  button: {
    background: "rgba(130,93,255,1)",
    textTransform: "capitalize",
    "&:hover": {
      background: "rgba(130,93,255,0.8)",
    },
  },
  buttonSecondaryModal: {
    textTransform: "capitalize",
  },
  buttonSecondary: {
    textTransform: "capitalize",
    [theme.breakpoints.down("md")]: {
      marginTop: 20,
    },
  },
  missionContentContainer: {
    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
    margin: "auto",
    overflow: "auto",
    borderRadius: 6,
  },
  sidenavMenu: {
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  },
  menuItem: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.14)",
    minHeight: 60,
    "&:hover": {
      borderLeft: "4px solid rgba(130,93,255,1)",
    },
  },
  selectedMenuItem: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.14)",
    minHeight: 60,
    borderLeft: "4px solid rgba(130,93,255,1)",
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
  },
  widgetContainerBrief: {
    marginBottom: 100,
    minHeight: 300,
    backgroundColor: "#FFF",
    [theme.breakpoints.up("md")]: {
      padding: 40,
    },
    padding: 20,
    borderRadius: 6,
    position: "relative",
  },
  widgetTitleBrief: {
    color: "#484848",
    fontSize: 24,
    fontWeight: 600,
  },
  widgetTextContentBrief: {
    fontSize: 15,
    fontWeight: 400,
    color: "#484848",
    whiteSpace: "pre-line",
  },
  accordion: {
    "&:before": {
      background: "transparent !important",
    },
  },
  mobileTab: {
    "& span": {
      fontFamily: "nunito-bold",
    },
  },
});

export default missionPageStyle;

import { Avatar, Chip, Grid } from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";
import React from "react";
import { getFormattedSocialNetworks } from "../../Brand/BrandProfilePage/PersonalInformation/utils";

const PersonalInformationMobileWidget = ({
  profile,
  isEditable = false,
  setIsDisplayingFormPersonalInfo,
  setIsDisplayingFormPrice,
  handleUpdateInfluencerProfile,
  classes,
  handleUploadImage,
}) => {
  let minPrice = Math.min(
    ...profile?.socialNetworks?.map((network) => {
      return network.price;
    })
  );

  if (
    typeof minPrice !== "number" ||
    Number.isNaN(minPrice) ||
    !Number.isFinite(minPrice)
  ) {
    minPrice = 0;
  }

  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={10}
      lg={10}
      className={classes.pageLayout}
      justify="center"
      alignItems="flex-start"
    >
      <Grid item xs={12} sm={12} md={4} lg={4} style={{ marginBottom: 40 }}>
        <label>
          <Avatar
            className={
              isEditable ? classes.userAvatarEditable : classes.userAvatar
            }
            src={profile.avatar}
          ></Avatar>
          {isEditable && (
            <input
              type="file"
              style={{ display: "none" }}
              onChange={(e) => {
                let file = e.target.files[0];
                handleUploadImage(file);
              }}
            />
          )}
        </label>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={8}
        lg={8}
        justify="center"
        className={
          isEditable ? classes.editableContainer : classes.notEditableContainer
        }
        id="influencer-profile"
      >
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={8}
          lg={8}
          alignItems="center"
          justify="center"
          style={{ cursor: "default" }}
          onClick={() => isEditable && setIsDisplayingFormPersonalInfo(true)}
        >
          <span
            style={{ marginLeft: 0, marginRight: 30 }}
            className={
              profile?.isActive
                ? classes.availabilityDotActive
                : classes.availabilityDotNotActive
            }
          ></span>
          <p className={classes.userName}>{profile.fullname}</p>
          <EditOutlined
            style={{
              marginLeft: 30,
              marginBottom: 10,
            }}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <p>{profile.email}</p>
        </Grid> */}
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={8}
          lg={8}
          justify="flex-start"
          style={{ textAlign: "center" }}
        >
          {getFormattedSocialNetworks(profile?.socialNetworks).map(
            (socialNetwork) => {
              return (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={3}
                  lg={3}
                  style={{ marginBottom: 5 }}
                  key={socialNetwork.value}
                >
                  <a
                    href={socialNetwork.link}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", cursor: "pointer" }}
                  >
                    <Chip
                      style={{
                        background: socialNetwork.background,
                        color: socialNetwork.color,
                        cursor: "pointer",
                      }}
                      icon={socialNetwork.icon}
                      label={socialNetwork.label}
                    />
                  </a>
                </Grid>
              );
            }
          )}
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={8}
          lg={8}
          style={{ marginTop: 40, padding: 10 }}
        >
          <Grid
            item
            container
            xs={6}
            sm={6}
            md={4}
            lg={4}
            className={classes.profileInfo}
            style={{
              cursor: "default",
              maxHeight: 80,
            }}
            // onClick={() => isEditable && setIsDisplayingFormPrice(true)}
          >
            <Grid item xs={12}>
              <p className={classes.infoTitle}>Tarif minimum</p>
            </Grid>
            <Grid item xs={12}>
              <strong>{`${
                minPrice === 0 ? "À renseigner" : `${minPrice}€`
              }`}</strong>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={6}
            sm={6}
            md={4}
            lg={4}
            className={classes.profileInfo}
            style={{ maxHeight: 80 }}
          >
            <Grid item xs={12}>
              <p className={classes.infoTitle}>Taux de réponse</p>
            </Grid>
            <Grid item xs={12}>
              <strong>{Math.round(profile.responseRate)}%</strong>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PersonalInformationMobileWidget;

import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, withRouter } from "react-router";
import { Switch, Route, Redirect } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import { NavLink, useLocation } from "react-router-dom";

import {
  AppBar,
  IconButton,
  Grid,
  Badge,
  Hidden,
  Popover,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

import AccountCircle from "@material-ui/icons/AccountCircle";
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";

import { Menu, MenuOpen } from "@material-ui/icons";

import Logo from "../assets/images/logo.png";

import mainRoutes from "../routes/mainRoutes";

import appLayoutStyle from "./styles/appLayout";
import companyRoutes from "../routes/companyRoutes";
import showcaseRoutes from "../routes/showcaseRoutes";
import ShowcaseWrapper from "../pages/Showcase/Showcase";
import ProfileMenu from "./ProfileMenu/ProfileMenu";
import MobileMenu from "./MobileMenu";
import Notifications from "../Components/Common/Notifications/Notifications";
import axios from "axios";
import { API_URL } from "../common/constants";

const AppLayout = (props) => {
  const { classes } = props;
  const history = useHistory();

  const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();

  const [notifications, setNotifications] = useState([]);
  const [displayNotifications, setDisplayNotifications] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const anchorRef = useRef(null);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const currentPage =
    localStorage.getItem("isInfluencer") === "true"
      ? mainRoutes.find((route) => route.path === location.pathname)
      : companyRoutes.find((route) => route.path === location.pathname);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setIsProfileMenuOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setIsProfileMenuOpen(false);
    }
  }

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  function getNotifications() {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;

    axios
      .get(
        `${API_URL}/${
          localStorage.getItem("isInfluencer") === "true"
            ? "influencer"
            : "brand"
        }/profile/notifications`
      )
      .then((res) => {
        setNotifications(res.data.reverse());
      })
      .catch((error) => {
        if (localStorage.getItem("token")?.length > 0) {
          localStorage.removeItem("token");
          history.push("/");
        }
      });
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")?.length > 0) {
      getNotifications();
    }
  }, [localStorage.getItem("token")]);

  let isMobile = width <= 768;

  const switchRoutes = (isAuthRoutes) => {
    const referrer = localStorage.getItem("referrer");
    if (referrer) {
      localStorage.clear();
      return <Redirect to={referrer} />;
    }

    if (!isAuthRoutes) {
      return (
        <Switch>
          {localStorage.getItem("isInfluencer") === "true"
            ? mainRoutes.map((prop, key) => {
                if (prop.redirect)
                  return (
                    <Redirect from={prop.path} to={prop.pathTo} key={key} />
                  );
                if (prop.exactPath)
                  return (
                    <Route
                      exact
                      path={prop.exactPath}
                      component={prop.component}
                      key={key}
                    />
                  );
                return (
                  <Route
                    exact={prop.exact}
                    path={prop.path}
                    render={(props) => <prop.component />}
                    key={key}
                  />
                );
              })
            : companyRoutes.map((prop, key) => {
                if (prop.redirect)
                  return (
                    <Redirect from={prop.path} to={prop.pathTo} key={key} />
                  );
                if (prop.exactPath)
                  return (
                    <Route
                      exact
                      path={prop.exactPath}
                      component={prop.component}
                      key={key}
                    />
                  );
                return (
                  <Route
                    exact={prop.exact}
                    path={prop.path}
                    render={(props) => <prop.component />}
                    key={key}
                  />
                );
              })}
        </Switch>
      );
    } else {
      return (
        <Switch>
          {showcaseRoutes.map((prop, key) => {
            return (
              <Route
                exact
                path={prop.path}
                render={(props) => <prop.component />}
                key={key}
              />
            );
          })}
        </Switch>
      );
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        {localStorage.getItem("token") ? (
          <>
            <AppBar
              position="static"
              color="transparent"
              className={
                currentPage && currentPage.displayName
                  ? localStorage.getItem("isInfluencer") === "true"
                    ? classes.borderHeader
                    : classes.borderHeaderBrand
                  : ""
              }
              style={
                currentPage && currentPage.displayName
                  ? {
                      boxShadow: "none",
                      height: 65,
                      position: "relative",
                      minHeight: "25vw",
                      overflow: "hidden",
                      marginBottom: "-10%",
                      zIndex: 1,
                    }
                  : {
                      height: 75,
                    }
              }
            >
              <Grid
                container
                direction="row"
                justify="space-between"
                style={{
                  zIndex: 2,
                  background: "#FFF",
                  minHeight: 65,
                  // overflow: "hidden",
                }}
                alignItems="center"
              >
                <Grid item sm={2} md={2} lg={2} xl={2}>
                  <div style={{ marginLeft: isMobile ? 20 : 50, flexGrow: 1 }}>
                    <a
                      href="/"
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      <img
                        alt="pop influence"
                        title="pop influence"
                        src={Logo}
                        style={{ height: 40 }}
                      />
                    </a>
                  </div>
                </Grid>
                <Hidden only={["md", "lg", "xl"]}>
                  <Grid
                    item
                    container
                    xs={6}
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item style={{ marginLeft: 30 }}>
                      <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        color="inherit"
                        style={{ marginTop: 5, borderRadius: 6 }}
                        onClick={(event) => {
                          setAnchorEl(event.currentTarget);
                          setDisplayNotifications(true);
                        }}
                      >
                        <Badge
                          classes={{
                            badge:
                              localStorage.getItem("isInfluencer") === "true"
                                ? classes.badgeColorInfluencer
                                : classes.badgeColorBrand,
                          }}
                          badgeContent={
                            notifications.filter((notif) => !notif.isRead)
                              .length
                          }
                          invisible={
                            notifications.filter((notif) => !notif.isRead)
                              .length === 0
                          }
                        >
                          <NotificationsOutlinedIcon />
                        </Badge>
                      </IconButton>
                    </Grid>
                    <Popover
                      open={displayNotifications}
                      anchorEl={anchorEl}
                      onClose={() => {
                        getNotifications();
                        setAnchorEl(null);
                        setDisplayNotifications(false);
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      className="popoverNotifications"
                      style={{ maxHeight: 500 }}
                    >
                      <Notifications
                        notifications={notifications}
                        setNotifications={setNotifications}
                        handleClose={() => {
                          getNotifications();
                          setDisplayNotifications(false);
                        }}
                        isInfluencer={
                          localStorage.getItem("isInfluencer") === "true"
                        }
                        badgeColorBrand={classes.badgeColorBrand}
                        badgeColorInfluencer={classes.badgeColorInfluencer}
                      />
                    </Popover>
                    <Grid
                      item
                      id="influencer-missions-menu"
                      style={{ marginLeft: 15 }}
                      onClick={() =>
                        setMobileMenuOpen((mobileMenuOpen) => !mobileMenuOpen)
                      }
                    >
                      {mobileMenuOpen ? (
                        <MenuOpen style={{ width: 30, height: 30 }} />
                      ) : (
                        <Menu style={{ width: 30, height: 30 }} />
                      )}
                    </Grid>
                  </Grid>
                </Hidden>
                <Hidden only={["sm", "xs"]}>
                  <Grid
                    item
                    container
                    sm={10}
                    md={10}
                    lg={10}
                    xl={10}
                    justify="flex-end"
                    alignItems="center"
                    style={{ height: "100%" }}
                  >
                    {localStorage.getItem("isInfluencer") === "true" ? (
                      <ul
                        style={{
                          display: "flex",
                          flex: 1,
                          height: "100%",
                          justifyContent: "flex-end",
                          listStyle: "none",
                          alignItems: "center",
                          margin: 0,
                        }}
                      >
                        <li
                          style={{
                            padding: "0 15px",
                            position: "relative",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <NavLink
                            className={classes.menuLink}
                            to="/influencer/profile"
                            activeClassName={classes.selectedMenuItem}
                          >
                            <span className={classes.menuItem}>Dashboard</span>
                          </NavLink>
                        </li>
                        <li
                          style={{
                            padding: "0 15px",
                            position: "relative",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                          id="influencer-missions-menu"
                        >
                          <NavLink
                            className={classes.menuLink}
                            to="/influencer/missions"
                            activeClassName={classes.selectedMenuItem}
                          >
                            <span className={classes.menuItem}>Missions</span>
                          </NavLink>
                        </li>
                      </ul>
                    ) : (
                      <ul
                        style={{
                          display: "flex",
                          flex: 1,
                          height: "100%",
                          justifyContent: "flex-end",
                          listStyle: "none",
                          alignItems: "center",
                          margin: 0,
                        }}
                      >
                        <li
                          style={{
                            padding: "0 15px",
                            position: "relative",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <NavLink
                            className={classes.menuLinkBrand}
                            to="/brand/new-mission"
                            activeClassName={classes.selectedMenuItemBrand}
                          >
                            <span className={classes.menuItem}>
                              Créer une campagne
                            </span>
                          </NavLink>
                        </li>
                        <li
                          style={{
                            padding: "0 15px",
                            position: "relative",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <NavLink
                            className={classes.menuLinkBrand}
                            to="/brand/dashboard"
                            activeClassName={classes.selectedMenuItemBrand}
                          >
                            <span className={classes.menuItem}>Dashboard</span>
                          </NavLink>
                        </li>
                        <li
                          style={{
                            padding: "0 15px",
                            position: "relative",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <NavLink
                            className={classes.menuLinkBrand}
                            to="/brand/missions"
                            activeClassName={classes.selectedMenuItemBrand}
                          >
                            <span className={classes.menuItem}>
                              Mes campagnes
                            </span>
                          </NavLink>
                        </li>
                      </ul>
                    )}
                    {/* </Grid> */}
                    <Grid
                      item
                      container
                      xs={2}
                      direction="row"
                      justify="center"
                    >
                      <Grid item style={{ textAlign: "right" }}>
                        <IconButton
                          aria-label="account of current user"
                          aria-controls="menu-appbar"
                          color="inherit"
                          style={{ marginTop: 5, borderRadius: 6 }}
                          onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                            setDisplayNotifications(true);
                          }}
                        >
                          <Badge
                            classes={{
                              badge:
                                localStorage.getItem("isInfluencer") === "true"
                                  ? classes.badgeColorInfluencer
                                  : classes.badgeColorBrand,
                            }}
                            badgeContent={
                              notifications.filter((notif) => !notif.isRead)
                                .length
                            }
                            invisible={
                              notifications.filter((notif) => !notif.isRead)
                                .length === 0
                            }
                          >
                            <NotificationsOutlinedIcon />
                          </Badge>
                        </IconButton>
                      </Grid>
                      <Popover
                        open={displayNotifications}
                        anchorEl={anchorEl}
                        onClose={() => {
                          getNotifications();
                          setAnchorEl(null);
                          setDisplayNotifications(false);
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        className="popoverNotifications"
                        style={{ maxHeight: 500 }}
                      >
                        <Notifications
                          notifications={notifications}
                          setNotifications={setNotifications}
                          handleClose={() => {
                            getNotifications();
                            setDisplayNotifications(false);
                          }}
                          isInfluencer={
                            localStorage.getItem("isInfluencer") === "true"
                          }
                          badgeColorBrand={classes.badgeColorBrand}
                          badgeColorInfluencer={classes.badgeColorInfluencer}
                        />
                      </Popover>
                      <Grid
                        item
                        style={{ textAlign: "right" }}
                        id="influencer-profile-menu"
                      >
                        <IconButton
                          ref={anchorRef}
                          aria-label="account of current user"
                          aria-controls="menu-appbar"
                          color="inherit"
                          style={{ marginTop: 5, borderRadius: 6 }}
                          onClick={() => {
                            if (
                              localStorage.getItem("isInfluencer") === "true"
                            ) {
                              setIsProfileMenuOpen(true);
                            } else {
                              history.push("/brand/settings/profile");
                            }
                          }}
                        >
                          <AccountCircle />
                        </IconButton>
                        {localStorage.getItem("isInfluencer") === "true" && (
                          <ProfileMenu
                            token={localStorage.getItem("token")}
                            isProfileMenuOpen={isProfileMenuOpen}
                            handleClose={handleClose}
                            anchorRef={anchorRef}
                            handleListKeyDown={handleListKeyDown}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Hidden>
              </Grid>
              {currentPage && currentPage.displayName ? (
                <Grid
                  item
                  container
                  justify="space-between"
                  style={{
                    zIndex: 1,
                    // overflow: "hidden",
                  }}
                  alignItems="center"
                >
                  <p
                    style={{
                      fontSize: 45,
                      paddingTop: 50,
                      textAlign: "center",
                      color: "#FFF",
                      zIndex: 1,
                      margin: "auto",
                    }}
                  >
                    {currentPage ? currentPage.name : "Page Introuvable"}
                  </p>
                </Grid>
              ) : (
                <div
                  style={{
                    height: 50,
                    background:
                      localStorage.getItem("isInfluencer") === "true"
                        ? "rgba(130,93,255,0.7)"
                        : "rgba(250, 128, 114, 0.7)",
                  }}
                ></div>
              )}
            </AppBar>
            <Grid
              item
              container
              xs={12}
              justify="center"
              alignItems="center"
              style={{
                backgroundColor:
                  localStorage.getItem("isInfluencer") === "true"
                    ? "rgba(130, 93, 255, 0.7)"
                    : "rgba(250, 128, 114, 0.7)",
                height: 60,
                borderTopRightRadius: 6,
                borderTopLeftRadius: 6,
                position: "absolute",
                width: "100%",
                bottom: 0,
              }}
            >
              <Grid item style={{ color: "#FFFFFF", paddingRight: 20 }}>
                <p>POP © 2021. Tous droits réservés</p>
              </Grid>
              <Grid
                item
                style={{ color: "#FFFFFF", paddingLeft: isMobile ? 0 : 20 }}
              >
                <a
                  href="mailto:contact@pop-app.fr"
                  style={{ color: "#FFF", fontFamily: "nunito-regular" }}
                >
                  <p>Contactez-nous</p>
                </a>
              </Grid>
            </Grid>
            {mobileMenuOpen ? (
              <MobileMenu
                setMobileMenuOpen={setMobileMenuOpen}
                isInfluencer={localStorage.getItem("isInfluencer") === "true"}
                token={localStorage.getItem("token")}
              />
            ) : (
              <div
                className={classes.content}
                id="appContent"
                style={{ zIndex: 1 }}
              >
                <div className={classes.container}>{switchRoutes(false)}</div>
              </div>
            )}
            <div style={{ paddingBottom: 150, background: "#f7f7f5" }}></div>
          </>
        ) : (
          <ShowcaseWrapper />
        )}
      </div>
    </div>
  );
};

AppLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(appLayoutStyle)(AppLayout));

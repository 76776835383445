import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router";
import { API_URL } from "../../../common/constants";
import NewMissionForm from "../../../Components/Brand/NewMission/NewMissionForm";

const NewMission = () => {
  const { id } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [mission, setMission] = useState({});

  const handleSaveMission = (values) => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;

    axios
      .put(`${API_URL}/brand/missions/${id}`, {
        title: values.title,
        description: values.description,
        influencerCaracteristics: {
          age: values.age,
          categories: values.categories.map((value) => value.value),
          gender: values.gender.map((value) => value.value),
          location: values.location,
        },
        socialNetworks: values.socialNetworks.map((network) => ({
          socialNetwork: network.value,
          followers: network.followers,
          prices: network.prices,
        })),
        isGifting: values.isGifting ?? false,
        target: values.target,
        briefs: values.briefs,
      })
      .then((res) => {
        history.push("/brand/missions");
      })
      .catch((error) => console.error(error));
  };

  const getMission = () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .get(`${API_URL}/brand/missions/${id}`)
      .then((res) => {
        setIsLoading(false);
        setMission(res.data);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    setIsLoading(true);
    getMission();
  }, [id]);

  if (isLoading) return null;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`POP App - Modification de la campagne ${mission?.brand?.brandName}`}</title>
        <link rel="canonical" href="https://pop-app.fr" />
      </Helmet>
      <NewMissionForm
        mission={mission.mission}
        handleSaveMission={handleSaveMission}
      />
    </>
  );
};

export default NewMission;

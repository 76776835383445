import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import Notices from "../../Influencer/InfluencerProfilePage/Widgets/Notices";
import UserEvolution from "../../Influencer/InfluencerProfilePage/Widgets/UserEvolution";
import PersonalInformation from "./PersonalInformation/PersonalInformation";

import profileStyle from "./styles/profilePage";
import SavedInfluencers from "./Widgets/SavedInfluenceurs";

const useStyles = makeStyles(profileStyle);

const BrandProfilePage = ({ profile, handleUpdateBrandProfile }) => {
  const classes = useStyles();
  return (
    <div>
      <PersonalInformation
        profile={profile}
        handleUpdateBrandProfile={handleUpdateBrandProfile}
      />
      <Grid
        container
        className={classes.pageLayout}
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid item container xs={12}>
          <UserEvolution brandId={profile._id} isInfluencer={false} />
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={4}
          lg={4}
          alignItems="flex-start"
          justify="center"
          style={{ marginTop: 20 }}
        >
          <SavedInfluencers profile={profile} />
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={8}
          lg={8}
          alignItems="flex-start"
          justify="center"
          className={classes.noticesContainer}
        >
          <Notices brandId={profile._id} isInfluencer={false} />
        </Grid>
      </Grid>
    </div>
  );
};

BrandProfilePage.propTypes = {};

export default BrandProfilePage;

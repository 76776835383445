import { Accordion, AccordionSummary, Avatar, Grid } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import React from "react";
import { makeStyles } from "@material-ui/styles";

import missionStyle from "./styles/missionStyle";
import { ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles(missionStyle);

const MissionDashboardMobile = ({ missions, type }) => {
  const classes = useStyles();
  return (
    <Grid item container justify="center">
      <Grid
        item
        container
        justify="center"
        alignItems="flex-start"
        xs={12}
        style={{ textAlign: "center", marginBottom: 30 }}
      >
        <p
          style={{
            color: "#484848",
            fontSize: 30,
            fontWeight: 600,
            fontFamily: "nunito-bold",
          }}
        >
          Mes campagnes
        </p>
      </Grid>
      {type === "CURRENT" ? (
        <>
          <Grid
            item
            container
            xs={11}
            sm={11}
            md={4}
            lg={4}
            style={{ marginBottom: 30 }}
            direction="column"
            justify="flex-start"
            alignItems="center"
          >
            <Accordion
              style={{
                width: "100%",
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                classes={{
                  expanded: classes.expanded,
                }}
                style={{ border: "1px solid rgb(227, 227, 227)" }}
              >
                <Grid item xs={12}>
                  <p className={classes.blockTitle}>{`Inactives (${
                    missions.missions.filter((opt) => opt.status === "INACTIVE")
                      .length
                  })`}</p>
                  <p
                    style={{
                      color: "#888",
                      fontStyle: "italic",
                      fontSize: 14,
                      marginTop: 10,
                    }}
                  >
                    Vos campagnes ne sont pas encore actives, vous pouvez encore
                    les modifier avant de les rendre visibles aux Influenceurs
                    correspondant à vos critères.
                  </p>
                </Grid>
              </AccordionSummary>
              <Grid item container style={{ marginTop: 30 }}>
                {missions.missions
                  .filter((opt) => opt.status === "INACTIVE")
                  .map((mission) => (
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.missionItems}
                      justify="center"
                      style={{ margin: "auto", marginBottom: 10 }}
                    >
                      <NavLink
                        className={classes.navLink}
                        exact={true}
                        to={`/brand/missions/${mission._id}/details`}
                      >
                        <Grid item container>
                          <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Avatar
                              className={classes.brandAvatar}
                              src={mission?.thumbnail ?? missions.brand.avatar}
                              style={{ margin: "auto" }}
                            ></Avatar>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            sm={12}
                            md={7}
                            lg={7}
                            className={classes.missionContent}
                          >
                            <Grid item xs={12}>
                              <p className={classes.brandTitle}>
                                {mission.title}
                              </p>
                            </Grid>
                            <Grid item xs={12}>
                              <p className={classes.missionDescription}>
                                {mission.description}
                              </p>
                            </Grid>
                          </Grid>
                          {/* <Grid
                    item
                    container
                    xs={2}
                    className={classes.missionContent}
                  >
                    <Grid item>
                      <p>550€</p>
                    </Grid>
                  </Grid> */}
                        </Grid>
                      </NavLink>
                    </Grid>
                  ))}
              </Grid>
            </Accordion>
          </Grid>
          <Grid
            item
            container
            xs={11}
            sm={11}
            md={4}
            lg={4}
            style={{ marginBottom: 30 }}
            direction="column"
            justify="flex-start"
            alignItems="center"
          >
            <Accordion
              style={{
                width: "100%",
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                classes={{
                  expanded: classes.expanded,
                }}
                style={{ border: "1px solid rgb(227, 227, 227)" }}
              >
                <Grid item xs={12}>
                  <p className={classes.blockTitle}>{`En attente (${
                    missions.missions.filter((opt) => opt.status === "PROPOSED")
                      .length
                  })`}</p>
                  <p
                    style={{
                      color: "#888",
                      fontStyle: "italic",
                      fontSize: 14,
                      marginTop: 10,
                    }}
                  >
                    Vos campagnes sont en attente d‘Influenceur vous
                    correspondant et acceptant de collaborer avec vous.
                  </p>
                </Grid>
              </AccordionSummary>
              <Grid item container style={{ marginTop: 30 }}>
                {missions.missions
                  .filter((opt) => opt.status === "PROPOSED")
                  .map((mission) => (
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.missionItems}
                      justify="center"
                      style={{ margin: "auto", marginBottom: 10 }}
                    >
                      <NavLink
                        className={classes.navLink}
                        exact={true}
                        to={`/brand/missions/${mission._id}/details`}
                      >
                        <Grid item container>
                          <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Avatar
                              className={classes.brandAvatar}
                              style={{ margin: "auto" }}
                              src={mission?.thumbnail ?? missions.brand.avatar}
                            ></Avatar>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            sm={12}
                            md={7}
                            lg={7}
                            className={classes.missionContent}
                          >
                            <Grid item xs={12}>
                              <p className={classes.brandTitle}>
                                {mission.title}
                              </p>
                            </Grid>
                            <Grid item xs={12}>
                              <p className={classes.missionDescription}>
                                {mission.description}
                              </p>
                            </Grid>
                          </Grid>
                          {/* <Grid
                    item
                    container
                    xs={2}
                    className={classes.missionContent}
                  >
                    <Grid item>
                      <p>550€</p>
                    </Grid>
                  </Grid> */}
                        </Grid>
                      </NavLink>
                    </Grid>
                  ))}
              </Grid>
            </Accordion>
          </Grid>
          <Grid
            item
            container
            xs={11}
            sm={11}
            md={4}
            lg={4}
            style={{ marginBottom: 30 }}
            direction="column"
            justify="flex-start"
            alignItems="center"
          >
            <Accordion
              style={{
                width: "100%",
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                classes={{
                  expanded: classes.expanded,
                }}
                style={{ border: "1px solid rgb(227, 227, 227)" }}
              >
                <Grid item xs={12}>
                  <p className={classes.blockTitle}>{`En cours (${
                    missions.missions.filter((opt) => opt.status === "ON_GOING")
                      .length
                  })`}</p>
                  <p
                    style={{
                      color: "#888",
                      fontStyle: "italic",
                      fontSize: 14,
                      marginTop: 10,
                    }}
                  >
                    Des Influenceurs souhaitent travailler avec vous sur ce
                    projet ! Sélectionnez-les et communiquez avec eux pour
                    effectuer le placement de produit comme vous le souhaitez.
                  </p>
                </Grid>
              </AccordionSummary>
              <Grid item container style={{ marginTop: 30 }}>
                {missions.missions
                  .filter((opt) => opt.status === "ON_GOING")
                  .map((mission) => (
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.missionItems}
                      justify="center"
                      style={{ margin: "auto", marginBottom: 10 }}
                    >
                      <NavLink
                        className={classes.navLink}
                        exact={true}
                        to={`/brand/missions/${mission._id}/details`}
                      >
                        <Grid item container>
                          <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Avatar
                              className={classes.brandAvatar}
                              style={{ margin: "auto" }}
                              src={mission?.thumbnail ?? missions.brand.avatar}
                            ></Avatar>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            sm={12}
                            md={7}
                            lg={7}
                            className={classes.missionContent}
                          >
                            <Grid item xs={12}>
                              <p className={classes.brandTitle}>
                                {mission.title}
                              </p>
                            </Grid>
                            <Grid item xs={12}>
                              <p className={classes.missionDescription}>
                                {mission.description}
                              </p>
                            </Grid>
                          </Grid>
                          {/* <Grid
                    item
                    container
                    xs={2}
                    className={classes.missionContent}
                  >
                    <Grid item>
                      <p>550€</p>
                    </Grid>
                  </Grid> */}
                        </Grid>
                      </NavLink>
                    </Grid>
                  ))}
              </Grid>
            </Accordion>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            item
            container
            xs={11}
            sm={11}
            md={4}
            lg={4}
            style={{ marginBottom: 30 }}
            direction="column"
            justify="flex-start"
            alignItems="center"
          >
            <Accordion
              style={{
                width: "100%",
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                classes={{
                  expanded: classes.expanded,
                }}
                style={{ border: "1px solid rgb(227, 227, 227)" }}
              >
                <Grid item xs={12}>
                  <p className={classes.blockTitle}>{`Terminées (${
                    missions.missions.filter((opt) => opt.status === "DONE")
                      .length
                  })`}</p>
                  <p
                    style={{
                      color: "#888",
                      fontStyle: "italic",
                      fontSize: 14,
                      marginTop: 10,
                    }}
                  >
                    Toutes les campagnes terminées.
                  </p>
                </Grid>
              </AccordionSummary>
              <Grid item container style={{ marginTop: 30 }}>
                {missions.missions
                  .filter((opt) => opt.status === "DONE")
                  .map((mission) => (
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.missionItems}
                      justify="center"
                      style={{ margin: "auto", marginBottom: 10 }}
                    >
                      <NavLink
                        className={classes.navLink}
                        exact={true}
                        to={`/brand/missions/${mission._id}/details`}
                      >
                        <Grid item container>
                          <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Avatar
                              className={classes.brandAvatar}
                              src={mission?.thumbnail ?? missions.brand.avatar}
                              style={{ margin: "auto" }}
                            ></Avatar>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            sm={12}
                            md={7}
                            lg={7}
                            className={classes.missionContent}
                          >
                            <Grid item xs={12}>
                              <p className={classes.brandTitle}>
                                {mission.title}
                              </p>
                            </Grid>
                            <Grid item xs={12}>
                              <p className={classes.missionDescription}>
                                {mission.description}
                              </p>
                            </Grid>
                          </Grid>
                          {/* <Grid
                    item
                    container
                    xs={2}
                    className={classes.missionContent}
                  >
                    <Grid item>
                      <p>550€</p>
                    </Grid>
                  </Grid> */}
                        </Grid>
                      </NavLink>
                    </Grid>
                  ))}
              </Grid>
            </Accordion>
          </Grid>
          <Grid
            item
            container
            xs={11}
            sm={11}
            md={4}
            lg={4}
            style={{ marginBottom: 30 }}
            direction="column"
            justify="flex-start"
            alignItems="center"
          >
            <Accordion
              style={{
                width: "100%",
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                classes={{
                  expanded: classes.expanded,
                }}
                style={{ border: "1px solid rgb(227, 227, 227)" }}
              >
                <Grid item xs={12}>
                  <p className={classes.blockTitle}>{`Annulées (${
                    missions.missions.filter((opt) => opt.isArchived).length
                  })`}</p>
                  <p
                    style={{
                      color: "#888",
                      fontStyle: "italic",
                      fontSize: 14,
                      marginTop: 10,
                    }}
                  >
                    Vos campagnes annulées.
                  </p>
                </Grid>
              </AccordionSummary>
              <Grid item container style={{ marginTop: 30 }}>
                {missions.missions
                  .filter((opt) => opt.isArchived)
                  .map((mission) => (
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.missionItems}
                      justify="center"
                      style={{ margin: "auto", marginBottom: 10 }}
                    >
                      <NavLink
                        className={classes.navLink}
                        exact={true}
                        to={`/brand/missions/${mission._id}/details`}
                      >
                        <Grid item container>
                          <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Avatar
                              className={classes.brandAvatar}
                              style={{ margin: "auto" }}
                              src={mission?.thumbnail ?? missions.brand.avatar}
                            ></Avatar>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            sm={12}
                            md={7}
                            lg={7}
                            className={classes.missionContent}
                          >
                            <Grid item xs={12}>
                              <p className={classes.brandTitle}>
                                {mission.title}
                              </p>
                            </Grid>
                            <Grid item xs={12}>
                              <p className={classes.missionDescription}>
                                {mission.description}
                              </p>
                            </Grid>
                          </Grid>
                          {/* <Grid
                    item
                    container
                    xs={2}
                    className={classes.missionContent}
                  >
                    <Grid item>
                      <p>550€</p>
                    </Grid>
                  </Grid> */}
                        </Grid>
                      </NavLink>
                    </Grid>
                  ))}
              </Grid>
            </Accordion>
          </Grid>
        </>
      )}
    </Grid>
  );
};

MissionDashboardMobile.propTypes = {};

export default MissionDashboardMobile;

import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
// import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { API_URL } from "../../../common/constants";
import axios from "axios";

// import settingsStyle from "./styles/settingsStyle";

// const useStyles = makeStyles(settingsStyle);

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: 16,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    []
  );

  return options;
};

const BankInformation = ({ title, profile }) => {
  // const classes = useStyles()
  // const options = useOptions();

  // const stripe = useStripe();
  // const elements = useElements();

  // const getCustomer = () => {
  //   axios.defaults.headers.common[
  //     "Authorization"
  //   ] = `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`;
  //   axios
  //     .get(`https://api.stripe.com/v1/customers/${profile.stripeId}`)
  //     .then((res) => {
  //       console.log("res ,here", res);
  //     })
  //     .catch((error) => console.error(error));
  // };

  // const stripeTokenHandler = (token) => {
  //   axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
  //     "token"
  //   )}`;
  //   axios
  //     .post(`${API_URL}/brand/profile/stripe-token`, {
  //       token: token.id,
  //     })
  //     .then((res) => {
  //       console.log("res ,here", res);
  //     })
  //     .catch((error) => console.error(error));
  // };

  // const savePaymentMethod = async () => {
  //   console.log("elements", elements.getElement(CardNumberElement));

  //   const card = elements.getElement(CardNumberElement);
  //   const result = await stripe.createToken(card);

  //   if (result.error) {
  //     // Show error to your customer.
  //     console.log(result.error.message);
  //   } else {
  //     // Send the token to your server.
  //     // This function does not exist yet; we will define it in the next step.
  //     // stripeTokenHandler(result.token);
  //     console.log("resulkt", result);
  //     stripeTokenHandler(result.token);
  //   }
  // };

  // const handleClick = async (event) => {
  //   // Get Stripe.js instance

  //   // Call your backend to create the Checkout Session
  //   axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
  //     "token"
  //   )}`;
  //   const session = await axios.post(`${API_URL}/brand/profile/stripe-token`);

  //   // const session = await response.json();
  //   console.log("reponse", session);

  //   // When the customer clicks on the button, redirect them to Checkout.
  //   const result = await stripe.redirectToCheckout({
  //     sessionId: session.data.id,
  //   });

  //   if (result.error) {
  //     // If `redirectToCheckout` fails due to a browser or network
  //     // error, display the localized error message to your customer
  //     // using `result.error.message`.
  //   }
  // };

  // useEffect(() => {
  //   getCustomer();
  //   // stripe.
  //   // const cards = stripe.customers.list({
  //   //   customer: profile.stripeId,
  //   //   type: "card",
  //   // });

  //   // console.log("carsd", cards);
  // }, [profile]);

  return (
    <Grid item container justify="flex-start" alignItems="flex-start">
      {/* <Grid
        item
        container
        justify="space-between"
        style={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
          marginBottom: 30,
          paddingBottom: 10,
          height: 50,
        }}
      >
        <Grid item xs={12}>
          <p
            style={{
              fontFamily: "nunito-bold",
              fontSize: 24,
              marginBottom: 20,
              display: "inline",
            }}
          >
            {title}
          </p>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <label>
          Card number
          <CardNumberElement
            options={options}
            onReady={() => {
              console.log("CardNumberElement [ready]");
            }}
            onChange={(event) => {
              console.log("CardNumberElement [change]", event);
            }}
            onBlur={() => {
              console.log("CardNumberElement [blur]");
            }}
            onFocus={() => {
              console.log("CardNumberElement [focus]");
            }}
          />
        </label>
        <label>
          Expiration date
          <CardExpiryElement
            options={options}
            onReady={() => {
              console.log("CardNumberElement [ready]");
            }}
            onChange={(event) => {
              console.log("CardNumberElement [change]", event);
            }}
            onBlur={() => {
              console.log("CardNumberElement [blur]");
            }}
            onFocus={() => {
              console.log("CardNumberElement [focus]");
            }}
          />
        </label>
        <label>
          CVC
          <CardCvcElement
            options={options}
            onReady={() => {
              console.log("CardNumberElement [ready]");
            }}
            onChange={(event) => {
              console.log("CardNumberElement [change]", event);
            }}
            onBlur={() => {
              console.log("CardNumberElement [blur]");
            }}
            onFocus={() => {
              console.log("CardNumberElement [focus]");
            }}
          />
        </label>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <button
          role="link"
          style={{
            fontFamily: "nunito-regular",
            fontSize: 12,
            color: "#FFFFFF",
            height: 30,
            paddingLeft: 20,
            paddingRight: 20,
            border: "1px solid #825DFF",
            borderRadius: 4,
            backgroundColor: "#825DFF",
          }}
          onClick={() => handleClick()}
        >
          Sauvegarder le moyen de paiement
        </button>
      </Grid> */}
    </Grid>
  );
};

BankInformation.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default BankInformation;

// ####################################
// // // Profile Page styles
// ###################################

const profilePageStyle = (theme) => ({
  pageLayout: {
    [theme.breakpoints.up("md")]: {
      width: "70%",
    },
    margin: "auto",
  },
  widgetContainer: {
    marginBottom: 30,
    backgroundColor: "#FFF",
    padding: 40,
    borderRadius: 6,
    position: "relative",
    cursor: "pointer",
  },
  widgetTitle: {
    fontSize: 28,
    fontFamily: "nunito-bold",
    fontWeight: 700,
    marginBottom: 20,
    color: "#333",
  },
  widgetTextContent: {
    fontSize: 17,
    fontWeight: 400,
    whiteSpace: "pre-line",
  },
  widgetActivityContainer: {
    marginBottom: 100,
    marginTop: 20,
    backgroundColor: "#FFF",
    padding: 40,
    borderRadius: 6,
    position: "relative",
  },
  widgetDescription: {
    borderRadius: 6,
    padding: 15,
    "&:hover": {
      backgroundColor: "rgba(130,93,255,0.1)",
    },
  },
  widgetCompanyTitle: {
    fontSize: 20,
    fontWeight: 600,
  },
  widgetCompanyDescription: {
    fontSize: 14,
    marginTop: 15,
  },
  companyContentContainer: {
    marginBottom: 30,
    paddingBottom: 30,
    borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    padding: 10,
    "&:hover": {
      border: "1px solid rgba(130,93,255,0.4)",
      borderRadius: 10,
    },
  },
  widgetPostDate: {
    fontSize: 12,
    color: "rgba(0, 0, 0, 0.4)",
    marginLeft: 10,
  },
  userPicture: {
    width: 150,
    height: 150,
    objectFit: "fill",
  },
  pictureContainer: {},
  contactItem: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.14)",
    minHeight: 80,
    cursor: "pointer",
    "&:last-child": {
      borderBottom: "none",
    },
    "&:hover": {
      borderLeft: "4px solid rgba(250, 128, 114,1)",
    },
  },
  userAvatar: {
    width: 50,
    height: 50,
    margin: "auto",
  },
  userName: {
    fontSize: 18,
    fontWeight: 500,
  },
  noticesContainer: {
    [theme.breakpoints.up("md")]: {
      padding: 20,
    },
  },
});

export default profilePageStyle;

import React, { useState } from "react";
import {
  Grid,
  Button,
  TextField,
  InputAdornment,
  Snackbar,
} from "@material-ui/core";
import { Email, Person, PhoneInTalk } from "@material-ui/icons";

import { API_URL } from "../../../common/constants";
import axios from "axios";
import { Alert } from "@material-ui/lab";
// import Pricing from "./Pricing";

const ContactUs = ({ isMobile }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [isMessageSent, setIsMessageSent] = useState(false);

  function handleSendMessage() {
    axios
      .post(`${API_URL}/contact`, {
        name,
        email,
        phoneNumber,
        message,
      })
      .then(() => {
        // setName("");
        // setMessage("");
        // setPhoneNumber("");
        // setEmail("");
        setIsMessageSent(true);
      });
  }

  return (
    <>
      <Grid
        item
        container
        xs={10}
        sm={10}
        md={8}
        lg={8}
        id="contact-us"
        style={{
          margin: "auto",
          boxShadow: "0 0px 50px rgba(0, 0, 0, 0.2)",
          padding: isMobile ? 20 : 40,
          marginTop: 60,
          borderRadius: 10,
        }}
        justify="center"
      >
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <p
            style={{
              textAlign: "center",
              fontFamily: "made-tommy-bold",
              fontSize: 36,
              color: "#733be0",
            }}
          >
            Contactez-nous
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10} style={{ marginBottom: 20 }}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person />
                </InputAdornment>
              ),
            }}
            inputProps={{ style: { padding: 14 } }}
            fullWidth
            id="name"
            name="name"
            value={name}
            variant="outlined"
            placeholder="Nom"
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10} style={{ marginBottom: 20 }}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneInTalk />
                </InputAdornment>
              ),
            }}
            inputProps={{ style: { padding: 14 } }}
            fullWidth
            id="phoneNumber"
            name="phoneNumber"
            value={phoneNumber}
            variant="outlined"
            placeholder="Numéro de téléphone"
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10} style={{ marginBottom: 20 }}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
            inputProps={{ style: { padding: 14 } }}
            fullWidth
            id="email"
            name="email"
            value={email}
            variant="outlined"
            placeholder="Email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10} style={{ marginBottom: 20 }}>
          <TextField
            inputProps={{ style: { padding: "8.5px 10px" } }}
            fullWidth
            rows={5}
            multiline
            id="message"
            name="message"
            value={message}
            variant="outlined"
            placeholder="Message"
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10} style={{ marginBottom: 20 }}>
          <Button
            style={{
              fontFamily: "nunito-bold",
              background: "rgba(130,93,255,1)",
              color: "#FFF",
              fontWeight: 800,
              borderRadius: 4,
              paddingTop: 10,
              paddingBottom: 10,
              paddingRight: 50,
              paddingLeft: 50,
              width: "100%",
              fontSize: 20,
              textTransform: "none",
            }}
            onClick={handleSendMessage}
          >
            Envoyer
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={isMessageSent}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setIsMessageSent(false)}
      >
        <Alert
          onClose={() => setIsMessageSent(false)}
          severity="success"
          variant="filled"
        >
          Votre message a bien été envoyé !
        </Alert>
      </Snackbar>
    </>
  );
};

export default ContactUs;

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { Grid, Button, makeStyles, TextField } from "@material-ui/core";
import axios from "axios";
import { API_URL } from "../../../common/constants";
import PopLogo from "../../../assets/images/logo.png";
import PopMiniLogo from "../../../assets/images/pop-mini-logo.png";

import authStyles from "./styles/login";

const useStyles = makeStyles(authStyles);

const colorBrand = "rgba(250, 128, 114,1)";
const colorInfluencer = "#825DFF";

const Login = withRouter(({ history, isInfluencer }) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [customError, setCustomError] = useState(undefined);

  const onLogin = async (e) => {
    e.preventDefault();
    if (email.length === 0) {
      setEmailError(true);
      return;
    }
    if (password.length === 0) {
      setPasswordError(true);
      return;
    }
    axios
      .post(`${API_URL}/${isInfluencer ? "influencer" : "brand"}/auth/login`, {
        email: email.toLowerCase(),
        password,
      })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("isInfluencer", isInfluencer);
        // let history = useHistory();
        history.push("/");
      })
      .catch((error) => {
        if (
          error?.response?.data?.message ===
          "Pending Account. Please Verify Your Email!"
        ) {
          setCustomError("Vérifiez vos mails pour activer votre compte !");
        } else {
          setCustomError("L'email ou le mot de passe n'existe pas.");
        }
      });
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.push("/");
    }
  }, [history]);

  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={5}
      lg={5}
      style={{
        marginTop: 150,
        boxShadow: "0 0px 50px rgba(0, 0, 0, 0.2)",
        borderRadius: 5,
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 150,
      }}
    >
      <div
        className={
          isInfluencer ? classes.coloredHeader : classes.coloredHeaderBrand
        }
      ></div>
      <form onSubmit={onLogin}>
        <Grid
          container
          justify="space-around"
          alignItems="center"
          style={{ textAlign: "center" }}
        >
          <div className={classes.popLogo}>
            <a href="/">
              <img alt="logo" src={PopMiniLogo} style={{ height: 100 }} />
            </a>
          </div>
          <Grid item xs={12} style={{ marginBottom: 40, textAlign: "center" }}>
            <p
              style={{ fontFamily: "nunito-bold", fontSize: 20 }}
            >{`Connexion ${isInfluencer ? "influenceur" : "marque"}`}</p>
          </Grid>
          <Grid item xs={8} style={{ marginBottom: 20 }}>
            <TextField
              fullWidth
              inputProps={{ style: { padding: "8.5px 10px" } }}
              id="email"
              name="email"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              label="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError(false);
              }}
              error={emailError}
              helperText={emailError && "Ce champs est requis."}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              inputProps={{ style: { padding: "8.5px 10px" } }}
              id="password"
              name="password"
              variant="outlined"
              type="password"
              InputLabelProps={{
                shrink: true,
              }}
              label="Mot de passe"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordError(false);
              }}
              error={passwordError}
              helperText={passwordError && "Le mot de passe est obligatoire."}
            />
          </Grid>
          {customError && (
            <Grid item xs={8}>
              <p
                style={{
                  marginTop: 10,
                  color: "#f44336",
                  fontFamily: "nunito-regular",
                  fontSize: 14,
                  textAlign: "left",
                }}
              >
                {customError}
              </p>
            </Grid>
          )}
          <Grid
            item
            xs={8}
            style={{ marginTop: 20, marginBottom: 20, textAlign: "center" }}
          >
            <Button
              style={{
                fontFamily: "nunito-bold",
                fontSize: 13,
                color: "#FFF",
                width: "100%",
                height: 40,
                paddingLeft: 20,
                paddingRight: 20,
                borderRadius: 18,
                textTransform: "none",
                backgroundColor: isInfluencer ? colorInfluencer : colorBrand,
              }}
              type="submit"
            >
              Se connecter
            </Button>
          </Grid>
          <Grid
            item
            container
            xs={8}
            justify="space-between"
            alignItems="center"
            style={{ marginBottom: 30 }}
          >
            <Grid item>
              <p
                style={{
                  fontSize: 10,
                  fontFamily: "nunito-semibold",
                  color: isInfluencer ? colorInfluencer : colorBrand,
                  cursor: "pointer",
                }}
                onClick={() =>
                  history.push(
                    `/password/recover/${isInfluencer ? "influencer" : "brand"}`
                  )
                }
              >
                Mot de passe oublié ?
              </p>
            </Grid>
            <Grid item>
              <p
                style={{
                  fontSize: 10,
                  fontFamily: "nunito-semibold",
                  color: "#000",
                  display: "inline",
                }}
              >
                Vous n'avez pas de compte ?
              </p>
              <p
                style={{
                  fontSize: 10,
                  fontFamily: "nunito-semibold",
                  color: isInfluencer ? colorInfluencer : colorBrand,
                  display: "inline",
                  marginLeft: 5,
                  cursor: "pointer",
                }}
                onClick={() =>
                  history.push(
                    `/register/${isInfluencer ? "influencer" : "brand"}`
                  )
                }
              >
                S'inscrire
              </p>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
});

export default Login;

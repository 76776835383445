// ####################################
// // // Mission Page styles
// ###################################

const missionPageStyle = (theme) => ({
  pageLayout: {
    [theme.breakpoints.up("md")]: {
      width: "70%",
    },
    margin: "auto",
  },
  companyAvatar: {
    width: 150,
    height: 150,
    [theme.breakpoints.up("md")]: {
      float: "right",
    },
    [theme.breakpoints.down("md")]: {
      margin: "auto",
    },
  },
  brandLabel: {
    fontWeight: 500,
    fontSize: 36,
    fontFamily: "nunito-bold",
    marginBottom: 10,
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  profileInfo: {
    height: 100,
    border: "1px solid #e0ded9",
    padding: "10px 15px",
    borderRadius: 6,
  },
  infoTitle: {
    padding: 5,
  },
  infoContent: {
    padding: 5,
  },
  widgetContainer: {
    marginBottom: 100,
    minHeight: 300,
    backgroundColor: "#FFF",
    padding: 40,
    borderRadius: 6,
    position: "relative",
  },
  widgetTitle: {
    color: "#484848",
    fontSize: 24,
    fontWeight: 600,
  },
  widgetTextContent: {
    fontSize: 15,
    fontWeight: 400,
    color: "#484848",
    whiteSpace: "pre-line",
  },
  detailCategory: {
    marginTop: 20,
    fontSize: 18,
    color: "#484848",
    borderBottom: "1px solid #ededed",
    paddingBottom: 12,
  },
  cardTitle: {
    fontSize: 18,
    color: "#484848",
  },
  buttonSection: {
    marginTop: 20,
  },
  button: {
    textTransform: "capitalize",
    [theme.breakpoints.down("md")]: {
      marginBottom: 10,
    },
  },
  missionContentContainer: {
    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
    margin: "auto",
    overflow: "auto",
    borderRadius: 6,
  },
  sidenavMenu: {
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  },
  menuItem: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.14)",
    minHeight: 60,
    cursor: "pointer",
    "&:hover": {
      borderLeft: "4px solid rgba(250, 128, 114,1)",
    },
  },
  selectedMenuItem: {
    cursor: "pointer",
    borderBottom: "1px solid rgba(0, 0, 0, 0.14)",
    minHeight: 60,
    borderLeft: "4px solid rgba(250, 128, 114,1))",
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
  },
  influencerInfos: {
    borderRadius: 5,
    padding: 15,
    marginTop: 20,
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    textAlign: "center",
  },

  // Influencer List
  contactListing: {
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    paddingTop: 30,
    maxHeight: "100%",
    overflow: "auto",
  },
  waitingForInfluencer: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.25)",
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  influencerItem: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.14)",
    minHeight: 80,
    // cursor: "pointer",
    // "&:hover": {
    //   borderLeft: "4px solid rgba(250, 128, 114,1)",
    // },
  },
  customChip: {
    borderRadius: 18,
    padding: 3,
    fontSize: 12,
    textAlign: "center",
    color: "#FFF",
    backgroundColor: "rgba(250, 128, 114,1)",
    marginTop: 10,
  },
  userAvatarContainer: {
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      marginTop: 10,
    },
  },
  influencerInformationContainer: {
    [theme.breakpoints.down("md")]: {
      paddingTop: 10,
    },
  },
  userAvatar: {
    width: 80,
    height: 80,
    margin: "auto",
    "& img": {
      objectFit: "cover !important",
    },
  },
  userName: {
    fontSize: 18,
    fontWeight: 500,
  },
  influencerContent: {
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.7)",
  },
  separator: {
    fontSize: 12,
    fontWeight: 600,
    color: "rgba(0, 0, 0, 1)",
    paddingLeft: 10,
    paddingRight: 10,
  },

  // Briefs
  fieldLabel: {
    fontSize: 18,
    fontWeight: 400,
    marginBottom: 10,
    color: "#484848",
  },
  socialNetworkLabel: {
    fontSize: 24,
    fontWeight: 500,
    marginBottom: 10,
    color: "#484848",
  },
  socialNetworkBorder: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
    paddingTop: 10,
  },
  saveInfluencer: {
    display: "flex",
    padding: 10,
    cursor: "pointer",
    borderRadius: 4,
    "&:hover": {
      border: "none !important",
      background: "rgb(247, 247, 247) !important",
      color: "rgb(0, 0, 0) !important",
    },
  },
  statisticsSection: {
    border: "1px solid #E2E2E2",
    textAlign: "center",
    paddingTop: 15,
    paddingBottom: 15,
    borderRadius: 5,
  },
  statisticsNumber: {
    fontSize: 40,
    fontFamily: "nunito-semibold",
    borderRight: "1px solid #FFF",
    paddingRight: 10,
    marginRight: 10,
    color: "#FFF",
  },
  statisticsTitle: {
    fontFamily: "nunito-semibold",
    fontSize: 14,
    color: "#363535",
  },
  accordion: {
    "&:before": {
      background: "transparent !important",
    },
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  paperUl: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: 10,
    margin: 0,
  },
});

export default missionPageStyle;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

const DescriptionModal = ({
  description,
  handleUpdateDescription,
  isModalOpen,
  handleCloseModal,
}) => {
  const [newDescription, setNewDescription] = useState(description);
  const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile = width <= 768;

  return (
    <Dialog
      open={isModalOpen}
      maxWidth="md"
      fullWidth={true}
      fullScreen={isMobile}
      onClose={() => handleCloseModal(false)}
    >
      <DialogTitle>Vous en quelques mots</DialogTitle>
      <DialogContent>
        <TextField
          rows={5}
          multiline
          variant="outlined"
          fullWidth
          value={newDescription}
          onChange={(e) => setNewDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions style={{ marginBottom: isMobile ? 30 : 0 }}>
        <Button
          variant="contained"
          color="primary"
          style={{
            fontFamily: "nunito-bold",
            fontSize: 13,
            color: "#FFF",
            height: 40,
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 18,
            textTransform: "none",
            backgroundColor: "#825DFF",
          }}
          onClick={() => {
            handleUpdateDescription(newDescription.replace(/↵/g, "\n"));
            handleCloseModal(false);
          }}
        >
          Enregistrer
        </Button>
        <Button
          variant="contained"
          style={{
            fontFamily: "nunito-bold",
            fontSize: 13,
            color: "#000",
            height: 40,
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 18,
            textTransform: "none",
          }}
          onClick={() => handleCloseModal(false)}
        >
          Annuler
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DescriptionModal.propTypes = {
  description: PropTypes.string,
  handleUpdateDescription: PropTypes.func,
  isModalOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func,
};

export default DescriptionModal;

import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";

import modalsStyle from "./styles/modalsStyle";
import { DialogActions, DialogTitle, DialogContent } from "../../Dialog/Dialog";
import axios from "axios";
import { API_URL } from "../../../../common/constants";
import { useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";
import { Folder } from "@material-ui/icons";

const useStyles = makeStyles(modalsStyle);

const OrangeCheckbox = withStyles({
  root: {
    color: "rgba(250, 128, 114,0.8)",
    "&$checked": {
      color: "rgba(250, 128, 114,1)",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const SendBriefsModal = ({
  isModalOpen,
  handleCloseModal,
  briefs,
  influencerId,
  influencerMissionId,
  missionId,
  influencerBriefs,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [selectedBriefs, setSelectedBriefs] = useState([]);

  const handleSelectBrief = (id) => {
    const idx = selectedBriefs.findIndex((brief) => brief === id);
    if (idx === -1) {
      setSelectedBriefs([...selectedBriefs, id]);
    } else {
      const tmp = [...selectedBriefs];
      tmp.splice(idx, 1);

      setSelectedBriefs([...tmp]);
    }
  };

  const handleSendBriefs = () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .post(
        `${API_URL}/brand/missions/${missionId}/send-briefs/${influencerMissionId}/${influencerId}`,
        {
          briefs: selectedBriefs,
        }
      )
      .then((res) => {
        handleCloseModal(false);
      });
  };

  useEffect(() => {
    if (influencerBriefs?.length > 0) {
      setSelectedBriefs(influencerBriefs);
    }
  }, [influencerBriefs]);

  return (
    <Dialog
      open={isModalOpen}
      maxWidth="md"
      fullWidth={true}
      fullScreen={matches}
      onClose={() => handleCloseModal(false)}
    >
      <DialogTitle onClose={() => handleCloseModal(false)}>
        Envoyer les briefs
      </DialogTitle>
      <DialogContent dividers>
        <Grid container>
          {briefs.length === 0 && (
            <Grid item style={{ textAlign: "center" }} xs={12}>
              <p style={{ fontSize: 20, marginTop: 10, marginBottom: 5 }}>
                Aucun brief n'a encore été ajouté.
              </p>
              <p style={{ fontSize: 18, marginBottom: 10 }}>
                Rendez-vous dans le menu{" "}
                <a href={`/brand/missions/${missionId}/briefs`}>Briefs</a> pour
                en ajouter.
              </p>
            </Grid>
          )}
          {briefs.map((brief) => {
            return (
              <ListItem
                key={brief._id}
                ContainerComponent="div"
                classes={{
                  container: classes.containerListItem,
                }}
                button
                className={
                  selectedBriefs?.some((selected) => brief._id === selected)
                    ? classes.selectedBrief
                    : classes.brief
                }
                onClick={() => handleSelectBrief(brief._id)}
              >
                <ListItemText primary={brief.title} secondary={null} />
                <ListItemSecondaryAction>
                  <OrangeCheckbox
                    onClick={() => handleSelectBrief(brief._id)}
                    checked={selectedBriefs?.some(
                      (selected) => brief._id === selected
                    )}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        {briefs.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            style={{
              backgroundColor:
                selectedBriefs.length === 0 ? "#eee" : "rgba(250, 128, 114,1)",
              borderRadius: 18,
            }}
            disabled={selectedBriefs.length === 0}
            onClick={() => {
              handleSendBriefs();
              handleCloseModal(false);
            }}
          >
            Envoyer
          </Button>
        )}
        <Button
          variant="contained"
          color="secondary"
          style={{
            border: "1px solid rgba(250, 128, 114,1)",
            color: "rgba(250, 128, 114,1)",
            boxShadow: "none",
            background: "transparent",
            borderRadius: 18,
          }}
          onClick={() => handleCloseModal(false)}
        >
          Annuler
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SendBriefsModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  briefs: PropTypes.array.isRequired,
  influencerId: PropTypes.string.isRequired,
};

export default SendBriefsModal;

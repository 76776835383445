import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";

import {
  Grid,
  Avatar,
  Chip,
  Button,
  Dialog,
  Snackbar,
  Tabs,
  Tab,
  Badge,
} from "@material-ui/core";

import MissionSidenav from "../../../Common/MissionSidenav/MissionSidenav";
import MissionDetails from "../../../Brand/Missions/BrandMissionPage/MissionDetails/MissionDetails";

import missionPageStyle from "./styles/missionPageStyles";
import MessagesContainer from "../../../Common/Messages/MessagesContainer";

import {
  DescriptionOutlined,
  MessageOutlined,
  FolderOpenOutlined,
} from "@material-ui/icons";
import { getFormattedSocialNetworks } from "../../InfluencerProfilePage/PersonalInformation/utils";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from "../../../Common/Dialog/Dialog";
import { API_URL } from "../../../../common/constants";
import axios from "axios";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles(missionPageStyle);

const menuProposed = [
  {
    id: 1,
    title: "Details",
    icon: <DescriptionOutlined />,
    component: <MissionDetails />,
  },
];

const menuAccepted = [
  {
    id: 1,
    title: "Details",
    icon: <DescriptionOutlined />,
    component: <MissionDetails />,
  },
  {
    id: 2,
    title: "Messages",
    icon: <MessageOutlined />,
    component: <MessagesContainer />,
  },
];

const menuPaid = [
  {
    id: 1,
    title: "Details",
    icon: <DescriptionOutlined />,
    component: <MissionDetails />,
  },
  {
    id: 2,
    title: "Messages",
    icon: <MessageOutlined />,
    component: <MessagesContainer />,
  },
  {
    id: 3,
    title: "Suivis",
    icon: <FolderOpenOutlined />,
    component: <div></div>,
  },
];

// const routes = [
//   {
//     icon: <DescriptionOutlined />,
//     label: "Détails",
//     to: "/details",
//     path: "/influencer/missions/:id/details",
//     component: MissionDetails,
//     exact: true,
//   },
//   {
//     icon: <MessageOutlined />,
//     label: "Messages",
//     to: "/messages",
//     path: "/influencer/missions/:id/messages",
//     component: MessagesContainer,
//     exact: true,
//   },
//   {
//     icon: <FolderOpenOutlined />,
//     label: "Suivi",
//     to: "/follow-process",
//     path: "/influencer/missions/:id/follow-process",
//     component: MissionFollow,
//     exact: true,
//   },
// ];

const discussion = [
  {
    avatar:
      "https://yt3.ggpht.com/ytc/AAUvwnjxMrpWI8jz4XYlIDN19XE8RCCxkiHPhZvwbY0P=s900-c-k-c0x00ffffff-no-rj",
    message: "Super je fais un test 22",
    position: "left",
    date: "2020-04-26 15:10:41.639Z",
    content: "post",
    socialNetworks: ["instagram"],
    price: 1000,
    offer: true,
  },
  {
    avatar:
      "https://yt3.ggpht.com/ytc/AAUvwnjxMrpWI8jz4XYlIDN19XE8RCCxkiHPhZvwbY0P=s900-c-k-c0x00ffffff-no-rj",
    message: "Super je fais un test",
    position: "left",
    date: "2020-03-31 15:10:41.639Z",
  },
  {
    avatar:
      "https://yt3.ggpht.com/ytc/AAUvwnjxMrpWI8jz4XYlIDN19XE8RCCxkiHPhZvwbY0P=s900-c-k-c0x00ffffff-no-rj",
    message: "Super je fais un test",
    position: "left",
    date: "2020-03-30 15:10:41.639Z",
  },
  {
    avatar:
      "https://yt3.ggpht.com/ytc/AAUvwnjxMrpWI8jz4XYlIDN19XE8RCCxkiHPhZvwbY0P=s900-c-k-c0x00ffffff-no-rj",
    message: "Super je fais un test",
    position: "right",
    date: "2020-03-29 15:10:41.639Z",
  },
  {
    avatar:
      "https://yt3.ggpht.com/ytc/AAUvwnjxMrpWI8jz4XYlIDN19XE8RCCxkiHPhZvwbY0P=s900-c-k-c0x00ffffff-no-rj",
    message: "Super je fais un test",
    position: "left",
    date: "2020-03-28 15:10:41.639Z",
  },
  {
    avatar:
      "https://yt3.ggpht.com/ytc/AAUvwnjxMrpWI8jz4XYlIDN19XE8RCCxkiHPhZvwbY0P=s900-c-k-c0x00ffffff-no-rj",
    message: "Super je fais un test",
    position: "right",
    date: "2020-03-27 15:10:41.639Z",
  },
  {
    avatar:
      "https://yt3.ggpht.com/ytc/AAUvwnjxMrpWI8jz4XYlIDN19XE8RCCxkiHPhZvwbY0P=s900-c-k-c0x00ffffff-no-rj",
    message: "Super je fais un test",
    position: "left",
    date: "2020-03-26 15:10:41.639Z",
  },
];

const MissionPage = ({ mission, handleRefreshMission, routes }) => {
  const classes = useStyles();

  const [selectedStep, setSelectedStep] = useState(0);
  const history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    message: "Êtes-vous sur de vouloir postuler à cette campagne ?",
    actionButton: "Postuler",
  });

  const [selectedMenuItem, setSelectedMenuItem] = useState({
    id: 1,
    title: "Details",
    component: <MissionDetails />,
  });

  const handleOnUpdateStatus = (status) => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .put(`${API_URL}/influencer/missions/${mission._id}/status`, {
        influencerStatus: status,
      })
      .then(() => handleRefreshMission())
      .catch((error) => console.error(error));
  };

  const getDiscussion = () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    return axios.get(
      `${API_URL}/influencer/missions/${mission.missionId._id}/messages`
    );
  };

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile = width <= 768;

  useEffect(() => {
    let paths = history.location.pathname.split("/");
    paths = paths.filter((path) => path.length > 0);

    const index = routes.findIndex(
      (route) => route.to === `/${paths[paths.length - 1]}`
    );
    setSelectedStep(index);
  }, [history.location.pathname]);

  return (
    <>
      {mission.isArchived && (
        <Alert
          severity="info"
          variant="filled"
          style={{
            justifyContent: "center",
            fontSize: 20,
            alignItems: "center",
          }}
        >
          Cette mission a été annulée.
        </Alert>
      )}
      {mission.influencerStatus === "DONE" && (
        <Alert
          severity="info"
          variant="filled"
          style={{
            justifyContent: "center",
            fontSize: 20,
            alignItems: "center",
          }}
        >
          Cette mission est terminée.
        </Alert>
      )}
      {mission.influencerStatus === "REFUSED_BY_BRAND" && (
        <Alert
          severity="warning"
          variant="filled"
          style={{
            justifyContent: "center",
            fontSize: 20,
            alignItems: "center",
          }}
        >
          La marque n'a pas retenu votre profil pour cette campagne.
        </Alert>
      )}
      {mission.influencerStatus === "DECLINED" && (
        <Alert
          severity="warning"
          variant="filled"
          style={{
            justifyContent: "center",
            fontSize: 20,
            alignItems: "center",
          }}
        >
          Vous avez décliné cette mission.
        </Alert>
      )}
      <Grid
        item
        container
        style={{
          marginBottom: 30,
          background: "#FFF",
          minHeight: 200,
          padding: 40,
          borderRadius: 6,
          position: "relative",
        }}
      >
        <Grid
          item
          container
          className={classes.pageLayout}
          justify="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Avatar
              className={classes.companyAvatar}
              style={{ margin: "auto" }}
              src={mission.missionId.thumbnail ?? mission.brandId.avatar}
            ></Avatar>
          </Grid>
          <Grid item container xs={12} sm={12} md={8} lg={8} justify="center">
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <p className={classes.brandLabel}>{mission.brandId.brandName}</p>
            </Grid>
            <Grid
              item
              xs={10}
              sm={10}
              md={8}
              lg={8}
              style={{ cursor: "pointer", marginBottom: 20 }}
            >
              <a
                href={mission.brandId.website}
                target="_blank"
                rel="noreferrer"
                className={classes.widgetTextContent}
              >
                {mission.brandId.website}
              </a>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <p className={classes.widgetTextContent}>
                {mission.brandId.description}
              </p>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={8}
              lg={8}
              justify="space-between"
              style={{ marginTop: 20 }}
            >
              {getFormattedSocialNetworks(mission.brandId.socialNetworks).map(
                (socialNetwork) => (
                  <Grid item xs={3} key={socialNetwork.value}>
                    <a
                      href={`${socialNetwork.link}/${socialNetwork.username}`}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none", cursor: "pointer" }}
                    >
                      <Chip
                        style={{
                          background: socialNetwork.background,
                          color: "#FFF",
                          cursor: "pointer",
                        }}
                        icon={socialNetwork.icon}
                        label="Instagram"
                      />
                    </a>
                  </Grid>
                )
              )}
            </Grid>
            {(mission.influencerStatus === "PROPOSED" ||
              mission.influencerStatus === "ACCEPTED") && (
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={8}
                lg={8}
                className={classes.buttonSection}
              >
                {mission.influencerStatus === "PROPOSED" && (
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      style={{
                        backgroundColor: "rgba(130,93,255,1)",
                        borderRadius: 18,
                        color: "#FFF",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setIsSnackbarOpen(true);
                        handleOnUpdateStatus("ACCEPTED");
                      }}
                    >
                      Postuler
                    </Button>
                  </Grid>
                )}

                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Button
                    className={classes.buttonSecondary}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setModalContent({
                        message:
                          "Êtes-vous sur de vouloir renoncer à cette campagne ?",
                        actionButton: "Renoncer",
                        status: "DECLINED",
                      });
                      setIsModalOpen(true);
                    }}
                    style={{
                      border: "1px solid rgba(130,93,255,1)",
                      color: "rgba(130,93,255,1)",
                      boxShadow: "none",
                      background: "transparent",
                      borderRadius: 18,
                    }}
                  >
                    {mission.influencerStatus === "ACCEPTED" ||
                    mission.influencerStatus === "DOING"
                      ? "Renoncer à cette campagne"
                      : "Pas interessé"}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {isMobile && (
        <Grid item xs={12}>
          <Tabs
            value={selectedStep}
            variant="fullWidth"
            scrollButtons={false}
            onChange={(e, value) => {
              setSelectedStep(value);
              history.push(
                `/influencer/missions/${mission.missionId._id}${routes[value].to}`
              );
            }}
          >
            {routes.map((config, index) => (
              <Tab
                style={{
                  textDecoration: "none",
                  textTransform: "none",
                  fontSize: 16,
                  fontFamily: "nunito-semibold",
                }}
                icon={
                  <Badge
                    color="secondary"
                    variant="dot"
                    invisible={
                      mission.startDiscussion || config.label !== "Messages"
                    }
                  >
                    {config.icon}
                  </Badge>
                }
                className={classes.mobileTab}
                label={config.label}
                value={index}
              />
            ))}
          </Tabs>
        </Grid>
      )}
      <Grid container className={classes.missionContentContainer}>
        {!isMobile && (
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={2}
            lg={2}
            style={{
              border: "1px solid rgba(0, 0, 0, 0.12)",
              height: "fit-content",
              background: "#FFF",
            }}
          >
            <MissionSidenav
              missionId={mission.missionId._id}
              influencerMission={mission}
              selectedMenuItem={selectedMenuItem}
              setSelectedMenuItem={setSelectedMenuItem}
              configMenu={
                mission.influencerStatus === "PROPOSED"
                  ? menuProposed
                  : mission.influencerStatus === "ACCEPTED"
                  ? menuAccepted
                  : menuPaid
              }
              routes={routes}
              isInfluencer
            />
          </Grid>
        )}
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={10}
          lg={10}
          alignItems="flex-start"
          style={{
            maxHeight: "100%",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            background: "#FFF",
            minHeight: 500,
          }}
        >
          {routes.length > 0 && (
            <Switch>
              {routes
                .map((prop) => {
                  if (!prop.disabled) {
                    return (
                      <Route
                        key={prop.path}
                        render={(props) => (
                          <prop.component
                            {...props}
                            mission={mission.missionId}
                            isInfluencer
                            discussion={discussion}
                            missionId={mission.missionId._id}
                            getDiscussion={getDiscussion}
                            followProcess={mission.followProcess}
                            influencerMissionId={mission._id}
                            handleRefreshFollowProcess={handleRefreshMission}
                            brandName={mission.brandId.brandName}
                            briefs={mission.briefs}
                            brand={mission.brandId}
                          />
                        )}
                        exact={prop.exact}
                        path={prop.path}
                      />
                    );
                  }
                  return null;
                })
                .filter((route) => route)}
              <Redirect
                from={"/influencer/missions/:id"}
                to={"/influencer/missions/:id/details"}
              />
            </Switch>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={isModalOpen}
        maxWidth="md"
        onClose={() => setIsModalOpen(false)}
      >
        <DialogTitle>Renoncer à cette campagne</DialogTitle>
        <DialogContent dividers>
          <p>{modalContent.message}</p>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{
              backgroundColor: "rgba(130,93,255,1)",
              borderRadius: 18,
            }}
            onClick={() => {
              handleOnUpdateStatus("DECLINED");
              setIsModalOpen(false);
            }}
          >
            {modalContent.actionButton}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.buttonSecondaryModal}
            style={{
              border: "1px solid rgba(130,93,255,1)",
              color: "rgba(130,93,255,1)",
              boxShadow: "none",
              background: "transparent",
              borderRadius: 18,
            }}
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        style={{ zIndex: 1400, paddingTop: 60 }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setIsSnackbarOpen(false)}
          severity="success"
          variant="filled"
        >
          Attendez que la marque vous contacte !
        </Alert>
      </Snackbar>
    </>
  );
};

MissionPage.propTypes = {};

export default MissionPage;

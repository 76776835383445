// ####################################
// // // Mission Page styles
// ###################################

const missionStyle = (theme) => ({
  missionBlock: {
    borderRight: "1px solid rgb(227, 227, 227)",
    minHeight: 500,
    paddingLeft: 25,
    paddingRight: 25,
  },
  blockTitle: {
    fontSize: 18,
    fontWeight: 500,
  },
  missionItems: {
    boxShadow: "rgb(0 0 0 / 10%) 0px 4px 12px 0px",
    minHeight: 80,
    borderRadius: 6,
    width: "100%",
    marginTop: 35,
    background: "#FFF",
    padding: 15,
  },
  brandAvatar: {
    width: 75,
    height: 75,
    "& > img": {
      objectFit: "contain",
    },
  },
  brandTitle: {
    fontWeight: 500,
    fontSize: 16,
    fontFamily: "nunito-bold",
  },
  missionDescription: {
    fontWeight: 400,
    fontSize: 12,
  },
  missionContent: {
    padding: 8,
  },
  navLink: {
    textDecoration: "none",
    color: "inherit",
    width: "100%",
  },
});

export default missionStyle;

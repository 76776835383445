import React from "react";
import Select from "react-select";

const CustomSelect = ({ error, helperText, ...rest }) => {
  return (
    <>
      <Select {...rest} />
      {error && (
        <p
          style={{
            color: "#f44336",
            fontSize: "0.75rem",
            marginTop: 3,
            textAlign: "left",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: 400,
            lineHeight: 1.66,
            letterSpacing: "0.03333em",
          }}
        >
          {helperText}
        </p>
      )}
    </>
  );
};

export default CustomSelect;

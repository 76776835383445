import LoginInfluencer from "../pages/Auth/LoginInfluencer/Login";
import LoginBrand from "../pages/Auth/LoginBrand/Login";
import RegisterBrand from "../pages/Auth/RegisterBrand/Register";
import RegisterInfluencer from "../pages/Auth/RegisterInfluencer/Register";
import Showcase from "../Components/Showcase/Showcase";
import SelectRole from "../Components/Auth/SelectRole/SelectRole";
import ResetPassword from "../Components/Auth/ResetPassword/ResetPassword";
import ConfirmAccount from "../Components/Auth/ConfirmAccount/ConfirmAccount";
import ResetPasswordForm from "../Components/Auth/ResetPassword/ResetPasswordForm";
import ResetPasswordWaitingMail from "../Components/Auth/ResetPassword/ResetPasswordWaitingMail";
import ConfirmAccountWaitingForEmail from "../Components/Auth/ConfirmAccountWaitingForEmail/ConfirmAccountWaitingForEmail";

const authRoutes = [
  {
    path: "/",
    name: "Showcase",
    component: Showcase,
  },
  {
    path: "/select-role/login",
    name: "Select Role",
    component: SelectRole,
  },
  {
    path: "/select-role/register",
    name: "Select Role",
    component: SelectRole,
  },
  {
    path: "/password/recover/:type",
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/password/recover/waiting-for-email/:type",
    name: "Reset Password",
    component: ResetPasswordWaitingMail,
  },
  {
    path: "/reset-password/:token/:type",
    name: "Reset Password",
    component: ResetPasswordForm,
  },
  // TODO: for brand
  {
    path: "/login/brand",
    name: "Login",
    component: LoginBrand,
  },
  {
    path: "/confirm/:confirmationCode/:type",
    name: "Activer mon compte",
    component: ConfirmAccount,
  },
  {
    path: "/waiting-for-email/:type",
    name: "Activer mon compte",
    component: ConfirmAccountWaitingForEmail,
  },
  {
    path: "/login/influencer",
    name: "Login",
    component: LoginInfluencer,
  },
  {
    path: "/register/influencer",
    name: "Register",
    component: RegisterInfluencer,
  },
  // TODO: for brand
  {
    path: "/register/brand",
    name: "Register",
    component: RegisterBrand,
  },
  { redirect: true, path: "/", pathTo: "/", name: "Showcase" },
];

export default authRoutes;

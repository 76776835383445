import "react-circular-progressbar/dist/styles.css";
import React, { useState, useEffect } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import {
  Grid,
  Hidden,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Tab,
  Tabs,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { ExpandMore } from "@material-ui/icons";

import Image1 from "./assets/slider image.png";
// import Image2 from "./assets/step2.png";
import KesakoImage from "./assets/kesako image.png";
import SaveTimeImage from "./assets/save timing icon.png";
import GrowthImage from "./assets/high incease icon.png";
import TargetImage from "./assets/target the right audience icon.png";
import CheckImage from "./assets/orange circle.png";
import VisibilityPercent from "./assets/increase the visibility image.png";
// import VisibilityPic from "./assets/visibility-pic.png";
import InfluencerPic from "./assets/influencer image.png";
// import OurClients from "./assets/our-clients.png";

import showcaseStyle from "./styles/showcaseStyle";
import { useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";
import { faqConfig } from "./faqConfig";
import ContactUs from "./ContactUs/ContactUs";
// import Pricing from "./Pricing";

const useStyles = makeStyles(showcaseStyle);

const styles = {
  text: {
    fontSize: 32,
    color: "rgba(130,93,255,1)",
    fill: "rgba(130,93,255,1)",
    fontFamily: "made-tommy-bold",
    margin: "auto",
    textAlign: "center",
  },
  path: {
    stroke: "rgba(130,93,255,1)",
    strokeLinecap: "round",
    transition: "stroke-dashoffset 0.5s ease 0s",
  },
  trail: {
    stroke: "rgba(130,93,255,0.2)",
  },
};

const Showcase = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const countDownDate = new Date("Nov 15, 2021 08:00:00").getTime();
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [selectedFaq, setSelectedFaq] = useState("influencer");

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile = width <= 768;

  useEffect(() => {
    setInterval(() => {
      const now = new Date().getTime();
      const timeleft = countDownDate - now;

      setDays(Math.floor(timeleft / (1000 * 60 * 60 * 24)));
      setHours(
        Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60) || 0)
      );
      setMinutes(Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60)) || 0);
      setSeconds(Math.floor((timeleft % (1000 * 60)) / 1000) || 0);
    }, 1000);
  }, []);

  return (
    <>
      <Hidden only={["md", "lg", "xl"]}>
        <Grid
          item
          container
          alignItems="flex-start"
          justify="space-between"
          style={{
            marginTop: isMobile ? 40 : 150,
            minHeight: isMobile ? "auto" : 600,
            paddingBottom: 40,
            textAlign: "center",
            background: "linear-gradient(0deg, #EDE6FA 0%, #FEE8E5 49%)",
          }}
        >
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={5}
            lg={5}
            alignItems="center"
            justify="center"
            style={{ marginTop: 30, padding: 20 }}
          >
            <Grid item xs={12}>
              <h1
                style={{
                  fontFamily: "made-tommy-bold",
                  fontSize: 34,
                  color: "#733be0",
                }}
              >
                La plateforme pour
              </h1>
              <h2
                style={{
                  fontFamily: "made-tommy-bold",
                  fontSize: 34,
                  color: "#fa8072",
                }}
              >
                Marques & Influenceurs.
              </h2>
            </Grid>
            <Grid
              item
              container
              xs={12}
              style={{ marginTop: 30 }}
              justify="center"
            >
              <p
                style={{
                  fontFamily: "nunito-bold",
                  fontSize: 20,
                  color: "rgba(130,93,255,1)",
                  display: "inline",
                }}
              >
                Les opportunités viennent à vous !
              </p>
              {/* <Grid item container xs={4}>
              <CircularProgressbar
                value={days}
                text={`${days}`}
                styles={styles}
                strokeWidth={3}
              />
              <p className={classes.timerNumber}>Jours</p>
            </Grid>
            <Grid item container style={{ marginLeft: 40 }} xs={4}>
              <CircularProgressbar
                value={hours}
                text={`${hours}`}
                styles={styles}
                strokeWidth={3}
                maxValue={24}
              />
              <p className={classes.timerNumber}>Heures</p>
            </Grid>
            <Grid item container style={{ marginTop: 60 }} xs={4}>
              <CircularProgressbar
                value={minutes}
                text={`${minutes}`}
                styles={styles}
                strokeWidth={3}
                minValue={0}
                maxValue={60}
              />
              <p className={classes.timerNumber}>Minutes</p>
            </Grid>
            <Grid
              item
              container
              style={{ marginLeft: 40, marginTop: 60 }}
              xs={4}
            >
              <CircularProgressbar
                value={seconds}
                text={`${seconds}`}
                styles={styles}
                strokeWidth={3}
                maxValue={60}
              />
              <p className={classes.timerNumber}>Secondes</p>
            </Grid> */}
            </Grid>
            <Grid
              item
              xs={12}
              justify="center"
              style={{ textAlign: "center", marginTop: 30 }}
            >
              <p
                style={{
                  fontFamily: "nunito-bold",
                  background: "rgba(250, 128, 114,0.7)",
                  color: "#FFF",
                  borderRadius: 18,
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingRight: 50,
                  paddingLeft: 50,
                }}
                onClick={() => {
                  history.push("/select-role/register");
                }}
              >
                Nous rejoindre
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              justify="center"
              style={{ textAlign: "center", marginTop: 30 }}
            >
              <a href="/#contact-us" style={{ textDecoration: "none" }}>
                <p
                  style={{
                    fontFamily: "nunito-bold",
                    background: "rgba(130,93,255,0.7)",
                    color: "#FFF",
                    borderRadius: 18,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingRight: 50,
                    paddingLeft: 50,
                  }}
                  // onClick={() => {
                  //   // history.push("/select-role/register");
                  // }}
                >
                  Nous contacter
                </p>
              </a>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={4}
            lg={4}
            justify="flex-end"
            alignItems="flex-end"
            style={{ marginTop: 30 }}
          >
            <Grid item xs={12}>
              <img
                src={Image1}
                alt="pic-1"
                style={{ width: 300 }}
                title="L'agence digitale pour les marques et influenceurs sur les réseaux sociaux"
              />
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <p
              style={{
                marginTop: 50,
                fontFamily: "made-tommy-regular",
                color: "rgba(0, 0, 0, 0.4)",
              }}
            >
              <a
                href={"/register/influencer"}
                style={{
                  color: "rgba(130,93,255,1)",
                  fill: "rgba(130,93,255,1)",
                  fontFamily: "made-tommy-regular",
                  textDecoration: "none",
                }}
              >
                Inscrivez-vous maintenant !
              </a>
              Pour avoir les premières missions.
            </p>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden only={["xs", "sm"]}>
        <Grid
          item
          container
          alignItems="flex-start"
          justify="space-around"
          // className={classes.topContainerHeaderCropped}
          style={{
            marginTop: isMobile ? 0 : 100,
            minHeight: isMobile ? "auto" : 600,
            background: "linear-gradient(0deg, #EDE6FA 0%, #FEE8E5 49%)",
          }}
        >
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={6}
            lg={6}
            alignItems="center"
            style={{ marginTop: 30, padding: 20, zIndex: 3, height: "100%" }}
          >
            <Grid item xs={12}>
              <h1
                style={{
                  fontFamily: "made-tommy-bold",
                  fontSize: 48,
                  color: "#733be0",
                }}
              >
                La plateforme pour
              </h1>
              <h2
                style={{
                  fontFamily: "made-tommy-bold",
                  fontSize: 54,
                  color: "#fa8072",
                  display: "inline",
                }}
              >
                Marques
                <h2
                  style={{
                    fontFamily: "made-tommy-bold",
                    fontSize: 54,
                    marginLeft: 15,
                    marginRight: 15,
                    color: "#733be0",
                    display: "inline",
                  }}
                >
                  &
                </h2>
                Influenceurs.
              </h2>
            </Grid>
            <Grid item container xs={12} justify="flex-start">
              <p
                style={{
                  fontFamily: "nunito-bold",
                  fontSize: 28,
                  color: "rgba(130,93,255,1)",
                  display: "inline",
                }}
              >
                Les opportunités viennent à vous !
              </p>
              {/* <Grid item container className={classes.timerNumberContainer}>
                <CircularProgressbar
                  value={days}
                  text={`${days}`}
                  styles={styles}
                  strokeWidth={3}
                />
                <p className={classes.timerNumber}>Jours</p>
              </Grid>
              <Grid
                item
                container
                className={classes.timerNumberContainer}
                style={{ marginLeft: 30 }}
              >
                <CircularProgressbar
                  value={hours}
                  text={`${hours}`}
                  styles={styles}
                  strokeWidth={3}
                  maxValue={24}
                />
                <p className={classes.timerNumber}>Heures</p>
              </Grid>
              <Grid
                item
                container
                className={classes.timerNumberContainer}
                style={{ marginLeft: 30 }}
              >
                <CircularProgressbar
                  value={minutes}
                  text={`${minutes}`}
                  styles={styles}
                  strokeWidth={3}
                  minValue={0}
                  maxValue={60}
                />
                <p className={classes.timerNumber}>Minutes</p>
              </Grid>
              <Grid
                item
                container
                className={classes.timerNumberContainer}
                style={{ marginLeft: 30 }}
              >
                <CircularProgressbar
                  value={seconds}
                  text={`${seconds}`}
                  styles={styles}
                  strokeWidth={3}
                  maxValue={60}
                />
                <p className={classes.timerNumber}>Secondes</p>
              </Grid> */}
            </Grid>
            <Grid
              item
              xs={5}
              justify="center"
              style={{ textAlign: "center", marginRight: 10 }}
            >
              <p
                style={{
                  fontFamily: "nunito-bold",
                  background: "#FFF",
                  color: "rgba(250, 128, 114,1)",
                  border: "1px solid rgba(250, 128, 114,1)",
                  borderRadius: 18,
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingRight: 50,
                  paddingLeft: 50,
                  cursor: "pointer",
                }}
                onClick={() => {
                  history.push("/select-role/register");
                }}
              >
                Nous rejoindre
              </p>
            </Grid>
            <Grid item xs={5} justify="center" style={{ textAlign: "center" }}>
              <a href="/#contact-us" style={{ textDecoration: "none" }}>
                <p
                  style={{
                    fontFamily: "nunito-bold",
                    background: "rgba(130,93,255,1)",
                    color: "#FFF",
                    borderRadius: 18,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingRight: 50,
                    paddingLeft: 50,
                    cursor: "pointer",
                  }}
                  // onClick={() => {
                  //   // history.push("/select-role/register");
                  // }}
                >
                  Nous contacter
                </p>
              </a>
            </Grid>
            <Grid
              item
              container
              xs={12}
              style={{ marginTop: 80, marginBottom: 50 }}
            >
              <p
                style={{
                  fontFamily: "made-tommy-regular",
                  color: "rgba(0, 0, 0, 0.4)",
                }}
              >
                <a
                  href={"/register/influencer"}
                  style={{
                    color: "rgba(130,93,255,1)",
                    fill: "rgba(130,93,255,1)",
                    fontFamily: "made-tommy-regular",
                    textDecoration: "none",
                  }}
                >
                  Inscrivez-vous maintenant !
                </a>{" "}
                Pour avoir les premières missions.
              </p>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={4}
            lg={4}
            justify="flex-end"
            alignItems="flex-end"
            style={{ zIndex: 3 }}
          >
            <Grid item xs={12}>
              <img
                src={Image1}
                alt="pic1"
                style={{ width: 450, marginTop: 40 }}
                title="Influenceur, sélectionnez la campagne de votre choix"
              />
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <Grid
          item
          container
          alignItems="flex-start"
          justify="space-around"
          style={{ background: "#FFF", height: "100%", padding: 20 }}
        >
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={5}
            lg={5}
            alignItems="center"
            style={{ marginTop: 40, marginBottom: 40 }}
          >
            <Grid item xs={12}>
              <p
                style={{
                  fontFamily: "made-tommy-bold",
                  fontSize: 40,
                  color: "#733be0",
                  paddingBottom: 10,
                }}
              >
                Kesako ?
              </p>
            </Grid>
            <Grid item xs={12}>
              <p style={{ fontFamily: "nunito-semibold", fontSize: 20 }}>
                Pop est l’outil le plus simple permettant de mettre en relation
                les Influenceurs  et les Marques pour effectuer des placements
                de produits sur tous les réseaux sociaux. 
              </p>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={4}
            lg={4}
            justify="flex-end"
            alignItems="flex-end"
          >
            <Grid item xs={12}>
              <img
                src={KesakoImage}
                alt="kesako"
                width={"100%"}
                title="L'outil de création de campagne d'influence intelligent"
              />
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden only={["sm", "xs"]}>
        <Grid
          item
          container
          alignItems="center"
          justify="space-around"
          // className={classes.topContainerHeaderCropped}
          style={{
            background: "#FFF",
            height: "100%",
            padding: 40,
            zIndex: 3,
            paddingTop: 100,
            paddingBottom: 100,
          }}
        >
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={4}
            lg={4}
            justify="flex-end"
            alignItems="flex-end"
            style={{ zIndex: 3 }}
          >
            <Grid item xs={12}>
              <img
                src={KesakoImage}
                alt="kesako"
                width={450}
                title="L'outil de création de campagne d'influence intelligent"
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={5}
            lg={5}
            alignItems="center"
            style={{ zIndex: 3 }}
          >
            <Grid item xs={12}>
              <p
                style={{
                  fontFamily: "made-tommy-bold",
                  fontSize: 40,
                  color: "#733be0",
                  marginBottom: 10,
                }}
              >
                Kesako ?
              </p>
            </Grid>
            <Grid item xs={12}>
              <p style={{ fontFamily: "nunito-semibold", fontSize: 20 }}>
                Pop est l’outil le plus simple permettant de mettre en relation
                les Influenceurs  et les Marques pour effectuer des placements
                de produits sur tous les réseaux sociaux. 
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <Grid
          item
          container
          alignItems="center"
          justify="center"
          // className={classes.withPopBlockContainerCropped}
          style={{
            padding: 20,
            paddingTop: 60,
            paddingBottom: -50,
            background:
              "linear-gradient(0deg, rgba(115,59,224,0.4) 0%, rgba(250,128,114,0.3) 60%)",
          }}
        >
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <p
              style={{
                fontFamily: "made-tommy-bold",
                fontSize: 40,
                color: "#733be0",
              }}
            >
              Avec POP c'est facile
            </p>
          </Grid>
          <Grid
            item
            container
            xs={11}
            justify="space-around"
            style={{ marginTop: 50 }}
          >
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                background: "#FFF",
                padding: 30,
                paddingTop: 80,
                paddingBottom: 80,
                margin: 5,
                borderRadius: 15,
                textAlign: "center",
              }}
              alignItems="center"
              justify="center"
            >
              <Grid item xs={12}>
                <img
                  src={SaveTimeImage}
                  alt="save-time"
                  style={{ width: 150 }}
                  title="La plateforme d'influence qui vous fait gagner du temps"
                />
              </Grid>
              <Grid item xs={12}>
                <p
                  style={{
                    fontFamily: "nunito-bold",
                    fontSize: 22,
                    color: "#fa8072",
                    paddingTop: 20,
                    paddingBottom: 10,
                  }}
                >
                  Gagnez du temps
                </p>
                <p style={{ fontFamily: "nunito-semibold", fontSize: 16 }}>
                  Grâce à POP, que vous soyez une marque ou un influenceur, ne
                  perdez plus de temps à trouver le meilleur partenariat
                </p>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                background: "white",
                padding: 30,
                paddingTop: 80,
                paddingBottom: 80,
                margin: 5,
                borderRadius: 15,
                textAlign: "center",
              }}
              alignItems="center"
              justify="center"
            >
              <Grid item xs={12}>
                <img
                  src={GrowthImage}
                  alt="growth"
                  style={{ width: 150 }}
                  title="Un algorithme qui sélectionne les meilleurs créateurs de contenu"
                />
              </Grid>
              <Grid item xs={12}>
                <p
                  style={{
                    fontFamily: "nunito-bold",
                    fontSize: 22,
                    color: "#fa8072",
                    paddingTop: 20,
                    paddingBottom: 10,
                  }}
                >
                  Ciblez la bonne audience
                </p>
                <p style={{ fontFamily: "nunito-semibold", fontSize: 16 }}>
                  Notre Algorithme permet de vous proposer uniquement la crème
                  de la crème
                </p>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                background: "white",
                padding: 30,
                paddingTop: 80,
                paddingBottom: 80,
                margin: 5,
                borderRadius: 15,
                textAlign: "center",
              }}
              alignItems="center"
              justify="center"
            >
              <Grid item xs={12}>
                <img
                  src={TargetImage}
                  alt="target-pic"
                  style={{ width: 150 }}
                  title="Améliorez la croissance de votre marques sur les réseaux sociaux"
                />
              </Grid>
              <Grid item xs={12}>
                <p
                  style={{
                    fontFamily: "nunito-bold",
                    fontSize: 22,
                    color: "#fa8072",
                    paddingTop: 20,
                    paddingBottom: 10,
                  }}
                >
                  Forte croissance
                </p>
                <p style={{ fontFamily: "nunito-semibold", fontSize: 16 }}>
                  En effectuant le meilleur placement, atteignez la bonne
                  audience et augmentez votre rentabilité
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden only={["sm", "xs"]}>
        <Grid
          item
          container
          alignItems="center"
          justify="center"
          // className={classes.withPopBlockContainerCropped}
          style={{
            padding: 20,
            paddingTop: 60,
            marginBottom: 200,
            height: 500,
            background:
              "linear-gradient(0deg, rgba(115,59,224,0.4) 0%, rgba(250,128,114,0.3) 60%)",
            zIndex: 3,
          }}
        >
          <Grid item xs={12} style={{ textAlign: "center", zIndex: 3 }}>
            <p
              style={{
                fontFamily: "made-tommy-bold",
                fontSize: 40,
                color: "#733be0",
              }}
            >
              Avec POP c'est facile
            </p>
          </Grid>
          <Grid
            item
            container
            xs={11}
            justify="space-around"
            style={{ marginTop: 50, zIndex: 3 }}
          >
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                background: "#FFF",
                padding: 30,
                paddingTop: 80,
                paddingBottom: 80,
                margin: 5,
                borderRadius: 15,
                textAlign: "center",
              }}
              alignItems="center"
              justify="center"
            >
              <Grid item xs={12}>
                <img
                  src={SaveTimeImage}
                  alt="save-time"
                  style={{ width: 150 }}
                  title="La plateforme d'influence qui vous fait gagner du temps"
                />
              </Grid>
              <Grid item xs={12}>
                <p
                  style={{
                    fontFamily: "nunito-bold",
                    fontSize: 22,
                    color: "#fa8072",
                    paddingTop: 20,
                    paddingBottom: 10,
                  }}
                >
                  Gagnez du temps
                </p>
                <p style={{ fontFamily: "nunito-semibold", fontSize: 16 }}>
                  Grâce à POP, que vous soyez une marque ou un influenceur, ne
                  perdez plus de temps à trouver le meilleur partenariat
                </p>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                background: "white",
                padding: 30,
                paddingTop: 80,
                paddingBottom: 80,
                margin: 5,
                borderRadius: 15,
                textAlign: "center",
              }}
              alignItems="center"
              justify="center"
            >
              <Grid item xs={12}>
                <img
                  src={GrowthImage}
                  alt="growth"
                  style={{ width: 150 }}
                  title="Un algorithme qui sélectionne les meilleurs créateurs de contenu"
                />
              </Grid>
              <Grid item xs={12}>
                <p
                  style={{
                    fontFamily: "nunito-bold",
                    fontSize: 22,
                    color: "#fa8072",
                    paddingTop: 20,
                    paddingBottom: 10,
                  }}
                >
                  Ciblez la bonne audience
                </p>
                <p style={{ fontFamily: "nunito-semibold", fontSize: 16 }}>
                  Notre Algorithme permet de vous proposer uniquement la crème
                  de la crème
                </p>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={3}
              lg={3}
              style={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                background: "white",
                padding: 30,
                paddingTop: 80,
                paddingBottom: 80,
                margin: 5,
                borderRadius: 15,
                textAlign: "center",
              }}
              alignItems="center"
              justify="center"
            >
              <Grid item xs={12}>
                <img
                  src={TargetImage}
                  alt="target-pic"
                  style={{ width: 150 }}
                  title="Améliorez la croissance de votre marques sur les réseaux sociaux"
                />
              </Grid>
              <Grid item xs={12}>
                <p
                  style={{
                    fontFamily: "nunito-bold",
                    fontSize: 22,
                    color: "#fa8072",
                    paddingTop: 20,
                    paddingBottom: 10,
                  }}
                >
                  Forte croissance
                </p>
                <p style={{ fontFamily: "nunito-semibold", fontSize: 16 }}>
                  En effectuant le meilleur placement, atteignez la bonne
                  audience et augmentez votre rentabilité
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden only={["sm", "xs"]}>
        <Grid
          item
          container
          alignItems="flex-start"
          justify="space-around"
          style={{ marginTop: 100 }}
        >
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={5}
            lg={5}
            alignItems="center"
            style={{ marginTop: 80, padding: 20 }}
          >
            <Grid item xs={12}>
              <p
                style={{
                  fontFamily: "made-tommy-bold",
                  fontSize: 40,
                  color: "#733be0",
                }}
              >
                Augmentez la visibilité de votre marque et votre chiffre
                d’affaire
              </p>
            </Grid>
            <Grid item xs={12}>
              <p
                style={{
                  fontFamily: "nunito-semibold",
                  fontSize: 22,
                  marginTop: 20,
                }}
              >
                20 fois plus d’efficacité que les pubs classiques
              </p>
            </Grid>
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              style={{ marginTop: 40 }}
            >
              <Grid item container xs={12} alignItems="center">
                <img
                  src={CheckImage}
                  alt="check-pic-1"
                  style={{ width: 12, height: 12 }}
                  title="Campagnes illimitées, nombre d’influenceurs associés illimité."
                />
                <p
                  style={{
                    fontFamily: "nunito-semibold",
                    fontSize: 16,
                    marginLeft: 10,
                    width: "90%",
                  }}
                >
                  Campagnes illimitées, nombre d’influenceurs associés illimité.
                </p>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              style={{ marginTop: 40 }}
            >
              <Grid item container xs={12} alignItems="center">
                <img
                  src={CheckImage}
                  alt="check-pic-2"
                  style={{ width: 12, height: 12 }}
                  title="Déterminez le prix avec l’influenceur en fonction de Vos besoins"
                />
                <p
                  style={{
                    fontFamily: "nunito-semibold",
                    fontSize: 16,
                    marginLeft: 10,
                  }}
                >
                  Déterminez le prix avec l’influenceur en fonction de Vos
                  besoins
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={4}
            lg={4}
            justify="flex-end"
            alignItems="flex-end"
          >
            <Grid
              item
              container
              xs={12}
              alignItems="flex-end"
              style={{
                borderRadius: 10,
              }}
            >
              <Grid item container xs={6}>
                <img
                  src={VisibilityPercent}
                  alt="visibility"
                  style={{
                    width: 450,
                    marginTop: 50,
                    marginLeft: -15,
                    borderRadius: 10,
                  }}
                  title="Augmentez la visibilité de votre marque et votre chiffre d’affaire"
                />
              </Grid>
              {/* <Grid
                item
                xs={4}
                style={{
                  position: "absolute",
                }}
              >
                <img
                  src={VisibilityPic}
                  style={{
                    width: 250,
                    marginLeft: -25,
                    marginBottom: -35,
                    zIndex: 1,
                    borderRadius: 10,
                  }}
                />
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <Grid
          item
          container
          alignItems="center"
          justify="center"
          style={{
            borderRadius: 10,
          }}
        >
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={5}
            lg={5}
            alignItems="center"
            justify="center"
            style={{
              marginTop: 40,
              padding: 20,
              marginBottom: 40,
              borderRadius: 10,
            }}
          >
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <p
                style={{
                  fontFamily: "made-tommy-bold",
                  fontSize: 34,
                  color: "#733be0",
                }}
              >
                Augmentez la visibilité de votre marque et votre chiffre
                d’affaire
              </p>
            </Grid>
            <Grid item xs={12}>
              <p
                style={{
                  fontFamily: "nunito-semibold",
                  fontSize: 22,
                  marginTop: 20,
                  color: "#000",
                }}
              >
                20 fois plus d’efficacité que les pubs classiques
              </p>
            </Grid>
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              justify="space-between"
              style={{ marginTop: 40 }}
            >
              <Grid item container alignItems="flex-start" xs={12}>
                <img
                  src={CheckImage}
                  alt="check-pic-1"
                  style={{ width: 12, height: 12, padding: 5 }}
                  title="Campagnes illimitées, nombre d’influenceurs associés illimité."
                />
                <p
                  style={{
                    fontFamily: "nunito-semibold",
                    fontSize: 16,
                    color: "#000",
                    marginLeft: 10,
                    width: "85%",
                  }}
                >
                  Campagnes illimitées, nombre d’influenceurs associés illimité.
                </p>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              style={{ marginTop: 40 }}
            >
              <Grid item container alignItems="flex-start" xs={12}>
                <img
                  src={CheckImage}
                  alt="check-pic-2"
                  style={{ width: 12, height: 12, padding: 5 }}
                  title="Déterminez le prix avec l’influenceur en fonction de Vos besoins"
                />
                <p
                  style={{
                    fontFamily: "nunito-semibold",
                    fontSize: 16,
                    color: "#000",
                    marginLeft: 10,
                    width: "85%",
                  }}
                >
                  Déterminez le prix avec l’influencer en fonction de Vos
                  besoins
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container justify="center" xs={12}>
            <img
              alt="visibility"
              src={VisibilityPercent}
              style={{
                width: "90%",
                marginTop: 50,
                marginBottom: 50,
              }}
            />
          </Grid>
        </Grid>
      </Hidden>
      <Hidden only={["sm", "xs"]}>
        <Grid
          item
          container
          alignItems="flex-start"
          justify="space-around"
          style={{ marginTop: 100, padding: 20 }}
        >
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={5}
            lg={5}
            justify="flex-end"
            alignItems="flex-end"
          >
            <Grid
              item
              container
              xs={12}
              alignItems="flex-start"
              justify="center"
              style={{}}
            >
              <Grid item container xs={12} sm={12} md={6} lg={6}>
                <img
                  src={InfluencerPic}
                  alt="influencer-pic"
                  style={{
                    height: 380,
                    marginTop: 50,
                  }}
                  title="Influenceur, sélectionnez la campagne de votre choix"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={5}
            lg={5}
            alignItems="center"
            style={{ marginTop: 80 }}
          >
            <Grid item xs={12}>
              <p
                style={{
                  fontFamily: "made-tommy-bold",
                  fontSize: 40,
                  color: "#733be0",
                }}
              >
                Influenceur, sélectionnez la campagne de votre choix
              </p>
            </Grid>
            <Grid item xs={12}>
              <p
                style={{
                  fontFamily: "nunito-semibold",
                  fontSize: 22,
                  marginTop: 20,
                }}
              >
                Nous ne faisons pas perdre de temps mais seulement gagner de
                l’argent. Rien à perdre, quelque soit votre nombre d’abonnés
                vous trouverez des missions sur toutes les plateformes
              </p>
            </Grid>
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              style={{ marginTop: 40 }}
            >
              <Grid item container alignItems="center" xs={12}>
                <img
                  src={CheckImage}
                  alt="check-pic-4"
                  style={{ width: 12, height: 12, padding: 5 }}
                  title="Pop est ouvert à tous, pas de limite minimum d'abonnés à
                  l'inscription pour laisser des opportunités à tout le monde."
                />
                <p
                  style={{
                    fontFamily: "nunito-semibold",
                    fontSize: 16,
                    marginLeft: 10,
                    width: "90%",
                  }}
                >
                  Pop est ouvert à tous, pas de limite minimum d'abonnés à
                  l'inscription pour laisser des opportunités à tout le monde.
                </p>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              style={{ marginTop: 40 }}
            >
              <Grid item container alignItems="center" xs={12}>
                <img
                  src={CheckImage}
                  alt="check-pic-5"
                  style={{ width: 12, height: 12, padding: 5 }}
                  title="Rémunération immédiate, fini les frais excessifs, soyez
                  rémunéré à la hauteur de votre travail. Même les mineurs !"
                />
                <p
                  style={{
                    fontFamily: "nunito-semibold",
                    fontSize: 16,
                    marginLeft: 10,
                    width: "90%",
                  }}
                >
                  Rémunération immédiate, fini les frais excessifs, soyez
                  rémunéré à la hauteur de votre travail. Même les mineurs !
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden only={["md", "lg", "xl"]}>
        <Grid
          item
          container
          alignItems="flex-start"
          justify="space-around"
          style={{
            padding: 20,
          }}
        >
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={5}
            lg={5}
            alignItems="center"
            style={{ marginTop: 40 }}
          >
            <Grid item xs={12} c style={{ textAlign: "center" }}>
              <p
                style={{
                  fontFamily: "made-tommy-bold",
                  fontSize: 34,
                  color: "#733be0",
                }}
              >
                Influenceur, sélectionnez la campagne de votre choix
              </p>
            </Grid>
            <Grid item xs={12}>
              <p
                style={{
                  fontFamily: "nunito-semibold",
                  fontSize: 22,
                  marginTop: 20,
                  color: "#000",
                }}
              >
                Nous ne faisons pas perdre de temps mais seulement gagner de
                l’argent. Rien à perdre, quelque soit votre nombre d’abonnés
                vous trouverez des missions sur toutes les plateformes
              </p>
            </Grid>
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              style={{ marginTop: 40 }}
            >
              <Grid item container xs={12} alignItems="flex-start">
                <img
                  src={CheckImage}
                  alt="check-pic-7"
                  style={{ width: 12, height: 12, padding: 5 }}
                  title="Pop est ouvert à tous, pas de limite minimum d'abonnés à
                  l'inscription pour laisser des opportunités à tout le monde."
                />
                <p
                  style={{
                    fontFamily: "nunito-semibold",
                    fontSize: 16,
                    color: "#000",
                    marginLeft: 10,
                    width: "85%",
                  }}
                >
                  Pop est ouvert à tous, pas de limite minimum d'abonnés à
                  l'inscription pour laisser des opportunités à tout le monde.
                </p>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              style={{ marginTop: 40 }}
            >
              <Grid item container xs={12} alignItems="flex-start">
                <img
                  alt="check-pic-8"
                  src={CheckImage}
                  style={{ width: 12, height: 12, padding: 5 }}
                  title="Rémunération immédiate, fini les frais excessifs, soyez
                  rémunéré à la hauteur de votre travail. Même les mineurs !"
                />
                <p
                  style={{
                    fontFamily: "nunito-semibold",
                    fontSize: 16,
                    color: "#000",
                    marginLeft: 10,
                    width: "85%",
                  }}
                >
                  Rémunération immédiate, fini les frais excessifs, soyez
                  rémunéré à la hauteur de votre travail. Même les mineurs !
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container justify="center" xs={12}>
            <img
              alt="inf-pic"
              src={InfluencerPic}
              style={{
                height: 280,
                marginTop: 50,
              }}
              title="Influenceur, sélectionnez la campagne de votre choix"
            />
          </Grid>
        </Grid>
      </Hidden>
      {/* // FAQ */}
      <Grid
        item
        container
        xs={10}
        style={{
          margin: "auto",
          marginTop: 60,
        }}
        id="faq"
        justify="center"
      >
        <Grid item xs={12} style={{ marginBottom: 40 }}>
          <p
            style={{
              textAlign: "center",
              fontFamily: "made-tommy-bold",
              fontSize: 36,
              color: "#733be0",
            }}
          >
            Foires aux questions
          </p>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={selectedFaq}
            onChange={(e, value) => {
              setSelectedFaq(value);
            }}
            aria-label="simple tabs example"
          >
            <Tab
              style={{
                fontSize: 20,
                fontFamily: "nunito-semibold",
                textTransform: "none",
              }}
              label="Influenceur"
              value={"influencer"}
            />
            <Tab
              style={{
                fontSize: 20,
                fontFamily: "nunito-semibold",
                textTransform: "none",
              }}
              label="Marque"
              value={"brand"}
            />
          </Tabs>
        </Grid>
        {faqConfig[selectedFaq].map((faq) => {
          return (
            <Grid item xs={12} style={{ marginBottom: 30 }}>
              <Accordion
                style={{
                  boxShadow: "0 0px 20px rgba(0, 0, 0, 0.2)",
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingLeft: isMobile ? 0 : 20,
                  paddingRight: isMobile ? 0 : 20,
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  style={{ paddingLeft: 30, paddingRight: 30 }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    style={{
                      fontSize: isMobile ? 18 : 24,
                      fontColor: "#000000",
                      fontFamily: "nunito-semibold",
                      fontWeight: 800,
                    }}
                  >
                    {faq.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ paddingLeft: 30, paddingRight: 30 }}>
                  <Typography>{faq.content}</Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
        })}
      </Grid>
      {/* </Hidden> */}
      {/* // CONCTACT US  */}
      <ContactUs isMobile={isMobile} />
      {/* <Hidden only={["sm", "xs"]}> */}
      {/* <Grid
        item
        container
        xs={10}
        sm={10}
        md={8}
        lg={8}
        style={{
          margin: "auto",
          boxShadow: "0 0px 50px rgba(0, 0, 0, 0.2)",
          padding: isMobile ? 20 : 40,
          marginTop: 60,
          borderRadius: 10,
        }}
        justify="center"
      >
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <p
            style={{
              textAlign: "center",
              fontFamily: "made-tommy-bold",
              fontSize: 36,
              color: "#733be0",
            }}
          >
            Contactez-nous
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10} style={{ marginBottom: 20 }}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person />
                </InputAdornment>
              ),
            }}
            inputProps={{ style: { padding: "8.5px 10px", padding: 14 } }}
            fullWidth
            id="brandName"
            name="brandName"
            variant="outlined"
            placeholder="Name"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10} style={{ marginBottom: 20 }}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneInTalk />
                </InputAdornment>
              ),
            }}
            inputProps={{ style: { padding: "8.5px 10px", padding: 14 } }}
            fullWidth
            id="brandName"
            name="brandName"
            variant="outlined"
            placeholder="Phone"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10} style={{ marginBottom: 20 }}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
            inputProps={{ style: { padding: "8.5px 10px", padding: 14 } }}
            fullWidth
            id="brandName"
            name="brandName"
            variant="outlined"
            placeholder="Email"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10} style={{ marginBottom: 20 }}>
          <TextField
            inputProps={{ style: { padding: "8.5px 10px" } }}
            fullWidth
            rows={5}
            multiline
            id="brandName"
            name="brandName"
            variant="outlined"
            placeholder="Message"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10} style={{ marginBottom: 20 }}>
          <Button
            style={{
              fontFamily: "nunito-bold",
              background: "rgba(130,93,255,1)",
              color: "#FFF",
              fontWeight: 800,
              borderRadius: 4,
              paddingTop: 10,
              paddingBottom: 10,
              paddingRight: 50,
              paddingLeft: 50,
              width: "100%",
              fontSize: 20,
              textTransform: "none",
            }}
          >
            Envoyer
          </Button>
        </Grid>
      </Grid> */}
      {/* </Hidden> */}
      {/* <Grid
        item
        container
        alignItems="flex-start"
        justify="space-around"
        style={{
          marginTop: 100,
          background: "rgba(250, 128, 114,0.7)",
          borderRadius: 10,
          padding: 30,
        }}
      >
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={5}
          lg={5}
          alignItems="center"
          style={{ marginTop: 50 }}
        >
          <Grid item xs={12}>
            <p
              style={{
                fontFamily: "nunito-bold",
                fontSize: 40,
                color: "#FFF",
              }}
            >
              Nos clients
            </p>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 50 }}>
            <p
              style={{
                fontFamily: "nunito-bold",
                fontSize: 42,
                color: "#FFF",
              }}
            >
              Pourquoi ils utilisent POP
            </p>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={4}
          lg={4}
          justify="flex-end"
          alignItems="flex-end"
        >
          <Grid item xs={12}>
            <img
              src={OurClients}
              style={{
                width: "100%",
                margin: isMobile ? 0 : 30,
                borderRadius: 10,
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }}
            />
          </Grid>
        </Grid>
      </Grid> */}
    </>
  );
};

export default Showcase;

import { Avatar, Grid, Hidden } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import MissionDashboardMobile from "./MissionDashboardMobile";

import missionStyle from "./styles/missionStyle";

const useStyles = makeStyles(missionStyle);

const MissionsContent = ({ missions, type }) => {
  const classes = useStyles();

  return (
    <Grid
      item
      container
      style={{ minHeight: 500, marginTop: 50 }}
      justify="flex-start"
      alignItems="flex-start"
      direction="row"
    >
      {type === "CURRENT" ? (
        <Hidden only={["sm", "xs"]}>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className={classes.missionBlock}
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <p className={classes.blockTitle}>{`Proposées (${
                missions.filter(
                  (mission) => mission.influencerStatus === "PROPOSED"
                ).length
              })`}</p>
            </Grid>
            {missions
              .filter((mission) => mission.influencerStatus === "PROPOSED")
              .map((mission) => (
                <Grid item container className={classes.missionItems}>
                  <NavLink
                    className={classes.navLink}
                    exact={true}
                    to={`/influencer/missions/${mission.missionId._id}`}
                  >
                    <Grid item container>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Avatar
                          className={classes.brandAvatar}
                          style={{ margin: "auto" }}
                          src={
                            mission?.missionId?.thumbnail ??
                            mission?.brandId?.avatar
                          }
                        ></Avatar>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        sm={12}
                        md={7}
                        lg={7}
                        className={classes.missionContent}
                      >
                        <Grid item xs={12}>
                          <p className={classes.brandTitle}>
                            {mission.missionId.title}
                          </p>
                        </Grid>
                        <Grid item xs={12}>
                          <p className={classes.missionDescription}>
                            {mission.missionId.description}
                          </p>
                        </Grid>
                      </Grid>
                      {/* <Grid
                    item
                    container
                    xs={2}
                    className={classes.missionContent}
                  >
                    <Grid item>
                      <p>550€</p>
                    </Grid>
                  </Grid> */}
                    </Grid>
                  </NavLink>
                </Grid>
              ))}
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className={classes.missionBlock}
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <p className={classes.blockTitle}>{`Acceptées (${
                missions.filter(
                  (mission) => mission.influencerStatus === "ACCEPTED"
                ).length
              })`}</p>
            </Grid>
            {missions
              .filter((mission) => mission.influencerStatus === "ACCEPTED")
              .map((mission) => (
                <Grid item container className={classes.missionItems}>
                  <NavLink
                    className={classes.navLink}
                    exact={true}
                    to={`/influencer/missions/${mission.missionId._id}`}
                  >
                    <Grid item container>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Avatar
                          className={classes.brandAvatar}
                          style={{ margin: "auto" }}
                          src={
                            mission?.missionId?.thumbnail ??
                            mission?.brandId?.avatar
                          }
                        ></Avatar>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        sm={12}
                        md={7}
                        lg={7}
                        className={classes.missionContent}
                      >
                        <Grid item xs={12}>
                          <p className={classes.brandTitle}>
                            {mission.missionId.title}
                          </p>
                        </Grid>
                        <Grid item xs={12}>
                          <p className={classes.missionDescription}>
                            {mission.missionId.description}
                          </p>
                        </Grid>
                      </Grid>
                      {/* <Grid
                    item
                    container
                    xs={2}
                    className={classes.missionContent}
                  >
                    <Grid item>
                      <p>550€</p>
                    </Grid>
                  </Grid> */}
                    </Grid>
                  </NavLink>
                </Grid>
              ))}
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={4}
            lg={4}
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            className={classes.missionBlock}
            style={{ borderRight: "none" }}
          >
            <Grid item xs={12}>
              <p className={classes.blockTitle}>{`En cours (${
                missions.filter(
                  (mission) => mission.influencerStatus === "DOING"
                ).length
              })`}</p>
            </Grid>
            {missions
              .filter((mission) => mission.influencerStatus === "DOING")
              .map((mission) => (
                <Grid item container className={classes.missionItems}>
                  <NavLink
                    className={classes.navLink}
                    exact={true}
                    to={`/influencer/missions/${mission.missionId._id}`}
                  >
                    <Grid item container>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Avatar
                          className={classes.brandAvatar}
                          style={{ margin: "auto" }}
                          src={
                            mission?.missionId?.thumbnail ??
                            mission?.brandId?.avatar
                          }
                        ></Avatar>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        sm={12}
                        md={7}
                        lg={7}
                        className={classes.missionContent}
                      >
                        <Grid item xs={12}>
                          <p className={classes.brandTitle}>
                            {mission.missionId.title}
                          </p>
                        </Grid>
                        <Grid item xs={12}>
                          <p className={classes.missionDescription}>
                            {mission.missionId.description}
                          </p>
                        </Grid>
                      </Grid>
                      {/* <Grid
                    item
                    container
                    xs={2}
                    className={classes.missionContent}
                  >
                    <Grid item>
                      <p>550€</p>
                    </Grid>
                  </Grid> */}
                    </Grid>
                  </NavLink>
                </Grid>
              ))}
          </Grid>
        </Hidden>
      ) : (
        <Hidden only={["sm", "xs"]}>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className={classes.missionBlock}
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <p className={classes.blockTitle}>{`Terminées (${
                missions.filter(
                  (mission) => mission.influencerStatus === "DONE"
                ).length
              })`}</p>
            </Grid>
            {missions
              .filter((mission) => mission.influencerStatus === "DONE")
              .map((mission) => (
                <Grid item container className={classes.missionItems}>
                  <NavLink
                    className={classes.navLink}
                    exact={true}
                    to={`/influencer/missions/${mission.missionId._id}`}
                  >
                    <Grid item container>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Avatar
                          className={classes.brandAvatar}
                          style={{ margin: "auto" }}
                          src={
                            mission?.missionId?.thumbnail ??
                            mission?.brandId?.avatar
                          }
                        ></Avatar>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        sm={12}
                        md={7}
                        lg={7}
                        className={classes.missionContent}
                      >
                        <Grid item xs={12}>
                          <p className={classes.brandTitle}>
                            {mission.missionId.title}
                          </p>
                        </Grid>
                        <Grid item xs={12}>
                          <p className={classes.missionDescription}>
                            {mission.missionId.description}
                          </p>
                        </Grid>
                      </Grid>
                      {/* <Grid
                    item
                    container
                    xs={2}
                    className={classes.missionContent}
                  >
                    <Grid item>
                      <p>550€</p>
                    </Grid>
                  </Grid> */}
                    </Grid>
                  </NavLink>
                </Grid>
              ))}
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className={classes.missionBlock}
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <p className={classes.blockTitle}>{`Mission refusées (${
                missions.filter(
                  (mission) => mission.influencerStatus === "DECLINED"
                ).length
              })`}</p>
            </Grid>
            {missions
              .filter((mission) => mission.influencerStatus === "DECLINED")
              .map((mission) => (
                <Grid item container className={classes.missionItems}>
                  <NavLink
                    className={classes.navLink}
                    exact={true}
                    to={`/influencer/missions/${mission.missionId._id}`}
                  >
                    <Grid item container>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Avatar
                          className={classes.brandAvatar}
                          style={{ margin: "auto" }}
                          src={
                            mission?.missionId?.thumbnail ??
                            mission?.brandId?.avatar
                          }
                        ></Avatar>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        sm={12}
                        md={7}
                        lg={7}
                        className={classes.missionContent}
                      >
                        <Grid item xs={12}>
                          <p className={classes.brandTitle}>
                            {mission.missionId.title}
                          </p>
                        </Grid>
                        <Grid item xs={12}>
                          <p className={classes.missionDescription}>
                            {mission.missionId.description}
                          </p>
                        </Grid>
                      </Grid>
                      {/* <Grid
                    item
                    container
                    xs={2}
                    className={classes.missionContent}
                  >
                    <Grid item>
                      <p>550€</p>
                    </Grid>
                  </Grid> */}
                    </Grid>
                  </NavLink>
                </Grid>
              ))}
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={4}
            lg={4}
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            className={classes.missionBlock}
            style={{ borderRight: "none" }}
          >
            <Grid item xs={12}>
              <p className={classes.blockTitle}>{`Candidatures déclinées (${
                missions.filter(
                  (mission) => mission.influencerStatus === "REFUSED_BY_BRAND"
                ).length
              })`}</p>
            </Grid>
            {missions
              .filter(
                (mission) => mission.influencerStatus === "REFUSED_BY_BRAND"
              )
              .map((mission) => (
                <Grid item container className={classes.missionItems}>
                  <NavLink
                    className={classes.navLink}
                    exact={true}
                    to={`/influencer/missions/${mission.missionId._id}`}
                  >
                    <Grid item container>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Avatar
                          className={classes.brandAvatar}
                          style={{ margin: "auto" }}
                          src={
                            mission?.missionId?.thumbnail ??
                            mission?.brandId?.avatar
                          }
                        ></Avatar>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        sm={12}
                        md={7}
                        lg={7}
                        className={classes.missionContent}
                      >
                        <Grid item xs={12}>
                          <p className={classes.brandTitle}>
                            {mission.missionId.title}
                          </p>
                        </Grid>
                        <Grid item xs={12}>
                          <p className={classes.missionDescription}>
                            {mission.missionId.description}
                          </p>
                        </Grid>
                      </Grid>
                      {/* <Grid
                    item
                    container
                    xs={2}
                    className={classes.missionContent}
                  >
                    <Grid item>
                      <p>550€</p>
                    </Grid>
                  </Grid> */}
                    </Grid>
                  </NavLink>
                </Grid>
              ))}
          </Grid>
        </Hidden>
      )}
      <Hidden only={["md", "lg", "xl"]}>
        <MissionDashboardMobile missions={missions} type={type} />
      </Hidden>
    </Grid>
  );
};

MissionsContent.propTypes = {};

export default MissionsContent;

import Profile from "./Profile";
import BankInformation from "./BankInformation";
import MyCompanyInformation from "./MyCompanyInformation";
import WithdrawMoney from "./WithdrawMoney";
import BrandCompanyInformation from "./BrandCompanyInformation";

export const settingsConfigBrand = [
  {
    title: "Mon profil",
    description: "Modifier mon profil",
    component: Profile,
    to: "/profile",
    path: "/brand/settings/profile",
    color: "#825DFF",
  },
  // {
  //   title: "Mes informations bancaires",
  //   description: "Modifier mes informations bancaires",
  //   component: BankInformation,
  //   to: "/bank-information",
  //   path: "/influencer/settings/bank-information",
  //   color: "#FED37B",
  // },
  // {
  //   title: "Ma société",
  //   description: "Reseigner mes informations de société",
  //   component: BrandCompanyInformation,
  //   to: "/company-information",
  //   path: "/brand/settings/company-information",
  //   color: "#FD83C7",
  // },
];

export const settingsConfigInfluencer = [
  {
    title: "Mon profil",
    description: "Modifier mon profil",
    component: Profile,
    to: "/profile",
    path: "/influencer/settings/profile",
    color: "#825DFF",
  },
  {
    title: "Ma cagnote",
    description: "Voir ma cagnote et récupérer mon argent",
    component: WithdrawMoney,
    to: "/withdraw",
    path: "/influencer/settings/withdraw",
    color: "#FED37B",
  },
  {
    title: "Ma société",
    description: "Renseigner mes informations de société",
    component: MyCompanyInformation,
    to: "/company-information",
    path: "/influencer/settings/company-information",
    color: "#FD83C7",
  },
  // {
  //   title: "Ma société",
  //   description: "Reseigner mes informations de société",
  //   component: () => MyCompanyInformation,
  //   color: "#FD83C7",
  // },
];

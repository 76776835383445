import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";

import { Grid, Chip, Button, useTheme, useMediaQuery } from "@material-ui/core";

import Select from "../../../Common/Select/Select";

import profilePageStyle from "../styles/profilePage";
import { getFormattedActivities } from "../PersonalInformation/utils";
import {
  categoriesOptions,
  colourStyles,
} from "../../../Brand/NewMission/utils";

const useStyles = makeStyles(profilePageStyle);

const Activity = ({
  userActivities,
  isEditable = true,
  handleUpdateInfluencerProfile,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const [newActivities, setNewActivities] = useState([]);
  const [isEditedCategories, setIsEditedCategories] = useState(false);

  useEffect(() => {
    setNewActivities(getFormattedActivities(userActivities));
  }, [userActivities]);

  return (
    <Grid
      item
      container
      className={classes.widgetContainer}
      id="influencer-activity"
    >
      <Grid item xs={12}>
        <p className={classes.widgetTitle}>Mon activité</p>
      </Grid>
      {isEditedCategories || userActivities?.length === 0 ? (
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Select
              styles={{
                ...colourStyles(true),
                width: "100%",
                placeholder: (styles, { data }) => {
                  return {
                    ...styles,
                    fontSize: 14,
                  };
                },
                container: (styles, { data }) => {
                  return {
                    ...styles,
                    width: "100%",
                  };
                },
              }}
              isMulti
              value={newActivities}
              name="categories"
              options={categoriesOptions}
              placeholder="Quelles sont vos catégories ?"
              onChange={(selectedValue) => setNewActivities([...selectedValue])}
              isSearchable={!matches}
              isDisabled={!isEditable}
            />
          </Grid>
          <Grid item style={{ marginTop: 20 }}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              style={{
                fontFamily: "nunito-bold",
                fontSize: 13,
                color: "#FFF",
                height: 40,
                paddingLeft: 20,
                paddingRight: 20,
                borderRadius: 18,
                textTransform: "none",
                backgroundColor: "#825DFF",
              }}
              onClick={() => {
                handleUpdateInfluencerProfile({
                  categories: newActivities.map((activity) => activity.value),
                });
                setIsEditedCategories(false);
              }}
            >
              Enregistrer
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid
          item
          container
          xs={12}
          style={{ cursor: isEditable ? "pointer" : "default" }}
          onClick={() => isEditable && setIsEditedCategories(true)}
        >
          {getFormattedActivities(userActivities).map((activity) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={4}
              style={{ padding: 10 }}
              key={activity.value}
            >
              <Chip
                style={{
                  width: "90%",
                  backgroundColor: "rgba(130,93,255,0.6)",
                  color: "#FFF",
                  cursor: "pointer",
                }}
                label={activity.label}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

Activity.propTypes = {};

export default Activity;

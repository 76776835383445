import React, { useState, useEffect } from "react";
import { useParams, withRouter } from "react-router";
import { Grid, Button, makeStyles, TextField } from "@material-ui/core";
import axios from "axios";
import { API_URL } from "../../../common/constants";
import PopMiniLogo from "../../../assets/images/pop-mini-logo.png";

import authStyles from "../Login/styles/login";

const useStyles = makeStyles(authStyles);

const colorBrand = "rgba(250, 128, 114,1)";
const colorInfluencer = "#825DFF";

const ConfirmAccount = withRouter(({ history }) => {
  const classes = useStyles();
  const { confirmationCode, type } = useParams();
  const [message, setMessage] = useState("");
  const [isInfluencer, setIsInfluencer] = useState(false);

  useEffect(() => {
    setIsInfluencer(type === "influencer");
  }, [type]);

  function handleConfirmAccount() {
    axios
      .post(
        `${API_URL}/${
          type === "influencer" ? "influencer" : "brand"
        }/auth/confirm-account`,
        {
          confirmationCode,
        }
      )
      .then((res) => {
        setMessage("Votre compte a bien été activé.");
      })
      .catch((error) => {
        setMessage("Votre compte ne peut être activé pour le moment.");
      });
  }

  useEffect(() => {
    handleConfirmAccount();
  }, [confirmationCode]);

  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={5}
      lg={5}
      style={{
        marginTop: 150,
        boxShadow: "0 0px 50px rgba(0, 0, 0, 0.2)",
        borderRadius: 5,
        marginLeft: 10,
        marginRight: 10,
      }}
      justify="center"
    >
      <div
        className={
          isInfluencer ? classes.coloredHeader : classes.coloredHeaderBrand
        }
      ></div>
      <Grid
        container
        justify="space-around"
        alignItems="center"
        style={{ textAlign: "center" }}
      >
        <div className={classes.popLogo}>
          <a href="/">
            <img alt="logo" src={PopMiniLogo} style={{ height: 100 }} />
          </a>
        </div>
        <Grid item xs={12} style={{ marginBottom: 40, textAlign: "center" }}>
          <p style={{ fontFamily: "nunito-bold", fontSize: 20 }}>{message}</p>
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        justify="center"
        style={{ textAlign: "center", marginTop: 30, paddingBottom: 50 }}
      >
        <p
          style={{
            fontFamily: "nunito-bold",
            background: isInfluencer ? colorInfluencer : colorBrand,
            cursor: "pointer",
            color: "#FFF",
            borderRadius: 18,
            paddingTop: 10,
            paddingBottom: 10,
            paddingRight: 50,
            paddingLeft: 50,
          }}
          onClick={() => {
            history.push(`/login/${isInfluencer ? "influencer" : "brand"}`);
          }}
        >
          Me connecter
        </p>
      </Grid>
    </Grid>
  );
});

export default ConfirmAccount;

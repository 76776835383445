import PropTypes from "prop-types";
import { withRouter } from "react-router";

import {
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles, styled } from "@material-ui/styles";

import settingsStyle from "./styles/settingsStyle";
import { CloudUpload } from "@material-ui/icons";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { API_URL } from "../../../common/constants";
import { useEffect } from "react";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles(settingsStyle);

const Input = styled("input")({
  display: "none",
});

const MyCompanyInformation = withRouter(
  ({ title, profile, handleUpdateMyProfile }) => {
    const classes = useStyles();
    const [file, setFile] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [isMissingFile, setIsMissingFile] = useState(false);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

    const validationSchema = yup.object({
      companyName: yup
        .string("Nom de la société")
        .required("Le nom de la société est obligatoire"),
      SIRET: yup.string("SIRET").required("Le numéro de siret est obligatoire"),
    });

    const formik = useFormik({
      initialValues: {
        companyName: "",
        SIRET: "",
      },
      validationSchema: validationSchema,
      validateOnBlur: false,
      validateOnChange: false,
      onSubmit: (values) => {
        if (!file) {
          setIsMissingFile(true);
        } else {
          handleUploadImage(values);
        }
      },
    });

    useEffect(() => {
      formik.setValues({ ...profile?.companyInformation });
      if (profile?.paymentMeans && !file) {
        setFile({
          name: `RIB-${profile?.fullname.replace(/\s/g, "-")}`,
        });
      }
    }, [profile]);

    const handleUploadImage = (values) => {
      let formData = new FormData();

      formData.append("file", file);
      axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
        "token"
      )}`;
      setIsLoading(true);
      axios
        .post(`${API_URL}/influencer/profile/payment-means`, formData)
        .then((res) => {
          setIsLoading(false);
          handleUpdateMyProfile({
            ...profile,
            companyInformation: values,
            paymentMeans: res.data.filename,
          });

          setIsSnackbarOpen(true);
        })
        .catch((error) => console.error(error));
    };

    return (
      <>
        <Grid
          item
          container
          justify="space-between"
          style={{
            borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
            marginBottom: 30,
            paddingBottom: 10,
            height: 50,
          }}
        >
          <Grid item>
            <p
              style={{
                fontFamily: "nunito-bold",
                fontSize: 24,
                marginBottom: 20,
                display: "inline",
              }}
            >
              {title}
            </p>
          </Grid>
        </Grid>
        <Grid item container>
          <form style={{ width: "100%" }}>
            <Grid
              item
              container
              xs={12}
              style={{ marginTop: 30 }}
              justify="space-between"
            >
              <Grid
                item
                container
                direction="column"
                xs={12}
                sm={12}
                md={5}
                lg={5}
              >
                <label className={classes.fieldLabel}>Nom de la société</label>
                <TextField
                  fullWidth
                  inputProps={{ style: { padding: "8.5px 10px" } }}
                  id="companyName"
                  name="companyName"
                  variant="outlined"
                  value={formik?.values.companyName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.companyName &&
                    Boolean(formik.errors.companyName)
                  }
                  helperText={
                    formik.touched.companyName && formik.errors.companyName
                  }
                />
              </Grid>
              <Grid
                item
                container
                direction="column"
                xs={12}
                sm={12}
                md={6}
                lg={6}
              >
                <label className={classes.fieldLabel}>SIRET</label>
                <TextField
                  fullWidth
                  inputProps={{ style: { padding: "8.5px 10px" } }}
                  id="SIRET"
                  name="SIRET"
                  variant="outlined"
                  value={formik?.values.SIRET}
                  onChange={formik.handleChange}
                  error={formik.touched.SIRET && Boolean(formik.errors.SIRET)}
                  helperText={formik.touched.SIRET && formik.errors.SIRET}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <label className={classes.socialNetworkLabel}>RIB</label>
                <div className={classes.socialNetworkBorder}></div>
                <p
                  style={{
                    fontSize: 12,
                    color: "rgb(158, 158, 158)",
                    marginBottom: 20,
                  }}
                >
                  Un RIB à jour est nécessaire pour recevoir votre paiement
                  chaque mois. Si vous changez de banque pendant une mission,
                  pensez à bien mettre à jour votre RIB sur cette page.
                </p>
              </Grid>
              <Grid
                item
                container
                direction="column"
                xs={12}
                sm={12}
                md={5}
                lg={5}
              >
                {/* <input
                          type="file"
                          onChange={(e) => {
                            let newFile = e.target.files[0];

                            setFile(newFile);
                            setIsMissingFile(false);
                            // handleUploadImage(file);
                          }}
                        /> */}
                <label htmlFor="contained-button-file">
                  <Input
                    id="contained-button-file"
                    type="file"
                    onChange={(e) => {
                      let newFile = e.target.files[0];

                      setFile(newFile);
                      setIsMissingFile(false);
                    }}
                  />
                  <Button
                    variant="contained"
                    component="span"
                    style={{ textTransform: "none" }}
                    endIcon={<CloudUpload />}
                  >
                    Sélectionner mon RIB
                  </Button>
                </label>
                {file?.name && <p style={{ marginTop: 10 }}>{file?.name}</p>}
              </Grid>
            </Grid>
            {isMissingFile && (
              <Grid item xs={12}>
                <p
                  style={{
                    color: "#f44336",
                    marginTop: 10,
                    fontSize: "0.75rem",
                  }}
                >
                  Le RIB est obligatoire
                </p>
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={5} lg={5} style={{ marginTop: 20 }}>
              {/* <Button
                style={{
                  fontFamily: "nunito-bold",
                  fontSize: 13,
                  color: "#FFF",
                  width: "100%",
                  height: 40,
                  paddingLeft: 20,
                  paddingRight: 20,
                  borderRadius: 4,
                  backgroundColor: "#825DFF",
                  marginTop: 20,
                  textTransform: "none",
                }}
                onClick={formik.handleSubmit}
              >
                Enregistrer
              </Button> */}
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  style={{
                    fontFamily: "nunito-bold",
                    fontSize: 13,
                    color: "#FFF",
                    width: "100%",
                    height: 40,
                    paddingLeft: 20,
                    paddingRight: 20,
                    borderRadius: 4,
                    backgroundColor: isLoading
                      ? "rgba(0, 0, 0, 0.2)"
                      : "#825DFF",
                    textTransform: "none",
                  }}
                  onClick={formik.handleSubmit}
                >
                  Enregistrer
                </Button>
                {isLoading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Grid>
          </form>
        </Grid>
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={6000}
          style={{ zIndex: 1400, paddingTop: 60 }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={() => setIsSnackbarOpen(false)}
        >
          <Alert
            onClose={() => setIsSnackbarOpen(false)}
            severity="success"
            variant="filled"
          >
            Vos informations ont bien été modifiés !
          </Alert>
        </Snackbar>
      </>
    );
  }
);

MyCompanyInformation.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default MyCompanyInformation;

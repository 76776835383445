import { Grid } from "@material-ui/core";
import axios from "axios";
import { Helmet } from "react-helmet";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../../common/constants";
import SettingsWrapper from "../../../Components/Common/SettingsPage/SettingsWrapper";

const SettingsPage = () => {
  const [profile, setProfile] = useState({});

  const getProfile = () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .get(`${API_URL}/brand/profile`)
      .then((res) => setProfile(res.data))
      .catch((error) => console.error(error));
  };

  const handleUpdateMyProfile = (body) => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .put(`${API_URL}/brand/profile`, {
        ...body,
      })
      .then((res) => getProfile())
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <Grid container xs={12} sm={12} md={9} lg={9} style={{ margin: "auto" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`POP App - Réglages - ${profile.brandName}`}</title>
        <link rel="canonical" href="https://pop-app.fr" />
      </Helmet>
      <SettingsWrapper
        profile={profile}
        isInfluencer={false}
        handleUpdateMyProfile={handleUpdateMyProfile}
      />
    </Grid>
  );
};

export default SettingsPage;

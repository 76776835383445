import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import * as yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { Camera, Edit2 } from "react-feather";

import {
  Grid,
  Avatar,
  TextField,
  Button,
  Chip,
  InputAdornment,
} from "@material-ui/core";

import { EditOutlined } from "@material-ui/icons";

import personalInformationStyle from "./styles/personalInformation";
import axios from "axios";
import { API_URL } from "../../../../common/constants";
import { colourStyles, socialNetworkOptions } from "../../NewMission/utils";
import { getFormattedSocialNetworks } from "../../../Influencer/InfluencerProfilePage/PersonalInformation/utils";

const useStyles = makeStyles(personalInformationStyle);

const PersonalInformation = ({ profile, handleUpdateBrandProfile }) => {
  const classes = useStyles();
  const [isDisplayingFormPersonalInfo, setIsDisplayingFormPersonalInfo] =
    useState(!profile.description);

  const validationSchema = yup.object({
    brandName: yup
      .string("Votre nom complet")
      .required("Le nom est obligatoire"),
    // description: yup
    //   .string("Décrivez votre campagne")
    //   .required("La description est obligatoire"),
  });

  const formik = useFormik({
    initialValues: {
      ...profile,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleUpdateBrandProfile(values);
    },
  });

  const handleUploadImage = (file) => {
    let formData = new FormData();

    formData.append("file", file);
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .post(`${API_URL}/images`, formData)
      .then((res) => {
        handleUpdateBrandProfile({
          avatar: `https://pop-influence.s3.us-east-2.amazonaws.com/${res.data.filename}`,
        });
      })
      .catch((error) => console.error(error));
  };

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile = width <= 768;

  return (
    <div
      className={classes.personalInformationContainer}
      // style={{
      //   marginBottom: 50,
      //   minHeight: 300,
      //   backgroundColor: "#FFF",
      //   padding: 40,
      //   borderRadius: 6,
      //   position: "relative",
      // }}
    >
      {isDisplayingFormPersonalInfo && (
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            className={classes.pageLayout}
            justify="center"
            alignItems="flex-start"
          >
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <label className={classes.avatarContainer}>
                <Avatar className={classes.brandAvatar} src={profile.avatar}>
                  <Edit2 style={{ width: 50, height: 50 }} />
                </Avatar>
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    let file = e.target.files[0];
                    handleUploadImage(file);
                  }}
                />
              </label>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={8}
              lg={8}
              justify="center"
              spacing={2}
            >
              <Grid item container xs={10} sm={10} md={8} lg={8}>
                <label className={classes.fieldLabel}>Nom de la marque</label>
                <TextField
                  inputProps={{ style: { padding: "8.5px 10px" } }}
                  fullWidth
                  id="brandName"
                  name="brandName"
                  variant="outlined"
                  value={formik.values.brandName}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item container xs={10} sm={10} md={8} lg={8}>
                <label className={classes.fieldLabel}>Description</label>
                <TextField
                  fullWidth
                  rows={5}
                  multiline
                  id="description"
                  name="description"
                  variant="outlined"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item container xs={10} sm={10} md={8} lg={8}>
                <label className={classes.fieldLabel}>Site internet</label>
                <TextField
                  inputProps={{ style: { padding: "8.5px 10px" } }}
                  fullWidth
                  id="website"
                  name="website"
                  variant="outlined"
                  value={formik.values.website}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={8}
                lg={8}
                justify="space-between"
                style={{ marginTop: 20 }}
              >
                <Grid item container xs={12} direction="column">
                  <label className={classes.fieldLabel}>
                    Quels sont vos réseaux sociaux ?
                  </label>
                  <Select
                    styles={{ ...colourStyles(false) }}
                    isMulti
                    name="socialNetworks"
                    id="socialNetworks"
                    value={getFormattedSocialNetworks(
                      formik.values.socialNetworks
                    )}
                    onChange={(selectedValue) =>
                      formik.setFieldValue(
                        "socialNetworks",
                        selectedValue.map((value) => ({
                          socialNetwork: value.value,
                          username: value.username || "",
                        }))
                      )
                    }
                    options={socialNetworkOptions}
                    placeholder="Réseaux sociaux"
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  justify="space-between"
                  style={{ marginTop: 20 }}
                >
                  {formik.values.socialNetworks.map((socialNetwork, key) => {
                    return (
                      <Grid
                        item
                        container
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        direction="row"
                        justify="flex-start"
                        style={{ paddingTop: 10 }}
                        key={socialNetwork.socialNetwork}
                        spacing={2}
                        className={classes.socialNetworkContainer}
                      >
                        <Grid
                          item
                          container
                          xs={12}
                          direction="column"
                          justify="space-between"
                        >
                          <label className={classes.fieldLabel}>
                            {`Compte ${
                              socialNetworkOptions.find(
                                (network) =>
                                  network.value === socialNetwork.socialNetwork
                              ).label
                            }`}
                          </label>
                          <TextField
                            inputProps={{ style: { padding: "8.5px 0px" } }}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  @
                                </InputAdornment>
                              ),
                            }}
                            id={`socialNetworks[${key}].username`}
                            name={`socialNetworks[${key}].username`}
                            variant="outlined"
                            value={socialNetwork.username}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={10}
                sm={10}
                md={8}
                lg={8}
                justify="space-between"
                style={{ marginTop: 20, marginBottom: 20 }}
                spacing={2}
              >
                <Grid item xs>
                  <Button
                    color="primary"
                    variant="contained"
                    type=""
                    style={{
                      fontFamily: "nunito-bold",
                      fontSize: 13,
                      color: "#FFF",
                      width: "100%",
                      height: 40,
                      paddingLeft: 20,
                      paddingRight: 20,
                      borderRadius: 18,
                      textTransform: "none",
                      backgroundColor: "#825DFF",
                    }}
                    onClick={() => {
                      formik.handleSubmit();
                      setIsDisplayingFormPersonalInfo(false);
                    }}
                  >
                    Enregistrer
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
      {!isDisplayingFormPersonalInfo && (
        <Grid
          container
          className={classes.pageLayout}
          justify="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <label className={classes.avatarContainer}>
              <Avatar
                className={classes.brandAvatar}
                style={{ marginTop: isMobile ? 40 : 0 }}
                src={profile.avatar}
              >
                <Edit2 style={{ width: 50, height: 50 }} />
              </Avatar>
              <input
                type="file"
                style={{ display: "none" }}
                onChange={(e) => {
                  let file = e.target.files[0];
                  handleUploadImage(file);
                }}
              />
            </label>
          </Grid>
          <Grid
            item
            container
            xs={10}
            sm={10}
            md={8}
            lg={8}
            justify="center"
            className={classes.editableContainer}
          >
            <Grid
              item
              container
              alignItems="center"
              xs={10}
              sm={10}
              md={8}
              lg={8}
              className={classes.brandTitle}
              onClick={() => setIsDisplayingFormPersonalInfo(true)}
            >
              <p className={classes.userName}>{profile.brandName}</p>
              <EditOutlined
                className="editable"
                style={{ marginLeft: 15, marginBottom: 10 }}
              />
            </Grid>
            <Grid
              item
              xs={10}
              sm={10}
              md={8}
              lg={8}
              style={{ cursor: "pointer", marginBottom: 20 }}
            >
              <a
                href={profile.website}
                rel="noreferrer"
                target="_blank"
                className={classes.widgetTextContent}
              >
                {profile.website}
              </a>
            </Grid>
            <Grid
              item
              xs={10}
              sm={10}
              md={8}
              lg={8}
              style={{ cursor: "pointer", marginBottom: 20 }}
              onClick={() => setIsDisplayingFormPersonalInfo(true)}
            >
              <p className={classes.widgetTextContent}>{profile.description}</p>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={8}
              lg={8}
              justify="flex-start"
              style={{ marginBottom: 10 }}
            >
              {getFormattedSocialNetworks(profile?.socialNetworks).map(
                (socialNetwork) => {
                  return (
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={4}
                      lg={4}
                      style={{ marginBottom: 5, marginLeft: 5 }}
                      key={socialNetwork.value}
                    >
                      <a
                        href={socialNetwork.link}
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none", cursor: "pointer" }}
                      >
                        <Chip
                          style={{
                            background: socialNetwork.background,
                            color: socialNetwork.color,
                            cursor: "pointer",
                          }}
                          icon={socialNetwork.icon}
                          label={socialNetwork.label}
                        />
                      </a>
                    </Grid>
                  );
                }
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

PersonalInformation.propTypes = {};

export default PersonalInformation;

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/styles";

import missionPageStyle from "../styles/missionPageStyles";
import { ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles(missionPageStyle);
const MissionBriefs = ({ briefs = [] }) => {
  const classes = useStyles();
  return (
    <>
      <Grid
        item
        container
        className={classes.widgetContainerBrief}
        style={{ minHeight: 0 }}
        alignItems="flex-start"
      >
        <Grid item container xs={12} direction="row">
          <p className={classes.widgetTitleBrief}>Les briefs</p>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <p className={classes.widgetTextContentBrief}>
            Retrouvez ici les briefs que la marque vous enverra pour mener à
            bien cette campagne.
          </p>
        </Grid>
        {briefs?.map((brief) => {
          return (
            // <Grid item xs={12} container style={{ padding: 10 }}>
            //   <Grid
            //     item
            //     container
            //     style={{
            //       marginBottom: 30,
            //       paddingBottom: 30,
            //       padding: 15,
            //       boxShadow:
            //         "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
            //       borderRadius: 4,
            //     }}
            //   >
            //     <Grid item xs={12} style={{ marginBottom: 15, padding: 10 }}>
            //       <p className={classes.widgetTitle}>{brief.title}</p>
            //     </Grid>
            //     <Grid item xs={12} style={{ paddingLeft: 20 }}>
            //       <div
            //         className={classes.widgetTextContent}
            //         style={{ color: "rgba(0, 0, 0, 0.54)" }}
            //         dangerouslySetInnerHTML={{ __html: brief.content }}
            //       />
            //     </Grid>
            //   </Grid>
            // </Grid>
            <Accordion
              // style={{ width: "100%", borderRadius: 4, marginTop: 20,  }}
              style={{
                paddingTop: 5,
                paddingBottom: 5,
                width: "100%",
                marginTop: 20,
                borderRadius: 10,
              }}
              className={classes.accordion}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography style={{ fontFamily: "nunito-bold" }}>
                  {`${brief.title}`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container xs={12}>
                  {/* <Grid item container xs={12} style={{ marginBottom: 30 }}>
              <label className={classes.fieldLabel}>Titre du brief</label>
              <TextField
                fullWidth
                inputProps={{ style: { padding: "8.5px 10px" } }}
                id="title"
                name="title"
                variant="outlined"
                value={brief.title}
                onChange={(e) =>
                  formik.setFieldValue(
                    `briefs[${key}].title`,
                    e.target.value
                  )
                }
              />
            </Grid> */}
                  <Grid item xs={12}>
                    <div
                      className={classes.widgetTextContent}
                      style={{ color: "rgba(0, 0, 0, 0.54)" }}
                      dangerouslySetInnerHTML={{ __html: brief.content }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 20 }}>
                    <Grid item container xs={12} style={{ marginTop: 20 }}>
                      {Array.from(brief?.files ?? []).map((data, index) => {
                        return (
                          <Grid item key={index}>
                            <Chip
                              label={
                                typeof data === "string"
                                  ? data.replace(
                                      "https://pop-influence.s3.us-east-2.amazonaws.com/briefs/",
                                      ""
                                    )
                                  : data.name
                              }
                              style={{ margin: 5 }}
                              onClick={() => {
                                typeof data === "string" && window.open(data);
                              }}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                    {/* <label className={classes.avatarContainer}>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      let file = e.target.files[0];
                      setFile(file);
                    }}
                  />
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    startIcon={<CloudUpload />}
                  >
                    Ajouter un fichier
                  </Button>
                </label> */}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Grid>
    </>
  );
};

export default MissionBriefs;

import { Avatar, Grid, Hidden } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Info } from "react-feather";

import missionStyle from "./styles/missionStyle";
import MissionDashboardMobile from "./MissionDashboardMobile";
import { LightTooltip } from "../../../Common/Messages/ContactHeader/ContactHeader";

const useStyles = makeStyles(missionStyle);

const MissionsDashboard = ({ missions, type }) => {
  const classes = useStyles();

  const [isInactiveOpen, setIsInactiveOpen] = useState(false);
  const [isProposedOpen, setIsProposedOpen] = useState(false);
  const [isOnGoingOpen, setIsOnGoingOpen] = useState(false);
  return (
    <Grid
      item
      container
      style={{ minHeight: 500, marginTop: 50 }}
      justify="flex-start"
      alignItems="flex-start"
      direction="row"
    >
      {type === "CURRENT" ? (
        // CURRENT MISSIONS
        <Hidden only={["sm", "xs"]}>
          <Grid
            item
            container
            xs={4}
            className={classes.missionBlock}
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
          >
            <LightTooltip
              PopperProps={{
                disablePortal: true,
              }}
              title="Vos campagnes ne sont pas encore actives, vous pouvez encore les
              modifier avant de les rendre visibles aux Influenceurs
              correspondant à vos critères."
            >
              <Grid item alignItems="center" container xs={12}>
                <p className={classes.blockTitle}>Inactives</p>
                <Info
                  style={{
                    width: 18,
                    height: 18,
                    color: "#000",
                    marginLeft: 10,
                  }}
                />
              </Grid>
            </LightTooltip>
            {missions.missions
              .filter((opt) => opt.status === "INACTIVE")
              .map((mission) => (
                <Grid
                  item
                  container
                  className={classes.missionItems}
                  key={mission._id}
                >
                  <NavLink
                    className={classes.navLink}
                    exact={true}
                    to={`/brand/missions/${mission._id}/details`}
                  >
                    <Grid item container>
                      <Grid item xs={3}>
                        <Avatar
                          className={classes.brandAvatar}
                          src={mission?.thumbnail ?? missions.brand.avatar}
                        ></Avatar>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={7}
                        className={classes.missionContent}
                      >
                        <Grid item xs={12}>
                          <p className={classes.brandTitle}>{mission.title}</p>
                        </Grid>
                        <Grid item xs={12}>
                          <p className={classes.missionDescription}>
                            {mission.description}
                          </p>
                        </Grid>
                      </Grid>
                      {/* <Grid
                    item
                    container
                    xs={2}
                    className={classes.missionContent}
                  >
                    <Grid item>
                      <p>{mission.}</p>
                    </Grid>
                  </Grid> */}
                    </Grid>
                  </NavLink>
                </Grid>
              ))}
          </Grid>
          <Grid
            item
            container
            xs={4}
            className={classes.missionBlock}
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
          >
            <LightTooltip
              PopperProps={{
                disablePortal: true,
              }}
              title="Vos campagnes sont en attente d‘Influenceur vous correspondant et
            acceptant de collaborer avec vous."
            >
              <Grid item container alignItems="center" xs={12}>
                <p className={classes.blockTitle}>En attente</p>
                <Info
                  style={{
                    width: 18,
                    height: 18,
                    color: "#000",
                    marginLeft: 10,
                  }}
                />
              </Grid>
            </LightTooltip>
            {missions.missions
              .filter((opt) => opt.status === "PROPOSED")
              .map((mission) => (
                <Grid
                  item
                  container
                  className={classes.missionItems}
                  key={mission._id}
                >
                  <NavLink
                    className={classes.navLink}
                    exact={true}
                    to={`/brand/missions/${mission._id}/details`}
                  >
                    <Grid item container>
                      <Grid item xs={3}>
                        <Avatar
                          className={classes.brandAvatar}
                          src={mission?.thumbnail ?? missions.brand.avatar}
                        ></Avatar>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={7}
                        className={classes.missionContent}
                      >
                        <Grid item xs={12}>
                          <p className={classes.brandTitle}>{mission.title}</p>
                        </Grid>
                        <Grid item xs={12}>
                          <p className={classes.missionDescription}>
                            {mission.description}
                          </p>
                        </Grid>
                      </Grid>
                      {/* <Grid
                    item
                    container
                    xs={2}
                    className={classes.missionContent}
                  >
                    <Grid item>
                      <p>{mission.}</p>
                    </Grid>
                  </Grid> */}
                    </Grid>
                  </NavLink>
                </Grid>
              ))}
          </Grid>
          <Grid
            item
            container
            xs={4}
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            className={classes.missionBlock}
          >
            <LightTooltip
              PopperProps={{
                disablePortal: true,
              }}
              title="Des Influenceurs souhaitent travailler avec vous sur ce projet
            ! Sélectionnez-les et communiquez avec eux pour effectuer le
            placement de produit comme vous le souhaitez."
            >
              <Grid item container alignItems="center" xs={12}>
                <p className={classes.blockTitle}>En cours</p>
                <Info
                  style={{
                    width: 18,
                    height: 18,
                    color: "#000",
                    marginLeft: 10,
                  }}
                />
              </Grid>
            </LightTooltip>
            {missions.missions
              .filter((opt) => opt.status === "ON_GOING")
              .map((mission) => (
                <Grid
                  item
                  container
                  className={classes.missionItems}
                  key={mission._id}
                >
                  <NavLink
                    className={classes.navLink}
                    exact={true}
                    to={`/brand/missions/${mission._id}/details`}
                  >
                    <Grid item container>
                      <Grid item xs={3}>
                        <Avatar
                          className={classes.brandAvatar}
                          src={mission?.thumbnail ?? missions.brand.avatar}
                        ></Avatar>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={7}
                        className={classes.missionContent}
                      >
                        <Grid item xs={12}>
                          <p className={classes.brandTitle}>{mission.title}</p>
                        </Grid>
                        <Grid item xs={12}>
                          <p className={classes.missionDescription}>
                            {mission.description}
                          </p>
                        </Grid>
                      </Grid>
                      {/* <Grid
                    item
                    container
                    xs={2}
                    className={classes.missionContent}
                  >
                    <Grid item>
                      <p>{mission.}</p>
                    </Grid>
                  </Grid> */}
                    </Grid>
                  </NavLink>
                </Grid>
              ))}
          </Grid>
        </Hidden>
      ) : (
        <Hidden only={["sm", "xs"]}>
          <Grid
            item
            container
            xs={4}
            className={classes.missionBlock}
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
          >
            <LightTooltip
              PopperProps={{
                disablePortal: true,
              }}
              title="Toutes les campagnes terminées."
            >
              <Grid item alignItems="center" container xs={12}>
                <p className={classes.blockTitle}>Terminées</p>
                <Info
                  style={{
                    width: 18,
                    height: 18,
                    color: "#000",
                    marginLeft: 10,
                  }}
                />
              </Grid>
            </LightTooltip>
            {missions.missions
              .filter((opt) => opt.status === "DONE")
              .map((mission) => (
                <Grid
                  item
                  container
                  className={classes.missionItems}
                  key={mission._id}
                >
                  <NavLink
                    className={classes.navLink}
                    exact={true}
                    to={`/brand/missions/${mission._id}/details`}
                  >
                    <Grid item container>
                      <Grid item xs={3}>
                        <Avatar
                          className={classes.brandAvatar}
                          src={mission?.thumbnail ?? missions.brand.avatar}
                        ></Avatar>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={7}
                        className={classes.missionContent}
                      >
                        <Grid item xs={12}>
                          <p className={classes.brandTitle}>{mission.title}</p>
                        </Grid>
                        <Grid item xs={12}>
                          <p className={classes.missionDescription}>
                            {mission.description}
                          </p>
                        </Grid>
                      </Grid>
                      {/* <Grid
                  item
                  container
                  xs={2}
                  className={classes.missionContent}
                >
                  <Grid item>
                    <p>{mission.}</p>
                  </Grid>
                </Grid> */}
                    </Grid>
                  </NavLink>
                </Grid>
              ))}
          </Grid>
          <Grid
            item
            container
            xs={4}
            className={classes.missionBlock}
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
          >
            <LightTooltip
              PopperProps={{
                disablePortal: true,
              }}
              title="Vos campagnes annulées."
            >
              <Grid item container alignItems="center" xs={12}>
                <p className={classes.blockTitle}>Annulées</p>
                <Info
                  style={{
                    width: 18,
                    height: 18,
                    color: "#000",
                    marginLeft: 10,
                  }}
                />
              </Grid>
            </LightTooltip>
            {missions.missions
              .filter((opt) => opt.isArchived)
              .map((mission) => (
                <Grid
                  item
                  container
                  className={classes.missionItems}
                  key={mission._id}
                >
                  <NavLink
                    className={classes.navLink}
                    exact={true}
                    to={`/brand/missions/${mission._id}/details`}
                  >
                    <Grid item container>
                      <Grid item xs={3}>
                        <Avatar
                          className={classes.brandAvatar}
                          src={mission?.thumbnail ?? missions.brand.avatar}
                        ></Avatar>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={7}
                        className={classes.missionContent}
                      >
                        <Grid item xs={12}>
                          <p className={classes.brandTitle}>{mission.title}</p>
                        </Grid>
                        <Grid item xs={12}>
                          <p className={classes.missionDescription}>
                            {mission.description}
                          </p>
                        </Grid>
                      </Grid>
                      {/* <Grid
                  item
                  container
                  xs={2}
                  className={classes.missionContent}
                >
                  <Grid item>
                    <p>{mission.}</p>
                  </Grid>
                </Grid> */}
                    </Grid>
                  </NavLink>
                </Grid>
              ))}
          </Grid>
        </Hidden>
      )}
      <Hidden only={["md", "lg", "xl"]}>
        <MissionDashboardMobile missions={missions} type={type} />
      </Hidden>
    </Grid>
  );
};

MissionsDashboard.propTypes = {};

export default MissionsDashboard;

import { CircularProgress, Grid, Tab, Tabs } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { API_URL } from "../../../common/constants";
import BrandMissionsDashboard from "../../../Components/Brand/Missions/MissionsDashboard/MissionsDashboard";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  missionDashboardContainer: {
    [theme.breakpoints.up("md")]: {
      width: "90%",
      padding: "30px 40px",
    },
    margin: "auto",
    boxhadow: "0 0 8px 0 rgb(0 0 0 / 5%)",
    borderRadius: 8,
    backgroundColor: "#fff",
  },
}));

const BrandMissionsDashboardWrapper = () => {
  const classes = useStyles();
  const [selectedFilter, setSelectedFilter] = useState("CURRENT");
  const [missions, setMissions] = useState({ missions: [], brand: {} });
  const [isLoading, setIsLoading] = useState(false);
  const getMissions = () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .get(`${API_URL}/brand/missions`)
      .then((res) => {
        setMissions(res.data);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    setIsLoading(true);
    getMissions();
  }, []);
  console.log("missions", missions);

  if (isLoading) return <CircularProgress />;
  return (
    <Grid
      container
      className={classes.missionDashboardContainer}
      justify="center"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`POP App - Dashboard ${missions.brand.brandName}`}</title>
        <link rel="canonical" href="https://pop-app.fr" />
      </Helmet>
      <Grid item xs={12}>
        <Tabs
          value={selectedFilter}
          onChange={(e, value) => {
            setSelectedFilter(value);
          }}
          aria-label="simple tabs example"
        >
          <Tab
            style={{
              fontSize: 14,
              fontFamily: "nunito-semibold",
              textTransform: "none",
            }}
            label="Actuelles"
            value={"CURRENT"}
          />
          <Tab
            style={{
              fontSize: 14,
              fontFamily: "nunito-semibold",
              textTransform: "none",
            }}
            label="Passées"
            value={"PASSED"}
          />
        </Tabs>
      </Grid>
      <BrandMissionsDashboard missions={missions} type={selectedFilter} />
    </Grid>
  );
};

export default BrandMissionsDashboardWrapper;

import React from "react";
import { makeStyles } from "@material-ui/styles";
import PersonalInformationWidget from "../../Common/InfluencerProfile/PersonalInformationWidget";
import UserEvolution from "../../Influencer/InfluencerProfilePage/Widgets/UserEvolution";
import Activity from "../../Influencer/InfluencerProfilePage/Widgets/Activity";
import UserDescription from "../../Influencer/InfluencerProfilePage/Widgets/UserDescription";
import Experiences from "../../Influencer/InfluencerProfilePage/Widgets/Experiences";

import { Grid } from "@material-ui/core";
// import PersonalInformation from "./PersonalInformation/PersonalInformation";

// import Activity from "./Widgets/Activity";
// import UserDescription from "./Widgets/UserDescription";
// import Experiences from "./Widgets/Experiences";
// import UserEvolution from "./Widgets/UserEvolution";
// import UserPictures from "./Widgets/UserPictures";

import profilePageStyle from "../../Influencer/InfluencerProfilePage/styles/profilePage";
import Notices from "../../Influencer/InfluencerProfilePage/Widgets/Notices";
const useStyles = makeStyles(profilePageStyle);

const InfluencerProfilePage = ({ profile }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.personalInformationContainer}>
        <PersonalInformationWidget profile={profile} isEditable={false} />
      </div>
      <Grid
        container
        xs={12}
        sm={12}
        md={10}
        lg={10}
        className={classes.pageLayout}
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid item container xs={12}>
          <UserEvolution isInfluencer influencerId={profile._id} />
        </Grid>
        <Grid
          item
          containerxs={12}
          sm={12}
          md={4}
          lg={4}
          alignItems="flex-start"
          justify="center"
          style={{ marginTop: 20 }}
        >
          <Activity userActivities={profile.categories} isEditable={false} />
          <Notices isInfluencer influencerId={profile._id} />
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={8}
          lg={8}
          alignItems="flex-start"
          justify="center"
          style={{ padding: 20 }}
        >
          <UserDescription
            profileDescription={profile.description}
            isEditable={false}
          />
          <Experiences experiences={profile.experiences} isEditable={false} />
        </Grid>
      </Grid>
    </div>
  );
};

InfluencerProfilePage.propTypes = {};

export default InfluencerProfilePage;

import React, { useEffect, useState } from "react";
import BrandMissionPage from "../../../Components/Brand/Missions/BrandMissionPage/BrandMissionPage";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import { API_URL } from "../../../common/constants";

import {
  DescriptionOutlined,
  MessageOutlined,
  AssignmentOutlined,
  PeopleAltOutlined,
  MailOutline,
  PostAdd,
} from "@material-ui/icons";
import MissionDetails from "../../../Components/Brand/Missions/BrandMissionPage/MissionDetails/MissionDetails";
import InfluencerList from "../../../Components/Brand/Missions/BrandMissionPage/InfluencerList/InfluencerList";
import MessagesContainer from "../../../Components/Common/Messages/MessagesContainer";
import MissionBriefs from "../../../Components/Brand/Missions/BrandMissionPage/MissionBriefs/MissionBriefs";

const inactiveMissionRoutes = [
  {
    icon: <DescriptionOutlined />,
    label: "Détails",
    to: "/details",
    path: "/brand/missions/:id/details",
    component: MissionDetails,
    exact: true,
  },
  {
    icon: <PostAdd />,
    label: "Briefs",
    to: "/briefs",
    path: "/brand/missions/:id/briefs",
    component: MissionBriefs,
    exact: true,
  },
];

const noInfluencersRoutes = [
  {
    icon: <DescriptionOutlined />,
    label: "Détails",
    to: "/details",
    path: "/brand/missions/:id/details",
    component: MissionDetails,
    exact: true,
  },
  {
    icon: <PostAdd />,
    label: "Briefs",
    to: "/briefs",
    path: "/brand/missions/:id/briefs",
    component: MissionBriefs,
    exact: true,
  },
  {
    icon: <PeopleAltOutlined />,
    label: "Les influenceurs",
    to: "/influencers",
    path: "/brand/missions/:id/influencers",
    component: InfluencerList,
    exact: true,
  },
];

const routes = (status) =>
  status === "DONE"
    ? [
        {
          icon: <DescriptionOutlined />,
          label: "Détails",
          to: "/details",
          path: "/brand/missions/:id/details",
          component: MissionDetails,
          exact: true,
        },
        {
          icon: <PostAdd />,
          label: "Briefs",
          to: "/briefs",
          path: "/brand/missions/:id/briefs",
          component: MissionBriefs,
          exact: true,
        },
        {
          icon: <PeopleAltOutlined />,
          label: "Les influenceurs",
          to: "/influencers",
          path: "/brand/missions/:id/influencers",
          component: InfluencerList,
          exact: true,
        },
        {
          icon: <MailOutline />,
          label: "Messages",
          to: "/messages",
          path: "/brand/missions/:id/messages",
          component: MessagesContainer,
          exact: true,
          disabled: true,
        },
      ]
    : [
        {
          icon: <DescriptionOutlined />,
          label: "Détails",
          to: "/details",
          path: "/brand/missions/:id/details",
          component: MissionDetails,
          exact: true,
        },
        {
          icon: <PostAdd />,
          label: "Briefs",
          to: "/briefs",
          path: "/brand/missions/:id/briefs",
          component: MissionBriefs,
          exact: true,
        },
        {
          icon: <PeopleAltOutlined />,
          label: "Les influenceurs",
          to: "/influencers",
          path: "/brand/missions/:id/influencers",
          component: InfluencerList,
          exact: true,
        },
        {
          icon: <MailOutline />,
          label: "Messages",
          to: "/messages",
          path: "/brand/missions/:id/messages",
          component: MessagesContainer,
          exact: true,
        },
      ];

const MissionPageWrapper = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [mission, setMission] = useState({
    brand: { socialNetworks: [] },
    mission: {},
  });

  const getMission = () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .get(`${API_URL}/brand/missions/${id}`)
      .then((res) => {
        setIsLoading(false);
        setMission(res.data);
      })
      .catch((error) => console.error(error));
  };

  const handleUpdateMissionBriefs = async (briefs) => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;

    const finalBriefs =
      (await Promise.all(
        briefs.map(async (brief) => {
          return {
            ...brief,
            files: await Promise.all(
              brief?.files.map(async (file) => {
                if (typeof file === "string") return file;
                let formData = new FormData();

                formData.append("file", file);

                const res = await axios.post(
                  `${API_URL}/images/brief`,
                  formData
                );
                const newFile = `https://pop-influence.s3.us-east-2.amazonaws.com/${res.data.filename}`;
                return newFile;
              })
            ),
          };
        })
      )) ?? [];

    axios
      .patch(`${API_URL}/brand/missions/${id}/briefs`, {
        briefs: finalBriefs,
      })
      .then((res) => getMission())
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    setIsLoading(true);
    getMission();
  }, [id]);

  if (!mission.mission) return <div>Cette mission n'existe pas</div>;

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`POP App - Campagne ${mission.mission.title}`}</title>
        <link rel="canonical" href="https://pop-app.fr" />
      </Helmet>
      <BrandMissionPage
        mission={mission}
        isLoading={isLoading}
        handleRefreshMission={getMission}
        handleUpdateMissionBriefs={handleUpdateMissionBriefs}
        routes={
          mission?.mission.status === "INACTIVE"
            ? inactiveMissionRoutes
            : (mission.influencers && mission.influencers.length === 0) ||
              !mission?.influencers?.some(
                (influencer) => influencer.startDiscussion
              ) ||
              mission?.influencers?.every(
                (influencer) =>
                  influencer?.influencerStatus === "DONE" ||
                  influencer?.followProcess?.influencerEvaluation
              )
            ? noInfluencersRoutes
            : routes(mission.mission.status)
        }
      />
    </div>
  );
};

MissionPageWrapper.propTypes = {};

export default MissionPageWrapper;

import { Avatar, Grid, InputBase } from "@material-ui/core";
import React from "react";
import { SearchOutlined } from "@material-ui/icons";

import { makeStyles } from "@material-ui/styles";

import messagesStyles from "../styles/messagesStyle";
import { useState } from "react";

const useStyles = makeStyles(messagesStyles);

const ContactListing = ({
  missionInfluencers,
  handleSelectInfluencer,
  selectedInfluencer,
}) => {
  const classes = useStyles();

  const [searchValue, setSearchValue] = useState("");
  return (
    <Grid item container justify="center" alignItems="flex-start">
      <Grid item xs={8} className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchOutlined />
        </div>
        <InputBase
          placeholder="Search…"
          style={{ width: "100%" }}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ "aria-label": "search" }}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
        />
      </Grid>
      {searchValue.length > 0 && (
        <Grid
          item
          container
          className={classes.listing}
          alignItems="flex-start"
        >
          {missionInfluencers
            .filter(
              (value) =>
                value.influencerId.fullname
                  .toLowerCase()
                  .includes(searchValue.toLowerCase()) &&
                value.startDiscussion &&
                value?.influencerStatus !== "DONE" &&
                !value?.followProcess?.influencerEvaluation?.rating
            )
            .map((influencer, key) => {
              return (
                <Grid
                  key={key}
                  item
                  container
                  xs={12}
                  className={
                    selectedInfluencer._id === influencer.influencerId._id
                      ? classes.contactItemSelected
                      : classes.contactItem
                  }
                  alignItems="center"
                  onClick={() =>
                    handleSelectInfluencer(influencer.influencerId)
                  }
                >
                  <Grid item xs={3}>
                    <Avatar
                      className={classes.userAvatar}
                      src={influencer.influencerId.avatar}
                    ></Avatar>
                  </Grid>
                  <Grid item container xs={9} alignItems="center">
                    <Grid item xs={6}>
                      <p className={classes.userName}>
                        {influencer.influencerId.fullname}
                      </p>
                    </Grid>
                    {influencer.influencerStatus === "DOING" && (
                      <Grid item xs={4}>
                        <p className={classes.customChip}>En cours</p>
                      </Grid>
                    )}
                    {/* <Grid item xs={12}>
                    <p className={classes.messageContent}>{influe<n}</p>
                  </Grid> */}
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      )}
      {searchValue.length === 0 &&
        missionInfluencers.filter(
          (inf) =>
            inf.hasMessages &&
            inf?.influencerStatus !== "DONE" &&
            !inf?.followProcess?.influencerEvaluation?.rating
        ).length > 0 && (
          <Grid
            item
            container
            className={classes.listing}
            alignItems="flex-start"
          >
            <p className={classes.listTitle}>
              <span>Discussion démarrée</span>
            </p>
            {missionInfluencers
              .filter(
                (inf) =>
                  inf.hasMessages &&
                  inf?.influencerStatus !== "DONE" &&
                  !inf?.followProcess?.influencerEvaluation?.rating
              )
              .map((influencer, key) => {
                return (
                  <Grid
                    key={key}
                    item
                    container
                    xs={12}
                    className={
                      selectedInfluencer._id === influencer.influencerId._id
                        ? classes.contactItemSelected
                        : classes.contactItem
                    }
                    alignItems="center"
                    onClick={() =>
                      handleSelectInfluencer(influencer.influencerId)
                    }
                  >
                    <Grid item xs={3}>
                      <Avatar
                        className={classes.userAvatar}
                        src={influencer.influencerId.avatar}
                      ></Avatar>
                    </Grid>
                    <Grid item container xs={9} alignItems="center">
                      <Grid item xs={6}>
                        <p className={classes.userName}>
                          {influencer.influencerId.fullname}
                        </p>
                      </Grid>
                      {influencer.influencerStatus === "DOING" && (
                        <Grid item xs={4}>
                          <p className={classes.customChip}>En cours</p>
                        </Grid>
                      )}
                      {/* <Grid item xs={12}>
                  <p className={classes.messageContent}>{influe<n}</p>
                </Grid> */}
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        )}

      {searchValue.length === 0 &&
        missionInfluencers.filter((inf) => {
          return (
            !inf.hasMessages &&
            inf.startDiscussion &&
            inf?.influencerStatus !== "DONE" &&
            !inf?.followProcess?.influencerEvaluation?.rating
          );
        }).length > 0 && (
          <Grid
            item
            container
            alignItems="flex-start"
            style={{ marginTop: 20 }}
          >
            <p className={classes.listTitle}>
              <span>En attente</span>
            </p>
            {missionInfluencers
              .filter(
                (inf) =>
                  !inf.hasMessages &&
                  inf.startDiscussion &&
                  inf?.influencerStatus !== "DONE" &&
                  !inf?.followProcess?.influencerEvaluation?.rating
              )
              .map((influencer, key) => {
                return (
                  <Grid
                    key={key}
                    item
                    container
                    xs={12}
                    className={
                      selectedInfluencer._id === influencer.influencerId._id
                        ? classes.contactItemSelected
                        : classes.contactItem
                    }
                    alignItems="center"
                    onClick={() =>
                      handleSelectInfluencer(influencer.influencerId)
                    }
                  >
                    <Grid item xs={3}>
                      <Avatar
                        className={classes.userAvatar}
                        src={influencer.influencerId.avatar}
                      ></Avatar>
                    </Grid>
                    <Grid item container xs={9}>
                      <Grid item xs={12}>
                        <p className={classes.userName}>
                          {influencer.influencerId.fullname}
                        </p>
                      </Grid>
                      {/* <Grid item xs={12}>
                  <p className={classes.messageContent}>{influe<n}</p>
                </Grid> */}
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        )}
    </Grid>
  );
};

ContactListing.propTypes = {};

export default ContactListing;

import {
  Button,
  Dialog,
  Grid,
  InputAdornment,
  Switch,
  TextField,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import Select from "react-select";

import modalsStyle from "./styles/modalsStyle";
import { DialogActions, DialogTitle, DialogContent } from "../../Dialog/Dialog";
import axios from "axios";
import { API_URL } from "../../../../common/constants";
import { useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";
import {
  getFormattedSocialNetworks,
  getFormattedSocialNetworksSimple,
} from "../../../Influencer/InfluencerProfilePage/PersonalInformation/utils";

const useStyles = makeStyles(modalsStyle);

const options = [
  {
    value: "instagram",
    label: "Instagram",
  },
  {
    value: "tiktok",
    label: "TikTok",
  },
  {
    value: "youtube",
    label: "Youtube",
  },
  {
    value: "twitch",
    label: "Twitch",
  },
  {
    value: "snapchat",
    label: "Snapchat",
  },
];

const NewOfferModal = ({
  isModalOpen,
  handleCloseModal,
  missionId,
  influencerId,
  handleRefreshDiscussion,
  isEditing,
  oldTitle = "",
  oldPrice = 0,
  oldOfferDescription = "",
  oldSocialNetworks = [],
  oldIsGifting = false,
  offerId,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [errors, setErrors] = useState(null);
  const [title, setTitle] = useState(oldTitle);
  const [price, setPrice] = useState(oldPrice);
  const [isGifting, setIsGifting] = useState(oldIsGifting);
  const [offerDescription, setOfferDescription] = useState(oldOfferDescription);
  const [socialNetworks, setSocialNetworks] = useState(
    getFormattedSocialNetworksSimple(oldSocialNetworks)
  );

  const hasErrors = () => {
    let newErrors = {};
    if (title.length === 0) {
      newErrors.title = true;
    }
    if (offerDescription.length === 0) {
      newErrors.offerDescription = true;
    }
    if (socialNetworks.length === 0) {
      newErrors.socialNetworks = true;
    }
    if (!isGifting && price === 0) {
      newErrors.price = true;
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length !== 0) return true;
    return false;
  };

  // const resetData = () => {
  //   setTitle("");
  //   setPrice(0);
  //   setOfferDescription("");
  //   setSocialNetworks([]);
  // };

  const handleSendNewOffer = () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;

    if (hasErrors()) return;
    axios
      .post(`${API_URL}/brand/missions/${missionId}/offer`, {
        influencerId,
        isOffer: true,
        offer: {
          title,
          price,
          description: offerDescription,
          socialNetworks: socialNetworks.map((social) => social.value),
          isGifting,
        },
      })
      .then(() => {
        handleRefreshDiscussion();
        handleCloseModal(false);
      })
      .catch((error) => console.error(error));
  };

  const handleEditOffer = () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    if (hasErrors()) return;

    axios
      .put(`${API_URL}/brand/missions/${missionId}/offer/${offerId}`, {
        title,
        price,
        description: offerDescription,
        socialNetworks: socialNetworks.map((social) => social.value),
        isGifting,
      })
      .then(() => {
        handleRefreshDiscussion();
        handleCloseModal(false);
      })
      .catch((error) => console.error(error));
  };

  return (
    <Dialog
      open={isModalOpen}
      maxWidth="md"
      fullWidth={true}
      fullScreen={matches}
      onClose={() => handleCloseModal(false)}
    >
      <DialogTitle onClose={() => handleCloseModal(false)}>
        Nouvelle offre
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={4}>
          <Grid item container xs={12} justify="space-between">
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <Grid item xs={12} className={classes.fieldLabel}>
                <label>Titre de l'offre</label>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  inputProps={{ style: { padding: "8.5px 10px" } }}
                  id="title"
                  name="title"
                  variant="outlined"
                  value={title}
                  error={errors?.title}
                  helperText={
                    errors?.title && "Le titre de l'offre est obligatoire"
                  }
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              xs={12}
              style={{ marginBottom: 10 }}
            >
              <Grid
                item
                className={classes.fieldLabel}
                style={{ marginRight: 20, paddingBottom: 0, marginBottom: 0 }}
              >
                <label>Offre de Gifting ?</label>
              </Grid>
              <Grid item>
                <Switch
                  onChange={(e) => {
                    setIsGifting(e.target.checked);
                    setPrice(0);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container xs={4}>
              <Grid item xs={12} className={classes.fieldLabel}>
                <label>Prix</label>
              </Grid>
              <Grid item>
                <TextField
                  type="number"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  inputProps={{ style: { padding: "8.5px 10px" }, min: 0 }}
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  error={errors?.price}
                  helperText={
                    errors?.price && "Le prix de l'offre est obligatoire"
                  }
                  disabled={isGifting}
                />
              </Grid>
            </Grid>
            <Grid item container xs={7}>
              <Grid item xs={12} className={classes.fieldLabel}>
                <label>Réseaux sociaux</label>
              </Grid>
              <Grid item xs={12}>
                <Select
                  isMulti
                  name="socialNetworks"
                  options={options}
                  onChange={(selectedValue) => setSocialNetworks(selectedValue)}
                  value={socialNetworks}
                  placeholder="Sélectionne les réseaux sociaux"
                />
                {errors?.socialNetworks && (
                  <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                    Les réseaux sociaux pour cette offre sont obligatoire
                  </p>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <label className={classes.fieldLabel}>Description de l'offre</label>
            <br />
            <p
              style={{
                color: "#888",
                fontStyle: "italic",
                width: "100%",
                fontSize: 13,
                marginBottom: 20,
              }}
            >
              Décrivez les conditions du placement de produits que l’influencer
              doit réaliser (nombres de post, durée, etc…)
            </p>
            <TextField
              rows={5}
              multiline
              variant="outlined"
              fullWidth
              value={offerDescription}
              error={errors?.offerDescription}
              helperText={
                errors?.offerDescription &&
                "La description de l'offre est obligatoire"
              }
              onChange={(e) => setOfferDescription(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          style={{
            backgroundColor: "rgba(250, 128, 114,1)",
            borderRadius: 18,
          }}
          onClick={() => {
            if (isEditing) {
              handleEditOffer();
            } else {
              handleSendNewOffer();
            }
          }}
        >
          Envoyer l'offre
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleCloseModal(false)}
          style={{
            border: "1px solid rgba(250, 128, 114,1)",
            color: "rgba(250, 128, 114,1)",
            boxShadow: "none",
            background: "transparent",
            borderRadius: 18,
          }}
        >
          Annuler
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NewOfferModal.propTypes = {
  description: PropTypes.string,
  handleUpdateDescription: PropTypes.func,
  isModalOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func,
};

export default NewOfferModal;

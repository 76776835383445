import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import * as yup from "yup";
import { useFormik } from "formik";
import { Camera, Edit2 } from "react-feather";

import {
  Grid,
  Avatar,
  TextField,
  Button,
  Switch,
  CircularProgress,
  InputAdornment,
} from "@material-ui/core";
import Select from "../../../Common/Select/Select";

import personalInformationStyle from "./styles/personalInformation";
import {
  colourStyles,
  genderOptions,
  socialNetworkOptions,
} from "../../../Brand/NewMission/utils";
import { getFormattedSocialNetworks } from "./utils";
import PersonalInformationWidget from "../../../Common/InfluencerProfile/PersonalInformationWidget";
import axios from "axios";
import { API_URL } from "../../../../common/constants";
import { useTour } from "@reactour/tour";

const useStyles = makeStyles(personalInformationStyle);

const PersonalInformation = ({ profile, handleUpdateInfluencerProfile }) => {
  const classes = useStyles();
  const { isOpen } = useTour();
  const [isDisplayingFormPersonalInfo, setIsDisplayingFormPersonalInfo] =
    useState(!profile.age && profile.isOnBoarded);
  const [isDisplayingFormPrice, setIsDisplayingFormPrice] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object({
    fullname: yup
      .string("Votre nom complet")
      .required("Le nom est obligatoire"),
    age: yup.string("Votre nom complet").required("L'âge est obligatoire"),
    gender: yup
      .string("Votre nom complet")
      .required("Le genre est obligatoire"),
    socialNetworks: yup
      .array(
        yup.object().shape({
          username: yup
            .string()
            .required("Le nom d'utilisateur est obligatoire."),
          followers: yup
            .number()
            .required("Le nombre de followers est obligatoire."),
          price: yup
            .number()
            .required("Le prix moyen par post est obligatoire."),
        })
      )
      .required("Les réseaux sociaux sont obligatoire"),
    // description: yup
    //   .string("Décrivez votre annonce")
    //   .required("La description est obligatoire"),
  });

  useEffect(() => {
    if (!isOpen) {
      setIsDisplayingFormPersonalInfo(!profile.age && profile.isOnBoarded);
    }
  }, [isOpen, profile]);

  const formik = useFormik({
    initialValues: {
      ...profile,
    },
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      handleUpdateInfluencerProfile(values);
      setIsDisplayingFormPersonalInfo(false);
    },
  });

  useEffect(() => {
    formik.setValues({ ...profile });
  }, [profile]);

  const handleUploadImage = (file) => {
    let formData = new FormData();

    setIsLoading(true);
    formData.append("file", file);
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .post(`${API_URL}/images`, formData)
      .then((res) => {
        handleUpdateInfluencerProfile({
          avatar: `https://pop-influence.s3.us-east-2.amazonaws.com/${res.data.filename}`,
        });
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      {isLoading && (
        <div
          style={{
            zIndex: 1,
            width: "100%",
            position: "absolute",
            textAlign: "center",
            paddingTop: 100,
            height: "100%",
            background: "rgba(238, 238, 238, 0.4)",
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div className={classes.personalInformationContainer}>
        {isDisplayingFormPersonalInfo && (
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              xs={12}
              sm={12}
              md={10}
              lg={10}
              justify="center"
              alignItems="flex-start"
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                className={classes.formPriceAvatarContainer}
              >
                {/* <Avatar
                  className={classes.userAvatar}
                  src={formik.values.avatar}
                ></Avatar> */}
                <label>
                  <Avatar
                    className={classes.userAvatarEditable}
                    src={profile.avatar}
                  >
                    <Edit2 style={{ width: 50, height: 50 }} />
                  </Avatar>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      let file = e.target.files[0];
                      handleUploadImage(file);
                    }}
                  />
                </label>
              </Grid>
              <Grid
                item
                container
                xs={11}
                sm={11}
                md={8}
                lg={8}
                justify="space-between"
                className={classes.formUserInformation}
              >
                <Grid item container xs={5} sm={5} md={3} lg={3}>
                  <label className={classes.fieldLabel}>Nom complet</label>
                  <TextField
                    inputProps={{ style: { padding: "8.5px 10px" } }}
                    fullWidth
                    id="fullname"
                    name="fullname"
                    variant="outlined"
                    value={formik.values.fullname}
                    error={Boolean(formik.errors.fullname)}
                    helperText={formik.errors.fullname}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item container xs={5} sm={5} md={3} lg={3}>
                  <label className={classes.fieldLabel}>Âge</label>
                  <TextField
                    inputProps={{ style: { padding: "8.5px 10px" }, min: 0 }}
                    fullWidth
                    type="number"
                    id="age"
                    name="age"
                    variant="outlined"
                    value={formik.values.age}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">ans</InputAdornment>
                      ),
                    }}
                    error={Boolean(formik.errors.age)}
                    helperText={formik.errors.age}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  className={classes.genderContainer}
                >
                  <label className={classes.fieldLabel}>Genre</label>
                  <Select
                    styles={{
                      container: (styles, { data }) => {
                        return {
                          ...styles,
                          width: "100%",
                        };
                      },
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      menu: (base) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                    menuPosition={"fixed"}
                    name="gender"
                    id="gender"
                    value={
                      genderOptions.find(
                        (gender) => gender.value === formik.values.gender
                      ) || ""
                    }
                    onChange={(selectedValue) => {
                      formik.setFieldValue("gender", selectedValue.value);
                    }}
                    options={genderOptions}
                    placeholder="Genre"
                  />
                </Grid>
                <Grid
                  item
                  container
                  style={{ marginTop: 20 }}
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <p
                      style={{
                        color: "#888",
                        fontStyle: "italic",
                      }}
                    >
                      Êtes-vous disponible pour recevoir des offres de missions
                      de la part des marques ?
                    </p>
                  </Grid>
                  <Grid item>
                    <p
                      style={{
                        color: "#333330",
                        fontFamily: "nunito-semibold",
                      }}
                    >
                      Non disponible
                    </p>
                  </Grid>
                  <Grid item>
                    <Switch
                      classes={{
                        checked: classes.checked,
                        track: classes.track,
                        switchBase: classes.notChecked,
                      }}
                      color="primary"
                      checked={formik.values.isActive}
                      onChange={(e) =>
                        formik.setFieldValue("isActive", e.target.checked)
                      }
                    />
                  </Grid>
                  <Grid item>
                    <p
                      style={{
                        color: "#333330",
                        fontFamily: "nunito-semibold",
                      }}
                    >
                      Disponible
                    </p>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  justify="space-between"
                  style={{ marginTop: 20 }}
                >
                  <Grid item container xs={12} direction="column">
                    <label className={classes.fieldLabel}>
                      Quels sont vos réseaux sociaux ?
                    </label>
                    <Select
                      styles={{ ...colourStyles(true) }}
                      menuPosition={"fixed"}
                      isMulti
                      name="socialNetworks"
                      id="socialNetworks"
                      value={getFormattedSocialNetworks(
                        formik.values.socialNetworks
                      )}
                      onChange={(selectedValue) =>
                        formik.setFieldValue(
                          "socialNetworks",
                          selectedValue.map((value) => ({
                            ...value,
                            socialNetwork: value.value,
                            username: value.username || "",
                          }))
                        )
                      }
                      options={socialNetworkOptions}
                      placeholder="Réseaux sociaux"
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  justify="space-between"
                  style={{ marginTop: 0 }}
                >
                  {formik.values.socialNetworks.map((socialNetwork, key) => {
                    return (
                      <Grid
                        item
                        container
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        direction="row"
                        justify="space-between"
                        style={{ paddingTop: 40 }}
                        key={socialNetwork.socialNetwork}
                        className={classes.socialNetworkContainer}
                        spacing={2}
                      >
                        <Grid
                          item
                          container
                          xs={6}
                          sm={6}
                          md={4}
                          lg={4}
                          direction="column"
                          justify="space-between"
                        >
                          {/* <label className={classes.fieldLabel}>
                          {`Compte ${
                            socialNetworkOptions.find(
                              (network) =>
                                network.value === socialNetwork.socialNetwork
                            ).label
                          }`}
                        </label> */}
                          <TextField
                            inputProps={{ style: { padding: "8.5px 0px" } }}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  @
                                </InputAdornment>
                              ),
                            }}
                            style={{ zIndex: 0 }}
                            label={`Compte ${
                              socialNetworkOptions.find(
                                (network) =>
                                  network.value === socialNetwork.socialNetwork
                              ).label
                            }`}
                            id={`socialNetworks[${key}].username`}
                            name={`socialNetworks[${key}].username`}
                            variant="outlined"
                            value={`${socialNetwork.username}`}
                            error={Boolean(
                              formik.errors?.socialNetworks?.length > 0 &&
                                formik.errors?.socialNetworks[key]?.username
                            )}
                            helperText={
                              formik.errors?.socialNetworks?.length > 0 &&
                              formik.errors?.socialNetworks[key]?.username
                            }
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          container
                          xs={6}
                          sm={6}
                          md={4}
                          lg={4}
                          direction="column"
                          justify="space-between"
                        >
                          {/* <label className={classes.fieldLabel}>
                          {`Followers ${
                            socialNetworkOptions.find(
                              (network) =>
                                network.value === socialNetwork.socialNetwork
                            ).label
                          }`}
                        </label> */}
                          <TextField
                            inputProps={{
                              style: { padding: "8.5px 10px" },
                              min: 0,
                            }}
                            style={{ zIndex: 0 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label={`Followers ${
                              socialNetworkOptions.find(
                                (network) =>
                                  network.value === socialNetwork.socialNetwork
                              ).label
                            }`}
                            fullWidth
                            type="number"
                            id={`socialNetworks[${key}].followers`}
                            name={`socialNetworks[${key}].followers`}
                            variant="outlined"
                            value={socialNetwork.followers}
                            error={Boolean(
                              formik?.errors?.socialNetworks?.length > 0 &&
                                formik.errors?.socialNetworks[key]?.followers
                            )}
                            helperText={
                              formik?.errors?.socialNetworks?.length > 0 &&
                              formik?.errors?.socialNetworks[key]?.followers
                            }
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid
                          item
                          container
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          direction="column"
                          justify="space-between"
                        >
                          {/* <label className={classes.fieldLabel}>
                          {`Prix moyen / post ${
                            socialNetworkOptions.find(
                              (network) =>
                                network.value === socialNetwork.socialNetwork
                            ).label
                          }`}
                        </label> */}
                          <TextField
                            inputProps={{
                              style: { padding: "8.5px 10px" },
                              min: 0,
                            }}
                            style={{ zIndex: 0 }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label={`Prix moyen / post`}
                            fullWidth
                            type="number"
                            id={`socialNetworks[${key}].price`}
                            name={`socialNetworks[${key}].price`}
                            variant="outlined"
                            value={socialNetwork.price}
                            error={Boolean(
                              formik?.errors?.socialNetworks?.length > 0 &&
                                formik?.errors?.socialNetworks[key]?.price
                            )}
                            helperText={
                              formik?.errors?.socialNetworks?.length > 0 &&
                              formik?.errors?.socialNetworks[key]?.price
                            }
                            onChange={formik.handleChange}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid
                  item
                  container
                  xs={8}
                  justify="space-between"
                  style={{ marginTop: 20, marginBottom: 20 }}
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      type=""
                      style={{
                        fontFamily: "nunito-bold",
                        fontSize: 13,
                        color: "#FFF",
                        height: 40,
                        paddingLeft: 20,
                        paddingRight: 20,
                        borderRadius: 18,
                        textTransform: "none",
                        backgroundColor: "#825DFF",
                      }}
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                    >
                      Enregistrer
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
        {isDisplayingFormPrice && (
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              className={classes.pageLayout}
              justify="center"
              alignItems="flex-start"
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                className={classes.formPriceAvatarContainer}
              >
                <Avatar
                  className={classes.userAvatar}
                  src={formik.values.avatar}
                >
                  <Edit2 style={{ width: 50, height: 50 }} />
                </Avatar>
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={8}
                lg={8}
                justify="center"
              >
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  justify="space-between"
                  style={{ marginTop: 20 }}
                >
                  <Grid item container xs={12} direction="column">
                    <label className={classes.fieldLabel}>
                      Quel est votre prix moyen par post ?
                    </label>
                    <TextField
                      type="number"
                      variant="outlined"
                      name="price"
                      inputProps={{ style: { padding: "8.5px 10px" }, min: 0 }}
                      value={formik.values.price}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  justify="space-between"
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  <Grid item xs>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        formik.handleSubmit();
                        setIsDisplayingFormPrice(false);
                      }}
                    >
                      Enregistrer
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
        {!isDisplayingFormPersonalInfo && !isDisplayingFormPrice && (
          <PersonalInformationWidget
            profile={formik.values}
            isEditable
            setIsDisplayingFormPersonalInfo={setIsDisplayingFormPersonalInfo}
            setIsDisplayingFormPrice={setIsDisplayingFormPrice}
            handleUpdateInfluencerProfile={handleUpdateInfluencerProfile}
            setIsLoading={setIsLoading}
          />
        )}
      </div>
    </>
  );
};

PersonalInformation.propTypes = {};

export default PersonalInformation;

import {
  Grid,
  Step,
  Stepper,
  Typography,
  StepLabel,
  useMediaQuery,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";

import missionPageStyle from "../styles/missionPageStyles";
import WaitingForInfluencer from "./WaitingForInfluencer";
import BrandValidation from "./BrandValidation";
import RatingBrand from "./RatingBrand";
import WaitingForPaiement from "./WaitingForPaiement";

const useStyles = makeStyles(missionPageStyle);

const steps = [
  "Paiement de la campagne",
  "Votre placement",
  "Validation de la marque",
  "Donnez nous votre avis",
];

const followProcessConfig = {
  BRAND_PAYMENT: 0,
  INFLUENCER_VALIDATION: 1,
  BRAND_VALIDATION: 2,
  EVALUATION: 3,
};

const MissionFollow = ({
  influencerMissionId,
  followProcess,
  handleRefreshFollowProcess,
  brandName,
}) => {
  const [activeStep, setActiveStep] = useState(
    followProcessConfig[followProcess.step]
  );
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const isStepOptional = (step) => {
    return step === 3;
  };

  useEffect(() => {
    setActiveStep(followProcessConfig[followProcess.step]);
  }, [followProcess.step]);

  return (
    <Grid
      item
      container
      className={classes.widgetContainer}
      alignItems="flex-start"
    >
      <Grid item xs={12}>
        <p className={classes.widgetTitle}>Suivi de la mission</p>
      </Grid>
      <Grid item xs={12}>
        <Stepper
          activeStep={activeStep}
          style={matches ? { textAlign: "center" } : {}}
          orientation={`${matches ? "vertical" : "horizontal"}`}
        >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            return (
              <Step
                key={label}
                {...stepProps}
                // style={{ cursor: "pointer" }}
                // onClick={() => setActiveStep(index)}
              >
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
      <Grid item container xs={12} style={{ marginTop: 50, padding: 15 }}>
        {activeStep === 0 && <WaitingForPaiement />}
        {activeStep === 1 && (
          <WaitingForInfluencer
            influencerMissionId={influencerMissionId}
            handleRefreshFollowProcess={handleRefreshFollowProcess}
            followProcess={followProcess}
          />
        )}
        {activeStep === 2 && <BrandValidation />}
        {activeStep === 3 && (
          <RatingBrand
            influencerMissionId={influencerMissionId}
            handleRefreshFollowProcess={handleRefreshFollowProcess}
            brandName={brandName}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default MissionFollow;

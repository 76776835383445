import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import { API_URL } from "../../../common/constants";
import MissionPage from "../../../Components/Influencer/Missions/MissionPage/MissionPage";

import MissionDetails from "../../../Components/Brand/Missions/BrandMissionPage/MissionDetails/MissionDetails";
import {
  DescriptionOutlined,
  AssignmentOutlined,
  PostAdd,
  MailOutline,
  FolderOpenOutlined,
} from "@material-ui/icons";
import MessagesContainer from "../../../Components/Common/Messages/MessagesContainer";
import MissionFollow from "../../../Components/Influencer/Missions/MissionPage/MissionFollow/MissionFollow";
import MissionBriefs from "../../../Components/Influencer/Missions/MissionPage/MissionBriefs/MissionBriefs";

const routesProposed = [
  {
    icon: <DescriptionOutlined />,
    label: "Détails",
    to: "/details",
    path: "/influencer/missions/:id/details",
    component: MissionDetails,
    exact: true,
  },
];

const routesAccepted = (hasMessages) => {
  if (hasMessages) {
    return [
      {
        icon: <DescriptionOutlined />,
        label: "Détails",
        to: "/details",
        path: "/influencer/missions/:id/details",
        component: MissionDetails,
        exact: true,
      },
      {
        icon: <PostAdd />,
        label: "Briefs",
        to: "/briefs",
        path: "/influencer/missions/:id/briefs",
        component: MissionBriefs,
        exact: true,
      },
      {
        icon: <MailOutline />,
        label: "Messages",
        to: "/messages",
        path: "/influencer/missions/:id/messages",
        component: MessagesContainer,
        exact: true,
      },
    ];
  } else {
    return [
      {
        icon: <DescriptionOutlined />,
        label: "Détails",
        to: "/details",
        path: "/influencer/missions/:id/details",
        component: MissionDetails,
        exact: true,
      },
      {
        icon: <PostAdd />,
        label: "Briefs",
        to: "/briefs",
        path: "/influencer/missions/:id/briefs",
        component: MissionBriefs,
        exact: true,
      },
    ];
  }
};

const routesPaid = [
  {
    icon: <DescriptionOutlined />,
    label: "Détails",
    to: "/details",
    path: "/influencer/missions/:id/details",
    component: MissionDetails,
    exact: true,
  },
  {
    icon: <AssignmentOutlined />,
    label: "Briefs",
    to: "/briefs",
    path: "/influencer/missions/:id/briefs",
    component: MissionBriefs,
    exact: true,
  },
  {
    icon: <MailOutline />,
    label: "Messages",
    to: "/messages",
    path: "/influencer/missions/:id/messages",
    component: MessagesContainer,
    exact: true,
  },
  {
    icon: <FolderOpenOutlined />,
    label: "Suivis",
    to: "/follow-process",
    path: "/influencer/missions/:id/follow-process",
    component: MissionFollow,
    exact: true,
  },
];

const routesPaidWithBriefs = [
  {
    icon: <DescriptionOutlined />,
    label: "Détails",
    to: "/details",
    path: "/influencer/missions/:id/details",
    component: MissionDetails,
    exact: true,
  },
  {
    icon: <AssignmentOutlined />,
    label: "Briefs",
    to: "/briefs",
    path: "/influencer/missions/:id/briefs",
    component: MissionBriefs,
    exact: true,
  },
  {
    icon: <MailOutline />,
    label: "Messages",
    to: "/messages",
    path: "/influencer/missions/:id/messages",
    component: MessagesContainer,
    exact: true,
  },
  {
    icon: <FolderOpenOutlined />,
    label: "Suivis",
    to: "/follow-process",
    path: "/influencer/missions/:id/follow-process",
    component: MissionFollow,
    exact: true,
  },
];

const missionDoneRoutes = [
  {
    icon: <DescriptionOutlined />,
    label: "Détails",
    to: "/details",
    path: "/influencer/missions/:id/details",
    component: MissionDetails,
    exact: true,
  },
  {
    icon: <MailOutline />,
    label: "Messages",
    to: "/messages",
    path: "/influencer/missions/:id/messages",
    component: MessagesContainer,
    exact: true,
    disabled: true,
  },
  {
    icon: <FolderOpenOutlined />,
    label: "Suivis",
    to: "/follow-process",
    path: "/influencer/missions/:id/follow-process",
    component: MissionFollow,
    exact: true,
    disabled: true,
  },
];

const MissionPageWrapper = () => {
  const { id } = useParams();
  const [mission, setMission] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getMission = () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .get(`${API_URL}/influencer/missions/${id}`)
      .then((res) => {
        setMission(res.data);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    setIsLoading(true);
    getMission();
  }, [id]);

  if (isLoading) return <CircularProgress />;
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`POP App - Campagne ${mission.missionId.title}`}</title>
        <link rel="canonical" href="https://pop-app.fr" />
      </Helmet>
      <MissionPage
        mission={mission}
        handleRefreshMission={getMission}
        routes={
          mission.influencerStatus === "PROPOSED" ||
          mission.influencerStatus === "DECLINED"
            ? routesProposed
            : mission.influencerStatus === "ACCEPTED" && !mission.status
            ? routesAccepted(true)
            : mission.influencerStatus === "DONE" ||
              mission.influencerStatus === "REFUSED_BY_BRAND"
            ? missionDoneRoutes
            : mission?.briefs?.length > 0
            ? routesPaidWithBriefs
            : routesPaid
        }
      />
    </div>
  );
};

MissionPageWrapper.propTypes = {};

export default MissionPageWrapper;

// ####################################
// // // New Mission Page styles
// ###################################

const newMissionStyle = (theme) => ({
  formContainer: {
    padding: "30px 40px",
    backgroundColor: "#fff",
    borderRadius: 8,
    boxShadow: "0 0 8px 0 rgb(0 0 0 / 5%)",
    [theme.breakpoints.up("md")]: {
      width: "70%",
    },
    margin: "auto",
  },
  fieldLabel: {
    fontSize: 18,
    fontWeight: 400,
    marginBottom: 10,
    color: "#484848",
  },
  socialNetworkLabel: {
    fontSize: 24,
    fontWeight: 500,
    marginBottom: 10,
    color: "#484848",
  },
  socialNetworkBorder: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
    paddingTop: 10,
  },
  activeStep: {
    color: "rgba(250, 128, 114,1) !important",
  },
  missionAvatar: {
    objectFit: "contain",
    width: 150,
    height: 150,
    [theme.breakpoints.down("md")]: {
      margin: "auto",
    },
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(250, 128, 114,0.3)",
    },
    "&:hover > img": {
      opacity: 0.6,
    },
  },
});

export default newMissionStyle;

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";

import { Grid, MenuItem, MenuList } from "@material-ui/core";
import appLayoutStyle from "./styles/appLayout";
import axios from "axios";
import { API_URL } from "../common/constants";

const useStyles = makeStyles(appLayoutStyle);

const MobileMenu = ({
  setMobileMenuOpen,
  isInfluencer,
  token,
  isProfileMenuOpen,
  handleClose,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [profile, setProfile] = useState(undefined);

  const getTotalMoney = (profile) => {
    const money = profile?.doneMissions?.reduce((acc, cur) => {
      if (!cur.isPaid) {
        return acc + cur.influencerMission.acceptedOffer.price;
      }
      return acc + 0;
    }, 0);

    return money;
  };

  useEffect(() => {
    const getProfile = () => {
      axios.defaults.headers.common["Authorization"] = token;
      axios
        .get(`${API_URL}/influencer/profile`)
        .then((res) => {
          const totalMoney = getTotalMoney(res.data);
          setProfile({ ...res.data, totalMoney: totalMoney || 0 });
        })
        .catch((error) => console.error(error));
    };

    if (isInfluencer) {
      getProfile();
    }
  }, [isProfileMenuOpen, isInfluencer, token]);

  if (isInfluencer) {
    return (
      <Grid
        container
        direction="column"
        xs={12}
        style={{
          height: "92%",
          background: "#FFF",
          position: "fixed",
          textAlign: "center",
          bottom: 0,
          borderRadius: 10,
          zIndex: 3,
        }}
      >
        <Grid
          item
          container
          justify="center"
          alignItems="center"
          style={{
            marginTop: 20,
            padding: 15,
            fontFamily: "nunito-bold",
          }}
          onClick={() => {
            history.push("/influencer/profile");
            setMobileMenuOpen(false);
          }}
        >
          <p style={{ fontFamily: "nunito-bold" }}>Dashboard</p>
        </Grid>
        <Grid
          item
          container
          justify="center"
          alignItems="center"
          style={{
            padding: 15,
            fontFamily: "nunito-bold",
            borderBottom: "1px solid pink",
            paddingBottom: 50,
          }}
          onClick={() => {
            history.push("/influencer/missions");
            setMobileMenuOpen(false);
          }}
        >
          <p style={{ fontFamily: "nunito-bold" }}>Missions</p>
        </Grid>
        <Grid item container justify="center">
          <MenuList style={{ minWidth: 250 }} id="menu-list-grow">
            <NavLink
              to={`/${
                localStorage.getItem("isInfluencer") === "true"
                  ? "influencer"
                  : "brand"
              }/settings/withdraw`}
              style={{ textDecoration: "none" }}
            >
              <MenuList
                onClick={() => setMobileMenuOpen(false)}
                style={{ padding: 20 }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: 16,
                    }}
                  >
                    {profile?.fullname}
                  </p>
                  <span
                    className={
                      true
                        ? classes.availabilityDotActive
                        : classes.availabilityDotNotActive
                    }
                  ></span>
                </div>
                <p style={{ fontWeight: 400, fontSize: 12 }}>
                  Cagnotte en cours: {profile?.totalMoney} €
                </p>
              </MenuList>
            </NavLink>
            <NavLink
              style={{
                color: "#000",
                textDecoration: "none",
              }}
              to={`/${
                localStorage.getItem("isInfluencer") === "true"
                  ? "influencer"
                  : "brand"
              }/settings/profile`}
            >
              <MenuItem
                style={{ justifyContent: "center" }}
                onClick={() => setMobileMenuOpen(false)}
              >
                Mon profil
              </MenuItem>
            </NavLink>
            <MenuItem
              onClick={(e) => {
                setMobileMenuOpen(false);
                localStorage.removeItem("token");
                history.push("/");
              }}
              style={{ justifyContent: "center" }}
            >
              Se déconnecter
            </MenuItem>
          </MenuList>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid
        container
        direction="column"
        xs={12}
        style={{
          height: "92%",
          background: "#FFF",
          position: "fixed",
          textAlign: "center",
          bottom: 0,
          borderRadius: 10,
          zIndex: 3,
        }}
      >
        <Grid
          item
          container
          justify="center"
          alignItems="center"
          style={{
            marginTop: 20,
            padding: 15,
            fontFamily: "nunito-bold",
          }}
          onClick={() => {
            history.push("/brand/new-mission");
            setMobileMenuOpen(false);
          }}
        >
          <p style={{ fontFamily: "nunito-bold" }}>Créer une campagne</p>
        </Grid>
        <Grid
          item
          container
          justify="center"
          alignItems="center"
          style={{
            padding: 15,
            fontFamily: "nunito-bold",
          }}
          onClick={() => {
            history.push("/brand/dashboard");
            setMobileMenuOpen(false);
          }}
        >
          <p style={{ fontFamily: "nunito-bold" }}>Dashboard</p>
        </Grid>
        <Grid
          item
          container
          justify="center"
          alignItems="center"
          style={{
            padding: 15,
            fontFamily: "nunito-bold",
            borderBottom: "1px solid pink",
            paddingBottom: 15,
          }}
          onClick={() => {
            history.push("/brand/missions");
            setMobileMenuOpen(false);
          }}
        >
          <p style={{ fontFamily: "nunito-bold" }}>Mes campagnes</p>
        </Grid>
        <Grid item container justify="center">
          <MenuList style={{ minWidth: 250 }} id="menu-list-grow">
            <NavLink
              style={{
                color: "#000",
                textDecoration: "none",
              }}
              to={`/${
                localStorage.getItem("isInfluencer") === "true"
                  ? "influencer"
                  : "brand"
              }/settings/profile`}
            >
              <MenuItem
                style={{ justifyContent: "center" }}
                onClick={() => setMobileMenuOpen(false)}
              >
                Mon profil
              </MenuItem>
            </NavLink>
            <MenuItem
              onClick={(e) => {
                setMobileMenuOpen(false);
                localStorage.removeItem("token");
                history.push("/");
              }}
              style={{ justifyContent: "center" }}
            >
              Se déconnecter
            </MenuItem>
          </MenuList>
        </Grid>
      </Grid>
    );
  }
};

export default MobileMenu;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import InfluencerProfilePage from "../../../Components/Influencer/InfluencerProfilePage/InfluencerProfilePage";
import { API_URL } from "../../../common/constants";
import { CircularProgress } from "@material-ui/core";
import { TourProvider, useTour } from "@reactour/tour";
import { useHistory } from "react-router";

const steps = [
  {
    selector: "#influencer-profile",
    content:
      "Remplissez les informations de votre profil, et précisez sur quels réseaux sociaux vous êtes actif.",
  },
  {
    selector: "#influencer-activity",
    content: "Ajoutez les domaines d'activités qui vous correspondent le plus.",
  },
  {
    selector: "#influencer-description",
    content:
      "Décrivez-vous avec le plus de détails possible pour donner envie aux marques de collaborer avec vous.",
  },
  {
    selector: "#influencer-experiences",
    content: "Ajoutez vos différentes experiences en tant qu'influenceurs.",
  },
  {
    selector: "#influencer-missions-menu",
    content:
      "Ici vous retrouverez toutes les missions pour lesquelles vous avez été sélectionné.",
  },
  {
    selector: "#influencer-profile-menu",
    content:
      "Dans le menu pourrez gérer votre profil et avoir accès à votre cagnotte.",
  },
];

const TitleTour = ({ isMobile }) => {
  const { currentStep } = useTour();
  if (currentStep > 0) return <div></div>;
  return (
    <div
      style={{
        position: "fixed",
        top: isMobile ? 20 : 10,
        width: isMobile ? "100%" : 500,
        height: 100,
        left: isMobile ? 0 : 10,
        background: "#FFF",
        borderRadius: 18,
        marginLeft: "auto",
        zIndex: 999999,
        padding: isMobile ? 0 : 20,
        paddingTop: isMobile ? 10 : 20,
        paddingBottom: isMobile ? 10 : 20,
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 10,
          width: "95%",
          margin: "auto",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p style={{ fontFamily: "nunito-bold", fontSize: 18 }}>
          Comment remplir votre profil ?
        </p>
        <p
          style={{
            marginTop: 10,
            fontFamily: "nunito-regular",
            fontSize: 16,
            fontStyle: "italic",
          }}
        >
          Ce didacticiel vous présente comment vous pourrez renseigner votre
          profil
        </p>
      </div>
    </div>
  );
};

const ProfilePage = () => {
  const history = useHistory();
  const [profile, setProfile] = useState(undefined);

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const getProfile = () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .get(`${API_URL}/influencer/profile`)
      .then((res) => {
        setProfile(res.data);
        setIsLoading(false);
        setIsOpen(false);
      })
      .catch((error) => {
        localStorage.removeItem("token");
        history.push("/");
      });
  };

  useEffect(() => {
    getProfile();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (!isOpen) {
      getProfile();
    }
  }, [isOpen]);

  function handleEndReactour() {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .put(`${API_URL}/influencer/profile`, {
        isOnBoarded: true,
      })
      .then(() => {
        getProfile();
      });
  }

  const handleUpdateInfluencerProfile = (body) => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .put(`${API_URL}/influencer/profile`, {
        ...body,
      })
      .then((res) => getProfile())
      .catch((error) => console.error(error));
  };
  let isMobile = width <= 768;

  if (!profile)
    return (
      <div
        style={{
          width: "100%",
          position: "absolute",
          textAlign: "center",
          paddingTop: 50,
        }}
      >
        <CircularProgress style={{ margin: "auto" }} />
      </div>
    );

  return (
    <TourProvider
      steps={steps}
      scrollSmooth
      beforeClose={async () => {
        setIsLoading(true);
        handleEndReactour();
      }}
      afterOpen={() => {
        setIsOpen(true);
      }}
      styles={{
        popover: (base) => ({
          ...base,
          boxShadow: "0 0 3em rgba(0, 0, 0, 0.5)",
          "--reactour-accent": "rgba(130, 93, 255, 1)",
          borderRadius: 18,
        }),
        badge: (base) => ({
          ...base,
          background: "rgba(130, 93, 255, 1)",
        }),
        dot: (base, props) => {
          return {
            ...base,
            background: props.current
              ? "rgba(130, 93, 255, 1)"
              : base.background,
          };
        },
        highlightedArea: (base) => ({
          ...base,
          borderRadius: 18,
          x: 10,
          background: "red",
        }),
        maskWrapper: (base) => ({
          ...base,
        }),
        // popover: (base) => ({
        //   ...base,
        //   "--reactour-accent": "rgba(130, 93, 255, 1)",
        //   borderRadius: 18,
        // }),
        maskArea: (base) => ({ ...base, rx: 18 }),
        // maskWrapper: (base) => ({
        //   ...base,
        //   color: "rgba(15, 32, 54, 1)",
        // }),
        // badge: (base) => ({ ...base, left: "auto", right: "-0.8125em" }),
        // controls: (base) => ({ ...base, marginTop: 100 }),
        // close: (base) => ({ ...base, right: "auto", left: 8, top: 8 }),
        // dot: (base) => ({
        //   ...base,
        //   animationDuration: "1s",
        //   animationIterationCount: "infinite",
        //   "&:nth-of-type(1)": {
        //     animationDelay: ".3s",
        //   },
        //   "&:nth-of-type(2)": {
        //     animationDelay: ".6s",
        //   },
        // }),
      }}
    >
      {isOpen && <TitleTour isMobile={isMobile} />}
      {isLoading && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            background: "rgba(8, 54, 113, 0.4)",
            zIndex: 999,
            textAlign: "center",
            paddingTop: 150,
          }}
        >
          <CircularProgress />
        </div>
      )}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`POP App - Dashboard - ${profile.fullname}`}</title>
        <link rel="canonical" href="https://pop-app.fr" />
      </Helmet>
      <InfluencerProfilePage
        profile={profile}
        handleUpdateInfluencerProfile={handleUpdateInfluencerProfile}
      />
    </TourProvider>
  );
};

export default ProfilePage;

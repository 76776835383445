export const faqConfig = {
  influencer: [
    {
      title: "A quoi sert POP ?",
      content:
        "POP est le meilleur outil pour les Influenceurs. ll vous permet de trouver facilement et rapidement, sans que vous ayez rien à faire, des campagnes qui vous correspondent en fonction de vos critères. Vous pourrez ainsi gagner rapidement de l’argent.",
    },
    {
      title: "Comment je trouve des campagnes publicitaires ?",
      content:
        "Avec POP nous avons simplifié tout ça, nous savons que votre temps est précieux et que vous êtes prêt à faire toutes les campagnes tant qu’elles vous correspondent ! Il suffit de vous inscrire, de décrire vos activités sur les réseaux et votre communauté. Lorsque des campagnes vous correspondant se créent vous serez alors les premiers informées pour vous permettre de les réaliser.",
    },
    {
      title: "Je dois payer un abonnement ? ",
      content:
        "Comparativement aux autres plateformes, avec POP il n’y a aucun abonnement requis pour être sur la plateforme. C’est gratuit et ça le restera.",
    },
    {
      title: "Comment m’inscrire ?",
      content:
        "L’inscription sur POP est rapide, facile et gratuite. Il suffit simplement de vous créer un compte pour accéder à POP. Ensuite vous pourrez compléter votre profil Influenceur pour obtenir les compagnes vous correspondant.",
    },
    {
      title:
        "A partir de quelle taille de communauté je peux trouver des campagnes ?",
      content:
        "Nous avons pris le parti de ne pas mettre de minimum de nombre d’abonnés pour laisser l’opportunité a chaque Influenceur de trouver une campagne qui lui correspondrai.",
    },
    {
      title: "Si je suis mineur comment ça se passe ?",
      content:
        "Avec POP, chaque campagne réalisée vous permet d’augmenter votre cagnotte sur le site. Pour recevoir cet argent, soit vous avez un représentant légal majeur qui représente votre société, soit vous convertissez votre cagnotte en bon cadeau.",
    },
    {
      title: "Sur quels réseaux sociaux sont proposées les campagnes ?",
      content:
        "La force de POP c’est de vous permettre de créer et d’effectuer des campagnes sur tous les réseaux sociaux.",
    },
    {
      title: "Comment se passe la rémunération ?",
      content:
        "Vous êtes payé.e dès que la mission est finie et validée par la marque. Une cagnotte se crée dans votre compte POP. À tout moment vous pouvez demander un virement qui sera immédiatement réalisé. Vous pouvez aussi demandé des bons cadeaux chez nos partenaires.",
    },
    {
      title: "Si j’ai un problème comment je dois faire ?",
      content:
        "Un des objectifs de POP: La proximité. Aux moindres questions, problèmes ou recommandations de votre part, envoyez-nous un message par le formulaire de contact. Nous nous efforcerons de répondre dans les 24h.",
    },
  ],
  brand: [
    {
      title: "A quoi sert POP ?",
      content:
        "A quoi sert POP ? POP est le meilleur outil pour les Marques. ll vous permet de trouver facilement et rapidement, sans que vous n'ayez rien à faire, les Influenceurs les plus adaptés à votre produit et votre cible. Vous pourrez ainsi rapidement gagner en visibilité et en rentabilité.",
    },
    {
      title:
        "Est-ce que je choisis les Influenceurs avec lesquels je collabore ?",
      content:
        "L’idée avec POP est de vous faire gagner du temps. Pour cela nous vous proposons une sélection d’Influenceurs qui correspondent le mieux à votre campagne. A vous ensuite de choisir ceux qui vous plaisent le plus. Nous savons à quel point votre temps est précieux.",
    },
    {
      title: "Je dois payer un abonnement ? ",
      content:
        "Comparativement aux autres plateformes, avec POP il n’y a aucun abonnement requis pour proposer des campagnes sur la plateforme. C’est gratuit et ça le restera.",
    },
    {
      title: "Quels moyens de payement est accepté sur le site ?",
      content:
        "Pour payer votre campagne nous utilisons pour le moment les payements ultras sécurisés et chiffrés par Stripe. C’est simple, rapide et sûre.",
    },
    {
      title:
        "Comment est géré l’envoi de produit si ce type de campagne est choisi ?",
      content:
        "Rien de plus simple, à vous d’en discuter avec l’influencer directement. Il vous communiquera son adresse ou un point de retrait.",
    },
    {
      title: "Sur quels réseaux sociaux je peux proposer des campagnes ?",
      content:
        "La force de POP c’est de vous permettre de créer et d’effectuer des campagnes sur tous les réseaux sociaux.",
    },
    {
      title: "Est-ce que les influenceurs sont rémunérés ?",
      content:
        "Lorsque vous créer une campagne avec POP, à vous de déterminer le budget que vous allouez. Négociez au mieux avec eux directement en fonction de vos attentes.",
    },
    {
      title: "Comment m’inscrire ?",
      content:
        "Avec POP, l’inscription est simple, rapide et gratuite. Il suffit simplement de vous créer un compte pour accéder à POP. Ensuite vous pourrez compléter votre profil de Marque,  créer votre campagne et ajouter des Briefs. Lancez là pour que les Influenceurs puissent y postuler.",
    },
    {
      title:
        "Comment ça se passe si le placement de produit n’est pas exécuté correctement par l’Influenceur ?",
      content:
        "Avec POP nous garantissons la qualité des Influenceurs que nous vous proposons. Si le placement est mal effectué et ne vous convient pas, alors ne validez pas la mission, discutez-en avec l’Influenceur, il n’aura peut être pas bien compris votre attente. En revanche s’il y a un problème avec l’Influenceur, contactez-nous pour tout litige.",
    },
    {
      title: "Si j’ai un problème comment je dois faire ?",
      content:
        "Un des objectif de POP, la proximité.  Aux moindres questions, problèmes ou recommandations de votre part, envoyez-nous un message par le formulaire de contact. Nous nous efforcerons de répondre dans les 24h.",
    },
  ],
};

// ##############################
// // // Login styles
// #############################

const loginStyle = (theme) => ({
  popLogo: {
    flexGrow: 1,
    marginTop: 40,
    marginBottom: 60,
  },
  coloredHeader: {
    background: "rgb(130, 93, 255)",
    height: 10,
    width: "100%",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  selectButton: {
    "& .MuiButton-label": {
      fontFamily: "nunito-regular",
    },
  },
});

export default loginStyle;

import React, { useState } from "react";
import { withRouter } from "react-router";
import { Grid, Button, makeStyles, TextField } from "@material-ui/core";
import axios from "axios";
import { API_URL } from "../../../common/constants";
import PopLogo from "../../../assets/images/logo.png";
import PopMiniLogo from "../../../assets/images/pop-mini-logo.png";

import authStyles from "./styles/login";

const useStyles = makeStyles(authStyles);

const colorBrand = "rgba(250, 128, 114,1)";
const colorInfluencer = "#825DFF";

const Register = withRouter(({ history, isInfluencer }) => {
  const classes = useStyles();

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [brandName, setBrandName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [SIRET, setSiret] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState({});

  const checkErrors = () => {
    if (isInfluencer && firstname.length === 0) {
      setError((error) => ({
        ...error,
        firstname: true,
      }));
    }
    if (isInfluencer && lastname.length === 0) {
      setError((error) => ({
        ...error,
        lastname: true,
      }));
    }
    if (!isInfluencer && brandName.length === 0) {
      setError((error) => ({
        ...error,
        brandName: true,
      }));
    }
    if (!isInfluencer && phoneNumber.length === 0) {
      setError((error) => ({
        ...error,
        phoneNumber: true,
      }));
    }
    if (email.length === 0) {
      setError((error) => ({
        ...error,
        email: true,
      }));
    }
    if (password.length === 0) {
      setError((error) => ({
        ...error,
        password: true,
      }));
    }
    if (
      Object.values(error).some((value) => {
        return value;
      })
    ) {
      return true;
    }
    return false;
  };

  const onRegister = async () => {
    if (checkErrors()) {
      return;
    }
    axios
      .post(
        `${API_URL}/${isInfluencer ? "influencer" : "brand"}/auth/register`,
        {
          email: email.toLowerCase(),
          password,
          ...(isInfluencer
            ? { fullname: `${firstname} ${lastname}` }
            : { brandName, SIRET, phoneNumber }),
        }
      )
      .then((res) =>
        history.push(
          `/waiting-for-email/${isInfluencer ? "influencer" : "brand"}`
        )
      )
      .catch((error) => {
        console.log("error", error);
        if (error?.response?.status === 409) {
          setError((error) => ({
            ...error,
            conflictError: true,
            conflictErrorMessage:
              "Un compte existe déjà avec cette adresse email.",
          }));
        }
        console.error("error", error.response);
      });
  };

  return (
    <Grid
      container
      xs={12}
      sm={12}
      md={5}
      lg={5}
      style={{
        width: "40%",
        marginTop: 200,
        boxShadow: "0 0px 50px rgba(0, 0, 0, 0.2)",
        borderRadius: 5,
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 150,
      }}
    >
      <div
        className={
          isInfluencer ? classes.coloredHeader : classes.coloredHeaderBrand
        }
      ></div>
      <Grid
        container
        justify="space-around"
        alignItems="center"
        style={{ textAlign: "center" }}
      >
        <div className={classes.popLogo}>
          <a href="/">
            <img alt="logo" src={PopMiniLogo} style={{ height: 100 }} />
          </a>
        </div>
        <Grid item xs={12} style={{ marginBottom: 40, textAlign: "center" }}>
          <p style={{ fontFamily: "nunito-bold", fontSize: 20 }}>
            {`Créer un compte ${isInfluencer ? "influenceur" : "marque"}`}
          </p>
        </Grid>
        {isInfluencer ? (
          <>
            <Grid item xs={8} style={{ marginBottom: 20 }}>
              <TextField
                fullWidth
                inputProps={{ style: { padding: "8.5px 10px" } }}
                id="firstname"
                name="firstname"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                label="Prénom"
                value={firstname}
                onChange={(e) => {
                  setFirstname(e.target.value);
                  setError((error) => ({
                    ...error,
                    firstname: false,
                  }));
                }}
                error={error?.firstname}
                helperText={error?.firstname && "Ce champs est requis."}
              />
            </Grid>
            <Grid item xs={8} style={{ marginBottom: 20 }}>
              <TextField
                fullWidth
                inputProps={{ style: { padding: "8.5px 10px" } }}
                id="lastname"
                name="lastname"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                label="Nom"
                value={lastname}
                onChange={(e) => {
                  setLastname(e.target.value);
                  setError((error) => ({
                    ...error,
                    lastname: false,
                  }));
                }}
                error={error?.lastname}
                helperText={error?.lastname && "Ce champs est requis."}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={8} style={{ marginBottom: 20 }}>
              <TextField
                fullWidth
                inputProps={{ style: { padding: "8.5px 10px" } }}
                id="brandName"
                name="brandName"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                label="Nom de la marque"
                value={brandName}
                onChange={(e) => {
                  setBrandName(e.target.value);
                  setError((error) => ({
                    ...error,
                    brandName: false,
                  }));
                }}
                error={error?.brandName}
                helperText={error?.brandName && "Ce champs est requis."}
              />
            </Grid>
            <Grid item xs={8} style={{ marginBottom: 20 }}>
              <TextField
                fullWidth
                inputProps={{ style: { padding: "8.5px 10px" } }}
                id="phoneNumber"
                name="phoneNumber"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                label="Numéro de téléphone"
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                  setError((error) => ({
                    ...error,
                    phoneNumber: false,
                  }));
                }}
                error={error?.phoneNumber}
                helperText={error?.phoneNumber && "Ce champs est requis."}
              />
            </Grid>
            <Grid item xs={8} style={{ marginBottom: 20 }}>
              <TextField
                fullWidth
                inputProps={{ style: { padding: "8.5px 10px" } }}
                id="SIRET"
                name="SIRET"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                label="SIRET (optionnel)"
                value={SIRET}
                onChange={(e) => {
                  setSiret(e.target.value);
                }}
              />
            </Grid>
          </>
        )}
        <Grid item xs={8} style={{ marginBottom: 20 }}>
          <TextField
            fullWidth
            inputProps={{ style: { padding: "8.5px 10px" } }}
            id="email"
            name="email"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            label="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setError((error) => ({
                ...error,
                email: false,
                conflictError: false,
                conflictErrorMessage: undefined,
              }));
            }}
            error={error?.email}
            helperText={error?.email && "Ce champs est requis."}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            inputProps={{ style: { padding: "8.5px 10px" } }}
            id="password"
            name="password"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="password"
            label="Mot de passe"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setError((error) => ({
                ...error,
                password: false,
              }));
            }}
            error={error?.password}
            helperText={error?.password && "Ce champs est requis."}
          />
        </Grid>
        {error?.conflictError && (
          <Grid item xs={8}>
            <p
              style={{
                marginTop: 10,
                color: "#f44336",
                fontFamily: "nunito-regular",
                fontSize: 14,
                textAlign: "left",
              }}
            >
              {error?.conflictErrorMessage}
            </p>
          </Grid>
        )}
        <Grid
          item
          xs={8}
          style={{ marginTop: 20, marginBottom: 20, textAlign: "center" }}
        >
          <Button
            style={{
              fontFamily: "nunito-bold",
              fontSize: 13,
              color: "#FFF",
              width: "100%",
              height: 40,
              paddingLeft: 20,
              paddingRight: 20,
              borderRadius: 18,
              textTransform: "none",
              backgroundColor: isInfluencer ? colorInfluencer : colorBrand,
            }}
            onClick={() => onRegister()}
          >
            Créer mon compte
          </Button>
        </Grid>
        <Grid
          item
          container
          xs={10}
          justify="center"
          alignItems="center"
          style={{ marginBottom: 10 }}
        >
          <Grid item>
            <p
              style={{
                fontSize: 10,
                fontFamily: "nunito-semibold",
                color: "#000",
              }}
            >
              En vous inscrivant, vous acceptez les CGU & CGV de Pop
            </p>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={10}
          justify="center"
          alignItems="center"
          style={{ marginBottom: 30 }}
        >
          <Grid item>
            <p
              style={{
                fontSize: 10,
                fontFamily: "nunito-semibold",
                color: "#000",
                display: "inline",
              }}
              onClick={() => {
                history.push("/register");
              }}
            >
              Déjà un compte ?
            </p>
            <p
              style={{
                fontSize: 10,
                fontFamily: "nunito-semibold",
                color: isInfluencer ? colorInfluencer : colorBrand,
                display: "inline",
                marginLeft: 5,
                cursor: "pointer",
              }}
              onClick={() =>
                history.push(`/login/${isInfluencer ? "influencer" : "brand"}`)
              }
            >
              Se connecter
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default Register;

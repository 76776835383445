import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";
import InfluencerProfilePage from "../../../Components/Brand/InfluencerProfilePage/InfluencerProfilePage";

import { CircularProgress } from "@material-ui/core";
import { API_URL } from "../../../common/constants";

const InfluencerProfile = () => {
  const { id } = useParams();
  const [profile, setProfile] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const getProfile = () => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .get(`${API_URL}/brand/profile/influencer/${id}`)
      .then((res) => {
        setProfile(res.data);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    setIsLoading(true);
    getProfile();
  }, []);

  if (isLoading)
    return (
      <div
        style={{
          width: "100%",
          position: "absolute",
          textAlign: "center",
          paddingTop: 50,
        }}
      >
        <CircularProgress style={{ margin: "auto" }} />
      </div>
    );
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`POP App - Influenceur - ${profile.fullname}`}</title>
        <link rel="canonical" href="https://pop-app.fr" />
      </Helmet>
      <InfluencerProfilePage profile={profile} />
    </>
  );
};

export default InfluencerProfile;

import {
  Button,
  Collapse,
  Grid,
  TextField,
  Snackbar,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Paper,
  Chip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import missionStyles from "../styles/missionPageStyles";
import {
  AddOutlined,
  Close,
  CloudUpload,
  Delete,
  ExpandMore,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles(missionStyles);

const MissionBriefs = ({ briefs = [], handleUpdateMissionBriefs }) => {
  const classes = useStyles();

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [editingBrief, setEditingBrief] = useState(0);
  const [currentBriefs, setCurrentBriefs] = useState([]);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile = width <= 768;

  const handleAddBrief = () => {
    setCurrentBriefs([
      ...currentBriefs,
      {
        title: "",
        content: "",
        files: [],
      },
    ]);

    setEditingBrief(currentBriefs.length);
  };

  const handleOnEditBrief = (value, type, index) => {
    const data = currentBriefs.map((brief, key) => {
      if (key === index) {
        return {
          ...brief,
          [type]: value,
        };
      }
      return {
        ...brief,
      };
    });

    setCurrentBriefs(data);
  };

  const handleDeleteBrief = (key) => {
    const briefs = [...currentBriefs];

    briefs.splice(key, 1);
    setCurrentBriefs(briefs);
  };

  useEffect(() => {
    setCurrentBriefs(briefs);
  }, [briefs]);

  return (
    <>
      <Grid
        item
        container
        className={classes.widgetContainer}
        style={{ minHeight: 0 }}
        alignItems="flex-start"
      >
        <Grid item container xs={12} direction="row">
          <p className={classes.widgetTitle}>Mes briefs</p>
          <Button
            style={{ marginLeft: 10 }}
            onClick={() => handleAddBrief()}
            startIcon={<AddOutlined />}
          >
            Ajouter un brief
          </Button>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <p className={classes.widgetTextContent}>
            Créez ou ajoutez vos briefs pour pouvoir les partager avec les
            influenceurs de votre choix.
          </p>
        </Grid>
        {/* <Grid
          item
          container
          xs={12}
          style={{ marginBottom: 40, marginTop: 40 }}
        >
          <Button
            onClick={() => handleAddBrief()}
            startIcon={<AddOutlined />}
            color="primary"
            variant="contained"
          >
            Ajouter un brief
          </Button>
        </Grid> */}

        {currentBriefs.map((brief, key) => {
          return (
            <Accordion
              // style={{ width: "100%", borderRadius: 4, marginTop: 20,  }}
              style={{
                paddingTop: 5,
                paddingBottom: 5,
                width: "100%",
                marginTop: 20,
                borderRadius: 10,
              }}
              className={classes.accordion}
              expanded={editingBrief === key}
              onChange={() => {
                if (editingBrief !== key) {
                  setEditingBrief(key);
                }
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMore
                    onClick={() =>
                      setEditingBrief((editingBrief) =>
                        editingBrief === key ? -1 : key
                      )
                    }
                  />
                }
              >
                {editingBrief !== key ? (
                  <Typography style={{ fontFamily: "nunito-bold" }}>
                    {brief.title?.length > 0
                      ? `${brief.title}`
                      : `Brief ${key + 1}`}
                  </Typography>
                ) : (
                  <TextField
                    fullWidth
                    inputProps={{ style: { padding: "8.5px 10px" } }}
                    id="title"
                    name="title"
                    variant="outlined"
                    label="Nom du brief"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={brief.title}
                    onChange={(e) =>
                      handleOnEditBrief(e.target.value, "title", key)
                    }
                  />
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Grid container xs={12}>
                  {/* <Grid item container xs={12} style={{ marginBottom: 30 }}>
                  <label className={classes.fieldLabel}>Titre du brief</label>
                  <TextField
                    fullWidth
                    inputProps={{ style: { padding: "8.5px 10px" } }}
                    id="title"
                    name="title"
                    variant="outlined"
                    value={brief.title}
                    onChange={(e) =>
                      formik.setFieldValue(
                        `briefs[${key}].title`,
                        e.target.value
                      )
                    }
                  />
                </Grid> */}
                  <Grid item xs={12}>
                    <CKEditor
                      editor={ClassicEditor}
                      data={brief.content}
                      config={{
                        placeholder:
                          "Redigez votre brief ou ajoutez vos propres documents",
                      }}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();

                        handleOnEditBrief(data, "content", key);
                      }}
                      // onBlur={(event, editor) => {
                      //   console.log("Blur.", editor);
                      // }}
                      // onFocus={(event, editor) => {
                      //   console.log("Focus.", editor);
                      // }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 20 }}>
                    <label htmlFor={`contained-button-file-${key}`}>
                      <input
                        className={classes.input}
                        id={`contained-button-file-${key}`}
                        multiple
                        type="file"
                        onChange={(e) => {
                          let files = e.target.files;
                          handleOnEditBrief(
                            [...files, ...(brief?.files ?? [])],
                            "files",
                            key
                          );
                        }}
                      />
                      <Button
                        variant="contained"
                        color="default"
                        className={classes.button}
                        startIcon={<CloudUpload />}
                        component="span"
                      >
                        Ajouter des fichiers
                      </Button>
                    </label>
                    <Grid
                      item
                      container
                      xs={12}
                      justify="flex-around"
                      style={{ marginTop: 20 }}
                    >
                      {Array.from(brief?.files ?? []).map((data, index) => {
                        return (
                          <Grid
                            item
                            key={index}
                            style={{
                              width: isMobile ? "100%" : "flex-content",
                            }}
                          >
                            <Chip
                              label={
                                typeof data === "string"
                                  ? data.replace(
                                      "https://pop-influence.s3.us-east-2.amazonaws.com/briefs/",
                                      ""
                                    )
                                  : data.name
                              }
                              onDelete={() => {
                                const tmpFiles = [...brief.files];
                                tmpFiles.splice(index, 1);
                                // setFiles(tmpFiles);
                                handleOnEditBrief(
                                  [...tmpFiles],
                                  "files",
                                  index
                                );
                              }}
                              style={{ margin: 5, maxWidth: "100%" }}
                              onClick={() => {
                                typeof data === "string" && window.open(data);
                              }}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                    {/* <label className={classes.avatarContainer}>
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          let file = e.target.files[0];
                          setFile(file);
                        }}
                      />
                      <Button
                        variant="contained"
                        color="default"
                        className={classes.button}
                        startIcon={<CloudUpload />}
                      >
                        Ajouter un fichier
                      </Button>
                    </label> */}
                  </Grid>
                  <Grid item container xs={12} justify="space-between">
                    <Grid item xs style={{ marginTop: 40 }}>
                      <Button
                        color="primary"
                        variant="contained"
                        className="button-new-mission"
                        style={{
                          backgroundColor: "rgba(250, 128, 114,1)",
                          borderRadius: 18,
                          fontFamily: "nunito-bold !important",
                        }}
                        onClick={async () => {
                          await handleUpdateMissionBriefs(currentBriefs);
                          setIsSnackbarOpen(true);
                        }}
                      >
                        Enregistrer
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs
                      style={{ marginTop: 40, textAlign: "right" }}
                      justify="flex-end"
                    >
                      <Button
                        variant="outlined"
                        startIcon={<Delete />}
                        style={{
                          border: "1px solid rgba(250, 128, 114,1)",
                          color: "rgba(250, 128, 114,1)",
                          boxShadow: "none",
                          background: "transparent",
                          borderRadius: 18,
                        }}
                        className="button-new-mission"
                        onClick={() => handleDeleteBrief(key)}
                      >
                        Supprimer
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            // <Grid key={key} item container xs={12} style={{ marginBottom: 30 }}>
            //   <Grid item xs={12} style={{ marginBottom: 10 }}>
            //     <Button
            //       onClick={() => setEditingBrief(key)}
            //       style={{ textDecoration: "underline" }}
            //       variant="contained"
            //     >
            //       Brief {key + 1}
            //     </Button>
            //   </Grid>
            //   <Collapse
            //     key={key}
            //     in={key === editingBrief}
            //     timeout="auto"
            //     unmountOnExit
            //     style={{ width: "100%" }}
            //   >
            //     <Grid item container xs={12}>
            //       <Grid item container xs={12} style={{ marginBottom: 30 }}>
            //         <label className={classes.fieldLabel}>Titre du brief</label>
            //         <TextField
            //           fullWidth
            //           inputProps={{ style: { padding: "8.5px 10px" } }}
            //           id="title"
            //           name="title"
            //           variant="outlined"
            //           value={brief.title}
            //           onChange={(e) =>
            //             handleOnEditBrief(e.target.value, "title", key)
            //           }
            //         />
            //       </Grid>

            //       <Grid item xs={12}>
            //         <CKEditor
            //           editor={ClassicEditor}
            //           data={brief.content}
            //           onReady={(editor) => {
            //             // You can store the "editor" and use when it is needed.
            //             console.log("Editor is ready to use!", editor);
            //           }}
            //           onChange={(event, editor) => {
            //             const data = editor.getData();

            //             handleOnEditBrief(data, "content", key);
            //           }}
            //           // onBlur={(event, editor) => {
            //           //   console.log("Blur.", editor);
            //           // }}
            //           // onFocus={(event, editor) => {
            //           //   console.log("Focus.", editor);
            //           // }}
            //         />
            //       </Grid>
            //     </Grid>
            //   </Collapse>
            // </Grid>
          );
        })}
        {/* {currentBriefs.length > 0 && (
          <Grid item xs={12} style={{ marginTop: 40 }}>
            <Button
              color="primary"
              variant="contained"
              className="button-new-mission"
              style={{
                backgroundColor: "rgba(250, 128, 114,1)",
                borderRadius: 18,
                fontFamily: "nunito-bold !important",
              }}
              onClick={async () => {
                await handleUpdateMissionBriefs(currentBriefs);
                setIsSnackbarOpen(true);
              }}
            >
              Enregistrer
            </Button>
          </Grid>
        )} */}
      </Grid>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setIsSnackbarOpen(false)}
          severity="success"
          variant="filled"
        >
          Vos briefs ont bien été sauvegardés !
        </Alert>
      </Snackbar>
    </>
  );
};

export default MissionBriefs;

import { Grid, TextField, Avatar, Switch } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { CloudUpload } from "@material-ui/icons";
import { Camera } from "react-feather";

import { socialNetworkOptions, colourStyles } from "../utils";

import newMissionStyle from "../styles/newMissionStyle";
import Select from "../../../Common/Select/Select";

const useStyles = makeStyles(newMissionStyle);
const MissionInformation = ({ formik }) => {
  const classes = useStyles();
  const [preview, setPreview] = useState(null);

  return (
    <>
      <Grid item xs={12}>
        <label className={classes.socialNetworkLabel}>
          Caractéristique de la campagne
        </label>
        <div className={classes.socialNetworkBorder}></div>
      </Grid>
      <Grid item container direction="column" xs={12}>
        <label className={classes.avatarContainer}>
          <Avatar
            className={classes.missionAvatar}
            src={preview ?? formik.values.thumbnail}
          >
            <Camera style={{ width: 50, height: 50 }} />
          </Avatar>
          <input
            type="file"
            style={{ display: "none" }}
            onChange={(e) => {
              let file = e.target.files[0];
              setPreview(URL.createObjectURL(file));
              formik.setFieldValue("thumbnail", file);
            }}
          />
        </label>
      </Grid>
      <Grid item container direction="column" xs={12}>
        <label className={classes.fieldLabel}>Titre de la campagne</label>
        <TextField
          fullWidth
          inputProps={{ style: { padding: "8.5px 10px" } }}
          id="title"
          name="title"
          variant="outlined"
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />
      </Grid>
      <Grid item container direction="column" xs={12}>
        <label className={classes.fieldLabel}>Décrivez votre campagne</label>
        <p
          style={{
            color: "#888",
            fontStyle: "italic",
            fontSize: 14,
            marginBottom: 20,
          }}
        >
          Donnez simplement envie aux Influenceurs de travailler avec vous sur
          cette campagne ! Vous renseignerez votre brief à l’étape suivante. 
        </p>
        <TextField
          fullWidth
          rows={5}
          multiline
          id="description"
          name="description"
          variant="outlined"
          value={formik.values.description}
          onChange={formik.handleChange}
          error={
            formik.touched.description && Boolean(formik.errors.description)
          }
          helperText={formik.touched.description && formik.errors.description}
        />
      </Grid>
      <Grid item container direction="column" xs={12} sm={12} md={6} lg={6}>
        <label className={classes.fieldLabel}>
          Sur quelle réseaux sociaux voulez-vous effectuer votre campagne ?
        </label>
        <Select
          styles={{ ...colourStyles(false) }}
          isMulti
          name="socialNetworks"
          id="socialNetworks"
          value={formik.values.socialNetworks}
          onChange={(selectedValue) =>
            formik.setFieldValue("socialNetworks", selectedValue)
          }
          options={socialNetworkOptions}
          placeholder="Sur quelles réseaux sociaux ?"
          error={Boolean(formik.errors.socialNetworks)}
          helperText={formik.errors.socialNetworks}
        />
      </Grid>
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        <label
          className={classes.fieldLabel}
          style={{ marginBottom: 0, marginRight: 20 }}
        >
          Campagne de type Gifting ?
        </label>
        <Switch
          checked={formik.values.isGifting}
          onChange={(e) => formik.setFieldValue("isGifting", e.target.checked)}
        />
      </Grid>
    </>
  );
};

export default MissionInformation;

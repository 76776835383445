import MissionPage from "../pages/Influencer/Missions/MissionPage";
import MissionsDashboardWrapper from "../pages/Influencer/Missions/MissionsDashboard";
import SettingsPage from "../pages/Influencer/Settings/SettingsPage";
import ProfilePage from "../pages/Influencer/Profile/ProfilePage";

const mainRoutes = [
  {
    path: "/influencer/profile",
    name: "Dashboard",
    displayName: false,
    exact: false,
    component: ProfilePage,
  },
  {
    path: "/influencer/missions/:id",
    name: "Missions",
    displayName: false,
    exact: false,
    component: MissionPage,
  },
  {
    path: "/influencer/missions",
    name: "Mes missions",
    displayName: true,
    exact: true,
    component: MissionsDashboardWrapper,
  },
  // {
  //   path: "/influencer/messages",
  //   name: "Messagerie",
  //   exact: false,
  //   component: MessagesWrapper,
  // },
  {
    path: "/influencer/settings/profile",
    name: "Réglages",
    displayName: true,
    exact: false,
    component: SettingsPage,
  },
  {
    path: "/influencer/settings/withdraw",
    name: "Réglages",
    displayName: true,
    exact: false,
    component: SettingsPage,
  },
  {
    path: "/influencer/settings/company-information",
    name: "Réglages",
    displayName: true,
    exact: false,
    component: SettingsPage,
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/influencer/profile",
    name: "Profile Influenceur",
  },
];

export default mainRoutes;

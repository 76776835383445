import React from "react";
import { withRouter } from "react-router";
import { Grid, Button, Tooltip, Popover, makeStyles } from "@material-ui/core";
import { Person, Business } from "@material-ui/icons";

import authStyles from "./styles/login";

const useStyles = makeStyles(authStyles);

const SelectRole = withRouter(({ history, type }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <Grid
      container
      justify="space-around"
      alignItems="center"
      xs={12}
      style={{ textAlign: "center", marginTop: 150 }}
    >
      <Grid item xs={12} style={{ marginBottom: 100 }}>
        <p
          style={{
            fontSize: 34,
            fontFamily: "nunito-bold",
            color: "#485481",
          }}
        >
          {type === "login" ? "Connectez-vous" : "Inscrivez-vous"}
        </p>
      </Grid>

      <Grid item xs={11} sm={11} md={3} lg={3}>
        <Button
          style={{
            fontFamily: "nunito-bold",
            fontSize: 20,
            color: "#000",
            width: "100%",
            height: 70,
            background: "#FFF",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
            borderRadius: 4,
            textTransform: "none",
            marginBottom: 80,
          }}
          startIcon={
            <Person
              style={{
                background: "rgba(130, 93, 255, 0.4)",
                padding: 5,
                marginRight: 10,
                borderRadius: 4,
                color: "rgba(130, 93, 255, 1)",
                fill: "rgba(130, 93, 255, 1)",
              }}
            />
          }
          className={classes.selectButton}
          onClick={() => history.push(`/${type}/influencer`)}
        >
          Je suis un Influenceur
        </Button>
      </Grid>
      <Grid item xs={11} sm={11} md={3} lg={3}>
        <Button
          style={{
            fontFamily: "nunito-bold",
            fontSize: 20,
            color: "#000",
            width: "100%",
            height: 70,
            marginBottom: 70,
            background: "#FFF",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
            borderRadius: 4,
            textTransform: "none",
          }}
          startIcon={
            <Business
              style={{
                background: "rgba(250, 128, 114, 0.4)",
                padding: 5,
                borderRadius: 4,
                marginRight: 10,
                color: "rgba(250, 128, 114, 1)",
                fill: "rgba(250, 128, 114, 1)",
              }}
            />
          }
          // TODO: for brand
          onClick={() => history.push(`/${type}/brand`)}
          // onClick={handleClick}
          className={classes.selectButton}
        >
          Je suis une Marque
        </Button>
        {/* <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          style={{
            root: (base) => ({
              ...base,
              borderRadius: 18,
            }),
            paper: (base) => ({
              ...base,
              borderRadius: 18,
            }),
          }}
        >
          <p
            style={{
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: "nunito-regular",
              fontSize: 16,
              fontFamily: "nunito-regular",
              padding: 10,
            }}
          >
            Cette section ne sera ouverte qu'à partir du jour de lancement
            officiel de POP aux marques.
          </p>
        </Popover> */}
      </Grid>
    </Grid>
  );
});

export default SelectRole;

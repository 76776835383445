import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";

import {
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuItem,
  MenuList,
} from "@material-ui/core";

import appLayoutStyle from "../styles/appLayout";
import axios from "axios";
import { API_URL } from "../../common/constants";

const useStyles = makeStyles(appLayoutStyle);

const ProfileMenu = ({
  token,
  isProfileMenuOpen,
  handleClose,
  anchorRef,
  handleListKeyDown,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [profile, setProfile] = useState(undefined);

  const getTotalMoney = (profile) => {
    const money = profile?.doneMissions?.reduce((acc, cur) => {
      if (!cur.isPaid) {
        return acc + cur.influencerMission.acceptedOffer.price;
      }
      return acc + 0;
    }, 0);

    return money;
  };

  useEffect(() => {
    const getProfile = () => {
      axios.defaults.headers.common["Authorization"] = token;
      axios
        .get(`${API_URL}/influencer/profile`)
        .then((res) => {
          const totalMoney = getTotalMoney(res.data);
          setProfile({ ...res.data, totalMoney });
        })
        .catch((error) => console.error(error));
    };

    getProfile();
  }, [isProfileMenuOpen, token]);

  return (
    <Popper
      open={isProfileMenuOpen}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      style={{ zIndex: 1000, marginRight: 50 }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                style={{ minWidth: 250 }}
                autoFocusItem={isProfileMenuOpen}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
              >
                <NavLink
                  to={`/${
                    localStorage.getItem("isInfluencer") === "true"
                      ? "influencer"
                      : "brand"
                  }/settings/profile`}
                  style={{ textDecoration: "none" }}
                >
                  <MenuList
                    onClick={handleClose}
                    style={{ textAlign: "left", padding: 20 }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p
                        style={{
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        {profile?.fullname}
                      </p>
                      <span
                        className={
                          profile.isActive
                            ? classes.availabilityDotActive
                            : classes.availabilityDotNotActive
                        }
                      ></span>
                    </div>
                    {/* <p style={{ fontWeight: 400, fontSize: 12 }}>
                      Cagnotte en cours: {profile?.totalMoney || 0} €
                    </p> */}
                  </MenuList>
                </NavLink>
                <NavLink
                  style={{
                    color: "#000",
                    textDecoration: "none",
                  }}
                  to={`/${
                    localStorage.getItem("isInfluencer") === "true"
                      ? "influencer"
                      : "brand"
                  }/settings/withdraw`}
                >
                  <MenuItem onClick={handleClose}>
                    Cagnotte en cours: {profile?.totalMoney || 0} €
                  </MenuItem>
                </NavLink>
                <MenuItem
                  onClick={(e) => {
                    handleClose(e);
                    localStorage.removeItem("token");
                    history.push("/");
                  }}
                >
                  Se déconnecter
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default ProfileMenu;

// ##############################
// // // App styles
// #############################

const appStyle = (theme) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    "&:after": {
      display: "table",
      clear: "both",
      content: '" "',
    },
  },
  popLogo: {
    marginLeft: 50,
    flexGrow: 1,
  },
  borderHeader: {
    // height: 80,
    // opacity: 0.7,
    overflow: "hidden",
    "&:after": {
      content: '""',
      background: "rgba(130,93,255,0.7)",
      color: "#FFF",
      width: "200%",
      height: "95%",
      position: "absolute",
      transform: "rotate(-4deg)",
      marginLeft: "-30px",
      marginTop: "-100px",
    },
    // "&:before": {
    //   content: '""',
    //   background: "rgba(130,93,255,0.7)",
    //   color: "#FFF",
    //   width: "200%",
    //   height: "60%",
    //   position: "absolute",
    //   transform: "rotate(-4deg)",
    //   marginLeft: "-30px",
    //   marginTop: "-100px",
    // },
    // background:
    //   "linear-gradient(90deg, rgba(254,200,90,1) 13%, rgba(252,48,161,1) 32%, rgba(130,93,255,1) 60%, rgba(252,48,161,1) 75%, rgba(254,200,90,1) 100%)",
  },
  borderHeaderBrand: {
    // height: 80,
    // opacity: 0.7,
    overflow: "hidden",
    "&:after": {
      content: '""',
      color: "#FFF",
      background: "rgba(250, 128, 114,0.7)",
      width: "200%",
      height: "95%",
      position: "absolute",
      transform: "rotate(-4deg)",
      marginLeft: "-30px",
      marginTop: "-100px",
    },
    // "&:before": {
    //   content: '""',
    //   color: "#FFF",
    //   background: "rgba(250, 128, 114,0.7)",
    //   width: "200%",
    //   height: "60%",
    //   position: "absolute",
    //   transform: "rotate(-4deg)",
    //   marginLeft: "-30px",
    //   marginTop: "-100px",
    // },
    // background:
    //   "linear-gradient(90deg, rgba(254,200,90,1) 13%, rgba(252,48,161,1) 32%, rgba(130,93,255,1) 60%, rgba(252,48,161,1) 75%, rgba(254,200,90,1) 100%)",
  },
  root: {
    minHeight: "100%",
    alignItems: "flex-start",
    flexGrow: 1,
    background: "#FFF",
    position: "relative",
    // background: "rgb(115,59,224)",
    // background: "linear-gradient(0deg, #EDE6FA 0%, #FEE8E5 60%)",
  },
  title: {
    flexGrow: 1,
  },
  menuText: {
    textAlign: "center",
  },
  menuItem: {
    justifyContent: "center",
    textAlign: "center",
    // borderBottom: "2px solid transparent",
    cursor: "pointer",
    fontFamily: "nunito-semibold",
    paddingTop: 4,
  },
  menuLink: {
    textDecoration: "none",
    borderBottom: "4px solid transparent",
    fontFamily: "nunito-bold",
    "&:hover": {
      color: "rgba(36, 28, 76, 0.75) !important",
      borderBottom: "4px solid rgba(130, 93, 255,1)",
      borderRadius: 4,
    },
    height: "100%",

    position: "relative",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    border: 0,
    backgroundColor: "transparent",
    color: "#333330",
    whiteSpace: "nowrap",
    fontWeight: 400,
    fontSize: 15,
    lineHeight: 1.25,
  },
  selectedMenuItem: {
    borderBottom: "4px solid rgba(130, 93, 255,1)",
  },
  menuLinkBrand: {
    textDecoration: "none",
    borderBottom: "4px solid transparent",
    "&:hover": {
      color: "rgba(36, 28, 76, 0.75) !important",
      borderBottom: "4px solid rgba(250, 128, 114,1)",
      borderRadius: 4,
    },
    height: "100%",

    position: "relative",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    border: 0,
    backgroundColor: "transparent",
    color: "#333330",
    whiteSpace: "nowrap",
    fontFamily: "Moderat,Arial,Helvetica,sans-serif",
    fontWeight: 400,
    fontSize: 15,
    lineHeight: 1.25,
  },
  selectedMenuItemBrand: {
    borderBottom: "4px solid rgba(250, 128, 114,1)",
  },
  menuItemSelected: {
    justifyContent: "center",
    textAlign: "center",
    cursor: "pointer",
    color: "#000",
    // borderBottom: "2px solid rgba(130, 93, 255,1)"
  },
  menuButton: {
    marginRight: theme.spacing(2),
    padding: 0,
    textAlign: "center",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  content: {
    // width: 1200,
    margin: "auto",
    backgroundColor: "#f7f7f5",
    "& > div > div": {
      zIndex: 1,
      position: "relative",
    },
  },
  flexContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    padding: 0,
  },
  headerText: {
    "& span": {
      fontSize: 12.5,
      fontFamily: "nunito-semibold",
      color: "#241C4C",
    },
  },
  cardContainer: {
    cursor: "pointer",
  },
  container: {
    "& > div ": {
      zIndex: 1,
    },
  },
  selectedCard: {
    backgroundColor: "rgba(130, 93, 255)",
    cursor: "pointer",
  },
  cardImage: {
    width: "100%",
    // height: "100%",
    // width: "250px",
    height: "100%",
    borderRadius: 2,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  },
  headerContainer: {
    "&:after": {
      content: '""',
      width: "200%",
      height: "60%",
      position: "absolute",
      transform: "rotate(-4deg)",
      marginLeft: "-30px",
      marginTop: "-100px",
    },
    overflow: "hidden",
  },
  availabilityDotActive: {
    width: 6,
    height: 6,
    borderRadius: "50%",
    borderStyle: "solid",
    borderColor: "#76CD99",
    backgroundColor: "#76CD99",
    marginLeft: 15,
    marginBottom: 5,
    marginTop: "auto",
  },
  availabilityDotNotActive: {
    width: 6,
    height: 6,
    borderRadius: "50%",
    borderStyle: "solid",
    borderColor: "#ff5c57",
    backgroundColor: "#ff5c57",
    marginLeft: 15,
    marginBottom: 5,
  },
  badgeColorInfluencer: {
    backgroundColor: "rgba(130,93,255,0.7)",
  },
  badgeColorBrand: {
    backgroundColor: "rgba(250, 128, 114, 0.7)",
  },
});

export default appStyle;

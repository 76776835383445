import { Avatar, Button, Chip, Grid, Hidden, Tooltip } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { IoLocationOutline, IoPeopleOutline } from "react-icons/io5";
import { useHistory } from "react-router";
import { categoriesOptions } from "../../../Brand/NewMission/utils";
import { getFormattedSocialNetworks } from "../../../Influencer/InfluencerProfilePage/PersonalInformation/utils";
import FollowProcessModal from "../modals/FollowProcessModal";
import SendBriefsModal from "../modals/SendBriefsModal";

import messageStyle from "../styles/messagesStyle";

const useStyles = makeStyles(messageStyle);

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    fontFamily: "nunito-semibold",
    fontSize: 13,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
  },
}))(Tooltip);

const ContactHeader = ({
  selectedInfluencer,
  setIsNewOfferModalOpen,
  influencerMission,
  handleRefreshFollowProcess,
  mission,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [isFollowModalOpen, setIsFollowModalOpen] = useState(false);
  const [isSendBriefsModalOpen, setIsSendBriefsModalOpen] = useState(false);

  let minPrice = Math.min(
    ...influencerMission?.influencerId?.socialNetworks?.map((network) => {
      return network.price;
    })
  );

  if (
    typeof minPrice !== "number" ||
    Number.isNaN(minPrice) ||
    !Number.isFinite(minPrice)
  ) {
    minPrice = 0;
  }

  return (
    <>
      <Grid
        item
        container
        xs={12}
        alignItems="flex-start"
        style={{ maxHeight: 200 }}
      >
        <Grid
          item
          container
          xs={12}
          className={classes.influencerItem}
          alignItems="center"
        >
          <Grid
            item
            xs={2}
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push(
                `/brand/missions/influencer-profile/${influencerMission?.influencerId?._id}`
              )
            }
          >
            <Avatar
              className={classes.userHeaderAvatar}
              src={selectedInfluencer.avatar}
            ></Avatar>
          </Grid>
          <Grid
            item
            container
            alignItems="flex-start"
            xs={10}
            style={{
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <Grid
              item
              container
              xs={6}
              sm={6}
              md={4}
              lg={4}
              className={classes.messagesHeaderInfluencerInfo}
            >
              <Grid
                item
                xs={12}
                style={{ paddingBottom: 5, cursor: "pointer" }}
                onClick={() =>
                  history.push(
                    `/brand/missions/influencer-profile/${influencerMission?.influencerId?._id}`
                  )
                }
              >
                <p className={classes.userName}>
                  {selectedInfluencer.fullname}
                </p>
              </Grid>
              {getFormattedSocialNetworks(
                selectedInfluencer.socialNetworks
              ).map((socialNetwork) => {
                return (
                  <Grid
                    item
                    container
                    xs={12}
                    key={socialNetwork.value}
                    style={{ marginTop: 5, cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        `${socialNetwork.link}${socialNetwork.username}/`
                      )
                    }
                  >
                    {socialNetwork.iconWithoutChip}
                    <p className={classes.separator}> | </p>
                    <IoPeopleOutline />
                    <p className={classes.influencerContent}> 150K </p>
                  </Grid>
                );
              })}
              {/* <Grid item container xs={12}>
                    <IoLogoInstagram />
                    <p className={classes.separator}> | </p>
                    <IoPeopleOutline />
                    <p className={classes.influencerContent}> 150K </p>
                  </Grid> */}
              <Grid item container xs={12} style={{ marginTop: 5 }}>
                <p className={classes.influencerContent}>
                  Tarif minimum: {minPrice} €
                </p>
                {/* <IoLocationOutline />
                <p className={classes.influencerContent}>75017 Paris</p> */}
              </Grid>
            </Grid>
            <Grid item container xs={6} sm={6} md={8} lg={8}>
              <Hidden only={["sm", "xs"]}>
                <Grid item container xs={12}>
                  {selectedInfluencer.categories.slice(0, 3).map((category) => (
                    <Grid item xs={4} style={{ padding: 10 }} key={category}>
                      <Chip
                        style={{
                          width: "90%",
                          backgroundColor: "rgba(130,93,255,0.6)",
                          color: "#FFF",
                        }}
                        label={
                          categoriesOptions.find(
                            (opt) => opt.value === category
                          ).label
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </Hidden>
              <Grid
                item
                container
                alignItems="center"
                style={{
                  marginTop: 10,
                }}
              >
                {influencerMission.status !== "PAID" && (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <LightTooltip
                      title="Il faut faire une offre à l’influenceur pour acter le placement de produit et procéder au paiement de celle-ci"
                      interactive
                    >
                      <Button
                        variant="contained"
                        type="primary"
                        style={{
                          fontSize: 12,
                          textTransform: "none",
                          background: "rgba(250, 128, 114,1)",
                          color: "#FFF",
                          borderRadius: 18,
                        }}
                        onClick={() => setIsNewOfferModalOpen(true)}
                      >
                        Faire une offre
                      </Button>
                    </LightTooltip>
                  </Grid>
                )}
                {influencerMission.status === "PAID" && (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Button
                      variant="contained"
                      type="secondary"
                      style={{
                        fontSize: 12,
                        textTransform: "none",
                        background: "rgba(250, 128, 116,1)",
                        color: "#FFF",
                        borderRadius: 18,
                      }}
                      onClick={() => setIsFollowModalOpen(true)}
                    >
                      Voir le suivi
                    </Button>
                  </Grid>
                )}
                {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Button
                    variant="contained"
                    type="primary"
                    className={classes.buttonSendBriefs}
                    onClick={() => setIsSendBriefsModalOpen(true)}
                  >
                    Envoyer les briefs
                  </Button>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FollowProcessModal
        isModalOpen={isFollowModalOpen}
        handleCloseModal={setIsFollowModalOpen}
        influencerMissionId={influencerMission._id}
        followProcess={influencerMission.followProcess}
        handleRefreshFollowProcess={handleRefreshFollowProcess}
        influencerName={selectedInfluencer.fullname}
        missionId={mission._id}
      />
      <SendBriefsModal
        isModalOpen={isSendBriefsModalOpen}
        handleCloseModal={setIsSendBriefsModalOpen}
        briefs={mission.briefs}
        missionId={mission._id}
        influencerMissionId={influencerMission._id}
        influencerId={influencerMission.influencerId._id}
        influencerBriefs={influencerMission.briefs}
      />
    </>
  );
};

export default ContactHeader;

// ####################################
// // // Messages Page styles
// ###################################

const messagesStyle = (theme) => ({
  pageLayout: {
    height: "100%",
    margin: "auto",
    backgroundColor: "#FFF",
    borderRadius: 6,
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
  contactListing: {
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    paddingTop: 30,
    maxHeight: "100%",
    overflow: "auto",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.25)",
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  listing: {
    marginTop: 20,
    // padding: 5,
  },
  contactItem: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.14)",
    minHeight: 80,
    cursor: "pointer",
    "&:hover": {
      borderLeft: "4px solid rgba(250, 128, 114,1)",
    },
    "&:last-child": {
      borderBottom: "none",
    },
  },
  contactItemSelected: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.14)",
    minHeight: 80,
    borderLeft: "4px solid rgba(250, 128, 114,1)",
    cursor: "pointer",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  userAvatar: {
    width: 50,
    height: 50,
    margin: "auto",
    "& img": {
      objectFit: "cover !important",
    },
  },
  userName: {
    fontSize: 18,
    fontWeight: 500,
  },
  messageContent: {
    fontSize: 12,
    color: "rgba(0, 0, 0, 0.7)",
  },
  inputContainer: {
    border: "1px solid rgba(0, 0, 0, 0.14)",
    minHeight: 60,
    position: "sticky",
    bottom: 0,
    // marginTop: 20,
  },
  messageInput: {
    width: "90%",
    height: 30,
    borderRadius: 10,
    paddingLeft: 10,
    background: "rgba(0, 0, 0, 0.1)",
    border: "none",
    "&:focus": {
      outline: "none",
    },
  },
  userAvatarMessage: {
    width: 40,
    height: 40,
    margin: "auto",
    marginRight: 0,
  },
  leftMessageBrief: {
    float: "left",
    margin: 10,
    minHeight: 30,
    padding: "10px 15px",
  },
  leftMessage: {
    background: "#f4f4f8",
    color: "#000",
    borderRadius: 14,
    float: "left",
    margin: 10,
    minHeight: 30,
    padding: "10px 15px",
  },
  rightMessageInfluencer: {
    background: "rgba(130,93,255,0.7)",
    color: "#FFF",
    borderRadius: 14,
    float: "right",
    margin: 10,
    minHeight: 30,
    padding: "7px 12px",
  },
  rightMessageBrand: {
    background: "rgba(130,93,255,0.7)",
    color: "#FFF",
    borderRadius: 14,
    float: "right",
    margin: 10,
    minHeight: 30,
    padding: "7px 12px",
  },
  offer: {
    border: "1px solid rgba(130,93,255,0.7)",
    color: "#000",
    borderRadius: 6,
    float: "right",
    margin: 10,
    minHeight: 30,
    padding: "7px 12px",
  },
  offerTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginRight: 10,
  },
  offerDescription: {
    fontSize: 12,
    fontWeight: 400,
    paddingTop: 5,
  },
  offerPrice: {
    fontSize: 14,
    fontWeight: 600,
  },
  userDiscussionMessage: {
    fontSize: 14,
    fontFamily: "nunito-semibold",
  },
  sendButtonInfluencer: {
    backgroundColor: "rgba(130,93,255,1)",
    "&:hover": {
      backgroundColor: "rgba(130,93,255,0.6)",
    },
  },
  sendButtonBrand: {
    backgroundColor: "rgba(250, 128, 114,1)",
    "&:hover": {
      backgroundColor: "rgba(250, 128, 114,0.6)",
    },
  },
  listTitle: {
    width: "100%",
    textAlign: "center",
    borderBottom: "1px solid #484848",
    color: "#484848",
    lineHeight: "0.1em",
    margin: "0px 0 20px",
    "& span": {
      background: "#FFF",
      padding: "0 10px",
    },
  },
  customChip: {
    borderRadius: 4,
    padding: 5,
    fontSize: 14,
    textAlign: "center",
    color: "#FFF",
    backgroundColor: "rgba(250, 128, 114,1)",
  },

  //Influencer header
  influencerItem: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.14)",
    minHeight: 80,
  },
  userHeaderAvatar: {
    width: 50,
    height: 50,
    margin: "auto",
    "& img": {
      objectFit: "cover !important",
    },
  },
  influencerContent: {
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.7)",
  },
  separator: {
    fontSize: 12,
    fontWeight: 600,
    color: "rgba(0, 0, 0, 1)",
    paddingLeft: 10,
    paddingRight: 10,
  },
  messagesHeaderInfluencerInfo: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: 10,
    },
  },
  buttonSendBriefs: {
    fontSize: 12,
    textTransform: "none",
    background: "transparent",
    border: "1px solid rgba(250, 128, 114,1)",
    color: "rgba(250, 128, 114,1)",
    borderRadius: 18,
    boxShadow: "none",
    [theme.breakpoints.up("md")]: {
      marginLeft: 8,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 10,
    },
    "&:hover": {
      background: "rgba(250, 128, 114,0.3)",
      color: "#000",
      boxShadow: "none",
    },
  },
  customChipRounded: {
    borderRadius: 18,
    padding: 3,
    fontSize: 14,
    textAlign: "center",
    color: "#FFF",
    backgroundColor: "rgba(250, 128, 114,1)",
    fontFamily: "nunito-bold",
    padding: "5px 30px 5px 30px",
    flexGrow: "initial",
  },
});

export default messagesStyle;

import React from "react";
import { Grid, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import profileStyle from "../styles/profilePage";

const useStyles = makeStyles(profileStyle);

const SavedInfluencers = ({ profile }) => {
  const classes = useStyles();
  return (
    <Grid item container className={classes.widgetContainer}>
      <Grid item xs={12}>
        <p className={classes.widgetTitle}>Influenceurs sauvegardés</p>
      </Grid>
      {profile.savedInfluencers.map((influencer, key) => (
        <Grid
          key={key}
          item
          container
          xs={12}
          className={classes.contactItem}
          alignItems="center"
          onClick={() =>
            window.open(`/brand/missions/influencer-profile/${influencer._id}`)
          }
        >
          <Grid item xs={3}>
            <Avatar
              className={classes.userAvatar}
              src={influencer.avatar}
            ></Avatar>
          </Grid>
          <Grid item container xs={9} alignItems="center">
            <Grid item xs={6}>
              <p className={classes.userName}>{influencer.fullname}</p>
            </Grid>
          </Grid>
        </Grid>
      ))}
      {profile.savedInfluencers.length === 0 && (
        <p>Vous n'avez aucun influenceurs sauvegardés.</p>
      )}
    </Grid>
  );
};

export default SavedInfluencers;

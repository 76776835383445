import { Button, Chip, CircularProgress, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";

import missionPageStyle from "../styles/missionPageStyles";
import {
  getFormattedActivities,
  getFormattedSocialNetworks,
} from "../../../../Influencer/InfluencerProfilePage/PersonalInformation/utils";
import { genderOptions } from "../../../NewMission/utils";
import { Delete, PeopleAltOutlined } from "@material-ui/icons";
import DeleteCampaignModal from "./DeleteCampaignModal";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../../../common/constants";
import { Gift } from "react-feather";

const useStyles = makeStyles(missionPageStyle);

const MissionDetails = ({
  mission = undefined,
  influencers = [],
  isInfluencer,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [isOnDeleteModalOpen, setIsOnDeleteModalOpen] = useState(false);

  const getPeopleTouchedByCampaign = (influencerMissions) => {
    const data = influencerMissions.map((influencer) => {
      return influencer?.acceptedOffer?.socialNetworks.map((social) => {
        const followers = influencer.influencerId.socialNetworks?.find(
          (network) => {
            return network.socialNetwork === social;
          }
        );
        return followers?.followers || 0;
      });
    });
    return data.flat().reduce((acc, cur) => acc + cur, 0);
  };

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile = width <= 768;

  function handleOnDeleteCampaign() {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .patch(`${API_URL}/brand/missions/${mission._id}/archived`)
      .then((res) => {
        history.push("/brand/missions");
      });
  }

  if (Object.keys(mission).length === 0) return <CircularProgress />;
  return (
    <Grid
      item
      container
      direction="column"
      className={classes.widgetContainer}
      style={{ marginBottom: 20 }}
      alignItems="flex-start"
    >
      {isInfluencer && (
        <Grid item container>
          <Grid item xs={12}>
            <p className={classes.widgetTitle}>{mission.title}</p>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 20 }}>
            <p className={classes.widgetTextContent}>{mission.description}</p>
          </Grid>
        </Grid>
      )}
      {isInfluencer && (
        <Grid item container justify="flex-start" style={{ marginTop: 20 }}>
          {mission.socialNetworks.some(
            (socialNetwork) => socialNetwork.prices
          ) && (
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              className={classes.statisticsSection}
              justify="flex-start"
              alignItems="center"
              style={{
                padding: 10,
                backgroundColor: "#ffffff",
                boxShadow: "0 5px 9px -4px rgba(139, 142, 159, 0.6)",
                borderRadius: 20,
                // width: isMobile ? "100%" : "fit-content",
                // flexBasis: isMobile ? "auto" : 0,
              }}
            >
              <Grid item xs={3}>
                <PeopleAltOutlined />
              </Grid>
              <Grid
                item
                container
                xs={9}
                justify="flex-start"
                style={{ textAlign: "left" }}
              >
                <Grid item xs={12}>
                  <p className={classes.statisticsTitle}>Budget max</p>
                </Grid>
                <Grid item xs={12}>
                  <p
                    style={{
                      fontFamily: "nunito-bold",
                      fontSize: 32,
                      color: "#083671",
                    }}
                  >
                    {Math.max(
                      ...mission?.socialNetworks?.map((network) => {
                        return network?.prices?.max ?? 0;
                      })
                    )}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          )}
          {mission.isGifting && (
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              container
              className={classes.statisticsSection}
              justify="flex-start"
              alignItems="center"
              style={{
                padding: 10,
                backgroundColor: "#ffffff",
                boxShadow: "0 5px 9px -4px rgba(139, 142, 159, 0.6)",
                borderRadius: 20,
                marginLeft:
                  isMobile ||
                  !mission.socialNetworks.some(
                    (socialNetwork) => socialNetwork.prices
                  )
                    ? 0
                    : 30,
                marginTop: isMobile ? 30 : 0,
                // width: isMobile ? "100%" : "fit-content",
                // flexBasis: isMobile ? "auto" : 0,
              }}
            >
              <Grid item xs={3}>
                <Gift />
              </Grid>
              <Grid
                item
                container
                xs={9}
                justify="flex-start"
                style={{ textAlign: "left" }}
              >
                <Grid item xs={12}>
                  <p className={classes.statisticsTitle}>Type</p>
                </Grid>
                <Grid item xs={12}>
                  <p
                    style={{
                      fontFamily: "nunito-bold",
                      fontSize: 32,
                      color: "#083671",
                    }}
                  >
                    Gifting
                  </p>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      {!isInfluencer && (
        <Grid item container justify="flex-start" style={{ marginTop: 20 }}>
          <Grid
            item
            container
            // xs={12}
            // sm={12}
            // md={4}
            // lg={4}
            // xl={4}
            xs
            className={classes.statisticsSection}
            justify="flex-start"
            alignItems="center"
            style={{
              padding: 10,
              backgroundColor: "#ffffff",
              boxShadow: "0 5px 9px -4px rgba(139, 142, 159, 0.6)",
              borderRadius: 20,
              width: isMobile ? "100%" : "fit-content",
              flexBasis: isMobile ? "auto" : 0,
            }}
          >
            <Grid item xs={3}>
              <PeopleAltOutlined />
            </Grid>
            <Grid
              item
              container
              xs={9}
              justify="flex-start"
              style={{ textAlign: "left" }}
            >
              <Grid item xs={12}>
                <p className={classes.statisticsTitle}>Influenceurs en cours</p>
              </Grid>
              <Grid item xs={12}>
                <p
                  style={{
                    fontFamily: "nunito-bold",
                    fontSize: 32,
                    color: "#083671",
                  }}
                >
                  {
                    influencers.filter(
                      (influencer) =>
                        influencer.influencerStatus === "DOING" ||
                        influencer.influencerStatus === "DONE"
                    ).length
                  }
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            // xs={12}
            // sm={12}
            // md={4}
            // lg={4}
            // xl={4}
            xs
            container
            className={classes.statisticsSection}
            justify="flex-start"
            alignItems="center"
            style={{
              padding: 10,
              backgroundColor: "#ffffff",
              boxShadow: "0 5px 9px -4px rgba(139, 142, 159, 0.6)",
              borderRadius: 20,
              marginLeft: isMobile ? 0 : 30,
              marginTop: isMobile ? 30 : 0,
              width: isMobile ? "100%" : "fit-content",
              flexBasis: isMobile ? "auto" : 0,
            }}
          >
            <Grid item xs={3}>
              <PeopleAltOutlined />
            </Grid>
            <Grid
              item
              container
              xs={9}
              justify="flex-start"
              style={{ textAlign: "left" }}
            >
              <Grid item xs={12}>
                <p className={classes.statisticsTitle}>Somme engagé</p>
              </Grid>
              <Grid item xs={12}>
                <p
                  style={{
                    fontFamily: "nunito-bold",
                    fontSize: 32,
                    color: "#083671",
                  }}
                >
                  {`${new Intl.NumberFormat("fr-FR", {
                    notation: "compact",
                    compactDisplay: "short",
                  }).format(
                    influencers.reduce((acc, cur) => {
                      return acc + (cur?.acceptedOffer?.price || 0);
                    }, 0)
                  )}€`}
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            // xs={12}
            // sm={12}
            // md={4}
            // lg={4}
            // xl={4}
            xs
            className={classes.statisticsSection}
            justify="flex-start"
            alignItems="center"
            style={{
              padding: 10,
              backgroundColor: "#ffffff",
              boxShadow: "0 5px 9px -4px rgba(139, 142, 159, 0.6)",
              borderRadius: 20,
              marginLeft: isMobile ? 0 : 30,
              marginTop: isMobile ? 30 : 0,
              width: isMobile ? "100%" : "fit-content",
              flexBasis: isMobile ? "auto" : 0,
            }}
          >
            <Grid item xs={3}>
              <PeopleAltOutlined />
            </Grid>
            <Grid
              item
              container
              xs={9}
              justify="flex-start"
              style={{ textAlign: "left" }}
            >
              <Grid item xs={12}>
                <p className={classes.statisticsTitle}>Personnes touchés</p>
              </Grid>
              <Grid item xs={12}>
                <p
                  style={{
                    fontFamily: "nunito-bold",
                    fontSize: 32,
                    color: "#083671",
                  }}
                >
                  {`${new Intl.NumberFormat("fr-FR", {
                    notation: "compact",
                    compactDisplay: "short",
                  }).format(
                    getPeopleTouchedByCampaign(
                      influencers.filter(
                        (influencer) =>
                          influencer.influencerStatus === "DOING" ||
                          influencer.influencerStatus === "DONE"
                      )
                    )
                  )}`}
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item container style={{ marginTop: 20 }}>
        {!isInfluencer && (
          <p className={classes.widgetTitle} style={{ marginTop: 20 }}>
            Résumé de votre campagne
          </p>
        )}
        <Grid item xs={12}>
          <p className={classes.detailCategory}>
            {isInfluencer
              ? "Caractéristiques de l'influenceur recherché par la marque"
              : "Caractéristiques de l'influenceur"}
          </p>
        </Grid>
        <Grid
          item
          container
          xs={12}
          style={{ marginTop: 20 }}
          justify="space-between"
        >
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={5}
            lg={5}
            className={classes.influencerInfos}
            justify="center"
          >
            <Grid item xs={12}>
              <p className={classes.cardTitle}>Catégories</p>
            </Grid>
            {getFormattedActivities(
              mission.influencerCaracteristics.categories
            ).map((category) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                lg={5}
                style={{ padding: 10 }}
                key={category.value}
              >
                <Chip
                  style={{
                    width: "90%",
                    backgroundColor: isInfluencer
                      ? "rgba(130,93,255,1)"
                      : "rgba(250, 128, 114,1)",
                    color: "#FFF",
                  }}
                  label={category.label}
                />
              </Grid>
            ))}
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={5}
            lg={5}
            className={classes.influencerInfos}
          >
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <p className={classes.cardTitle}>Age</p>
            </Grid>
            <Grid item xs={6}>
              <p>Min: {mission.influencerCaracteristics.age.min}</p>
            </Grid>
            <Grid item xs={6}>
              <p>Max: {mission.influencerCaracteristics.age.max}</p>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={5}
            lg={5}
            className={classes.influencerInfos}
          >
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <p className={classes.cardTitle}>Genre</p>
            </Grid>
            {mission.influencerCaracteristics.gender.map((gender, key) => {
              const genderLabel = genderOptions.find(
                (opt) => opt.value === gender
              );
              return (
                <Grid item xs={12} key={key}>
                  <p>{genderLabel.label}</p>
                </Grid>
              );
            })}
          </Grid>
          {/* <Grid
            item
            container
            xs={12}
            sm={12}
            md={5}
            lg={5}
            className={classes.influencerInfos}
          >
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <p className={classes.cardTitle}>Localisation</p>
            </Grid>
            <Grid item xs={12}>
              <p>{mission.influencerCaracteristics.location}</p>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
      {mission?.target && (
        <Grid item container style={{ marginTop: 20 }}>
          <Grid item xs={12}>
            <p className={classes.detailCategory}>
              {isInfluencer
                ? "Caractéristiques de la cible recherché par la marque"
                : "Caractéristiques de la cible"}
            </p>
          </Grid>
          <Grid
            item
            container
            xs={12}
            style={{ marginTop: 20 }}
            justify="space-between"
          >
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={5}
              lg={5}
              className={classes.influencerInfos}
            >
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <p className={classes.cardTitle}>Age</p>
              </Grid>
              <Grid item xs={6}>
                <p>Min: {mission?.target?.age.min}</p>
              </Grid>
              <Grid item xs={6}>
                <p>Max: {mission?.target?.age.max}</p>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={5}
              lg={5}
              className={classes.influencerInfos}
            >
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <p className={classes.cardTitle}>Genre</p>
              </Grid>
              {mission?.target?.gender.map((gender, key) => {
                const genderLabel = genderOptions.find(
                  (opt) => opt.value === gender
                );
                return (
                  <Grid item xs={12} key={key}>
                    <p>{genderLabel.label}</p>
                  </Grid>
                );
              })}
            </Grid>
            {/* <Grid
            item
            container
            xs={12}
            sm={12}
            md={5}
            lg={5}
            className={classes.influencerInfos}
          >
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <p className={classes.cardTitle}>Localisation</p>
            </Grid>
            <Grid item xs={12}>
              <p>{mission.influencerCaracteristics.location}</p>
            </Grid>
          </Grid> */}
          </Grid>
        </Grid>
      )}
      <Grid item container style={{ marginTop: 20 }}>
        <Grid item xs={12}>
          <p className={classes.detailCategory}>Réseaux sociaux</p>
        </Grid>
        {getFormattedSocialNetworks(mission.socialNetworks).map(
          (socialNetwork, key) => {
            return (
              <Grid
                key={key}
                item
                container
                xs={12}
                style={{ marginTop: 20 }}
                justify="space-between"
              >
                <Grid item xs={12}>
                  <p>{socialNetwork.label}</p>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  md={5}
                  lg={5}
                  className={classes.influencerInfos}
                >
                  <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <p className={classes.cardTitle}>Followers</p>
                  </Grid>
                  <Grid item xs={6}>
                    <p>Min: {socialNetwork?.followers?.min}</p>
                  </Grid>
                  <Grid item xs={6}>
                    <p>Max: {socialNetwork?.followers?.max}</p>
                  </Grid>
                </Grid>
                {socialNetwork?.prices && (
                  <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    md={5}
                    lg={5}
                    className={classes.influencerInfos}
                  >
                    <Grid item xs={12} style={{ marginBottom: 10 }}>
                      <p className={classes.cardTitle}>Prix</p>
                    </Grid>
                    <Grid item xs={6}>
                      <p>Min: {socialNetwork?.prices?.min}</p>
                    </Grid>
                    <Grid item xs={6}>
                      <p>Max: {socialNetwork?.prices?.max}</p>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            );
          }
        )}
      </Grid>
      {!isInfluencer && (
        <>
          <Grid item xs={12} style={{ marginTop: 50 }}>
            <Button
              variant="outlined"
              startIcon={<Delete />}
              style={{
                border: "1px solid rgba(250, 128, 114,1)",
                color: "rgba(250, 128, 114,1)",
                boxShadow: "none",
                background: "transparent",
                borderRadius: 18,
              }}
              className="button-new-mission"
              onClick={() => setIsOnDeleteModalOpen(true)}
            >
              Supprimer la campagne
            </Button>
          </Grid>
          <DeleteCampaignModal
            isModalOpen={isOnDeleteModalOpen}
            handleCloseModal={() => setIsOnDeleteModalOpen(false)}
            handleOnDeleteCampaign={handleOnDeleteCampaign}
          />
        </>
      )}
    </Grid>
  );
};

export default MissionDetails;

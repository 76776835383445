import React from "react";
import { createBrowserHistory } from "history";
import { QueryParamProvider } from "use-query-params";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import { Router, Route, Switch } from "react-router-dom";

import indexRoutes from "./indexRoutes";

let history = createBrowserHistory();

const theme = createMuiTheme({});

const Routes = () => {
  return (
    <Router history={history}>
      <QueryParamProvider history={history}>
        <MuiThemeProvider theme={theme}>
          <Switch>
            <Switch>
              {indexRoutes.map((prop, key) => (
                <Route path={prop.path} component={prop.component} key={key} />
              ))}
            </Switch>
          </Switch>
        </MuiThemeProvider>
      </QueryParamProvider>
    </Router>
  );
};

export default Routes;

import { Avatar, Button, Chip, Grid, Hidden, Tooltip } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { IoLocationOutline, IoPeopleOutline } from "react-icons/io5";
import { useHistory } from "react-router";
import { categoriesOptions } from "../../../Brand/NewMission/utils";
import { getFormattedSocialNetworks } from "../../../Influencer/InfluencerProfilePage/PersonalInformation/utils";
import FollowProcessModal from "../modals/FollowProcessModal";
import SendBriefsModal from "../modals/SendBriefsModal";

import messageStyle from "../styles/messagesStyle";

const useStyles = makeStyles(messageStyle);

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    fontFamily: "nunito-semibold",
    fontSize: 13,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
  },
}))(Tooltip);

const ContactHeader = ({ brand, discussionLength }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Grid
        item
        container
        xs={12}
        alignItems="flex-start"
        style={{ maxHeight: 200 }}
      >
        <Grid
          item
          container
          xs={12}
          className={classes.influencerItem}
          alignItems="center"
        >
          <Grid
            item
            xs={2}
            style={{ cursor: "pointer" }}
            // onClick={() =>
            //   history.push(
            //     `/brand/missions/influencer-profile/${influencerbrand?.influencerId?._id}`
            //   )
            // }
          >
            <Avatar
              className={classes.userHeaderAvatar}
              src={brand?.avatar}
            ></Avatar>
          </Grid>
          <Grid
            item
            container
            alignItems="flex-start"
            xs={10}
            style={{
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <Grid
              item
              container
              xs={6}
              sm={6}
              md={4}
              lg={4}
              className={classes.messagesHeaderInfluencerInfo}
            >
              <Grid
                item
                xs={12}
                style={{ paddingBottom: 5, cursor: "pointer" }}
                // onClick={() =>
                //   history.push(
                //     `/brand/missions/influencer-profile/${influencerbrand?.influencerId?._id}`
                //   )
                // }
              >
                <p
                  className={classes.userName}
                  style={{ fontFamily: "nunito-bold" }}
                >
                  {brand?.brandName}
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {discussionLength === 0 && (
          <Grid item container justify="flex-start" alignItems="flex-start">
            <Grid item xs={12} style={{}}>
              <p
                style={{
                  fontFamily: "nunito-semibold",
                  fontStyle: "italic",
                  marginLeft: 15,
                  marginTop: -30,
                  fontSize: 18,
                  color: "rgba(0, 0, 0, 0.7)",
                }}
              >
                Engagez la discussion avec la marque
              </p>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ContactHeader;

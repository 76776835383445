import { Button, Chip, Collapse, Grid, TextField } from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router";

import { API_URL } from "../../../../../common/constants";

const BrandValidation = ({
  influencerMissionId,
  handleRefreshFollowProcess,
  followProcess,
  missionId,
}) => {
  const history = useHistory();
  const [comment, setComment] = useState("");
  const [isDeclined, setIsDeclined] = useState(false);
  const [contentError, setContentError] = useState(false);

  const handleBrandValidateContent = (decision) => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .put(
        `${API_URL}/brand/missions/influencer-mission/${influencerMissionId}/follow-process`,
        {
          comment,
          decision,
        }
      )
      .then((res) => {
        handleRefreshFollowProcess();
        // history.push(`/brand/missions/${missionId}/influencers`);
      });
  };

  return (
    <Grid item container xs={12} justify="center" alignItems="flex-start">
      <Grid item xs={12}>
        <p
          style={{
            marginTop: 5,
            marginBottom: 25,
            fontSize: 20,
            color: "#484848",
            paddingBottom: 12,
            textAlign: "center",
          }}
        >
           Voici le travail effectué par l’influenceur
        </p>
      </Grid>
      {followProcess.influencerContent.length > 0 && (
        <Grid item container xs={10} style={{ marginBottom: 20 }}>
          {followProcess?.influencerContent?.map((content) => {
            let date = new Date(content.createdAt);
            const options = {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            };
            date = date.toLocaleString("fr-FR", options);
            return (
              <Grid
                item
                xs={12}
                style={{
                  paddingTop: 20,
                  paddingBottom: 20,
                  color: "#484848",
                  borderBottom: "2px solid #ebebeb",
                }}
              >
                <p style={{ fontSize: 16 }}>{content.description}</p>
                {Array.from(content.files).map((data, index) => {
                  return (
                    <Grid item key={index}>
                      <Chip
                        label={
                          typeof data === "string"
                            ? data.replace(
                                "https://pop-influence.s3.us-east-2.amazonaws.com/follow-process/",
                                ""
                              )
                            : data.name
                        }
                        style={{ marginTop: 10, marginBottom: 10 }}
                        onClick={() => {
                          typeof data === "string" && window.open(data);
                        }}
                      />
                    </Grid>
                  );
                })}
                <p
                  style={{
                    color: "#888",
                    fontStyle: "italic",
                    fontSize: 10,
                  }}
                >
                  {date}
                </p>
              </Grid>
            );
          })}
        </Grid>
      )}
      <Grid item container xs={12} justify="center">
        {/* <Grid item xs={12}>
          <p
            style={{
              width: "60%",
              textAlign: "center",
              margin: "auto",
              marginTop: 10,
              fontSize: 16,
            }}
          >
            Contenu validé par l'influenceur:
          </p>
        </Grid> */}

        <Grid
          item
          container
          xs={12}
          justify="space-around"
          style={{ padding: 50 }}
        >
          <Grid item xs={4} style={{ marginTop: 20 }}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              style={{
                backgroundColor: isDeclined ? "#eee" : "rgba(250, 128, 114,1)",
                borderRadius: 18,
                fontFamily: "nunito-bold !important",
              }}
              onClick={() => handleBrandValidateContent("VALIDATED")}
              disabled={isDeclined}
            >
              Valider le travail
            </Button>
          </Grid>
          <Grid item xs={4} style={{ marginTop: 20 }}>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              type="submit"
              style={{
                border: "1px solid rgba(250, 128, 114,1)",
                color: "rgba(250, 128, 114,1)",
                boxShadow: "none",
                background: "transparent",
                borderRadius: 18,
              }}
              // onClick={() => handleBrandValidateContent("DECLINED")}
              onClick={() => setIsDeclined(!isDeclined)}
            >
              Refuser
            </Button>
          </Grid>
        </Grid>
        <Collapse in={isDeclined} style={{ width: "70%" }}>
          <Grid item xs={12}>
            {/* <p
            style={{
              width: "60%",
              textAlign: "center",
              margin: "auto",
              marginTop: 10,
              fontSize: 16,
            }}
          >
            Ce contenu est-il à la hauteur de vos attentes ?
          </p> */}
            <label>Expliquez à l'influenceur les raisons de votre refus </label>
            <TextField
              style={{ marginTop: 10 }}
              rows={5}
              multiline
              variant="outlined"
              fullWidth
              value={comment}
              error={contentError}
              helperText={
                contentError && "L'explication du refus est obligatoire."
              }
              onChange={(e) => setComment(e.target.value)}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            justify="space-around"
            style={{ padding: 50 }}
          >
            <Grid item xs={4} style={{ marginTop: 20 }}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                style={{
                  backgroundColor: "rgba(250, 128, 114,1)",
                  borderRadius: 18,
                  fontFamily: "nunito-bold !important",
                }}
                onClick={() => {
                  if (comment.length === 0) {
                    setContentError(true);
                  }
                  setContentError(false);
                  handleBrandValidateContent("DECLINED");
                }}
              >
                Confirmer le refus
              </Button>
            </Grid>
            <Grid item xs={4} style={{ marginTop: 20 }}>
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                type="submit"
                style={{
                  border: "1px solid rgba(250, 128, 114,1)",
                  color: "rgba(250, 128, 114,1)",
                  boxShadow: "none",
                  background: "transparent",
                  borderRadius: 18,
                }}
                // onClick={() => handleBrandValidateContent("DECLINED")}
                onClick={() => setIsDeclined(false)}
              >
                Annuler
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default BrandValidation;

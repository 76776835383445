import BrandMissionPage from "../pages/Brand/Missions/BrandMissionPage";
import InfluencerProfile from "../pages/Brand/InfluencerProfile/InfluencerProfile";
import BrandMissionsDashboardWrapper from "../pages/Brand/Missions/MissionsDashboard";
import NewMission from "../pages/Brand/NewMission/NewMission";
import UpdateMission from "../pages/Brand/UpdateMission/UpdateMission";
import ProfilePage from "../pages/Brand/Profile/ProfilePage";
import SettingsPage from "../pages/Brand/Settings/SettingsPage";

const companyRoutes = [
  {
    path: "/brand/dashboard",
    name: "Dashboard",
    displayName: false,
    exact: false,
    component: ProfilePage,
  },
  {
    path: "/brand/missions/influencer-profile/:id",
    name: "Influenceur",
    exact: true,
    displayName: false,
    component: InfluencerProfile,
  },
  {
    path: "/brand/missions/:id",
    name: "Mission",
    exact: false,
    displayName: false,
    component: BrandMissionPage,
  },
  {
    path: "/brand/new-mission",
    name: "Créer une campagne",
    exact: true,
    displayName: true,
    component: NewMission,
  },
  {
    path: "/brand/update-mission/:id",
    name: "Modifier une campagne",
    exact: true,
    displayName: true,
    component: UpdateMission,
  },
  {
    path: "/brand/missions",
    name: "Mes campagnes",
    exact: true,
    displayName: true,
    component: BrandMissionsDashboardWrapper,
  },
  {
    path: "/brand/settings/profile",
    name: "Réglages",
    exact: false,
    displayName: false,
    component: SettingsPage,
  },
  // {
  //   path: "/brand/settings/company-information",
  //   name: "Informations de la société",
  //   exact: false,
  //   displayName: false,
  //   component: SettingsPage,
  // },
  // {
  //   path: "/influencer/messages",
  //   name: "Messagerie",
  //   component: MessagesWrapper,
  // },
  {
    redirect: true,
    path: "/",
    pathTo: "/brand/dashboard",
    name: "Dashboard",
  },
];

export default companyRoutes;

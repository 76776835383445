import { Grid, InputAdornment, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";

import { categoriesOptions, genderOptions, colourStyles } from "../utils";

import Select from "../../../Common/Select/Select";
import newMissionStyle from "../styles/newMissionStyle";

const useStyles = makeStyles(newMissionStyle);

const InfluencerInformation = ({ formik }) => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {formik.values.socialNetworks.map((value, key) => {
        return (
          <React.Fragment key={value.value}>
            <Grid item xs={12}>
              <label className={classes.socialNetworkLabel}>
                {value.label}
              </label>
              <div className={classes.socialNetworkBorder}></div>
            </Grid>
            <Grid
              item
              container
              direction="row"
              xs={12}
              sm={12}
              md={6}
              lg={6}
              spacing={2}
            >
              <Grid item xs={12}>
                <label className={classes.fieldLabel}>
                  Nombre de followers ?
                </label>
              </Grid>
              <Grid
                item
                container
                xs={6}
                sm={6}
                md={5}
                lg={5}
                direction="column"
              >
                {/* <label style={{ fontSize: 14, paddingBottom: 5 }}>Min</label> */}
                <TextField
                  type="number"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Min"
                  inputProps={{ style: { padding: "8.5px 10px" }, min: 0 }}
                  error={Boolean(
                    formik.errors.socialNetworks?.length > 0 &&
                      formik.errors?.socialNetworks[key]?.followers?.min
                  )}
                  helperText={
                    formik.errors.socialNetworks?.length > 0 &&
                    formik.errors?.socialNetworks[key]?.followers?.min
                  }
                  value={value.followers && value.followers.min}
                  onChange={(e) => {
                    formik.setFieldValue(
                      `socialNetworks[${key}].followers.min`,
                      isNaN(parseInt(e.target.value))
                        ? undefined
                        : parseInt(e.target.value)
                    );
                  }}
                />
              </Grid>
              <Grid
                item
                container
                xs={6}
                sm={6}
                md={5}
                lg={5}
                direction="column"
              >
                {/* <label style={{ fontSize: 14, paddingBottom: 5 }}>Max</label> */}
                <TextField
                  type="number"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Max"
                  inputProps={{ style: { padding: "8.5px 10px" }, min: 0 }}
                  value={value.followers && value.followers.max}
                  helperText={
                    formik.errors.socialNetworks?.length > 0 &&
                    formik.errors?.socialNetworks[key]?.followers?.max
                  }
                  error={Boolean(
                    formik.errors.socialNetworks?.length > 0 &&
                      formik.errors?.socialNetworks[key]?.followers?.max
                  )}
                  onChange={(e) =>
                    formik.setFieldValue(
                      `socialNetworks[${key}].followers.max`,
                      isNaN(parseInt(e.target.value))
                        ? undefined
                        : parseInt(e.target.value)
                    )
                  }
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              xs={12}
              sm={12}
              md={6}
              lg={6}
              spacing={2}
            >
              <Grid item xs={12}>
                <label className={classes.fieldLabel}>
                  Prix de l'influenceur ?
                </label>
              </Grid>
              <Grid
                item
                container
                xs={6}
                sm={6}
                md={5}
                lg={5}
                direction="column"
              >
                {/* <label style={{ fontSize: 14, paddingBottom: 5 }}>Min</label> */}
                <TextField
                  type="number"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Min"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  inputProps={{ style: { padding: "8.5px 10px" }, min: 0 }}
                  value={value.prices && value.prices.min}
                  helperText={
                    formik.errors.socialNetworks?.length > 0 &&
                    formik.errors?.socialNetworks[key]?.prices?.min
                  }
                  error={Boolean(
                    formik.errors.socialNetworks?.length > 0 &&
                      formik.errors?.socialNetworks[key]?.prices?.min
                  )}
                  onChange={(e) =>
                    formik.setFieldValue(
                      `socialNetworks[${key}].prices.min`,
                      isNaN(parseInt(e.target.value))
                        ? undefined
                        : parseInt(e.target.value)
                    )
                  }
                />
              </Grid>
              <Grid
                item
                container
                xs={6}
                sm={6}
                md={5}
                lg={5}
                direction="column"
              >
                {/* <label style={{ fontSize: 14, paddingBottom: 5 }}>Max</label> */}
                <TextField
                  type="number"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Max"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                  inputProps={{ style: { padding: "8.5px 10px" }, min: 0 }}
                  value={value.prices && value.prices.max}
                  helperText={
                    formik.errors.socialNetworks?.length > 0 &&
                    formik.errors?.socialNetworks[key]?.prices?.max
                  }
                  error={Boolean(
                    formik.errors.socialNetworks?.length > 0 &&
                      formik.errors?.socialNetworks[key]?.prices?.max
                  )}
                  onChange={(e) =>
                    formik.setFieldValue(
                      `socialNetworks[${key}].prices.max`,
                      isNaN(parseInt(e.target.value))
                        ? undefined
                        : parseInt(e.target.value)
                    )
                  }
                />
              </Grid>
            </Grid>
          </React.Fragment>
        );
      })}
      <Grid item xs={12}>
        <label className={classes.socialNetworkLabel}>
          Caractéristiques de l'influenceur
        </label>
        <div className={classes.socialNetworkBorder}></div>
      </Grid>
      <Grid item container direction="column" xs={12}>
        <label className={classes.fieldLabel}>
          Quelles sont les catégories cible ?
        </label>
        <Select
          styles={{ ...colourStyles(false) }}
          isMulti
          value={formik.values.categories}
          name="categories"
          options={categoriesOptions}
          placeholder="Lifestyle, Sport, etc, ..."
          onChange={(selectedValue) =>
            formik.setFieldValue("categories", selectedValue)
          }
          error={Boolean(formik.errors.categories)}
          helperText={formik.errors.categories}
        />
      </Grid>
      <Grid item container direction="column" xs={12} sm={12} md={6} lg={6}>
        <label className={classes.fieldLabel} style={{ marginBottom: 20 }}>
          Genre
        </label>
        <Select
          styles={{ ...colourStyles(false) }}
          isMulti
          value={formik.values.gender}
          name="gender"
          options={genderOptions}
          placeholder="Le genre de votre influenceur"
          onChange={(selectedValue) =>
            formik.setFieldValue("gender", selectedValue)
          }
          error={Boolean(formik.errors.gender)}
          helperText={formik.errors.gender}
        />
      </Grid>
      <Grid item container direction="row" xs={12} sm={12} md={6} lg={6}>
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <label className={classes.fieldLabel}>Âge</label>
        </Grid>
        <Grid item container xs={6} direction="row" alignItems="center">
          {/* <Grid item xs={2}>
            <label style={{ fontSize: 14 }}>Min</label>
          </Grid> */}
          <Grid item xs={8}>
            <TextField
              type="number"
              variant="outlined"
              id="age.min"
              name="age.min"
              InputLabelProps={{
                shrink: true,
              }}
              label="Min"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">ans</InputAdornment>
                ),
              }}
              inputProps={{ style: { padding: "8.5px 10px" }, min: 0 }}
              value={formik.values.age.min}
              onChange={(e) =>
                formik.setFieldValue("age.min", parseInt(e.target.value))
              }
            />
          </Grid>
        </Grid>
        <Grid item container xs={6} direction="row" alignItems="center">
          {/* <Grid item xs={2}>
            <label style={{ fontSize: 14 }}>Max</label>
          </Grid> */}
          <Grid item xs={8}>
            <TextField
              type="number"
              variant="outlined"
              id="age.max"
              name="age.max"
              InputLabelProps={{
                shrink: true,
              }}
              label="Max"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">ans</InputAdornment>
                ),
              }}
              inputProps={{ style: { padding: "8.5px 10px" }, min: 0 }}
              value={formik.values.age.max}
              onChange={(e) =>
                formik.setFieldValue("age.max", parseInt(e.target.value))
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <label className={classes.socialNetworkLabel}>
          Caractéristiques de votre cible
        </label>
        <div className={classes.socialNetworkBorder}></div>
      </Grid>
      <Grid item container direction="column" xs={12} sm={12} md={6} lg={6}>
        <label className={classes.fieldLabel} style={{ marginBottom: 20 }}>
          Genre de votre cible
        </label>
        <Select
          styles={{ ...colourStyles(false) }}
          isMulti
          value={formik.values.target?.gender.map((g) =>
            genderOptions.find((gend) => gend.value === g)
          )}
          name="target.gender"
          options={genderOptions}
          placeholder="Le genre de votre cible"
          onChange={(selectedValue) =>
            formik.setFieldValue(
              "target.gender",
              selectedValue.map((gender) => gender.value)
            )
          }
          error={Boolean(formik.errors?.target?.gender)}
          helperText={formik.errors?.target?.gender}
        />
      </Grid>
      <Grid item container direction="row" xs={12} sm={12} md={6} lg={6}>
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <label className={classes.fieldLabel}>Âge de votre cible</label>
        </Grid>
        <Grid item container xs={6} direction="row" alignItems="center">
          {/* <Grid item xs={2}>
            <label style={{ fontSize: 14 }}>Min</label>
          </Grid> */}
          <Grid item xs={8}>
            <TextField
              type="number"
              variant="outlined"
              id="target.age.min"
              name="target.age.min"
              InputLabelProps={{
                shrink: true,
              }}
              label="Min"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">ans</InputAdornment>
                ),
              }}
              inputProps={{ style: { padding: "8.5px 10px" }, min: 0 }}
              value={formik.values.target?.age?.min}
              onChange={(e) => {
                formik.setFieldValue(
                  "target.age.min",
                  parseInt(e.target.value)
                );
                formik.setFieldError("target.age.min", null);
              }}
              error={Boolean(formik.errors?.target?.age?.min)}
              helperText={formik.errors?.target?.age?.min}
            />
          </Grid>
        </Grid>
        <Grid item container xs={6} direction="row" alignItems="center">
          {/* <Grid item xs={2}>
            <label style={{ fontSize: 14 }}>Max</label>
          </Grid> */}
          <Grid item xs={8}>
            <TextField
              type="number"
              variant="outlined"
              id="target.age.max"
              name="target.age.max"
              InputLabelProps={{
                shrink: true,
              }}
              label="Max"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">ans</InputAdornment>
                ),
              }}
              inputProps={{ style: { padding: "8.5px 10px" }, min: 0 }}
              value={formik.values.target?.age?.max}
              onChange={(e) => {
                formik.setFieldValue(
                  "target.age.max",
                  parseInt(e.target.value)
                );

                formik.setFieldError("target.age.max", null);
              }}
              error={Boolean(formik.errors?.target?.age?.max)}
              helperText={formik.errors?.target?.age?.max}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item container direction="column" xs={12} sm={12} md={4} lg={4}>
        <label className={classes.fieldLabel}>Localisation</label>
        <TextField
          fullWidth
          inputProps={{ style: { padding: "8.5px 10px" } }}
          id="location"
          name="location"
          variant="outlined"
          value={formik.values.location}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.location)}
          helperText={formik.errors.location}
        />
      </Grid> */}
    </>
  );
};

export default InfluencerInformation;

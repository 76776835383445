import React, { useEffect, useState } from "react";
import {
  Menu,
  Grid,
  Button,
  Avatar,
  IconButton,
  Chip,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { Delete, MoreVert } from "@material-ui/icons";
import { Gift, Send, Upload } from "react-feather";

import messagesStyles from "../styles/messagesStyle";
import axios from "axios";
import { API_URL } from "../../../../common/constants";
import { getFormattedSocialNetworksSimple } from "../../../Influencer/InfluencerProfilePage/PersonalInformation/utils";
import { NavLink } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";
import RemoveInfluencerModal from "../../../Brand/Missions/BrandMissionPage/InfluencerList/RemoveInfluencerModal";

import { Edit, XCircle } from "react-feather";
import NewOfferModal from "../modals/NewOffer";
import { LightTooltip } from "../ContactHeader/ContactHeader";

const useStyles = makeStyles(messagesStyles);

const Discussion = ({
  isInfluencer,
  discussion,
  missionId,
  influencerId,
  handleRefreshDiscussion,
  influencerMission,
  handleRefreshMission,
}) => {
  const classes = useStyles();
  const stripe = useStripe();
  let interval;

  const [isUpdateOfferModalOpen, setIsUpdateOfferModalOpen] = useState(false);

  const [isDisplayingRemoveModal, setIsDisplayingRemoveModal] = useState(false);
  const [message, setMessage] = useState("");
  const [removedInfluencerMission, setRemovedInfluencerMission] =
    useState(undefined);
  const [filesToSend, setFilesToSend] = useState([]);
  const [isSendMessageDisabled, setIsSendMessageDisabled] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const sendMessageInfluencer = (files = []) => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .post(`${API_URL}/influencer/missions/${missionId}/messages`, {
        message: message,
        files,
      })
      .then(() => {
        setMessage("");
        setFilesToSend([]);
        handleRefreshDiscussion();
        setIsSendMessageDisabled(false);
      })
      .catch((error) => console.error(error));
  };

  const sendMessageBrand = (files = []) => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .post(`${API_URL}/brand/missions/${missionId}/messages`, {
        influencerId,
        message: message,
        files,
      })
      .then(() => {
        setMessage("");
        setFilesToSend([]);
        handleRefreshDiscussion();
        setIsSendMessageDisabled(false);
      })
      .catch((error) => console.error(error));
  };

  const handleInfluencerUpdateOffer = (offerId, status) => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    axios
      .put(`${API_URL}/influencer/missions/${missionId}/offer/${offerId}`, {
        status,
      })
      .then(() => {
        handleRefreshDiscussion();
      })
      .catch((error) => console.error(error));
  };

  const handleOfferPayment = async (offerId) => {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    // axios
    //   .post(`${API_URL}/brand/missions/${missionId}/offer/${offerId}/payment`, {
    //     influencerId,
    //   })
    //   .then(() => {
    //     handleRefreshDiscussion();
    //   })
    //   .catch((error) => console.error(error));
    // Get Stripe.js instance

    // Call your backend to create the Checkout Session
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;
    const session = await axios.post(
      `${API_URL}/brand/missions/${missionId}/proceed-checkout`,
      {
        offerId,
      }
    );

    // const session = await response.json();

    // When the customer clicks on the button, redirect them to Checkout.
    if (!session.data.isGifting) {
      const result = await stripe.redirectToCheckout({
        sessionId: session.data.id,
      });

      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      }
    } else {
      handleRefreshDiscussion();
    }
  };

  const handleSendMessage = () => {
    if (message.length === 0 || isSendMessageDisabled) return;
    setIsSendMessageDisabled(true);
    if (isInfluencer) {
      sendMessageInfluencer();
    } else {
      sendMessageBrand();
    }
  };

  async function handleSendFiles() {
    setIsSendMessageDisabled(true);
    const finalFiles = await Promise.all(
      filesToSend?.map(async (file) => {
        let formData = new FormData();

        formData.append("file", file);

        const res = await axios.post(`${API_URL}/images/messages`, formData);
        const newFile = `https://pop-influence.s3.us-east-2.amazonaws.com/${res.data.filename}`;
        return newFile;
      })
    );

    if (isInfluencer) {
      sendMessageInfluencer(finalFiles);
    } else {
      sendMessageBrand(finalFiles);
    }
  }

  function handleCancelOffer(offerId) {
    axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
      "token"
    )}`;

    axios
      .put(`${API_URL}/brand/missions/${missionId}/offer/${offerId}`, {
        status: "CANCELED",
      })
      .then(() => {
        handleRefreshDiscussion();
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    document.querySelector("#ui-chat").scrollTop =
      document.querySelector("#ui-chat").scrollHeight;
  }, [discussion]);

  useEffect(() => {
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(() => {
      handleRefreshDiscussion();
    }, 5000);
    return () => clearInterval(interval);
  }, [influencerMission]);

  useEffect(() => {
    return () => clearInterval(interval);
  }, []);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile = width <= 768;

  return (
    <>
      <Grid item container justify="center" alignItems="flex-end">
        <Grid
          item
          container
          xs={12}
          id="ui-chat"
          style={{
            maxHeight: 550,
            padding: 10,
            overflow: "auto",
            display: "flex",
          }}
        >
          {discussion
            .sort((a, b) => {
              return new Date(a.date) - new Date(b.date);
            })
            .map((message, key) => {
              const position =
                isInfluencer && message.isAuthorInfluencer
                  ? "right"
                  : !isInfluencer && !message.isAuthorInfluencer
                  ? "right"
                  : "left";

              let date = new Date(message.createdAt);
              const options = {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              };
              date = date.toLocaleString("fr-FR", options);

              if (message.isBriefs) {
                return (
                  <Grid
                    key={message._id}
                    item
                    container
                    xs={12}
                    alignItems="center"
                  >
                    <Grid
                      item
                      container
                      xs={12}
                      justify={position === "left" ? "flex-start" : "flex-end"}
                    >
                      <Grid
                        item
                        container
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        justify={
                          position === "left" ? "flex-start" : "flex-end"
                        }
                      >
                        <Grid
                          item
                          container
                          xs
                          justify={
                            position === "left" ? "flex-start" : "flex-end"
                          }
                        >
                          <Grid
                            item
                            className={
                              position === "right"
                                ? isInfluencer
                                  ? classes.rightMessageInfluencer
                                  : {}
                                : classes.leftMessageBrief
                            }
                          >
                            {isInfluencer ? (
                              <NavLink
                                exact={true}
                                to={`/influencer/missions/${missionId}/briefs`}
                              >
                                <p
                                  style={{
                                    fontFamily: "nunito-bold",
                                    fontSize: 14,
                                    padding: 10,
                                    marginBottom: 20,
                                    color: "rgb(8, 54, 113)",
                                    textDecoration: "underline",
                                    fontStyle: "italic",
                                  }}
                                >
                                  La marque vous a envoyé des briefs. Voir les
                                  briefs
                                </p>
                              </NavLink>
                            ) : (
                              <p
                                style={{
                                  fontFamily: "nunito-bold",
                                  fontSize: 14,
                                  padding: 10,
                                  marginBottom: 20,
                                  color: "rgb(8, 54, 113)",
                                  fontStyle: "italic",
                                }}
                              >
                                Les briefs ont bien été envoyés
                              </p>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              } else if (message.isOffer) {
                return (
                  <React.Fragment key={message._id}>
                    {isUpdateOfferModalOpen && (
                      <NewOfferModal
                        isModalOpen={isUpdateOfferModalOpen}
                        handleCloseModal={setIsUpdateOfferModalOpen}
                        missionId={missionId}
                        influencerId={influencerId}
                        handleRefreshDiscussion={handleRefreshDiscussion}
                        oldTitle={message?.offer?.title}
                        oldPrice={message?.offer?.price}
                        oldOfferDescription={message?.offer?.description}
                        oldSocialNetworks={message?.offer?.socialNetworks}
                        isEditing
                        oldIsGifting={message?.offer?.isGifting}
                        offerId={message?.offer._id}
                      />
                    )}
                    <Grid item container xs={12} alignItems="center">
                      <Grid
                        item
                        container
                        xs={12}
                        justify={
                          position === "left" ? "flex-start" : "flex-end"
                        }
                      >
                        <Grid
                          item
                          container
                          xs={12}
                          sm={12}
                          md={10}
                          lg={10}
                          justify={
                            position === "left" ? "flex-start" : "flex-end"
                          }
                        >
                          {position === "left" && (
                            <Grid
                              item
                              xs={1}
                              sm={1}
                              md={1}
                              lg={1}
                              style={{
                                margin: "auto",
                                marginLeft: 15,
                                marginRight: 10,
                              }}
                            >
                              <Avatar
                                className={classes.userAvatarMessage}
                                src={
                                  message.isAuthorInfluencer
                                    ? message.brandId.avatar
                                    : message.influencerId.avatar
                                }
                              ></Avatar>
                            </Grid>
                          )}
                          <Grid
                            item
                            container
                            xs={position === "left" ? 10 : 12}
                            justify={
                              position === "left" ? "flex-start" : "flex-end"
                            }
                          >
                            <Grid
                              item
                              container
                              className={classes.offer}
                              style={{ float: position }}
                              justify="space-between"
                              alignItems="center"
                            >
                              <Grid item container xs={6}>
                                <Grid item container xs={12}>
                                  <p className={classes.offerTitle}>
                                    {message.offer.title} -{" "}
                                  </p>
                                  {getFormattedSocialNetworksSimple(
                                    message.offer.socialNetworks
                                  ).map((socialNetwork, key) => {
                                    return (
                                      <p
                                        key={key}
                                        style={{
                                          marginLeft: 5,
                                          marginRight: 5,
                                        }}
                                      >
                                        {socialNetwork.iconWithoutChip}
                                      </p>
                                    );
                                  })}
                                  {/* <IoLogoInstagram /> */}
                                </Grid>
                                <Grid item container xs={12}>
                                  <p className={classes.offerDescription}>
                                    {message.offer.description}
                                  </p>
                                </Grid>
                                {message.offer.isGifting && (
                                  <Grid
                                    item
                                    container
                                    alignItems="center"
                                    xs={12}
                                  >
                                    <Gift
                                      style={{ width: 12, marginRight: 10 }}
                                    />
                                    <p className={classes.offerPrice}>
                                      Gifting
                                    </p>
                                  </Grid>
                                )}
                                {message.offer.price !== 0 && (
                                  <Grid item xs={12}>
                                    <p className={classes.offerPrice}>
                                      {message.offer.price} euros
                                    </p>
                                  </Grid>
                                )}
                              </Grid>
                              {message.offer.status === "ACCEPTED" &&
                                isInfluencer && (
                                  <Grid
                                    item
                                    container
                                    justify="center"
                                    className={classes.customChipRounded}
                                    style={{
                                      textAlign: "center",
                                      fontFamily: "nunito-bold",
                                      background: "rgba(130, 93, 255, 1)",
                                    }}
                                    xs={5}
                                  >
                                    <p style={{ fontFamily: "nunito-bold" }}>
                                      En attente du paiement de la marque
                                    </p>
                                  </Grid>
                                )}
                              {message.offer.status === "ACCEPTED" &&
                                !isInfluencer && (
                                  <Grid item container xs={5}>
                                    <Button
                                      variant="contained"
                                      type="primary"
                                      style={{
                                        fontSize: 12,
                                        textTransform: "none",
                                        background: "rgba(250, 128, 114,1)",
                                        border:
                                          "1px solid rgba(250, 128, 114,1)",
                                        color: "#FFF",
                                      }}
                                      onClick={() => {
                                        handleOfferPayment(message.offer._id);
                                      }}
                                    >
                                      Démarrer la campagne
                                    </Button>
                                  </Grid>
                                )}
                              {message.offer.status === "PAID" && (
                                <Grid
                                  item
                                  xs
                                  className={classes.customChipRounded}
                                  container
                                  alignItems="center"
                                  justify="center"
                                >
                                  <p style={{ fontFamily: "nunito-bold" }}>
                                    Payée
                                  </p>
                                </Grid>
                              )}
                              {message.offer.status === "CANCELED" && (
                                <Grid
                                  item
                                  xs
                                  className={classes.customChipRounded}
                                  container
                                  alignItems="center"
                                  justify="center"
                                >
                                  <p style={{ fontFamily: "nunito-bold" }}>
                                    Annulée
                                  </p>
                                </Grid>
                              )}
                              {message.offer.status === "DECLINED" && (
                                <Grid
                                  item
                                  xs
                                  className={classes.customChipRounded}
                                  container
                                  alignItems="center"
                                  justify="center"
                                >
                                  <p style={{ fontFamily: "nunito-bold" }}>
                                    Déclinée
                                  </p>
                                </Grid>
                              )}
                              {message.offer.status !== "ACCEPTED" &&
                                message.offer.status !== "PAID" &&
                                message.offer.status !== "CANCELED" &&
                                message.offer.status !== "DECLINED" && (
                                  <Grid item container xs={5}>
                                    <Grid item xs={6}>
                                      <LightTooltip
                                        title={
                                          position === "left"
                                            ? "Acceptez cette offre"
                                            : "Editez votre offre"
                                        }
                                        interactive
                                      >
                                        <Button
                                          variant="contained"
                                          type="primary"
                                          style={{
                                            backgroundColor:
                                              "rgba(250, 128, 114,1)",
                                            borderRadius: 18,
                                            color: "white",
                                          }}
                                          onClick={() => {
                                            if (isInfluencer) {
                                              handleInfluencerUpdateOffer(
                                                message.offer._id,
                                                "ACCEPTED"
                                              );
                                            } else {
                                              setIsUpdateOfferModalOpen(true);
                                            }
                                          }}
                                        >
                                          {position === "left" ? (
                                            "Accepter"
                                          ) : (
                                            <Edit />
                                          )}
                                        </Button>
                                      </LightTooltip>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <LightTooltip
                                        title={
                                          position === "left"
                                            ? "Déclinez cette offre"
                                            : "Annuler votre offre"
                                        }
                                        interactive
                                      >
                                        <Button
                                          variant="contained"
                                          type="secondary"
                                          style={{
                                            border:
                                              "1px solid rgba(250, 128, 114,1)",
                                            color: "rgba(250, 128, 114,1)",
                                            boxShadow: "none",
                                            background: "transparent",
                                            borderRadius: 18,
                                          }}
                                          onClick={() => {
                                            if (isInfluencer) {
                                              handleInfluencerUpdateOffer(
                                                message.offer._id,
                                                "DECLINED"
                                              );
                                            } else {
                                              handleCancelOffer(
                                                message?.offer?._id
                                              );
                                            }
                                          }}
                                        >
                                          {position === "left" ? (
                                            "Refuser"
                                          ) : (
                                            <XCircle />
                                          )}
                                        </Button>
                                      </LightTooltip>
                                    </Grid>
                                  </Grid>
                                )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                );
              } else if (message.files.length > 0) {
                return (
                  <React.Fragment key={key}>
                    <Grid item container xs={12} alignItems="center">
                      <Grid
                        item
                        container
                        xs={12}
                        justify={
                          position === "left" ? "flex-start" : "flex-end"
                        }
                      >
                        {Array.from(message.files).map((data, index) => {
                          return (
                            <Grid
                              item
                              key={index}
                              style={{ maxWidth: "inherit" }}
                            >
                              <Chip
                                label={data.replace(
                                  "https://pop-influence.s3.us-east-2.amazonaws.com/messages/",
                                  ""
                                )}
                                style={{
                                  marginTop: 10,
                                  marginBottom: 10,
                                  maxWidth: "100%",
                                }}
                                onClick={() => {
                                  typeof data === "string" && window.open(data);
                                }}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginLeft: position === "left" ? 10 : 0,
                          marginRight: position === "left" ? 0 : 10,
                          textAlign: position === "left" ? "left" : "right",
                        }}
                      >
                        <p
                          style={{
                            color: "#888",
                            fontStyle: "italic",
                            fontSize: 10,
                          }}
                        >
                          {date}
                        </p>
                      </Grid>
                    </Grid>
                    {message.message.length > 0 && (
                      <Grid
                        key={message._id}
                        item
                        container
                        xs={12}
                        alignItems="center"
                      >
                        <Grid
                          item
                          container
                          xs={12}
                          justify={
                            position === "left" ? "flex-start" : "flex-end"
                          }
                        >
                          <Grid
                            item
                            container
                            xs={12}
                            sm={12}
                            md={8}
                            lg={8}
                            justify={
                              position === "left" ? "flex-start" : "flex-end"
                            }
                          >
                            {position === "left" && (
                              <Grid
                                item
                                xs={1}
                                sm={1}
                                md={1}
                                lg={1}
                                style={{
                                  margin: "auto",
                                  marginLeft: 15,
                                  marginRight: 10,
                                }}
                              >
                                <Avatar
                                  className={classes.userAvatarMessage}
                                  src={
                                    message.isAuthorInfluencer
                                      ? message.brandId.avatar
                                      : message.influencerId.avatar
                                  }
                                ></Avatar>
                              </Grid>
                            )}
                            <Grid
                              item
                              container
                              xs
                              justify={
                                position === "left" ? "flex-start" : "flex-end"
                              }
                            >
                              <Grid
                                item
                                className={
                                  position === "right"
                                    ? isInfluencer
                                      ? classes.rightMessageInfluencer
                                      : classes.rightMessageBrand
                                    : classes.leftMessage
                                }
                              >
                                <p className={classes.userDiscussionMessage}>
                                  {message.message}
                                </p>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              marginLeft: position === "left" ? 10 : 0,
                              marginRight: position === "left" ? 0 : 10,
                              textAlign: position === "left" ? "left" : "right",
                            }}
                          >
                            <p
                              style={{
                                color: "#888",
                                fontStyle: "italic",
                                fontSize: 10,
                              }}
                            >
                              {date}
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </React.Fragment>
                );
              }
              return (
                <Grid
                  key={message._id}
                  item
                  container
                  xs={12}
                  alignItems="center"
                >
                  <Grid
                    item
                    container
                    xs={12}
                    justify={position === "left" ? "flex-start" : "flex-end"}
                  >
                    <Grid
                      item
                      container
                      xs={12}
                      sm={12}
                      md={8}
                      lg={8}
                      justify={position === "left" ? "flex-start" : "flex-end"}
                    >
                      {position === "left" && (
                        <Grid
                          item
                          xs={1}
                          sm={1}
                          md={1}
                          lg={1}
                          style={{
                            margin: "auto",
                            marginLeft: 15,
                            marginRight: 10,
                          }}
                        >
                          <Avatar
                            className={classes.userAvatarMessage}
                            src={
                              message.isAuthorInfluencer
                                ? message.brandId.avatar
                                : message.influencerId.avatar
                            }
                          ></Avatar>
                        </Grid>
                      )}
                      <Grid
                        item
                        container
                        xs
                        justify={
                          position === "left" ? "flex-start" : "flex-end"
                        }
                      >
                        <Grid
                          item
                          className={
                            position === "right"
                              ? isInfluencer
                                ? classes.rightMessageInfluencer
                                : classes.rightMessageBrand
                              : classes.leftMessage
                          }
                        >
                          <p className={classes.userDiscussionMessage}>
                            {message.message}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginLeft: position === "left" ? 10 : 0,
                        marginRight: position === "left" ? 0 : 10,
                        textAlign: position === "left" ? "left" : "right",
                      }}
                    >
                      <p
                        style={{
                          color: "#888",
                          fontStyle: "italic",
                          fontSize: 10,
                        }}
                      >
                        {date}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        className={classes.inputContainer}
        style={{ paddingBottom: filesToSend.length > 0 ? 10 : 0 }}
        alignItems="center"
        justify="space-between"
      >
        {filesToSend.length > 0 && (
          <Grid item container justify="flex-start">
            {Array.from(filesToSend ?? []).map((data, index) => {
              return (
                <Grid item key={index} xs>
                  <Chip
                    label={data.name}
                    onDelete={() => {
                      const tmpFiles = [...filesToSend];
                      tmpFiles.splice(index, 1);
                      setFilesToSend(tmpFiles);
                    }}
                    style={{ margin: 5, maxWidth: "inherit" }}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
        <Grid
          item
          xs={7}
          sm={7}
          md={6}
          lg={8}
          xl={8}
          style={{
            marginLeft: 20,
          }}
        >
          <input
            className={classes.messageInput}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Envoyez un message"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (filesToSend.length > 0) {
                  handleSendFiles();
                } else if (message.length > 0) {
                  handleSendMessage();
                }
              }
            }}
          />
        </Grid>

        <div
          style={{
            marginLeft: 5,
            marginRight: 10,
            display: "flex",
            alignItems: "center",
          }}
        >
          {!isMobile && (
            <>
              <Button
                variant="contained"
                color="primary"
                className={
                  isInfluencer
                    ? classes.sendButtonInfluencer
                    : classes.sendButtonBrand
                }
                disabled={
                  !(message.length > 0 || filesToSend.length > 0) ||
                  isSendMessageDisabled
                }
                style={{
                  textTransform: "none",
                  marginRight: isInfluencer ? 20 : 0,
                }}
                endIcon={
                  isSendMessageDisabled ? (
                    <CircularProgress size={12} />
                  ) : (
                    <Send size={12} />
                  )
                }
                onClick={() => {
                  if (filesToSend.length > 0) {
                    handleSendFiles();
                  } else if (message.length > 0) {
                    handleSendMessage();
                  }
                }}
                type="submit"
              >
                Envoyer
              </Button>
            </>
          )}
          {isMobile && (
            <Button
              variant="contained"
              color="primary"
              className={
                isInfluencer
                  ? classes.sendButtonInfluencer
                  : classes.sendButtonBrand
              }
              disabled={
                !(message.length > 0 || filesToSend.length > 0) ||
                isSendMessageDisabled
              }
              style={{ textTransform: "none", marginRight: 5 }}
              onClick={() => {
                if (filesToSend.length > 0) {
                  handleSendFiles();
                } else if (message.length > 0) {
                  handleSendMessage();
                }
              }}
              type="submit"
            >
              {isSendMessageDisabled ? (
                <CircularProgress size={12} />
              ) : (
                <Send size={20} />
              )}
            </Button>
          )}
          {isInfluencer && (
            <label
              htmlFor={`contained-button-file`}
              style={{
                paddingRight: 5,
                paddingRight: 5,
                marginLeft: 10,
                color: "rgba(0, 0, 0, 0.8)",
              }}
            >
              <input
                style={{ display: "none" }}
                id={`contained-button-file`}
                multiple
                type="file"
                onChange={(e) => {
                  let files = e.target.files;
                  setFilesToSend((currentFiles) => [...currentFiles, ...files]);
                }}
              />
              <Upload />
            </label>
          )}
          {!isInfluencer && (
            <>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVert />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <MenuItem>
                  <label
                    htmlFor={`contained-button-file`}
                    style={{
                      color: "rgba(0, 0, 0, 0.8)",
                      textAlign: "center",
                      width: "100%",
                      marginLeft: 5,
                      marginRight: 5,
                      padding: 5,
                    }}
                  >
                    <input
                      style={{ display: "none" }}
                      id={`contained-button-file`}
                      multiple
                      type="file"
                      onChange={(e) => {
                        let files = e.target.files;
                        setFilesToSend((currentFiles) => [
                          ...currentFiles,
                          ...files,
                        ]);
                      }}
                    />
                    <Upload />
                  </label>
                </MenuItem>
                <MenuItem>
                  <IconButton
                    size="large"
                    style={{
                      border: "1px solid rgba(250, 128, 114,1)",
                      color: "rgba(250, 128, 114,1)",
                      boxShadow: "none",
                      background: "transparent",
                      borderRadius: 18,
                      marginLeft: 5,
                      marginRight: 5,
                      padding: 5,
                    }}
                    onClick={() => {
                      setIsDisplayingRemoveModal(influencerMission._id);
                      setRemovedInfluencerMission(influencerMission);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </MenuItem>
              </Menu>
            </>
          )}
          {/* {!isMobile && !isInfluencer && (
            <IconButton
              size="large"
              style={{
                border: "1px solid rgba(250, 128, 114,1)",
                color: "rgba(250, 128, 114,1)",
                boxShadow: "none",
                background: "transparent",
                borderRadius: 18,
                marginLeft: 10,
                marginRight: 5,
                padding: 5,
              }}
              onClick={() => {
                setIsDisplayingRemoveModal(influencerMission._id);
                setRemovedInfluencerMission(influencerMission);
              }}
            >
              <Delete />
            </IconButton>
          )} */}
        </div>
      </Grid>
      {removedInfluencerMission && (
        <RemoveInfluencerModal
          isModalOpen={isDisplayingRemoveModal}
          handleCloseModal={setIsDisplayingRemoveModal}
          missionId={missionId}
          influencerMissionId={removedInfluencerMission?._id}
          influencer={removedInfluencerMission?.influencerId}
          handleRefreshMission={handleRefreshMission}
          isDiscussion
        />
      )}
    </>
  );
};

Discussion.propTypes = {};

export default Discussion;

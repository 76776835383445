// ####################################
// // // Personal Information styles
// ###################################

const personalInformationStyle = (theme) => ({
  pageLayout: {
    width: "80%",
    margin: "auto",
  },
  pageLayoutForm: {
    width: "80%",
    margin: "auto",
  },
  userAvatar: {
    width: 220,
    height: 220,
    [theme.breakpoints.up("md")]: {
      float: "right",
    },
    [theme.breakpoints.down("md")]: {
      margin: "auto",
    },
    cursor: "pointer",
    "& > img": {
      objectFit: "cover !important",
    },
  },
  userAvatarEditable: {
    width: 220,
    height: 220,
    [theme.breakpoints.up("md")]: {
      float: "right",
    },
    [theme.breakpoints.down("md")]: {
      margin: "auto",
      marginTop: 20,
    },
    cursor: "pointer",
    "& > img": {
      objectFit: "cover !important",
    },
    "&:hover": {
      backgroundColor: "rgba(130,93,255,0.3)",
    },
    "&:hover > img": {
      opacity: 0.6,
    },
  },
  formPriceAvatarContainer: {
    [theme.breakpoints.down("md")]: {
      marginTop: 20,
    },
  },
  userName: {
    fontWeight: 500,
    fontSize: 36,
    marginBottom: 10,
  },
  profileInfo: {
    height: 100,
    border: "1px solid #e0ded9",
    padding: "10px 15px",
    borderRadius: 6,
  },
  infoTitle: {
    padding: 5,
  },
  infoContent: {
    padding: 5,
  },
  fieldLabel: {
    fontSize: 18,
    fontWeight: 400,
    marginBottom: 10,
    color: "#484848",
  },
  checked: {
    color: "#76CD99 !important",
  },
  notChecked: {
    color: "#ff5c57 ",
    "&$checked": {
      color: "#76CD99 !important",
    },
    "&$checked + $track": {
      backgroundColor: "rgba(18, 207, 201, 0.4) !important",
    },
  },
  track: {
    backgroundColor: "rgba(18, 207, 201, 0.4) !important",
  },
  personalInformationContainer: {
    marginBottom: 50,
    minHeight: 300,
    backgroundColor: "#FFF",
    borderRadius: 6,
    position: "relative",
    [theme.breakpoints.up("md")]: {
      padding: 40,
    },
  },
  formUserInformation: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 50,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 40,
    },
  },
  genderContainer: {
    [theme.breakpoints.down("md")]: {
      marginTop: 20,
    },
    zIndex: 99999,
  },
  socialNetworkContainer: {
    [theme.breakpoints.down("md")]: {
      marginTop: 20,
    },
  },
});

export default personalInformationStyle;

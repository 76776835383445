import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";

import {
  Button,
  Grid,
  IconButton,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import profilePageStyle from "../styles/profilePage";
import { AddCircleOutline, DeleteOutlined } from "@material-ui/icons";

const useStyles = makeStyles(profilePageStyle);

const Experiences = ({
  isEditable,
  experiences = [],
  handleUpdateInfluencerProfile,
  isTourOpen,
}) => {
  const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();

  const [editedExperience, setEditedExperience] = useState(undefined);
  const [isAddingExperience, setIsAddingExperience] = useState(
    experiences.length === 0
  );

  const [brand, setBrand] = useState("");
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");

  const handleUpdateExperience = (experience) => {
    setIsAddingExperience(false);
    setEditedExperience(experience);
    setBrand(experience.brand);
    setDate(experience.date);
    setDescription(experience.description);
  };

  const onUpdateExperience = () => {
    const data = experiences.map((experience) => {
      if (experience._id === editedExperience._id) {
        return { brand, date, description, _id: editedExperience._id };
      }
      return experience;
    });

    handleUpdateInfluencerProfile({ experiences: data });
  };

  const onDeleteExperience = (index) => {
    const data = [...experiences];

    data.splice(index, 1);

    handleUpdateInfluencerProfile({ experiences: data });
  };

  const resetData = () => {
    setBrand("");
    setDate("");
    setDescription("");
  };

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile = width <= 768;
  return (
    <Grid
      item
      container
      className={classes.widgetContainer}
      alignItems="flex-start"
    >
      <Grid item container xs={12} justify="space-between">
        <Grid item xs={6}>
          <p className={classes.widgetTitle} style={{ marginBottom: 0 }}>
            Mes expériences
          </p>
        </Grid>
        {isEditable && (
          <Grid item style={{ textAlign: "right" }}>
            <IconButton
              onClick={() => {
                if (!isAddingExperience && isEditable) {
                  setEditedExperience(undefined);
                  resetData();
                  setIsAddingExperience(true);
                }
              }}
            >
              <AddCircleOutline />
            </IconButton>
          </Grid>
        )}
        {isEditable && (
          <Grid item xs={12} style={{ marginBottom: 25 }}>
            <p
              style={{
                color: "#888",
                fontStyle: "italic",
                fontSize: 14,
              }}
            >
              Vos expériences en tant qu'influenceur sont un plus pour mettre en
              avant votre profil !
            </p>
          </Grid>
        )}
      </Grid>
      {experiences.map((experience, key) => {
        if (editedExperience?._id === experience._id) {
          return (
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              spacing={4}
              style={{ marginBottom: 30 }}
            >
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  inputProps={{ style: { padding: "8.5px 10px" } }}
                  label="Marque"
                  id="brand"
                  name="brand"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                  disabled={isTourOpen}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="date"
                  label="Date"
                  variant="outlined"
                  inputProps={{ style: { padding: "8.5px 10px" } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  disabled={isTourOpen}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="description"
                  label="Description"
                  fullWidth
                  rows={5}
                  multiline
                  variant="outlined"
                  inputProps={{ style: { padding: "8.5px 10px" } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  disabled={isTourOpen}
                />
              </Grid>
              <Grid item container xs={12} justify="space-between">
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{
                      fontFamily: "nunito-bold",
                      fontSize: 13,
                      color: "#FFF",
                      height: 40,
                      paddingLeft: 20,
                      paddingRight: 20,
                      borderRadius: 18,
                      textTransform: "none",
                      backgroundColor: "#825DFF",
                    }}
                    onClick={() => {
                      if (isAddingExperience) {
                        handleUpdateInfluencerProfile({
                          experiences: [
                            ...experiences,
                            { brand, date, description },
                          ],
                        });
                      } else {
                        onUpdateExperience();
                      }
                      setIsAddingExperience(false);
                      setEditedExperience(undefined);
                      resetData();
                    }}
                    disabled={isTourOpen}
                  >
                    Enregistrer
                  </Button>
                  <Button
                    style={{
                      marginLeft: 10,
                      fontFamily: "nunito-bold",
                      fontSize: 13,
                      height: 40,
                      paddingLeft: 20,
                      paddingRight: 20,
                      borderRadius: 18,
                      textTransform: "none",
                      marginLeft: isMobile ? 0 : 20,
                      marginTop: isMobile ? 20 : 0,
                    }}
                    variant="contained"
                    onClick={() => {
                      setIsAddingExperience(false);
                      setEditedExperience(undefined);
                      resetData();
                    }}
                    disabled={isTourOpen}
                  >
                    Annuler
                  </Button>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<DeleteOutlined />}
                    style={{
                      marginLeft: 10,
                      fontFamily: "nunito-bold",
                      color: "#000",
                      fontSize: 13,
                      height: 40,
                      paddingLeft: 20,
                      paddingRight: 20,
                      borderRadius: 18,
                      textTransform: "none",
                    }}
                    onClick={() => onDeleteExperience(key)}
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          );
        }
        return (
          <Grid
            key={key}
            item
            container
            xs={12}
            className={
              isEditable
                ? classes.companyContentContainerEditable
                : classes.companyContentContainer
            }
            onClick={() => isEditable && handleUpdateExperience(experience)}
            alignItems="center"
          >
            <Grid item>
              <p className={classes.widgetCompanyTitle}>{experience.brand}</p>
            </Grid>
            <Grid item>
              <p className={classes.widgetPostDate}> - {experience.date}</p>
            </Grid>
            <Grid item xs={12}>
              <p className={classes.widgetCompanyDescription}>
                {experience.description}
              </p>
            </Grid>
          </Grid>
        );
      })}
      {isEditable && (experiences.length === 0 || isAddingExperience === true) && (
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          spacing={4}
          id="influencer-experiences"
        >
          <Grid item xs={6}>
            <TextField
              fullWidth
              inputProps={{ style: { padding: "8.5px 10px" } }}
              label="Marque"
              id="brand"
              name="brand"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
              disabled={isTourOpen}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="date"
              label="Date"
              variant="outlined"
              inputProps={{ style: { padding: "8.5px 10px" } }}
              InputLabelProps={{
                shrink: true,
              }}
              value={date}
              onChange={(e) => setDate(e.target.value)}
              disabled={isTourOpen}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="description"
              label="Description"
              fullWidth
              rows={5}
              multiline
              variant="outlined"
              inputProps={{ style: { padding: "8.5px 10px" } }}
              InputLabelProps={{
                shrink: true,
              }}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              disabled={isTourOpen}
            />
          </Grid>
          <Grid item container xs={12} justify="space-between">
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="secondary"
                style={{
                  fontFamily: "nunito-bold",
                  fontSize: 13,
                  color: "#FFF",
                  height: 40,
                  paddingLeft: 20,
                  paddingRight: 20,
                  borderRadius: 18,
                  textTransform: "none",
                  backgroundColor: "#825DFF",
                }}
                disabled={isTourOpen}
                onClick={() => {
                  if (isAddingExperience) {
                    handleUpdateInfluencerProfile({
                      experiences: [
                        ...experiences,
                        { brand, date, description },
                      ],
                    });
                  } else {
                    onUpdateExperience();
                  }
                  setIsAddingExperience(false);
                  setEditedExperience(undefined);
                  resetData();
                }}
              >
                Enregistrer
              </Button>
              <Button
                variant="contained"
                style={{
                  fontFamily: "nunito-bold",
                  fontSize: 13,
                  color: "#000",
                  height: 40,
                  paddingLeft: 20,
                  paddingRight: 20,
                  borderRadius: 18,
                  textTransform: "none",
                  marginLeft: isMobile ? 0 : 20,
                  marginTop: isMobile ? 20 : 0,
                }}
                disabled={isTourOpen}
                onClick={() => {
                  setIsAddingExperience(false);
                  setEditedExperience(undefined);
                  resetData();
                }}
              >
                Annuler
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

Experiences.propTypes = {};

export default Experiences;

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { makeStyles } from "@material-ui/styles";
import {
  Avatar,
  Button,
  Grid,
  CircularProgress,
  Chip,
} from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";

import settingsStyle from "./styles/settingsStyle";
import WithdrawMoneyModal from "./modals/WithdrawMoneyModal";

const useStyles = makeStyles(settingsStyle);

const WithdrawMoney = withRouter(
  ({ title, profile, handleUpdateMyProfile }) => {
    const classes = useStyles();
    const [totalMoney, setTotalMoney] = useState(0);
    const [isWithdrawMoneyModalOpen, setIsWithdrawMoneyModalOpen] =
      useState(false);

    useEffect(() => {
      const money = profile?.doneMissions?.reduce((acc, cur) => {
        if (!cur.isPaid) {
          return acc + cur.influencerMission.acceptedOffer.price;
        }
        return acc + 0;
      }, 0);

      setTotalMoney(money || 0);
    }, [profile]);

    if (!profile) return <CircularProgress />;

    return (
      <Grid container alignItems="flex-start">
        <Grid
          item
          container
          justify="space-between"
          style={{
            borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
            marginBottom: 30,
            paddingBottom: 10,
            height: 50,
          }}
        >
          <Grid item>
            <p
              style={{
                fontFamily: "nunito-bold",
                fontSize: 24,
                marginBottom: 20,
                display: "inline",
              }}
            >
              {title}
            </p>
          </Grid>
        </Grid>
        <Grid item container alignItems="flex-start" justify="space-between">
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <p style={{ marginBottom: 10 }}>Ma cagnotte totale</p>
            <p
              style={{
                padding: 15,
                border: "1px solid rgba(130,93,255,0.5)",
                borderRadius: 4,
                textAlign: "center",
              }}
            >
              {totalMoney} euros
            </p>
            <Button
              style={{
                fontFamily: "nunito-bold",
                fontSize: 13,
                color: "#FFF",
                width: "100%",
                height: 40,
                paddingLeft: 10,
                paddingRight: 10,
                borderRadius: 4,
                backgroundColor: totalMoney === 0 ? "grey" : "#825DFF",
                marginTop: 20,
                textTransform: "none",
              }}
              onClick={() => setIsWithdrawMoneyModalOpen(true)}
              disabled={totalMoney === 0}
            >
              Retirer mon argent
            </Button>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={8}
            lg={8}
            className={classes.doneMissions}
          >
            <p>Détails de missions</p>
            {profile?.doneMissions?.map((doneMission) => {
              const mission = doneMission.influencerMission;
              return (
                <Grid
                  key={doneMission._id}
                  item
                  container
                  style={{
                    border: "1px solid rgb(227, 227, 227)",
                    minHeight: 80,
                    borderRadius: 6,
                    width: "100%",
                    marginTop: 35,
                    background: "#FFF",
                    padding: 15,
                  }}
                >
                  <Grid item container>
                    <Grid item xs={4} sm={4} md={3} lg={3}>
                      <Avatar
                        style={{
                          width: 75,
                          height: 75,
                          "& > img": {
                            objectFit: "contain",
                          },
                        }}
                        src={mission?.brandId?.avatar}
                      ></Avatar>
                    </Grid>
                    <Grid item container xs={5} style={{ padding: 8 }}>
                      <Grid item xs={12}>
                        <p style={{ fontWeight: 500, fontSize: 16 }}>
                          {mission?.missionId?.title}
                        </p>
                      </Grid>
                      <Grid item xs={12}>
                        <p style={{ fontWeight: 400, fontSize: 12 }}>
                          {mission.acceptedOffer.price} €
                        </p>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={4}
                      alignItems="center"
                      justify="center"
                    >
                      <Chip
                        style={{
                          width: "90%",
                          backgroundColor: "rgba(130,93,255,0.6)",
                          color: "#FFF",
                        }}
                        label={doneMission.isPaid ? "Payée" : "À retirer"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <WithdrawMoneyModal
          isModalOpen={isWithdrawMoneyModalOpen}
          handleCloseModal={setIsWithdrawMoneyModalOpen}
          profile={profile}
          handleUpdateInfluencerProfile={handleUpdateMyProfile}
        />
      </Grid>
    );
  }
);

WithdrawMoney.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default WithdrawMoney;

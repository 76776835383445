import Showcase from "../pages/Showcase/Showcase";

const authRoutes = [
  {
    path: "/",
    name: "Showcase",
    component: Showcase,
  },
  { redirect: true, path: "/", pathTo: "/", name: "Showcase" },
];

export default authRoutes;

// ####################################
// // // Mission Page styles
// ###################################

const missionPageStyle = (theme) => ({
  pageLayout: {
    width: "70%",
    margin: "auto",
  },
  companyAvatar: {
    width: 150,
    height: 150,
    float: "right",
  },
  brandLabel: {
    fontWeight: 500,
    fontSize: 36,
    marginBottom: 10,
  },
  profileInfo: {
    height: 100,
    border: "1px solid #e0ded9",
    padding: "10px 15px",
    borderRadius: 6,
  },
  infoTitle: {
    padding: 5,
  },
  infoContent: {
    padding: 5,
  },
  widgetContainer: {
    marginBottom: 100,
    height: 300,
    backgroundColor: "#FFF",
    padding: 40,
    borderRadius: 6,
    position: "relative",
  },
  widgetTitle: {
    fontSize: 28,
    fontFamily: "nunito-bold",
    fontWeight: 700,
  },
  widgetTextContent: {
    fontSize: 17,
    fontWeight: 400,
    whiteSpace: "pre-line",
  },
  buttonSection: {
    marginTop: 20,
  },
  button: {
    textTransform: "capitalize",
  },
  missionContentContainer: {
    width: "80%",
    margin: "auto",
    overflow: "auto",
    borderRadius: 6,
  },
  sidenavMenu: {
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  },
  menuItem: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.14)",
    minHeight: 60,
    cursor: "pointer",
    "&:hover": {
      borderLeft: "4px solid rgba(130,93,255,1)",
    },
    display: "flex",
    width: "100%",
    textDecoration: "none",
    color: "#000",
  },
  selectedMenuItem: {
    cursor: "pointer",
    borderBottom: "1px solid rgba(0, 0, 0, 0.14)",
    minHeight: 60,
    borderLeft: "4px solid rgba(130,93,255,1)",
  },
  menuItemBrand: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.14)",
    minHeight: 60,
    cursor: "pointer",
    "&:hover": {
      borderLeft: "4px solid rgba(250, 128, 114,1)",
    },
    display: "flex",
    width: "100%",
    textDecoration: "none",
    color: "#000",
  },
  selectedMenuItemBrand: {
    cursor: "pointer",
    borderBottom: "1px solid rgba(0, 0, 0, 0.14)",
    minHeight: 60,
    borderLeft: "4px solid rgba(250, 128, 114,1)",
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
  },
  menuItemDisabled: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.14)",
    minHeight: 60,
    cursor: "default",
    display: "flex",
    width: "100%",
    textDecoration: "none",
    color: "#888",
  },

  // Influencer List
  contactListing: {
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    paddingTop: 30,
    maxHeight: "100%",
    overflow: "auto",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.25)",
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  influencerItem: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.14)",
    minHeight: 80,
    cursor: "pointer",
    "&:hover": {
      borderLeft: "4px solid rgba(130,93,255,1)",
    },
  },
  userAvatar: {
    width: 80,
    height: 80,
    margin: "auto",
  },
  userName: {
    fontSize: 18,
    fontWeight: 500,
  },
  influencerContent: {
    fontSize: 14,
    color: "rgba(0, 0, 0, 0.7)",
  },
  separator: {
    fontSize: 12,
    fontWeight: 600,
    color: "rgba(0, 0, 0, 1)",
    paddingLeft: 10,
    paddingRight: 10,
  },
});

export default missionPageStyle;
